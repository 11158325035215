import classNames from "classnames";
import { Currencies } from "interfaces/wallet";
import { formatToCurrency } from "utils";

import Text, { TextVariants } from "..";

import styles from "./styles.module.scss";

export enum CurrencySizes {
  DEFAULT = "default",
  SMALL = "small",
}

interface CurrencyProps {
  amount: number;
  color: string;
  ticker?: string | Currencies;
  withoutDecimals?: boolean;
  decimal?: number;
  className?: string;
  size?: CurrencySizes;
  signColor?: string;
  alignTicker?: "left" | "right";
}

// TODO: Move folder to components/common when we deprecate the old Currency
const Currency: React.FC<CurrencyProps> = ({
  amount,
  color,
  ticker = Currencies.ARS,
  withoutDecimals,
  decimal = 2,
  className = "currency",
  size = CurrencySizes.DEFAULT,
  signColor = "var(--slate800)",
  alignTicker,
}) => {
  const isUSD = ticker === Currencies.USD;
  const isReal = ticker === Currencies.BRL;
  const isCurrency = ticker === Currencies.ARS || isUSD || isReal;

  const isTickerAtLeft = (alignTicker && alignTicker === "left") ?? isCurrency;

  const getCurrencySymbol = () => {
    if (isUSD) return "US$";

    if (isReal) return "R$";

    if (isCurrency) return "$";

    return `${ticker} `;
  };

  const value = (
    formatToCurrency({
      value: amount,
      withoutDecimals,
      numberOfDecimals: decimal,
    }) || "0,0"
  )?.split(",");

  const defaultSize = size === CurrencySizes.DEFAULT;

  const currencyVariant = defaultSize
    ? TextVariants.RegularUpperValue
    : TextVariants.RegularUpperValueS;
  const numberVariant = defaultSize
    ? TextVariants.SemiboldUpperValue
    : TextVariants.SemiboldUpperValueS;

  return (
    <Text
      className={classNames(styles.currency, className)}
      variant={currencyVariant}
      color={signColor}
    >
      {isTickerAtLeft && getCurrencySymbol()}
      <Text
        variant={numberVariant}
        className={styles.integer}
        component="span"
        color={color}
      >
        {value[0]}
        {!withoutDecimals && (
          <>
            {","}
            <span className={styles.decimal}>{value[1]}</span>
          </>
        )}
      </Text>
      {!isTickerAtLeft && " " + getCurrencySymbol()}
    </Text>
  );
};

export default Currency;
