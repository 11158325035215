import { useEffect } from "react";

import type { FilterOrderType } from "context/ActivityProvider";
import { useAppState } from "context/AppStateProvider";
import { useSearchParams } from "react-router-dom";
import type { OrdersResponse } from "interfaces/orders/interface";

import TableRowHeader from "../components/TableRowHeader";
import TableRowDate from "../components/TableRowDate";
import { getFilteredOrders, formatDateToSpanish } from "../utils";
import OrderTableRow from "./OrderTableRow";
import OrderDetail from "../components/OrderDetail";
import BottomText from "../components/BottomText";

import styles from "./styles.module.scss";

interface OrdersTableProps {
  orderFilter: FilterOrderType;
  orders: OrdersResponse[];
}

const OrdersTable = ({ orderFilter, orders }: OrdersTableProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { openRightModalContent } = useAppState();

  const headerTable = [
    "Fecha",
    "Status",
    "Operación",
    "Especie",
    "Ejecutado/Restante",
    "Precio Promedio",
    "Total Operación",
  ];
  const rightAlignedRow = [4, 5, 6];

  const handleOpenRightModal = () => {
    openRightModalContent(<OrderDetail />, () => setSearchParams({}));
  };

  useEffect(() => {
    if (searchParams.get("id")) {
      handleOpenRightModal();
    }
  }, []);

  const onRowClick = (orderId: string) => {
    setSearchParams({ id: orderId });
    handleOpenRightModal();
  };

  return (
    <>
      <div className={styles.tableWrapper}>
        <div className={styles.desktopTableRow}>
          {headerTable.map((item, index) => (
            <TableRowHeader
              key={item}
              alignRight={rightAlignedRow.includes(index)}
              label={item}
            />
          ))}
        </div>
        {orders?.map(({ createdAt, orders }) => {
          const filteredOrders = getFilteredOrders(orderFilter, orders);

          return (
            <>
              {filteredOrders.length > 0 && (
                <TableRowDate date={formatDateToSpanish(createdAt)} />
              )}
              {filteredOrders.map((order, index) => (
                <OrderTableRow
                  onClick={onRowClick}
                  key={order.orderId}
                  order={order}
                  lastRow={index === filteredOrders.length - 1}
                />
              ))}
            </>
          );
        })}
      </div>
      <BottomText />
    </>
  );
};

export default OrdersTable;
