import { useAppSelector } from "hooks/redux";
import CommonLayout from "layouts/common";
import { RootState } from "store/store";
import ErrorContainer from "components/common/error-modal";
import mainRoutes from "router/routes";
import { useDeviceCheck } from "hooks/useDeviceCheck";

const NotFoundPage: React.FC = () => {
  const user = useAppSelector((state: RootState) => state.user.user);
  const { isMobile } = useDeviceCheck();

  const NotFoundContainer = (
    <ErrorContainer
      title="Parece que esta página no existe."
      subtitle="No pudimos encontrar la página que estás buscando."
      textPrimaryButton="Ir a la home"
      urlPrimaryButton={mainRoutes.home}
    />
  );

  return user && !isMobile ? (
    <CommonLayout>{NotFoundContainer}</CommonLayout>
  ) : (
    NotFoundContainer
  );
};

export default NotFoundPage;
