import { useState } from "react";

import Text, { TextVariants } from "components/common/Text";
import { ArrowUpRight } from "lucide-react";
import { UIModalSlider } from "components/common/modal-slider";
import classNames from "classnames";
import getPlatform from "utils/getPlatform";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { useAppState } from "context/AppStateProvider";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import { useAppDispatch } from "hooks/redux";

import StatusModalSlider from "./StatusModalSlider";

import styles from "./styles.module.scss";

const BottomText = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { isIOS } = getPlatform();
  const { isDesktop } = useDeviceCheck();
  const dispatch = useAppDispatch();
  const { openRightModalContent } = useAppState();

  const onClose = () => {
    setIsOpen(false);
  };

  const onClick = () => {
    if (isDesktop) {
      return openRightModalContent(
        <div className={styles.bottomSheet}>
          <StatusModalSlider onClose={() => dispatch(closeRightModal())} />
        </div>
      );
    }

    setIsOpen(true);
  };

  return (
    <>
      <div className={styles.bottomText}>
        <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
          No hay más órdenes por mostrar.
        </Text>
        <div className={styles.linkContainer} onClick={onClick}>
          <Text variant={TextVariants.SemiboldTextS} color="var(--blue800)">
            Estados de una orden
          </Text>
          <ArrowUpRight color="var(--blue800)" />
        </div>
      </div>
      <UIModalSlider
        open={isOpen}
        onToggleDisplay={onClose}
        classes={classNames(styles.bottomSheet, { [styles.ios]: isIOS })}
      >
        <StatusModalSlider onClose={onClose} />
      </UIModalSlider>
    </>
  );
};

export default BottomText;
