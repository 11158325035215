import { Receipt, BadgePercent } from "lucide-react";
import { useShippingPaymentMethodsQuery } from "apis/card/queries/useShippingPaymentMethods";

import InfoRow from "../../components/InfoRow";

const ShippingDescription: React.FC = () => {
  const { data: shippingData, isLoading } = useShippingPaymentMethodsQuery();

  if (isLoading) {
    return null;
  }

  if (shippingData?.isFreeShipping) {
    return (
      <InfoRow
        icon={BadgePercent}
        title="Tu tarjeta está 100% bonificada"
        subtitle="Por tiempo limitado, pedí tu tarjeta completamente gratis."
      />
    );
  }

  return (
    <InfoRow
      icon={Receipt}
      title="Abonar el costo del envío"
      subtitle="Solo te vamos a cobrar el costo del envío, tu primera tarjeta es sin cargo."
    />
  );
};

export default ShippingDescription;
