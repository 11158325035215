import { IconProps } from "../../BTC";

const AVAXIcon: React.FC<IconProps> = ({ size = 24, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <circle cx="12" cy="12" r="12" fill="#E84142" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7181 3.5622C12.3023 2.84409 11.6409 2.84409 11.2252 3.5622L3.67558 16.8567C3.25983 17.5842 3.59999 18.1606 4.43148 18.1606H8.22046C8.98581 18.1134 9.67558 17.7071 10.0913 17.0646L14.6551 9.1559C14.9858 8.47559 14.9858 7.67244 14.6551 6.99212L13.2945 4.60157L12.7181 3.5622ZM17.7354 12.3118C17.3197 11.5937 16.6488 11.5937 16.2331 12.3118L13.5968 16.8567C13.1906 17.5748 13.5307 18.1606 14.3528 18.1606H19.5685C20.4 18.1606 20.7402 17.5748 20.3244 16.8567L17.7354 12.3118Z"
      fill="white"
    />
  </svg>
);

export default AVAXIcon;
