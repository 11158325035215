import dayjs from "dayjs";
import "dayjs/locale/es-mx";

export const formatDateToHour = (isoDate: string) => {
  const date = new Date(isoDate);
  return date
    .toLocaleString("es-AR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZone: "America/Argentina/Buenos_Aires",
    })
    .replace(",", " -");
};

const formatDate = (date: string) => {
  const dateToCheck = dayjs(date);
  const today = dayjs();
  const yesterday = today.subtract(1, "day");

  if (dateToCheck.isSame(today, "day")) {
    return "Hoy";
  } else if (dateToCheck.isSame(yesterday, "day")) {
    return "Ayer";
  } else {
    return dayjs(date)
      .locale("es-mx")
      .format("DD MMM YYYY")
      .replace(/^(.{3})(.)/, (match, group1, group2) => {
        return group1 + group2.toUpperCase();
      });
  }
};

export default formatDate;
