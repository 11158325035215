import Success from "components/common/success";
import CommonLayout from "layouts/common";
import mainRoutes from "router/routes";

import "./index.scss";

export const SuccessView = () => {
  return (
    <div className="success-view-content">
      <CommonLayout hideBottomNavigation mobileHeaderTitle="">
        <Success
          subtitle="Tu orden fue cancelada con éxito, si se concretó parcialmente, vas a tener dos órdenes en tus movimientos del día."
          textPrimaryButton="Ir a Mercado"
          title="Orden cancelada con éxito"
          urlPrimaryButton={mainRoutes.market}
        />
      </CommonLayout>
    </div>
  );
};
