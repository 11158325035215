import { useState } from "react";

import TextBadge from "components/common/Badges/TextBadge";
import Text, { TextVariants } from "components/common/Text";
import { X } from "lucide-react";
import px2rem from "utils/px2rem";
import { useBalanceQuery } from "apis/portfolio/queries/useBalance";
import { Currencies } from "interfaces/wallet";
import { PeriodTime } from "interfaces/portfolio";
import { useAppSelector } from "hooks/redux";
import { getLoggedUser } from "store/selectors/user.selector";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import { useBanner } from "apis/banners/queries/useBanner";
import API from "apis";
import { BannersService } from "apis/services";
import dayjs from "dayjs";
import { MIN_GOLD_BALANCE } from "utils/constants";
import { Tier } from "interfaces/user";

import styles from "./styles.module.scss";

const GoldBanner: React.FC = () => {
  const user = useAppSelector(getLoggedUser);
  const navigate = useNavigate();
  const [isClosed, setIsClosed] = useState<true | false>(false);

  const { data } = useBalanceQuery({
    currency: Currencies.USD,
    period: PeriodTime.DAY,
  });

  const { data: bannerData } = useBanner("tier_upgrade_gold");

  if (!user) return null;

  const { account } = user;

  const isSuppressionExpired =
    !bannerData?.supressUntil ||
    new Date() >= new Date(bannerData.supressUntil);

  const hasBalanceForGold = !!(data && data.totalBalance > MIN_GOLD_BALANCE);

  const canRequestGold =
    (hasBalanceForGold && account?.tier === Tier.FREE) ||
    account?.proposeTierUpgrade;

  const shouldHidebanner = !isSuppressionExpired || isClosed || !canRequestGold;

  const onClickBanner = () => {
    navigate(mainRoutes.cocosGold);
  };

  const onClickCloseBanner = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setIsClosed(true);
    const supressUntil = dayjs().add(1, "year");

    API.put(`${BannersService.banners}/tier_upgrade_gold`, {
      supressUntil,
    });
  };

  if (shouldHidebanner || !bannerData) return null;

  return (
    <div className={styles.banner} onClick={onClickBanner}>
      <TextBadge text="Gold" variant="GOLD" />
      <Text
        variant={TextVariants.SemiboldTextS}
        color="var(--yellow800)"
        className={styles.text}
      >
        ¿Querés ser Gold?{" "}
        <Text
          variant={TextVariants.RegularTextS}
          component="span"
          color="var(--slate800)"
        >
          Conocé todos los beneficios de una cuenta exclusiva.
        </Text>
      </Text>
      <div className={styles.icon}>
        <X
          size={px2rem(20)}
          color="var(--yellow800)"
          onClick={onClickCloseBanner}
        />
      </div>
      <div className={styles.background} />
    </div>
  );
};

export default GoldBanner;
