import CommonLayout from "layouts/common";
import FixedHeader from "components/common/FixedHeader";
import NavigationHeader from "components/common/NavigationHeader";
import { useNavigate, useSearchParams } from "react-router-dom";
import mainRoutes from "router/routes";
import { ListRestart } from "lucide-react";
import px2rem from "utils/px2rem";
import { useOrders } from "apis/activity/queries/useOrders";

import OrdersContent from "./OrdersContent";
import OrderDetail from "../components/OrderDetail";

const OrdersMobile = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { refetch } = useOrders();

  const currentOrderId = searchParams.get("id");
  const mepOrderId = searchParams.get("mepOrderId");

  if (currentOrderId || mepOrderId) return <OrderDetail />;

  return (
    <CommonLayout withHeader={false}>
      <FixedHeader>
        <NavigationHeader
          title="Órdenes"
          onClick={() => navigate(mainRoutes.home)}
          withCloseIcon={false}
          rightIconClick={refetch}
          rightIcon={<ListRestart size={px2rem(24)} color="var(--blue900)" />}
        />
      </FixedHeader>
      <OrdersContent />
    </CommonLayout>
  );
};

export default OrdersMobile;
