import classNames from "classnames";
import { Currencies } from "interfaces/wallet";
import { formatToCurrency, getCurrencySymbol } from "utils";
import HiddenValue from "components/common/HiddenValue";
import { PaymentCurrencies } from "interfaces/pay/enums";

import { InstrumentMapping } from "../trading-container/components/trading-body/utils";

import styles from "./styles.module.scss";

export enum PriceLabelSizes {
  Default = "default",
  Large = "large",
  Small = "small",
  Xsmall = "xsmall",
  Medium = "medium",
}

enum HiddenValueSizes {
  Default = 18,
  Large = 32,
  Small = 10,
  Xsmall = 7,
  Medium = 14,
}

interface PriceLabelProps {
  price: number;
  color?: string;
  symbolColor?: string;
  currency?: Currencies | PaymentCurrencies;
  size?: PriceLabelSizes;
  type?: string;
  isBalanceHidden?: boolean;
  isArsSymbol?: boolean;
}

const PriceLabel: React.FC<PriceLabelProps> = ({
  price,
  isBalanceHidden,
  currency = Currencies.ARS,
  color = "var(--slate900)",
  size = PriceLabelSizes.Default,
  type,
  symbolColor = "var(--slate800)",
  isArsSymbol = false,
}) => {
  const absPrice = Math.abs(price);
  const isNegative = price < 0;
  const splittedPrice = (formatToCurrency({ value: absPrice }) || "0,00").split(
    ","
  );

  const integerPart = splittedPrice[0];
  const decimalPart = splittedPrice[1];

  const getHiddenValueSize: () => number = () => {
    if (size === PriceLabelSizes.Xsmall) return HiddenValueSizes.Xsmall;

    if (size === PriceLabelSizes.Small) return HiddenValueSizes.Small;

    if (size === PriceLabelSizes.Medium) return HiddenValueSizes.Medium;

    if (size === PriceLabelSizes.Large) return HiddenValueSizes.Large;

    return HiddenValueSizes.Default;
  };

  if (type === InstrumentMapping.LETRA_INTERNACIONAL.plural) return null;

  return (
    <div className={styles.divContainer}>
      <p
        className={classNames(styles.priceLabelContainer, styles[size])}
        style={{ color }}
      >
        {isBalanceHidden ? (
          <HiddenValue color={color} size={getHiddenValueSize()} />
        ) : (
          <>
            <span style={{ color: symbolColor }}>{isNegative && "-"}</span>
            <span className={styles.symbol} style={{ color: symbolColor }}>
              {getCurrencySymbol(currency, isArsSymbol)}{" "}
            </span>
            {integerPart},
            <span className={styles.decimal}>{decimalPart || "00"}</span>
          </>
        )}
      </p>
    </div>
  );
};

export default PriceLabel;
