import { IconProps } from "../../BTC";

const AAVEIcon: React.FC<IconProps> = ({ size = 24, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M24 12.0063C24 5.36693 18.6331 0 12.0063 0C5.37953 0 0 5.36693 0 12.0063C0 18.6457 5.36693 24.0126 12.0063 24.0126C18.6457 24.0126 24 18.6331 24 12.0063Z"
      fill="#9391F7"
    />
    <path
      d="M9.76379 12.5858C10.7969 12.422 11.4898 11.452 11.326 10.4189C11.1622 9.38582 10.1921 8.69291 9.15907 8.85669C8.126 9.02047 7.43308 9.99055 7.59686 11.0236C7.77324 12.0567 8.74332 12.7496 9.76379 12.5858ZM14.7024 12.5858C15.7354 12.422 16.4284 11.452 16.2646 10.4189C16.1008 9.38582 15.1307 8.69291 14.0976 8.85669C13.0646 9.02047 12.3717 9.99055 12.5354 11.0236C12.6992 12.0567 13.6693 12.7496 14.7024 12.5858Z"
      fill="white"
    />
    <path
      d="M11.9307 2.93543C6.80314 2.93543 2.64566 7.1685 2.64566 12.3843H5.01416C5.01416 8.46614 8.08818 5.30394 11.9181 5.30394C15.7606 5.30394 18.822 8.47874 18.822 12.3843H21.1905C21.2157 7.1685 17.0583 2.93543 11.9307 2.93543Z"
      fill="white"
    />
  </svg>
);

export default AAVEIcon;
