import FeatureFlags from "constants/featureFlags";

import ActivityPage from "components/page-activity";
import useFeatureFlag from "hooks/useFeatureFlag";
import { Navigate } from "react-router-dom";
import mainRoutes from "router/routes";

const Activity: React.FC = () => {
  const { isFeatureEnabled, isLoading } = useFeatureFlag(
    FeatureFlags.ENABLE_ACTIVITY_REDESIGN
  );

  if (isLoading) return null;

  if (!isFeatureEnabled) return <Navigate to={mainRoutes.home} />;

  return <ActivityPage />;
};

export default Activity;
