import ff from "constants/featureFlags";

import React, { useEffect, useState } from "react";

import {
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import API from "apis";
import { CircleCheckIcon } from "assets/icons/circle-check";
import { ClockIcon } from "assets/icons/clock";
import { DownloadIcon } from "assets/icons/download";
import { ErrorIcon } from "assets/icons/error";
import { ShareIcon } from "assets/icons/share";
import { ACTIVE_BID } from "components/common/bid/constants";
import { Currency } from "components/common/currency";
import ErrorContainer from "components/common/error-modal";
import WithdrawError from "components/page-orders/WithdrawError";
import dayjs from "dayjs";
import * as htmlToImage from "html-to-image";
import {
  ApiOrderResponse,
  OrderDetailResponse,
  OrderStatusTypes,
} from "interfaces/api-responses";
import { OrderType } from "interfaces/markets";
import CommonLayout from "layouts/common";
import { useParams } from "react-router-dom";
import {
  clearOrderDetail,
  getOrderById,
  getOrders,
  setCancelOrderError,
  setCancelOrderLoading,
} from "features/markets";
import { RootState } from "store/store";
import ButtonUI, {
  ButtonSizes,
  ButtonVariants,
} from "components/common/button";
import {
  InstrumentLogoUI,
  InstrumentLogoVariants,
} from "components/common/instument-logo";
import { getCurrencyLabel, getOrderStatus } from "utils";
import mainRoutes from "router/routes";
import { ordersService } from "apis/services";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import useFeatureFlag from "hooks/useFeatureFlag";

import { SuccessView } from "./success";

import "./index.scss";

type OrderParams = {
  id: string;
};

const OrderDetailPage: React.FC = () => {
  const { id } = useParams<OrderParams>();
  const dispatch = useAppDispatch();

  const orderLoading = useAppSelector(
    (state: RootState) => state.markets.getOrderLoading
  );
  const { orderDetail } = useAppSelector((state: RootState) => state.markets);

  const orderError = useAppSelector(
    (state: RootState) => state.markets.getOrderError
  );
  const [cancelSucceeded, setCancelSucceeded] = useState<boolean | undefined>();
  const cancelOrderLoading = useAppSelector(
    (state: RootState) => state.markets.cancelOrderLoading
  );
  const { isFeatureEnabled } = useFeatureFlag(ff.ENABLE_ACTIVITY_REDESIGN);

  const activityRoute = isFeatureEnabled
    ? mainRoutes.activity
    : mainRoutes.movements;

  const today = dayjs();

  useEffect(() => {
    if (id) {
      orderDetail?.order_type !== "WITHDRAWAL" && dispatch(getOrderById(id));
    }
  }, [id]);

  useEffect(() => {
    return () => {
      orderDetail?.order_type !== "WITHDRAWAL" && dispatch(clearOrderDetail());
    };
  }, []);

  const cancelOrder = async (order: OrderDetailResponse) => {
    try {
      dispatch(setCancelOrderError({ message: "", status: null }));
      dispatch(setCancelOrderLoading(true));
      await API.delete<ApiOrderResponse>(
        `${ordersService.orders}/${order.order_id}`,
        {
          data: { ticker: order.ticker, instrument: order.instrument },
        }
      );

      dispatch(getOrders());
      dispatch(clearOrderDetail());
      setCancelSucceeded(true);
      dispatch(setCancelOrderLoading(false));
    } catch (error: any) {
      if (error?.response?.status !== 401) {
        dispatch(setCancelOrderError(error));
        setCancelSucceeded(false);
        dispatch(setCancelOrderLoading(false));
      }
    }
  };

  const handleCancelOrder = async () => {
    if (orderDetail) {
      cancelOrder(orderDetail);
    }
  };

  const handleDownload = () => {
    const div = document.getElementById("order-container");

    if (div) {
      htmlToImage
        .toPng(div, { skipAutoScale: true, height: 500 })
        .then(function (dataUrl) {
          const link = document.createElement("a");

          link.download = `Orden ${orderDetail?.order_id} - ${today.format(
            "DD-MM-YYYY HH:mm"
          )}.png`;
          link.href = dataUrl;
          link.click();
        });
    }
  };

  const handleClickGoBack = () => {
    dispatch(setCancelOrderError({ message: "", status: null }));
  };

  const handleShare = () => {
    const div = document.getElementById("order-container");

    if (div) {
      htmlToImage
        .toBlob(div, { skipAutoScale: true, height: 500 })
        .then(async function (blob) {
          if (navigator && blob) {
            navigator.share({
              files: [
                new File(
                  [blob],
                  `Orden ${orderDetail?.order_id} - ${today.format(
                    "DD-MM-YYYY HH:mm"
                  )}.jpeg`,
                  {
                    type: "image/jpeg",
                    lastModified: new Date().getTime(),
                  }
                ),
              ],
            });
          } else if (blob) {
            navigator.clipboard.write([
              new ClipboardItem({
                [blob.type]: blob,
              }),
            ]);
          }
        });
    }
  };

  const textMinimizer = () => {
    if (
      orderDetail?.instrument_short_name &&
      orderDetail.instrument_short_name.length > 8
    ) {
      return "textXS";
    }

    return "textS";
  };

  if (orderError.message && !orderLoading && !orderDetail) {
    return (
      <ErrorContainer
        textPrimaryButton="Ir a movimientos"
        title="Ups...Algo salió mal"
        urlPrimaryButton={activityRoute}
      />
    );
  }

  if (cancelSucceeded) {
    return <SuccessView />;
  }

  if (orderLoading && !orderDetail) {
    return (
      <div className="pages-order-detail">
        <CommonLayout
          hideBottomNavigation
          withHeader
          mobileHeaderTitle="Detalle de orden"
        >
          {orderLoading && <CircularProgress />}
        </CommonLayout>
      </div>
    );
  }

  const getExecuted = () => {
    if (!orderDetail) return;

    if (
      orderDetail.status === OrderStatusTypes.EXECUTED ||
      orderDetail.status === OrderStatusTypes.PARTIALLY_EXECUTED
    )
      return orderDetail.result_quantity;

    if (
      orderDetail.status === OrderStatusTypes.CANCELLED ||
      orderDetail.status === OrderStatusTypes.PENDING
    )
      return 0;
  };

  const getLeftOvers = () => {
    if (!orderDetail) return;

    if (
      orderDetail.status === OrderStatusTypes.CANCELLED ||
      orderDetail.status === OrderStatusTypes.PENDING ||
      orderDetail.status === OrderStatusTypes.EXECUTED
    )
      return orderDetail.result_quantity;

    if (
      orderDetail.status === OrderStatusTypes.PARTIALLY_EXECUTED &&
      orderDetail.result_quantity
    )
      return orderDetail.set_quantity - orderDetail.result_quantity;
  };

  const isOrderExecutedToday = dayjs(orderDetail?.set_date).isSame(
    today,
    "day"
  );

  const showCancelButton =
    orderDetail?.order_type !== "WITHDRAWAL" &&
    (orderDetail?.status === OrderStatusTypes.PENDING ||
      orderDetail?.status === OrderStatusTypes.PARTIALLY_EXECUTED ||
      orderDetail?.status === OrderStatusTypes.MARKET) &&
    isOrderExecutedToday;

  const isActiveBid = orderDetail?.ticker === ACTIVE_BID.instrument_code;

  return (
    <div className="pages-order-detail">
      <CommonLayout
        hideBottomNavigation
        withHeader
        mobileHeaderTitle="Detalle de la orden"
      >
        {orderDetail && !orderError.message && cancelSucceeded === undefined && (
          <div className="order-wrapper">
            <IconButton
              aria-label="menu"
              className="button-icon"
              color="inherit"
              edge="start"
              onClick={handleShare}
            >
              <ShareIcon />
            </IconButton>
            <IconButton
              aria-label="menu"
              className="button-icon"
              color="inherit"
              edge="start"
              onClick={handleDownload}
            >
              <DownloadIcon />
            </IconButton>
            <div className="order-container" id="order-container">
              <div className="icons-wrapper">
                {(orderDetail.status === OrderStatusTypes.PENDING ||
                  orderDetail.status ===
                    OrderStatusTypes.PARTIALLY_EXECUTED) && (
                  <>
                    <div className="status-icon">
                      <ClockIcon />
                    </div>
                    <Typography>
                      {getOrderStatus(orderDetail.status).label}
                    </Typography>
                  </>
                )}
                {orderDetail.status === OrderStatusTypes.EXECUTED && (
                  <>
                    <div className="status-icon">
                      <CircleCheckIcon
                        color="#00C28F"
                        size={48}
                        thickness={4}
                      />
                    </div>
                    <Typography>
                      {getOrderStatus(orderDetail.status).label}
                    </Typography>
                  </>
                )}
                {orderDetail.status === OrderStatusTypes.CANCELLED ||
                  (orderDetail.status === OrderStatusTypes.REJECTED && (
                    <>
                      <div className="status-icon">
                        <ErrorIcon />
                      </div>
                      <Typography>
                        {getOrderStatus(orderDetail.status).label}
                      </Typography>
                    </>
                  ))}
              </div>
              <div className="border-order">
                <div className="grid-1">
                  <InstrumentLogoUI
                    logoFileName={orderDetail.logo_file_name}
                    placeholderStyle={
                      orderDetail.order_type === OrderType.Buy
                        ? "positive"
                        : "negative"
                    }
                    type={
                      orderDetail.order_type === "WITHDRAWAL"
                        ? "Extraccion"
                        : orderDetail.instrument_type
                    }
                    variant={InstrumentLogoVariants.Secondary}
                  />
                  <div className="text-ticker">
                    <Typography component="p" variant="captionS">
                      Especie
                    </Typography>
                    <span className="ticker-instrument">
                      {orderDetail.order_type !== "WITHDRAWAL" && (
                        <>
                          <Typography variant="textM_bold">
                            {orderDetail.ticker?.split("-")[0]}
                          </Typography>
                          <Divider orientation="vertical" />
                        </>
                      )}
                      <Typography variant={textMinimizer()}>
                        {orderDetail.order_type === "WITHDRAWAL"
                          ? "Extracción"
                          : orderDetail.instrument_short_name || ""}
                      </Typography>
                    </span>
                  </div>
                </div>
                {orderDetail.order_type !== "FUND" &&
                  orderDetail.order_type !== "REDEMPTION" && (
                    <div className="grid-2">
                      <span>
                        <Typography component="p" variant="captionS">
                          Cantidad
                        </Typography>
                        <Typography component="p" variant="textS_bold">
                          {isActiveBid ? "-" : <>{orderDetail.set_quantity}</>}
                        </Typography>
                      </span>
                      <Divider orientation="vertical" />
                      <span>
                        <Typography component="p" variant="captionS">
                          Ejecutadas
                        </Typography>
                        <Typography component="p" variant="textS_bold">
                          {getExecuted()}
                        </Typography>
                      </span>
                      <Divider orientation="vertical" />
                      <span>
                        <Typography component="p" variant="captionS">
                          {getOrderStatus(orderDetail.status).label}
                        </Typography>
                        <Typography component="p" variant="textS_bold">
                          <Currency withoutDecimals>{getLeftOvers()}</Currency>
                        </Typography>
                      </span>
                    </div>
                  )}
                <div className="price-amount border-amount">
                  {orderDetail.order_type !== "FUND" &&
                    orderDetail.order_type !== "REDEMPTION" && (
                      <div className="grid-3">
                        <Typography component="p" variant="captionS">
                          Precio
                        </Typography>
                        <Typography component="p" variant="textS_bold">
                          {isActiveBid ? (
                            "-"
                          ) : (
                            <>
                              {`${getCurrencyLabel(orderDetail.currency)} `}
                              <Currency>
                                {orderDetail.set_price ||
                                  orderDetail.result_price}
                              </Currency>
                            </>
                          )}
                        </Typography>
                      </div>
                    )}
                  <div
                    className={
                      orderDetail.order_type === "FUND" ||
                      orderDetail.order_type === "REDEMPTION"
                        ? "fci-background"
                        : "grid-4"
                    }
                  >
                    <Typography component="p" variant="captionS">
                      Monto total
                    </Typography>
                    <Typography component="p" variant="textM_bold">
                      {`${getCurrencyLabel(orderDetail.currency)} `}
                      <Currency>
                        {orderDetail.order_type === "CAUCION_COLOCADORA"
                          ? Math.abs(orderDetail.set_amount)
                          : orderDetail.set_amount || orderDetail.result_amount}
                      </Currency>
                    </Typography>
                  </div>
                </div>
                {orderDetail.order_type !== "FUND" &&
                  orderDetail.order_type !== "REDEMPTION" && (
                    <div className="price-amount">
                      <div className="grid-3">
                        <Typography component="p" variant="captionS">
                          Precio ejecución
                        </Typography>
                        <Typography component="p" variant="textS_bold">
                          {orderDetail.result_price === null || isActiveBid ? (
                            "-"
                          ) : (
                            <>
                              {`${getCurrencyLabel(orderDetail.currency)} `}
                              <Currency>{orderDetail.result_price}</Currency>
                            </>
                          )}
                        </Typography>
                      </div>
                      <div className="grid-4">
                        <Typography component="p" variant="captionS">
                          Monto ejecución
                        </Typography>
                        <Typography component="p" variant="textM_bold">
                          {orderDetail.result_amount === null ? (
                            "-"
                          ) : (
                            <>
                              {`${getCurrencyLabel(orderDetail.currency)} `}
                              <Currency>
                                {orderDetail.order_type === "CAUCION_COLOCADORA"
                                  ? Math.abs(orderDetail.set_amount)
                                  : orderDetail.result_amount}
                              </Currency>
                            </>
                          )}
                        </Typography>
                      </div>
                    </div>
                  )}
              </div>
              <div className="column-1">
                {orderDetail.status !== OrderStatusTypes.REJECTED ||
                orderDetail.order_type !== "WITHDRAWAL" ? (
                  <div className="date">
                    <Typography component="p" variant="textS">
                      Fecha
                    </Typography>
                    <Typography component="p" variant="textXS_bold">
                      {dayjs(orderDetail.set_date).format("DD/MM/YYYY")}
                    </Typography>
                  </div>
                ) : (
                  <WithdrawError error={orderDetail.error || ""} />
                )}
                <Divider />
              </div>
            </div>
            {showCancelButton && (
              <div className="buttons-wrapper order cancel">
                <ButtonUI
                  disabled={orderLoading || cancelOrderLoading}
                  size={ButtonSizes.Full}
                  variant={ButtonVariants.Primary}
                  onClick={handleCancelOrder}
                >
                  {orderLoading || cancelOrderLoading ? (
                    <CircularProgress />
                  ) : (
                    <Typography variant="buttonL">Cancelar orden</Typography>
                  )}
                </ButtonUI>
              </div>
            )}
          </div>
        )}
        {cancelSucceeded === false && (
          <ErrorContainer
            textPrimaryButton="Ir a movimientos"
            urlPrimaryButton={activityRoute}
            title="Tu orden no pudo ser cancelada"
            onClickPrimaryButton={handleClickGoBack}
          />
        )}
      </CommonLayout>
    </div>
  );
};

export default OrderDetailPage;
