import AssetSelectCard from "components/common/AssetSelectCard";
import Text, { TextVariants } from "components/common/Text";
import { PaymentCurrencies } from "interfaces/pay/enums";
import {
  PaymentCurrencyDetails,
  PaymentMethodSelected,
} from "interfaces/card/interface";
import { isEmpty } from "lodash";

import styles from "./styles.module.scss";

type MethodsListProps = {
  title: string;
  onClickAsset: (ticker: PaymentCurrencies) => void;
  assetList: PaymentCurrencyDetails[];
  paymentMethodSelected: PaymentMethodSelected;
  getIsDisabled: (ticker: PaymentCurrencies) => boolean;
};

const MethodsList: React.FC<MethodsListProps> = ({
  title,
  onClickAsset,
  assetList,
  paymentMethodSelected,
  getIsDisabled,
}) => {
  if (isEmpty(assetList)) return null;

  return (
    <>
      <Text variant={TextVariants.RegularText} className={styles.subtitle}>
        {title}
      </Text>
      <div className={styles.assetWrapper}>
        {assetList.map(({ ticker, name, icon }) => (
          <AssetSelectCard
            icon={icon}
            ticker={ticker}
            name={name}
            key={ticker}
            onClick={() => onClickAsset(ticker)}
            checked={paymentMethodSelected === ticker}
            disabled={getIsDisabled(ticker)}
          />
        ))}
      </div>
    </>
  );
};

export default MethodsList;
