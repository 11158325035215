import { useTrading } from "context/TradingProvider";
import { InstrumentData } from "interfaces/api-responses";
import { OrderType } from "interfaces/markets";
import { useAppSelector } from "hooks/redux";
import { screenNames } from "router/routes";
import { RootState } from "store/store";
import UIDropdownCard from "components/common/dropdown-card";
import {
  OverlappedButtonsSizes,
  OverlappedButtonsTypes,
  UIOverlappedButtons,
} from "components/common/overlapped-buttons";
import { trackAction } from "utils/amplitude";
import { ampli } from "ampli";

import TradingCardHidden from "./components/card-hidden";
import TradingCardVisible from "./components/card-visible";

import styles from "./styles.module.scss";

interface TradingHeaderProps {
  instrumentProp: InstrumentData;
}

const TradingHeader: React.FC<TradingHeaderProps> = ({ instrumentProp }) => {
  const { type, setType } = useTrading();
  const hasPricesData = instrumentProp.bids || instrumentProp.asks;
  const sideOptions = [
    {
      label: "Vender",
      value: OrderType.Sell,
    },
    {
      label: "Comprar",
      value: OrderType.Buy,
    },
  ];

  const tickerRules = useAppSelector(
    (state: RootState) => state.markets.tickersRulesData
  );

  const getNumOfDecimals = () => {
    let numOfDecimals = 2;

    if (
      tickerRules &&
      instrumentProp.id_tick_size_rule &&
      instrumentProp.tick_size
    ) {
      const { id_tick_size_rule, tick_size } = instrumentProp;
      const rule = tickerRules[id_tick_size_rule].find(
        (r) => tick_size === r.tick_size
      );
      if (rule) numOfDecimals = rule?.num_decimals;
    }
    return numOfDecimals;
  };

  const handleToggleOperation = (newType: string) => {
    trackAction(`${screenNames.trading} - Click button ${newType}`);
    setType(newType as OrderType);
  };

  return (
    <div className={styles.tradingHeader}>
      <UIDropdownCard
        expandLabel
        trackFunction={(expanded) => {
          if (!expanded) {
            ampli.orderBookOpened();
          }
        }}
      >
        <TradingCardVisible
          instrumentProp={instrumentProp}
          numOfDecimals={getNumOfDecimals()}
        />
        {hasPricesData && (
          <TradingCardHidden
            instrumentProp={instrumentProp}
            numOfDecimals={getNumOfDecimals()}
          />
        )}
      </UIDropdownCard>
      <UIOverlappedButtons
        styleClass={styles.operationToggle}
        options={sideOptions}
        selectedValue={type}
        size={OverlappedButtonsSizes.Full}
        type={OverlappedButtonsTypes.BuySell}
        onChangeValue={handleToggleOperation}
      />
    </div>
  );
};

export default TradingHeader;
