import DesktopOrdersContent from "components/page-orders/v2/desktop/OrdersContent";
import MobileOrdersContent from "components/page-orders/v2/mobile/OrdersContent";
import { useDeviceCheck } from "hooks/useDeviceCheck";

const Orders = () => {
  const { isDesktop } = useDeviceCheck();

  if (isDesktop) return <DesktopOrdersContent />;

  return <MobileOrdersContent />;
};

export default Orders;
