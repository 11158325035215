import API from "apis";
import { useAppQuery } from "apis/queryClient";
import { portfolioKeys } from "apis/queryKeyFactories";
import { portfolioService } from "apis/services";
import type { PeriodTime, BalanceResponse } from "interfaces/portfolio";
import type { BalanceCurrencies } from "interfaces/wallet";

const HALF_MINUTE = 30 * 1000;

interface useBalanceQueryProps {
  currency: BalanceCurrencies;
  period: PeriodTime;
}

export const fetchBalance = async ({
  currency,
  period,
}: useBalanceQueryProps) => {
  const { data } = await API.get<BalanceResponse>(portfolioService.balance, {
    params: {
      currency,
      period,
    },
  });

  return data;
};

export const useBalanceQuery = ({ currency, period }: useBalanceQueryProps) => {
  return useAppQuery({
    options: {
      queryKey: portfolioKeys.balance(currency, period),
      queryFn: async () => fetchBalance({ currency, period }),
      staleTime: HALF_MINUTE,
      gcTime: HALF_MINUTE,
    },
  });
};
