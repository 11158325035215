import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";

import { ThemeVariants } from "interfaces/theme";
import { LucideIcon } from "lucide-react";
import px2rem from "utils/px2rem";
import Text, { TextVariants } from "components/common/Text";

import SegmentedControl, { SegmentedControlProps } from "../SegmentedControl";
import Avatar from "../avatar";

import styles from "./styles.module.scss";

interface NavigationBarProps<T>
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  title: string;
  leftIcon?: LucideIcon;
  rightIcon?: LucideIcon;
  onClickLeft: () => void;
  onClickRight?: () => void;
  segmentedControlProps?: SegmentedControlProps<T>;
  variant?: ThemeVariants;
}

const NavigationBar = <T extends string>({
  title,
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  onClickLeft,
  onClickRight,
  segmentedControlProps,
}: NavigationBarProps<T>) => {
  return (
    <header className={styles.headerWrapper}>
      <div className={styles.navigationHeaderContainer}>
        <button onClick={onClickLeft}>
          {LeftIcon ? (
            <LeftIcon size={px2rem(24)} color="var(--slate900)" />
          ) : (
            <Avatar />
          )}
        </button>
        <div className={styles.titleWrapper}>
          <Text variant={TextVariants.RegularText} color="var(--slate800)">
            {title}
          </Text>
        </div>
        {RightIcon && (
          <div className={styles.rightIcon} onClick={onClickRight}>
            <RightIcon size={px2rem(24)} color="var(--slate900)" />
          </div>
        )}
      </div>
      {segmentedControlProps && (
        <div className={styles.tabControls}>
          <SegmentedControl {...segmentedControlProps} />
        </div>
      )}
    </header>
  );
};

export default NavigationBar;
