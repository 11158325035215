import { useState } from "react";

import API from "apis";
import { ampli } from "ampli";
import { transfersService } from "apis/services";
import { WithdrawResponse } from "interfaces/api-responses";
import {
  useCapitalSendAccounts,
  AccountsSteps,
} from "context/CapitalSendAccountsProvider";
import classNames from "classnames";
import getPlatform from "utils/getPlatform";
import { UIModalSlider } from "components/common/modal-slider";
import OutlinedButton from "components/common/OutlinedButton";
import FilledButton from "components/common/FilledButton";
import { queryClient } from "apis/queryClient";
import { activityKeys } from "apis/queryKeyFactories";

import DetailsHeader from "./DetailsHeader";
import DetailsBody from "./DetailsBody";

import styles from "./styles.module.scss";

interface ConfirmWithdrawSheetProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  bankAccount: any;
}

const ConfirmWithdrawSheet: React.FC<ConfirmWithdrawSheetProps> = ({
  isOpen,
  setIsOpen,
  bankAccount,
}) => {
  const { amount, currency, setStep, isDesktop } = useCapitalSendAccounts();
  const { isIOS } = getPlatform();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onConfirm = async () => {
    ampli.withdrawOrderConfirmed({ amount, currency });
    setIsLoading(true);

    try {
      await API.post<WithdrawResponse>(transfersService.withdraw, {
        order: "1",
        currency: currency,
        amount: amount,
        id_bank_account: bankAccount.id_bank_account,
      });
      setStep(AccountsSteps.SUCCESS);
      queryClient.invalidateQueries({ queryKey: activityKeys.orders });
      ampli.withdrawOrderSuccess({ amount, currency });
    } catch (error: any) {
      setStep(AccountsSteps.ERROR);
      ampli.withdrawOrderError({ amount, currency });
    } finally {
      setIsLoading(false);
    }
  };

  const onClickBack = () => {
    ampli.withdrawOrderCanceled({ amount, currency });
    setIsOpen(false);
  };

  return (
    <UIModalSlider
      open={isOpen}
      onToggleDisplay={onClickBack}
      classes={classNames(styles.sheetWrapper, { [styles.desktop]: isDesktop })}
    >
      <DetailsHeader amount={amount} currency={currency} />
      <DetailsBody bankAccount={bankAccount} />
      <div
        className={classNames(styles.buttonsWrapper, { [styles.ios]: isIOS })}
      >
        <OutlinedButton onClick={onClickBack}>Volver</OutlinedButton>
        <FilledButton onClick={onConfirm} isLoading={isLoading}>
          Confirmar
        </FilledButton>
      </div>
    </UIModalSlider>
  );
};

export default ConfirmWithdrawSheet;
