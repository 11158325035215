import { useMutation } from "@tanstack/react-query";
import API from "apis";
import { queryClient } from "apis/queryClient";
import { activityKeys } from "apis/queryKeyFactories";
import { ordersService } from "apis/services";
import type { ApiOrderResponse } from "interfaces/api-responses";
import type { ErrorMessage } from "interfaces/errors";

export const useCancelOrder = () => {
  const cancelMutation = useMutation({
    mutationFn: async (orderId: string) => {
      return await API.delete<ApiOrderResponse>(
        `${ordersService.orders}/${orderId}`
      );
    },
    onError: (err: ErrorMessage) => {
      return {
        message: err.message,
        status: 500,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: activityKeys.orders });
    },
  });

  const cancelOrder = (orderId: string) => cancelMutation.mutateAsync(orderId);

  return {
    cancelOrder,
    isLoading: cancelMutation.isPending,
    isSuccess: cancelMutation.isSuccess,
    isError: cancelMutation.isError,
  };
};
