import Text, { TextVariants } from "components/common/Text";
import { ThemeVariants } from "interfaces/theme";
import classNames from "classnames";

import styles from "./styles.module.scss";

type TextBadgeVariants = ThemeVariants | "GOLD";
interface TextBadgeProps {
  text: string;
  variant: TextBadgeVariants;
}

const TextBadge: React.FC<TextBadgeProps> = ({ text, variant }) => {
  return (
    <Text
      variant={TextVariants.SemiboldValueXS}
      className={classNames(styles.badge, styles[variant])}
    >
      {text}
    </Text>
  );
};

export default TextBadge;
