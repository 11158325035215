import Icon, { IconSizes, IconVariants } from "components/common/Icon";
import PageHeader from "components/common/PageHeader";
import PriceLabel, { PriceLabelSizes } from "components/common/PriceLabel";
import { useCocosPay } from "context/CocosPayProvider";
import { PaySource, PaySteps, Payment } from "interfaces/pay/enums";
import { ArrowLeft, Receipt } from "lucide-react";
import { useNavigate } from "react-router-dom";
import Text, { TextVariants } from "components/common/Text";
import { Currencies } from "interfaces/wallet";
import { formatToCurrency, getCurrencySymbol } from "utils";

import styles from "./styles.module.scss";

export interface MethodHeaderProps {
  balance: boolean;
  isCapital?: boolean;
}

const MethodHeader: React.FC<MethodHeaderProps> = ({ balance, isCapital }) => {
  const navigate = useNavigate();
  const { paymentData, setStep, source, pixPrices } = useCocosPay();

  const isBridge = source === PaySource.BRIDGE;

  const { quantity, currency, transactionCurrency } = paymentData;

  const isBR = transactionCurrency === Currencies.BRL;

  const getIconVariant = () => {
    if (isCapital) {
      return IconVariants.INFO;
    }

    if (isBridge) {
      return IconVariants.CRYPTO;
    }

    return IconVariants.PAY;
  };

  const iconVariant = balance ? getIconVariant() : IconVariants.FAIL;

  const handleOnClick = () => {
    if (isBridge) navigate(-1);
    if (paymentData.paymentType === Payment.CLOSE) {
      return setStep(paymentData.isChave ? PaySteps.CODE : PaySteps.QR);
    }
    return setStep(PaySteps.AMOUNT);
  };

  return (
    <div className={styles.methodHeaderContainer}>
      <PageHeader
        className={styles.header}
        icon={ArrowLeft}
        withIconTitle
        iconColor="var(--black-to-white)"
        iconTitle={
          <Icon size={IconSizes.SMALL} variant={iconVariant} icon={Receipt} />
        }
        onClick={handleOnClick}
      />
      <PriceLabel
        currency={currency}
        price={quantity}
        size={PriceLabelSizes.Large}
      />
      {isBR && pixPrices && (
        <Text
          className={styles.exchangeRate}
          color="var(--slate800)"
          variant={TextVariants.RegularUpperValueS}
        >
          {getCurrencySymbol(Currencies.BRL)}
          <Text
            component="span"
            color="var(--slate900)"
            variant={TextVariants.SemiboldUpperValueS}
          >
            {" 1 = "}
          </Text>
          {getCurrencySymbol(Currencies.ARS)}&nbsp;
          <Text
            component="span"
            color="var(--slate900)"
            variant={TextVariants.SemiboldUpperValueS}
          >
            {formatToCurrency({ value: pixPrices.brlToArsRate })}
          </Text>
        </Text>
      )}
    </div>
  );
};

export default MethodHeader;
