import { ampli } from "ampli";
import { useCardUserQuery } from "apis/card/queries/useCardUser";
import classNames from "classnames";
import Text, { TextVariants } from "components/common/Text";
import { useAppSelector } from "hooks/redux";
import { CardTypes } from "interfaces/card/enums";
import { StatusOnboarding } from "interfaces/createAccount";
import { ThemeVariants } from "interfaces/theme";
import { ArrowUpRight, Globe, Store } from "lucide-react";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import { Projects } from "utils/amplitude";
import px2rem from "utils/px2rem";

import CardBanner from "./CardBanner";

import styles from "./styles.module.scss";

interface CardSectionProps {
  setIsNoAccountSheetOpen: (isOpen: boolean) => void;
  variant?: ThemeVariants;
  referral?: Projects;
}

const CardSection: React.FC<CardSectionProps> = ({
  setIsNoAccountSheetOpen,
  referral = Projects.CAPITAL,
  variant = ThemeVariants.Capital,
}) => {
  const navigate = useNavigate();
  const { data, isLoading, isError } = useCardUserQuery();

  const noCards = !data || (data && data.cards.length < 1);

  const sortedCards = noCards
    ? []
    : data?.cards.sort((a) => (a.cardType === "PHYSICAL" ? -1 : 1));

  const statusOnboarding = useAppSelector(
    (state) => state.user.user?.statusOnboarding
  );

  const isDisabled = statusOnboarding !== StatusOnboarding.APPROVED;

  const arrowColor = variant === ThemeVariants.Capital ? "#5EB1EF" : "#BE93E4";

  const getLastFourDigits = (cardNumber: string) => {
    return `**** ${cardNumber}`;
  };

  const handleOnClick = (cardType?: CardTypes) => {
    ampli.homeTapCard({ referral });
    if (isDisabled) {
      ampli.onboardingBottomsheetViewed({ referral: Projects.CARD });
      setIsNoAccountSheetOpen(true);
      return;
    }

    const routeToNavigateTo = cardType
      ? mainRoutes.card + `?cardType=${cardType}`
      : mainRoutes.card;

    ampli.homeExited({ referral: Projects.CARD });

    navigate(routeToNavigateTo);
  };

  if (isLoading || isError) return null;

  return (
    <div
      className={classNames([
        styles.bannerWrapper,
        { [styles.noCards]: noCards },
      ])}
    >
      {!noCards && (
        <div className={styles.cards} onClick={() => handleOnClick()}>
          <ArrowUpRight color={arrowColor} size={px2rem(20)} />
          <Text variant={TextVariants.RegularTextS} color="white">
            Tarjetas
          </Text>
        </div>
      )}

      {noCards && (
        <div className={styles.cardWrapper}>
          <CardBanner
            mainContent="Obtené tu tarjeta física"
            secondaryContent="¡Disfrutá de todos sus beneficios!"
            onClick={() => handleOnClick()}
            variant={variant}
          />
        </div>
      )}
      {!noCards && (
        <div className={styles.cardList}>
          {sortedCards
            .filter((card) => card.lastFour)
            .map((cardData) => {
              const isPhysical = cardData.cardType === CardTypes.PHYSICAL;
              return (
                <div className={styles.cardWrapper} key={cardData.lastFour}>
                  <CardBanner
                    mainContent={getLastFourDigits(cardData.lastFour)}
                    secondaryContent={
                      <>
                        {isPhysical ? (
                          <Store size={px2rem(12)} />
                        ) : (
                          <Globe size={px2rem(12)} />
                        )}
                        {isPhysical ? "Física" : "Virtual"}
                      </>
                    }
                    onClick={() => handleOnClick(cardData.cardType)}
                    cardType={cardData.cardType}
                    variant={variant}
                  />
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default CardSection;
