import { Cryptos, getCryptoElement } from "constants/cryptos";

import ARGFlag from "assets/icons/ARGFlag";
import { Operations } from "interfaces/crypto/enums";
import { Currencies } from "interfaces/wallet";
import { ArrowRight } from "lucide-react";
import px2rem from "utils/px2rem";
import classNames from "classnames";

import styles from "./styles.module.scss";

type SheetIconsProps = {
  baseTicker: Cryptos;
} & (BuyAndSellIcon | SwapIcon | SendAndRecevieIcon);

type BuyAndSellIcon = {
  quoteTicker: Currencies.ARS;
  operation: Operations.BUY | Operations.SELL;
};

type SwapIcon = {
  operation: Operations.SWAP;
  quoteTicker: Cryptos;
};

type SendAndRecevieIcon = {
  operation: Operations.RECEIVE | Operations.SEND;
};

const SheetIcons: React.FC<SheetIconsProps> = (props) => {
  const { operation, baseTicker } = props;
  const BaseIcon = getCryptoElement(baseTicker).icon;
  const arrowSize = px2rem(16);
  const arrowColor = "var(--purple900)";
  const iconSize = px2rem(32);
  const isSell = operation === Operations.SELL;

  if (operation === Operations.BUY || isSell) {
    return (
      <div
        className={classNames(styles.twoIconsWrapper, {
          [styles.sell]: isSell,
        })}
      >
        <ARGFlag size={iconSize} />
        <ArrowRight size={arrowSize} color={arrowColor} />
        <BaseIcon size={iconSize} />
      </div>
    );
  }

  if (operation === Operations.SWAP) {
    const QuoteIcon = getCryptoElement(props.quoteTicker).icon;
    return (
      <div className={styles.twoIconsWrapper}>
        <BaseIcon size={iconSize} />
        <ArrowRight size={arrowSize} color={arrowColor} />
        <QuoteIcon size={iconSize} />
      </div>
    );
  }
  return <BaseIcon size={iconSize} />;
};

export default SheetIcons;
