import { getCryptoElement } from "constants/cryptos";

import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";
import { CryptoHolding } from "interfaces/api-responses";
import { isEmpty } from "lodash";
import { getColor } from "utils/getColor";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface PieChartProps {
  holdings: CryptoHolding[];
  size?: number;
  thickness?: number;
  innerCircleColor?: string;
  hasCenter?: boolean;
}

const PieChart: React.FC<PieChartProps> = ({
  size = 64,
  thickness = 12,
  holdings,
  innerCircleColor = "var(--background-1)",
  hasCenter,
}) => {
  const chartSize = px2rem(size);

  const totalAmount = holdings.reduce(
    (acc, { amountArs }) => acc + amountArs,
    0
  );

  const holdingsWithRatio = holdings.map(({ ticker, amountArs }) => {
    return {
      ticker,
      ratio: amountArs / totalAmount,
    };
  });

  const getPieChartGradientBackground = () => {
    if (isEmpty(holdings)) {
      const emptyColor = getColor("--slate400");
      return `conic-gradient(${emptyColor}, ${emptyColor})`;
    }
    if (holdings.length === 1) {
      const circleColor = getCryptoElement(holdings[0].ticker).color;

      return `conic-gradient(${circleColor}, ${circleColor})`;
    }

    const gradientDegrees = holdingsWithRatio.reduce(
      ({ degree, gradient }, { ticker, ratio }) => {
        const TOTAL_DEGREES = 360;
        const SPACE = 4;

        const degreeToRatio = (TOTAL_DEGREES - SPACE * holdings.length) * ratio;
        const { color } = getCryptoElement(ticker);
        const newEnd = degree + degreeToRatio + SPACE;

        const newGradient =
          gradient +
          ` ${innerCircleColor} 0 ${degree + SPACE / 2}deg, ${color} 0 ${
            degree + degreeToRatio + SPACE / 2
          }deg,  ${innerCircleColor} 0 ${degree + degreeToRatio + SPACE}deg, `;

        return {
          degree: newEnd,
          gradient: newGradient,
        };
      },
      { degree: 0, gradient: "" }
    );

    // Remove the last two characters ", "
    const finalGradient = gradientDegrees.gradient.slice(0, -2);

    return `conic-gradient(${finalGradient})`;
  };

  return (
    <div
      className={styles.pieChartWrapper}
      style={{
        width: chartSize,
        height: chartSize,
        backgroundColor: innerCircleColor,
      }}
    >
      <div
        className={styles.pieChart}
        style={{
          width: chartSize,
          height: chartSize,
          backgroundImage: getPieChartGradientBackground(),
          backgroundColor: innerCircleColor,
        }}
      />
      <div
        className={styles.innerCircle}
        style={{
          width: `${(size - thickness) / 16}rem`,
          height: `${(size - thickness) / 16}rem`,
          backgroundColor: innerCircleColor,
        }}
      />
      {hasCenter && (
        <div className={styles.pieChartCenter}>
          <Heading
            variant={HeadingVariants.SemiboldValue}
            component="h2"
            color="var(--slate900)"
          >
            {holdings.length}
          </Heading>
          <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
            activos
          </Text>
        </div>
      )}
    </div>
  );
};

export default PieChart;
