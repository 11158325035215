const CARP = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M112 56C112 86.9279 86.9279 112 56 112C25.0721 112 0 86.9279 0 56C0 25.0721 25.0721 0 56 0C86.9279 0 112 25.0721 112 56Z"
        fill="white"
      />
      <path
        d="M54.4578 9.06723C54.2389 9.17661 54.1732 18.7799 54.1732 56.0992V103H57.1941C58.8359 103 60.5652 102.934 61.003 102.869L61.8348 102.716L61.8786 91.3623L61.9443 80.009L63.6298 83.9028C67.5263 92.9155 70.8098 100.441 70.9193 100.55C71.2038 100.834 78.3838 97.7062 78.1868 97.378C78.0773 97.2249 72.2108 84.5153 71.0287 81.8903C70.766 81.2778 70.1531 79.9871 69.6934 78.9809C69.2118 77.9965 68.8397 77.1433 68.8397 77.0996C68.8397 77.0558 69.4307 76.6183 70.1312 76.1152C72.167 74.6933 73.962 72.6151 74.7938 70.6682L75.5162 68.9838V43.0615C75.5162 17.6861 75.5162 17.1174 75.0784 15.958C73.8306 12.5454 70.4377 10.7298 63.3233 9.61412C60.6527 9.19849 54.9613 8.84848 54.4578 9.06723ZM65.4467 18.9111L67.7452 19.4799V66.4244L66.5412 67.5182C65.3154 68.6338 63.2139 70.012 62.3383 70.2963L61.8348 70.4276V18.3424H62.4915C62.8636 18.3424 64.199 18.6049 65.4467 18.9111Z"
        fill="black"
      />
      <path
        d="M47.6499 9.72349C31.2542 12.6985 17.4414 24.3362 11.75 39.999C10.7868 42.624 10.0207 45.5553 9.4953 48.7491C8.83859 52.5336 8.83859 59.7087 9.47341 63.4056C12.4286 80.4246 23.3956 93.878 39.3317 100.025C41.8709 101.009 46.1395 102.169 48.2191 102.453L49.3574 102.606V93.8561L47.4092 93.3967C39.9884 91.6467 33.5308 87.9498 28.2333 82.3934C15.537 69.0932 14.0922 48.8366 24.7528 33.8301C27.1826 30.3957 31.8233 25.9988 35.3914 23.7237C38.8063 21.5581 43.7097 19.5237 47.5624 18.6705L49.3574 18.2768V13.8142C49.3574 11.386 49.3355 9.39536 49.3136 9.39536C49.2698 9.41724 48.5256 9.57036 47.6499 9.72349Z"
        fill="black"
      />
      <path
        d="M80.2226 56.0774C80.2226 78.1496 80.2883 96.2186 80.3539 96.2186C80.6604 96.2186 84.1628 93.7467 86.0892 92.1717L88.1031 90.5092V81.1903L89.8105 80.2059C93.0065 78.3246 96.4651 75.1089 98.5447 72.0464C100.296 69.4651 101.85 65.5275 102.529 62.0056C103.098 59.0743 103.164 53.1461 102.66 49.646C101.478 41.4428 98.1288 33.5895 93.0721 27.0925C91.5617 25.1675 87.906 21.4268 85.9578 19.8299C84.2504 18.4299 80.7042 15.9361 80.3977 15.9361C80.3101 15.9361 80.2226 34.0051 80.2226 56.0774ZM90.3796 39.2333C92.9408 44.4178 94.0353 48.7054 94.3637 54.6555C94.6045 58.943 93.707 63.4494 91.9995 66.4244C91.2771 67.6932 89.4603 69.7057 88.5846 70.1651L88.1031 70.4276V35.2958L88.5409 35.8427C88.7817 36.1489 89.6135 37.6583 90.3796 39.2333Z"
        fill="black"
      />
      <path
        d="M47.6499 23.6143C41.1486 25.2769 34.6253 29.4332 30.2472 34.7051C19.7618 47.2835 19.9369 65.3525 30.6631 77.8433C32.1736 79.6152 33.684 81.0371 35.7417 82.634L36.6611 83.3559V71.2589L35.8292 70.012C33.8591 67.1025 32.5895 64.1931 31.9984 61.3056L31.692 59.9056H41.6958V86.3091L42.7465 86.7685C44.257 87.4466 47.3654 88.4748 48.438 88.6716L49.3574 88.8248V55.9899C49.3574 37.9208 49.3355 23.155 49.3136 23.1768C49.2698 23.1768 48.5256 23.3737 47.6499 23.6143ZM41.6958 44.0459V52.0304H31.8233L31.9766 50.9804C32.4581 47.7429 34.6909 43.1928 37.4053 39.9771C38.2153 39.0365 41.4112 36.0614 41.652 36.0614C41.6739 36.0614 41.6958 39.649 41.6958 44.0459Z"
        fill="black"
      />
    </svg>
  );
};

export default CARP;
