import API from "apis";
import { useAppQuery } from "apis/queryClient";
import { cardKeys } from "apis/queryKeyFactories";
import { cardService } from "apis/services";
import {
  PaymentMethod,
  PaymentMethodsResponse,
} from "interfaces/pay/interface";
import { Currencies } from "interfaces/wallet";

const TWO_HOURS = 2 * 60 * 60 * 1000;

const fetchShippingPaymentMethodsData = async () => {
  const { data } = await API.get<PaymentMethodsResponse>(
    cardService.shippingPaymentMethod
  );

  return data;
};

export const useShippingPaymentMethodsQuery = () => {
  return useAppQuery({
    options: {
      queryKey: cardKeys.shippingPaymentMethods,
      queryFn: fetchShippingPaymentMethodsData,
      retry: false,
      staleTime: TWO_HOURS,
      gcTime: TWO_HOURS,
      select: (shippingData) => {
        const shippingAmount =
          shippingData.paymentMethods.find(
            (spm: PaymentMethod) => spm.name === Currencies.ARS
          )?.amount || 0;
        const isFreeShipping = shippingAmount === 0;

        return {
          ...shippingData,
          shippingAmount,
          isFreeShipping,
        };
      },
    },
  });
};
