import { Typography } from "@mui/material";
import { formatToCurrency } from "utils";

import styles from "./styles.module.scss";

interface PropTypes {
  price: number;
  currency: string;
  numOfDecimals: number;
}

const UIPriceLabel = (props: PropTypes) => {
  const { price, currency, numOfDecimals } = props;

  const formatPrice = () => {
    const priceToString = formatToCurrency({
      value: price,
      numberOfDecimals: numOfDecimals,
    });

    const priceSplitted = (priceToString ?? "0,0").split(",");
    return (
      <Typography className={styles.integerPart}>
        {priceSplitted[0]},
        <Typography className={styles.decimalPart} component={"span"}>
          {priceSplitted[1]}
        </Typography>
        <Typography className={styles.currency} component={"span"}>
          &nbsp;
          {currency}
        </Typography>
      </Typography>
    );
  };

  return formatPrice();
};

export default UIPriceLabel;
