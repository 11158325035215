import { CocosFunds } from "constants/instruments";

import { useEffect } from "react";

import { Spinner } from "components/common/spinner";
import { useFundsHoldings } from "apis/fci/queries/useFundsHoldings";
import { useFunds } from "context/FundsProvider";
import type { CocosFund } from "components/page-capital-funds/helpers";
import { ampli } from "ampli";
import GoogleTagManager from "react-gtm-module";
import { FbEvents, trackFbEvent } from "utils/fbTracking";
import type { FundsHoldingResponse } from "interfaces/fci";

import FundsHeader from "./FundsHeader";
import FundsChart from "./FundsChart";
import FundsMovements from "./FundsMovements";

import styles from "./styles.module.scss";

interface HoldingsTabProps {
  holdings: FundsHoldingResponse;
  selectedFund: CocosFund;
}

const HoldingsTab = ({ selectedFund, holdings }: HoldingsTabProps) => {
  const { offset, selectedPeriod: period } = useFunds();
  const {
    data: holdingResponse,
    isLoading: isHoldingsLoading,
    isError,
  } = useFundsHoldings({
    securityId: selectedFund.securityId,
    period,
    offset,
  });

  const isChartLoading = isHoldingsLoading || !holdingResponse || isError;

  useEffect(() => {
    ampli.fciScreenViewed({ tab: "Returns", ticker: selectedFund.ticker });

    GoogleTagManager.dataLayer({
      dataLayer: {
        event: "fci_screen_viewed",
        ticker: selectedFund.ticker,
      },
    });

    if (selectedFund.ticker === CocosFunds.RMA) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.fbq("track", "cocorma_fci_screen_viewed");

      trackFbEvent(FbEvents.COCORMA_SCREEN_VIEWED);
    }
  }, []);

  return (
    <>
      <FundsHeader fund={selectedFund} holding={holdings?.holding} />
      {isChartLoading ? (
        <div className={styles.chartLoadingWrapper}>
          <Spinner />
        </div>
      ) : (
        <FundsChart
          rate={selectedFund.rate}
          maxOffset={holdingResponse.maxOffset}
          historicalProfits={holdingResponse.historicalProfits}
          periodProfit={holdingResponse.periodProfit}
        />
      )}
      <FundsMovements dailyMovements={holdings.dailyMovements} />
    </>
  );
};

export default HoldingsTab;
