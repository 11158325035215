import { ACADEMY_URL } from "constants/index";

enum mainRoutes {
  accounts = "/accounts",
  altas = "/altas",
  bid = "/bid",
  calculator = "/calculadora",
  calculatorResult = "/calculadora/resultado",
  card = "/card",
  cardTransactions = "/card/transactions",
  cardSettings = "/card/settings",
  cardIssues = "/card/issues",
  cardIssuesMovements = "/card/issues/movements",
  cardHelp = "/card/help",
  cardLimits = "/card/limits",
  cardPin = "/card/pin",
  changePassword = "/change-password",
  chat = "/chat",
  cocosGold = "/cocos-gold",
  cocos2FA = "/2FA",
  createAccount = "/create-account",
  crypto = "/crypto",
  emailConfirmation = "/email-confirmation",
  emailValidation = "/email-validation",
  enableBiometry = "/enable-biometry",
  faqs = "/faqs",
  funds = "/funds",
  forgotPassword = "/forgot-password",
  generatePassword = "/generate-password",
  help = "/help",
  home = "/",
  investment = "/suggested-investment",
  login = "/login",
  logout = "/logout",
  maintenance = "/maintenance",
  market = "/market",
  marketMep = "/MEP",
  movementReceipt = "/movements/receipt",
  movements = "/movements",
  activity = "/activity",
  orderConfirm = "/order-confirm",
  orderDetail = "/market/order-detail",
  orders = "/orders",
  pay = "/pay",
  personalData = "/profile/personal-data",
  phoneValidation = "/phone-validation",
  portfolio = "/capital-portfolio",
  profile = "/profile",
  profileConfig = "/profile/config",
  profileLearn = "/profile/learn",
  profileLegals = "/profile/legals",
  profileTest = "/profile/test",
  receive = "/receive",
  resetPassword = "/reset-password",
  security = "/security",
  send = "/send",
  signUp = "/sign-up",
  trustedDevice = "/trusted-device",
  donate = "/donate",
}

export default mainRoutes;

export const screenNames = {
  login: "Login",
  home: "Home",
  mep: "Dolar Mep",
  profile: "Profile",
  caucion: "Caucion",
  fci: "FCI",
  investment: "Inversion Sugerida",
  bid: "Bid",
  orders: "Movements - Orders",
  history: "Movements - Historic",
  walletPortfolio: "Wallet Portfolio",
  walletPerformance: "Wallet Performance",
  walletTransfer: "Wallet Transfer",
  newOrder: "New Order",
  ccl: "Dolar CCL",
  bankAccounts: "Bank Accounts",
  newBankAccount: "Add Bank Account",
  market: "Market",
  search: "Search",
  trading: "Trading",
  gold: "Gold",
  help: "Help",
  security: "Security",
  updater: "Capacitor Updater",
  instrumentMovements: "Instrument Movements",
  monetaryMovements: "Monetary Movements",
  movementsDetail: "Movements Detail",
  trustedDevice: "safe_device_screen_viewed",
  phoneValidation: "phonevalidation_screen_viewed",
  phoneValidationChallenge: "phone_verification_code_screen_viewed",
  phoneValidationCurrentNumber: "phonevalidation_current_number_screen_viewed",
};

export const cryptoScreenNames = {
  tyc: "TyC",
  faConfig: "2FA Config",
  faCode: "2FA Code",
  home: "Home",
  market: "Market",
  marketDetail: "Market Detail",
  activity: "Activity",
  activityDetail: "Activity Detail",
  portfolio: "Portfolio",
  portfolioPerformance: "Portfolio Performance",
  portfolioAllocation: "Portfolio Allocation",
  swapSelectAsset: "Swap select asset",
  swap: "Swap",
  swapConfirm: "Swap Confirm",
  swapSuccess: "Swap Success",
  swapFail: "Swap Fail",
  send: "Send",
  sendSelectAsset: "Send Select Asset",
  sendSelectWallet: "Send Select Wallet",
  sendSelectAmount: "Send Select Amount",
  sendConfirm: "Send Confirm",
  receive: "Receive",
  receiveSelectAsset: "Receive Select Asset",
  pin: "PIN",
  profile: "Profile",
  agenda: "Agenda",
  agendaCreate: "Agenda Create",
  qrScanner: "QR Scanner",
  previewConfirm: "Preview Confirm",
  success: "Success",
  fail: "Fail",
  limits: "Limits",
};

export const onboardingScreenNames = {
  tyc: "TyC",
  scanIdFront: "Scan ID Front",
  scanIdBack: "Scan ID Back",
  personalInfo: "Personal Info",
  selfie: "Selfie",
  personalQuestions: "Personal Questions",
  verificationPending: "Verification Pending",
  verificationComplete: "Verification Complete",
  rejected: "Rejected",
  checkEmail: "Check Email",
  error: "Error",
};

export enum CryptoRoutes {
  HOME = "/crypto",
  ACTIVITY = "/crypto/activity",
  SWAP = "/crypto/swap",
  PROFILE = "/crypto/profile",
  MARKET = "/crypto/market",
  TICKER = "/crypto/market/:ticker",
  SEND = "/crypto/send",
  RECEIVE = "/crypto/receive",
  RECEIVE_ARS = "/crypto/receive/ars",
  AGENDA = "/crypto/agenda",
  PORTFOLIO = "/crypto/portfolio",
  PERFORMANCE = "/crypto/performance",
  ALLOCATION = "/crypto/allocation",
  TWOFA = "/crypto/2FA",
  TYC = "/crypto/TYC",
  LEARN = "/crypto/learn",
  LEGALS = "/crypto/legals",
  SECURITY = "/crypto/security",
  CONFIG = "/crypto/config",
  TRENDS = "/crypto/trends",
  CABLE = "/crypto/cable",
  TAG = "/crypto/tag",
  LIMITS = "/crypto/limits",
  AGREEMENT = "/crypto/agreement",
  HELP = "/crypto/help",
  FAQS = "/crypto/faqs",
}

export const externalRoutes = {
  academy: ACADEMY_URL,
};
