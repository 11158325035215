import { Typography } from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/es-mx";
import { formatToCurrency } from "utils";

import styles from "./styles.module.scss";

interface MovementsHeaderProps {
  settlementDate: string;
  balance?: number;
  isTickerDetail?: boolean;
  displaySettlementDate: boolean;
}

const MovementsHeader: React.FC<MovementsHeaderProps> = ({
  settlementDate,
  balance,
  isTickerDetail,
  displaySettlementDate,
}) => {
  const formattedDate = dayjs(settlementDate)
    .locale("es-mx")
    .format("DD MMM YYYY")
    .replace(/^(.{3})(.)/, (match, group1, group2) => {
      return group1 + group2.toUpperCase();
    });

  return (
    <div className={styles.cashHeader}>
      {displaySettlementDate ? (
        <Typography className={styles.settlement}>{formattedDate}</Typography>
      ) : (
        <div />
      )}

      {(balance || balance === 0) && (
        <Typography className={styles.balance}>
          {isTickerDetail ? (
            <span>
              Tenencia al día{" "}
              <span className={styles.balanceValue}>
                {formatToCurrency({ value: balance })}
              </span>
            </span>
          ) : (
            <span>
              Saldo del día{" "}
              <span className={styles.balanceValue}>
                ${formatToCurrency({ value: balance })}
              </span>
            </span>
          )}
        </Typography>
      )}
    </div>
  );
};

export default MovementsHeader;
