import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import API from "apis";
import { paymentService } from "apis/services";
import {
  PayError,
  PaySource,
  PaySteps,
  Payment,
  PaymentCurrencies,
  PaymentMethodErrors,
  PaymentStatus,
} from "interfaces/pay/enums";
import {
  PaymentData,
  PaymentMethod,
  PaymentMethodsResponse,
  ReceiptData,
} from "interfaces/pay/interface";
import { Currencies } from "interfaces/wallet";
import { usePixPricesQuery } from "apis/pay/queries/usePixPrices";
import { PixPrices } from "interfaces/api-responses";
import { ampli } from "ampli";
import dayjs from "dayjs";

interface Context {
  isLoading: boolean;
  setIsLoading: (v: boolean) => void;
  step: PaySteps;
  setStep: (v: PaySteps) => void;
  paymentData: PaymentData;
  setPaymentData: (v: PaymentData) => void;
  receiptData: ReceiptData;
  onScanQR: (v: string) => void;
  onPayQR: (currency: PaymentCurrencies) => Promise<void>;
  changeQuantity: (v: number) => void;
  error?: PayError;
  setError: (v?: PayError) => void;
  serviceError: boolean;
  setServiceError: (v: boolean) => void;
  loaderTitle: string;
  loaderSubtitle: string;
  selectedMethod?: PaymentMethod;
  setSelectedMethod: (v?: PaymentMethod) => void;
  availableMethods: PaymentMethod[];
  setAvailableMethods: (v: PaymentMethod[]) => void;
  goToPaymentMethods: (id: string, quantity: number) => void;
  paymentMethodMessages: PaymentMethodErrors[];
  setPaymentMethodMessages: (v: PaymentMethodErrors[]) => void;
  source: PaySource;
  setSource: (v: PaySource) => void;
  pixPrices?: PixPrices;
}

const CocosPayContext = createContext<Context | null>(null);

const paymentInitialState = {
  idPayment: "",
  quantity: 0,
  currency: Currencies.ARS,
  transactionQuantity: 0,
  transactionCurrency: Currencies.ARS,
  businessName: "",
  paymentType: Payment.CLOSE,
  countryCode: "ARS",
  serviceStatus: {
    serviceDisruption: null,
  },
};

const receiptInitialState: ReceiptData = {
  businessName: "",
  quantity: 0,
  createdAt: "",
  currency: Currencies.ARS,
  idPayment: "",
  idPaymentShort: "",
  paymentType: Payment.CLOSE,
  status: PaymentStatus.PENDING_USER_CONFIRMATION,
  paymentMethod: Currencies.ARS,
  localQuantity: 0,
  localCurrency: Currencies.ARS,
  settlementQuantity: 0,
  settlementCurrency: Currencies.ARS,
  transactionQuantity: 0,
  transactionCurrency: Currencies.ARS,
};

export const CocosPayProvider = ({ children }: { children: ReactNode }) => {
  const [source, setSource] = useState<PaySource>(PaySource.QR);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<PayError>();
  const [serviceError, setServiceError] = useState<boolean>(false);
  const [step, setStep] = useState<PaySteps>(PaySteps.QR);
  const [paymentData, setPaymentData] = useState<PaymentData>(
    paymentInitialState
  );
  const [receiptData, setReceiptData] = useState<ReceiptData>(
    receiptInitialState
  );
  const [selectedMethod, setSelectedMethod] = useState<
    PaymentMethod | undefined
  >();

  const [loaderTitle, setLoaderTitle] = useState<string>("");
  const [loaderSubtitle, setLoaderSubtitle] = useState<string>("");

  const [availableMethods, setAvailableMethods] = useState<PaymentMethod[]>([]);
  const [paymentMethodMessages, setPaymentMethodMessages] = useState<
    PaymentMethodErrors[]
  >([]);
  const { data: pixPrices } = usePixPricesQuery();

  useEffect(() => {
    if (
      receiptData.status === PaymentStatus.PROCESSING ||
      receiptData.status === PaymentStatus.PENDING_EXECUTION
    ) {
      const TIME_LIMIT = 150000;
      const firstRequest = dayjs();

      const intervalCall = setInterval(async () => {
        if (Math.abs(firstRequest.diff()) >= TIME_LIMIT) {
          clearInterval(intervalCall);
          setReceiptData({ ...receiptData, status: PaymentStatus.FAILED });
          setStep(PaySteps.RESPONSE);
          setIsLoading(false);
        }
        try {
          const { data } = await API.get<ReceiptData>(
            `${paymentService.payment}/${paymentData?.idPayment}`
          );
          if (
            data.status === PaymentStatus.COMPLETED ||
            data.status === PaymentStatus.FAILED
          ) {
            clearInterval(intervalCall);
            setReceiptData(data);
            setStep(PaySteps.RESPONSE);
            setIsLoading(false);
          }
        } catch (error: any) {
          console.error(error);
        }
      }, 2500);
    }
  }, [receiptData]);

  const changeQuantity = (value: number) => {
    if (paymentData)
      setPaymentData({
        ...paymentData,
        quantity: value,
      });
  };

  const goToPaymentMethods = async (id: string, quantity: number) => {
    setSelectedMethod(undefined);
    setError(undefined);
    try {
      const { data } = await API.get<PaymentMethodsResponse>(
        `${paymentService.payment}/${id}/methods`,
        {
          params: {
            quantity,
          },
        }
      );
      setAvailableMethods(data.paymentMethods);
      setPaymentMethodMessages(data.messages);
      setStep(PaySteps.METHOD);
    } catch (error) {
      setError(PayError.PAYMENT_METHODS_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const onScanQR = async (qrCode: string) => {
    setLoaderTitle("Procesando el QR...");
    setLoaderSubtitle("Esto puede tardar unos segundos.");
    setIsLoading(true);
    try {
      const { data } = await API.get<PaymentData>(paymentService.qr, {
        params: {
          qrData: qrCode,
        },
      });

      ampli.payScanQr({
        country_code: data.countryCode,
        type: data.paymentType,
      });

      setPaymentData(data);

      if (data.serviceStatus.serviceDisruption) {
        setIsLoading(false);
        setServiceError(true);
        return;
      }

      if (data.paymentType === Payment.OPEN) {
        setStep(PaySteps.AMOUNT);
        setIsLoading(false);
        return;
      }

      goToPaymentMethods(data.idPayment, data.quantity);
    } catch (error: any) {
      const messageError =
        (error?.response?.data?.message as PayError) ||
        PayError.INTERNAL_SERVER_ERROR;
      setError(messageError);
      setIsLoading(false);
    }
  };

  const onPayQR = async (currency: PaymentCurrencies) => {
    if (source === PaySource.BRIDGE) {
      setReceiptData({
        ...receiptData,
        status: PaymentStatus.TO_BE_CONFIRM_BY_BRIDGE,
      });
      return;
    }

    setLoaderTitle("Procesando tu pago...");
    setLoaderSubtitle("Por favor, aguardá un instante.");
    setIsLoading(true);

    setTimeout(() => {
      setLoaderTitle("Validando el comercio...");
      setLoaderSubtitle("Procesaremos tu pago a continuación.");
    }, 5000);

    setTimeout(() => {
      setLoaderTitle("Por favor, no cierres la app...");
      setLoaderSubtitle(
        "Procesando tu pago. Esto puede tardar un poco más de lo habitual."
      );
    }, 10000);

    try {
      const { data } = await API.post<ReceiptData>(
        `${paymentService.payment}/${paymentData?.idPayment}`,
        {
          paymentMethod: currency,
          quantity:
            paymentData?.paymentType === Payment.OPEN
              ? paymentData?.quantity
              : undefined,
        }
      );

      if (
        data.status === PaymentStatus.COMPLETED ||
        data.status === PaymentStatus.FAILED
      ) {
        setStep(PaySteps.RESPONSE);
        setIsLoading(false);
      }

      setReceiptData(data);
    } catch (error) {
      setReceiptData({
        ...receiptData,
        status: PaymentStatus.FAILED,
        businessName: paymentData?.businessName,
        quantity: paymentData?.quantity,
        idPayment: paymentData?.idPayment,
      });
      setStep(PaySteps.RESPONSE);
      setIsLoading(false);
    }
  };

  const memoizedValues = useMemo(() => {
    return {
      isLoading,
      setIsLoading,
      step,
      setStep,
      paymentData,
      setPaymentData,
      receiptData,
      onScanQR,
      onPayQR,
      changeQuantity,
      error,
      setError,
      serviceError,
      setServiceError,
      loaderTitle,
      loaderSubtitle,
      selectedMethod,
      setSelectedMethod,
      availableMethods,
      setAvailableMethods,
      goToPaymentMethods,
      paymentMethodMessages,
      source,
      setSource,
      setPaymentMethodMessages,
      pixPrices,
    };
  }, [
    isLoading,
    setIsLoading,
    step,
    setStep,
    paymentData,
    setPaymentData,
    receiptData,
    onScanQR,
    onPayQR,
    changeQuantity,
    error,
    setError,
    serviceError,
    setServiceError,
    loaderTitle,
    loaderSubtitle,
    selectedMethod,
    setSelectedMethod,
    availableMethods,
    setAvailableMethods,
    goToPaymentMethods,
    paymentMethodMessages,
    source,
    setSource,
    setPaymentMethodMessages,
    pixPrices,
  ]);

  return (
    <CocosPayContext.Provider value={memoizedValues}>
      {children}
    </CocosPayContext.Provider>
  );
};

export default CocosPayContext;

export const useCocosPay = () => {
  const context = useContext(CocosPayContext);
  if (!context) {
    throw new Error("[CocosPayContext] Missing context");
  }
  return context;
};
