import Text, { TextVariants } from "components/common/Text";

import styles from "./styles.module.scss";

const TableRowDate = ({ date }: { date: string }) => {
  return (
    <div className={styles.tableRowDate}>
      <Text variant={TextVariants.RegularValueS} color="var(--slate800)">
        {date}
      </Text>
    </div>
  );
};

export default TableRowDate;
