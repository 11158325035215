import { useEffect, useState } from "react";

import TradingContainer from "components/common/trading-container";
import { TradingProvider } from "context/TradingProvider";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { InstrumentData } from "interfaces/api-responses";
import { Currencies } from "interfaces/wallet";
import CommonLayout from "layouts/common";
import LayoutV2 from "layouts/v2";
import { isEmpty } from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getMarketDetailByShortTicker } from "features/markets/marketsActions";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import { Spinner } from "components/common/spinner";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import TradingDesktop from "./components/desktop";

import "./index.scss";

type MarketParams = {
  id: string;
  instrument_type: string;
};

const DEFAULT_TERM = 1;
const DEFAULT_CURRENCY = Currencies.ARS;

const TradingPage: React.FC = () => {
  const { isMobile } = useDeviceCheck();
  const { id, instrument_type } = useParams<MarketParams>();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const state = location.state as InstrumentData;
  const [selectedInstrument, setSelectedInstrument] = useState<InstrumentData>(
    state
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { shortTickerData } = useAppSelector((state) => state.markets);
  const stateDiffSelected =
    !isEmpty(state) &&
    state.instrument_code &&
    state.instrument_code !== selectedInstrument.instrument_code;

  useEffect(() => {
    if (!isMobile) dispatch(closeRightModal());
    if (!state || stateDiffSelected) {
      if (id && instrument_type) {
        dispatch(
          getMarketDetailByShortTicker({ short_ticker: id, instrument_type })
        );
      }
      return;
    }
    setIsLoading(false);
  }, [location.state]);

  useEffect(() => {
    if ((isEmpty(selectedInstrument) && shortTickerData) || stateDiffSelected) {
      const ticker = shortTickerData.find(
        (s: InstrumentData) =>
          s.term === DEFAULT_TERM && s.currency === DEFAULT_CURRENCY
      );
      if (ticker) {
        setSelectedInstrument(ticker);
        setIsLoading(false);
      }
    }
  }, [shortTickerData]);

  useEffect(() => {
    setSelectedInstrument(state);
  }, [state]);

  const lastPage = sessionStorage.getItem("lastPage") || "";

  const goBack = () => {
    if (lastPage) {
      navigate(lastPage);
    } else {
      navigate(-1);
    }
  };

  if (!isMobile)
    return (
      <TradingProvider>
        <CommonLayout withHeader={false}>
          <div className="page-trading">
            {isLoading && <Spinner />}
            {!isLoading && <TradingDesktop instrument={selectedInstrument} />}
          </div>
        </CommonLayout>
      </TradingProvider>
    );

  return (
    <LayoutV2
      goBack={goBack}
      title={selectedInstrument?.instrument_code ?? "Buscar ticker"}
      withSearch={isMobile}
      quickOperation
      instrument={selectedInstrument}
    >
      {isLoading && <Spinner />}
      {!isLoading && <TradingContainer instrumentProp={selectedInstrument} />}
    </LayoutV2>
  );
};

export default TradingPage;
