import { getTimeData, PORTFOLIO_CHART_PERIOD } from "constants/portfolio";

import { useEffect, useState } from "react";

import { useBalanceQuery } from "apis/portfolio/queries/useBalance";
import { PortfolioScreen, usePortfolio } from "context/PortfolioProvider";
import { ThemeVariants } from "interfaces/theme";
import ChartSection from "components/page-cocos-portfolio/PortfolioContent/components/common/ChartSection";
import dayjs from "dayjs";
import { ChartDataPoint } from "components/common/LineChart/NewLineChart";
import { SelectedTab } from "components/page-cocos-portfolio/utils";
import { ampli } from "ampli";

import Balance from "./Balance";
import CurrencyDetails from "./CurrencyDetails";
import InstrumentsTableWrapper from "./InstrumentsTableWrapper";

import styles from "./styles.module.scss";

interface DesktopProps {
  variant: ThemeVariants;
}

const Desktop: React.FC<DesktopProps> = ({ variant }) => {
  const {
    selectedCurrency,
    selectedPeriod,
    setSelectedPeriod,
    setPortfolioScreen,
  } = usePortfolio();
  const {
    data: portfolioBalance,
    isLoading: isBalanceLoading,
    isError: isBalanceError,
  } = useBalanceQuery({
    currency: selectedCurrency,
    period: selectedPeriod,
  });

  const { variationText, timeOption } = getTimeData(selectedPeriod);

  const initialChartData = {
    balance: portfolioBalance?.totalBalance,
    percentage: portfolioBalance?.variation.percentage || 0,
    amount: portfolioBalance?.variation.amount,
    date: variationText,
  };

  const [chartData, setChartData] = useState(initialChartData);

  const availableBalance = chartData.balance || portfolioBalance?.totalBalance;
  const variationAmount =
    chartData.amount ?? portfolioBalance?.variation.amount;
  const variationPercentage =
    chartData.percentage ?? portfolioBalance?.variation.percentage;

  const handleOnChangeTime = (value: number) => {
    ampli.portfolioChartRangeSwitched({
      tab: SelectedTab.RETURNS,
      range: PORTFOLIO_CHART_PERIOD[value],
    });

    setSelectedPeriod(PORTFOLIO_CHART_PERIOD[value]);
  };

  const onChangePortfolio = (data?: ChartDataPoint) => {
    const date = data ? dayjs(data.date).format("DD-MM-YYYY") : "";

    if (!data?.variation) {
      return setChartData(initialChartData);
    }

    setChartData({
      balance: data.value,
      percentage: data.variation.percentage,
      amount: data.variation.amount,
      date,
    });
  };

  useEffect(() => {
    setChartData(initialChartData);
  }, [portfolioBalance]);

  useEffect(() => {
    if (isBalanceError) setPortfolioScreen(PortfolioScreen.PORTFOLIO_ERROR);
  }, [isBalanceError]);

  return (
    <div className={styles.desktopWrapper}>
      <Balance
        isLoading={isBalanceLoading}
        balanceData={{
          totalBalance: availableBalance,
          variation: {
            amount: variationAmount,
            percentage: variationPercentage,
            label: chartData.date,
          },
          cashBalance: portfolioBalance?.cashBalance,
          holdingsBalance: portfolioBalance?.holdingsBalance,
        }}
        variant={variant}
      />
      <ChartSection
        handleOnChangeTime={handleOnChangeTime}
        onChangePortfolio={onChangePortfolio}
        selectedTime={timeOption}
        variant={variant}
      />
      <CurrencyDetails />
      <InstrumentsTableWrapper currency={selectedCurrency} />
    </div>
  );
};

export default Desktop;
