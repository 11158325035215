import BasicWrapper from "components/common/BasicWrapper";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { Clock4 } from "lucide-react";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";

const GoldConfirm: React.FC = () => {
  const navigate = useNavigate();

  const { isMobile } = useDeviceCheck();

  const navigationHeaderProps = isMobile
    ? {
        title: "Activación en proceso",
        onClick: () => navigate(-1),
      }
    : undefined;

  const primaryButtonProps = isMobile
    ? {
        children: "Volver al inicio",
        onClick: () => navigate(-1),
      }
    : undefined;

  return (
    <BasicWrapper
      navigationHeaderProps={navigationHeaderProps}
      icon={Clock4}
      title="¡Gracias por tu tiempo!"
      subtitle="Tu solicitud ya fue enviada. El equipo de Cocos Gold te contactará en los próximos 2 a 5 días hábiles."
      badgeColor="var(--yellow300)"
      className={styles.confirmWrapper}
      primaryButtonProps={primaryButtonProps}
    />
  );
};

export default GoldConfirm;
