import Text, { TextVariants } from "components/common/Text";
import Divider from "components/common/Divider";
import type { OrderDetailType } from "interfaces/orders/interface";
import StackedIcons from "components/common/StackedIcons";
import ARGFlag from "assets/icons/ARGFlag";
import { ChevronRight, Minus, Plus } from "lucide-react";
import { OperationSide, OrderStatus } from "interfaces/orders/enums";
import { useSearchParams } from "react-router-dom";
import { Currencies, BalanceCurrencies } from "interfaces/wallet";
import USFlag from "assets/icons/USFlag";

import { formatLocalDate, getOrderStatusMap } from "../../utils";

import styles from "./styles.module.scss";

interface CardChildOrderProps {
  status: OrderStatus;
  executionDate: string;
  side: OperationSide;
  ticker: string;
  orderId: string;
  currency: BalanceCurrencies;
}

const CardChildOrder = ({
  status,
  ticker,
  executionDate,
  side,
  orderId,
  currency,
}: CardChildOrderProps) => {
  const { icon, text, color, lightColor } = getOrderStatusMap(status);
  const [_, setSeachParams] = useSearchParams();

  const operation = side === OperationSide.BUY ? "Compra" : "Venta";
  const smallIcon = side === OperationSide.BUY ? Plus : Minus;
  const flagIcon = currency === Currencies.ARS ? <ARGFlag /> : <USFlag />;

  const title = `${operation} de ${ticker}`;
  const date =
    status === OrderStatus.PENDING
      ? "Esperando el plazo reglamentario"
      : formatLocalDate(executionDate);

  const handleOnClick = () => {
    setSeachParams({ mepOrderId: orderId });
  };

  return (
    <div className={styles.cardChildOrder} onClick={handleOnClick}>
      <div className={styles.cardOrderHeaderWrapper}>
        <div className={styles.cardOrderHeader}>
          <StackedIcons
            withImage={false}
            icon={flagIcon}
            smallIcon={smallIcon}
            backgroundColor="var(--blue800)"
          />
          <div>
            <Text color="var(--slate900)" variant={TextVariants.RegularText}>
              {title}
            </Text>
            <Text color="var(--slate800)" variant={TextVariants.RegularTextS}>
              {date}
            </Text>
          </div>
        </div>
        <ChevronRight color="var(--slate800)" />
      </div>
      <div
        className={styles.cardChildStatus}
        style={{ backgroundColor: lightColor }}
      >
        {icon}{" "}
        <Text color={color} variant={TextVariants.RegularTextS}>
          {text}
        </Text>
      </div>
    </div>
  );
};

const MepChildOrders = ({
  childOrders,
  side,
}: {
  childOrders: OrderDetailType[];
  side: OperationSide;
}) => {
  const title = side === OperationSide.BUY ? "Compra" : "Venta";

  return (
    <>
      <Divider className={styles.mepOrderDivider} />
      <div className={styles.childOrderWrapper}>
        <div className={styles.mepOrderWrapperHeader}>
          <Text variant={TextVariants.SemiboldText} color="var(--slate900)">
            Resumen de {title}
          </Text>
          <Text color="var(--slate800)" variant={TextVariants.RegularTextS}>
            2 Operaciones
          </Text>
        </div>
        <div className={styles.barAndCardWrapper}>
          <div className={styles.bulletsBar}>
            <div className={styles.bullet}>1</div>
            <div className={styles.bullet}>2</div>
          </div>
          <div className={styles.cardChildOrderWrapper}>
            {childOrders.map(
              ({ currency, orderId, status, executionDate, side, ticker }) => (
                <CardChildOrder
                  currency={currency}
                  key={orderId}
                  orderId={orderId}
                  status={status}
                  ticker={ticker}
                  side={side}
                  executionDate={executionDate}
                />
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MepChildOrders;
