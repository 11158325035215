import CheckedDNI from "assets/illustrations/CheckedDNI";
import { useAppSelector } from "hooks/redux";
import { StatusOnboarding } from "interfaces/createAccount";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import { getLoggedUser } from "store/selectors/user.selector";
import EmptyState, {
  EmptyButtonTypes,
  EmptyStateProps,
} from "components/common/EmptyState";
import { ThemeVariants } from "interfaces/theme";
import px2rem from "utils/px2rem";
import { ampli } from "ampli";
import getPlatform from "utils/getPlatform";
import DownloadApp from "assets/illustrations/DownloadApp";
import { Device } from "@capacitor/device";

import styles from "./styles.module.scss";

type EmptyStateWithOnboardingProps = {
  withBorder?: boolean;
} & (
  | { extraEmptyState: false }
  | (EmptyStateProps & { extraEmptyState: true })
);

const EmptyStateWithOnboarding: React.FC<EmptyStateWithOnboardingProps> = ({
  withBorder = true,
  ...props
}) => {
  const navigate = useNavigate();
  const user = useAppSelector(getLoggedUser);
  const { isNative } = getPlatform();
  const withExtraEmptyState = props.extraEmptyState;

  const extraClass = withBorder ? "" : styles.noBorder;

  if (!user) return null;

  const { statusOnboarding } = user;

  const handleCreateAccount = () => {
    ampli.homeExited({ referral: "onboarding" });
    navigate(mainRoutes.createAccount);
  };

  const getEmptyTitle = () => {
    if (statusOnboarding === StatusOnboarding.NOT_STARTED) {
      return "Abrí tu cuenta comitente";
    }

    if (statusOnboarding === StatusOnboarding.IN_PROGRESS) {
      return "Finalizá tu cuenta comitente";
    }

    return "Chequeá el estado de tu apertura";
  };

  const empyTitle = getEmptyTitle();

  const getButtonText = () => {
    if (statusOnboarding === StatusOnboarding.NOT_STARTED) {
      return "Abrir cuenta";
    }

    if (statusOnboarding === StatusOnboarding.IN_PROGRESS) {
      return "Finalizar cuenta";
    }

    return "Chequear estado";
  };

  const buttonText = getButtonText();

  const getSubtitle = () => {
    if (statusOnboarding === StatusOnboarding.NOT_STARTED) {
      return "Completá tus datos personales para poder operar en Cocos.";
    }

    if (statusOnboarding === StatusOnboarding.IN_PROGRESS) {
      return "Falta poco para completar tus datos y poder operar en Cocos.";
    }

    return "Revisá el progreso de tu apertura y completá los pasos que te faltan para comenzar a operar.";
  };

  const fetchDownloadButton = async () => {
    const { operatingSystem } = await Device.getInfo();

    if (operatingSystem === "android") {
      window.location.href =
        "https://play.google.com/store/apps/details?id=capital.cocos.app.twa";
    } else {
      window.location.href =
        "https://apps.apple.com/us/app/cocos-capital/id1634675415";
    }
  };

  const emptySubtitle = getSubtitle();

  if (user.hasAccount && withExtraEmptyState) return <EmptyState {...props} />;

  if (!isNative)
    return (
      <EmptyState
        title="Descargá la app de Cocos"
        buttonText="Descargar Cocos"
        subtitle="Para poder operar en Cocos y abrir tu cuenta comitente descargá nuestra app del store."
        onClick={fetchDownloadButton}
        buttonType={EmptyButtonTypes.BUTTON}
        buttonVariant={ThemeVariants.Capital}
        illustration={<DownloadApp size={px2rem(68)} />}
      />
    );

  const illustration = isNative ? (
    <CheckedDNI size={px2rem(68)} />
  ) : (
    <DownloadApp size={px2rem(68)} />
  );

  return (
    <EmptyState
      title={empyTitle}
      className={extraClass}
      buttonText={buttonText}
      subtitle={emptySubtitle}
      onClick={handleCreateAccount}
      buttonType={EmptyButtonTypes.BUTTON}
      buttonVariant={ThemeVariants.Capital}
      illustration={illustration}
    />
  );
};

export default EmptyStateWithOnboarding;
