import API from "apis";
import { useAppQuery } from "apis/queryClient";
import { cryptoKeys } from "apis/queryKeyFactories";
import { cryptoService } from "apis/services";
import { CryptoPrice } from "interfaces/api-responses";

const REFETCH_TIME = 10000;

const fetchPrices = async () => {
  const { data } = await API.get<CryptoPrice[]>(cryptoService.prices);

  return data;
};

export const usePricesQuery = () => {
  return useAppQuery({
    options: {
      queryKey: cryptoKeys.prices,
      queryFn: fetchPrices,
      retry: false,
      refetchInterval: REFETCH_TIME,
      staleTime: REFETCH_TIME - 1000,
      gcTime: REFETCH_TIME - 1000,
    },
  });
};
