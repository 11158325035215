import Heading, { HeadingVariants } from "components/common/Heading";
import QuietButton, { QuietButtonSizes } from "components/common/QuietButton";
import Text, { TextVariants } from "components/common/Text";
import { useProfile } from "context/ProfileProvider";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { Tier } from "interfaces/user";
import { ChevronRight, Crown } from "lucide-react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import { getLoggedUser } from "store/selectors/user.selector";
import px2rem from "utils/px2rem";
import { useBalanceQuery } from "apis/portfolio/queries/useBalance";
import { Currencies } from "interfaces/wallet";
import { PeriodTime } from "interfaces/portfolio";
import { MIN_GOLD_BALANCE } from "utils/constants";

import GoldBannerBenefitsWrapper from "./components/BenefitsWrapper";

import styles from "./styles.module.scss";

const CocosGoldBanner: React.FC = () => {
  const { isMobile } = useDeviceCheck();
  const { goldSection, onClickSection } = useProfile();
  const navigate = useNavigate();

  const { data: balanceData } = useBalanceQuery({
    currency: Currencies.USD,
    period: PeriodTime.DAY,
  });

  const user = useSelector(getLoggedUser);

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  const { account } = user;

  const hasBalanceForGold = !!(
    balanceData && balanceData.totalBalance > MIN_GOLD_BALANCE
  );

  const canRequestGold =
    (hasBalanceForGold && account?.tier === Tier.FREE) ||
    account?.proposeTierUpgrade;

  if (!goldSection || !canRequestGold) return null;

  if (isMobile)
    return (
      <div className={styles.cocosGoldBannerContainer}>
        <div className={styles.leftWrapper}>
          <Heading
            variant={HeadingVariants.RegularSubtitle}
            component="h2"
            color="var(--yellow800)"
          >
            ¿Querés ser Gold?
          </Heading>
          <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
            Conocé todos los beneficios de tener una cuenta exclusiva.
          </Text>
          <QuietButton
            className={styles.button}
            size={QuietButtonSizes.Small}
            onClick={() => navigate(mainRoutes.cocosGold)}
          >
            Quiero Cocos Gold
          </QuietButton>
        </div>
        <GoldBannerBenefitsWrapper />
        <div className={styles.gradient} />
      </div>
    );

  return (
    <div
      className={styles.goldBannerContainer}
      onClick={() => onClickSection(goldSection)}
    >
      <div className={styles.wrapper}>
        <div className={styles.icon}>
          <Crown color="var(--yellow50)" size={px2rem(16)} />
        </div>
        <div>
          <Heading
            variant={HeadingVariants.RegularSubtitle}
            color="var(--yellow900)"
            component="h3"
          >
            Activá Cocos Gold
          </Heading>
          <Text variant={TextVariants.RegularTextS} color="var(--yellow800)">
            Conocé todos los beneficios y tarifas
          </Text>
        </div>
      </div>

      <ChevronRight color="var(--yellow800)" size={px2rem(16)} />
    </div>
  );
};

export default CocosGoldBanner;
