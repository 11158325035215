import Heading, { HeadingVariants } from "components/common/Heading";
import classNames from "classnames";
import CapitalActivityCard from "components/common/ActivityList/ActivityCard/CapitalActivityCard";
import { useSearchParams } from "react-router-dom";
import type { FilterOrderType } from "context/ActivityProvider";
import type { OrdersResponse } from "interfaces/orders/interface";
import Divider from "components/common/Divider";

import {
  getMainIcon,
  getOperationSideHeading,
  getSettlementDays,
  getOrderStatusMap,
  getSmallIcon,
  formatDateToSpanish,
  getFilteredOrders,
} from "../../../utils";

import styles from "./styles.module.scss";

interface OrderListProps {
  orders: OrdersResponse[];
  filterStatus: FilterOrderType;
}

const OrdersList = ({ filterStatus, orders }: OrderListProps) => {
  const [_, setSeachParams] = useSearchParams();

  const onClickOrder = (id: string) => {
    setSeachParams({ id });
  };

  return (
    <>
      {orders?.map(({ createdAt, orders }) => {
        const filteredOrders = getFilteredOrders(filterStatus, orders);
        return (
          <>
            {filteredOrders.length > 0 && (
              <Heading
                variant={HeadingVariants.RegularSubtitle}
                color="var(--slate800)"
                className={styles.dateHeading}
                component="h2"
              >
                {formatDateToSpanish(createdAt)}
              </Heading>
            )}
            <div
              className={classNames(styles.activityContainer, {
                [styles.bordered]: filteredOrders.length > 0,
              })}
            >
              {filteredOrders.map(
                (
                  {
                    amount,
                    logo,
                    currency,
                    orderId,
                    quantity,
                    settlementDays,
                    side,
                    status,
                    source,
                    ticker,
                    childOrders,
                    usdAmount,
                  },
                  index
                ) => (
                  <>
                    <div
                      key={orderId}
                      className={styles.activityCardRowContainer}
                    >
                      <div>{getOrderStatusMap(status).icon}</div>
                      <div className={styles.activityCardRow}>
                        <CapitalActivityCard
                          amount={amount}
                          currency={currency}
                          heading={getOperationSideHeading(side)}
                          isMepOperation={!!childOrders}
                          mainIcon={getMainIcon(!!childOrders, logo)}
                          onClick={() => onClickOrder(orderId)}
                          operationSide={side}
                          quantity={quantity?.total}
                          settlementDate={getSettlementDays(settlementDays)}
                          smallIcon={getSmallIcon(side, source)}
                          ticker={ticker}
                          usdAmount={usdAmount}
                        />
                      </div>
                    </div>
                    {index !== filteredOrders.length - 1 && (
                      <Divider className={styles.divider} />
                    )}
                  </>
                )
              )}
            </div>
          </>
        );
      })}
    </>
  );
};

export default OrdersList;
