import { ConfirmOrder } from "components/common/confirm-order";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { Instruments, InvestmentSuggestedResponse } from "interfaces/markets";
import Drawer from "layouts/drawer";
import { Navigate, useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import {
  setConfirmOrderData,
  getOrderById,
  updateOrderOptions,
} from "features/markets";
import { RootState } from "store/store";
import { getLoggedUser } from "store/selectors/user.selector";
import { formatToCurrency, getCurrencyLabel } from "utils";
import { trackAction } from "utils/amplitude";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useAppState } from "context/AppStateProvider";
import FilledButton from "components/common/FilledButton";
import { ThemeVariants } from "interfaces/theme";
import classNames from "classnames";
import getPlatform from "utils/getPlatform";

import InvestmentErrors from "./components/bid-errors";
import { ACTIVE_BID } from "../../constants";

import styles from "./styles.module.scss";

interface BidConfirmProps {
  instrument: InvestmentSuggestedResponse;
  isMarketOpen: boolean;
  operationAble: boolean;
}

const BidConfirm: React.FC<BidConfirmProps> = ({
  instrument,
  operationAble,
}) => {
  const { isMobile } = useDeviceCheck();
  const { openRightModalContent } = useAppState();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isIOS } = getPlatform();
  const user = useAppSelector(getLoggedUser);
  const order = useAppSelector((state: RootState) => state.markets.newOrder);

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  const { hasAccount } = user;
  const disabled = !hasAccount || !operationAble;

  const handleCloseSidebar = () => {
    if (isMobile) {
      navigate(-1);
      return;
    }

    dispatch(closeRightModal());
  };

  const handleClickOrderRow = (orderId: string) => {
    dispatch(getOrderById(orderId));
  };

  const goToOrder = (data: any) => (
    <Drawer>
      <ConfirmOrder
        data={data}
        onClickOrderDetail={handleClickOrderRow}
        onClose={handleCloseSidebar}
        instrument_type={Instruments.BONOS_CORP}
        isBidOrder
      />
    </Drawer>
  );

  const confirmOrder = () => {
    if (instrument && instrument.long_ticker && order.quantity) {
      dispatch(
        updateOrderOptions({
          ...order,
          amount: order.quantity * (instrument.price / 100),
        })
      );

      trackAction("bid_order_confirmation_screen_viewed");
      const data: any = [
        {
          label: "Especie",
          value: order.instrument_code,
        },
        {
          label: "Plazo de liquidación",
          value: ACTIVE_BID.settlement_date,
        },
        {
          label: "Monto a Invertir",
          value: `${getCurrencyLabel(order.currency)} ${formatToCurrency({
            value: order.amount,
          })}`,
        },
      ];

      dispatch(setConfirmOrderData(data));

      if (isMobile) {
        navigate(`${mainRoutes.orderConfirm}?isBid=true`);
      } else {
        openRightModalContent(goToOrder(data));
      }
    }
  };

  return (
    <div className={styles.confirmSection}>
      <InvestmentErrors instrument={instrument} />
      <div
        className={classNames(styles.confirmButton, { [styles.isIos]: isIOS })}
      >
        <FilledButton
          disabled={disabled}
          variant={ThemeVariants.Capital}
          onClick={confirmOrder}
        >
          Confirmar Compra
        </FilledButton>
      </div>
    </div>
  );
};

export default BidConfirm;
