import { Cryptos } from "constants/cryptos";

import AvailableLabel from "components/common/AvailableLabel";

import styles from "./styles.module.scss";

interface LimitBarProps {
  value: number;
  percentage: number;
}

const LimitBar: React.FC<LimitBarProps> = ({ value, percentage }) => {
  return (
    <div className={styles.LimitUsedContainer}>
      <AvailableLabel
        withHideButton={false}
        available={value}
        currency={Cryptos.USDT}
      />
      <div
        className={styles.usedBar}
        style={{
          backgroundColor: "var(--purple800)",
          background: `linear-gradient(90deg, var(--purple800) ${
            percentage * 100
          }%, var(--slate200) 0%, var(--slate200) 100%)`,
        }}
      />
    </div>
  );
};

export default LimitBar;
