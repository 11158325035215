import type { ReactNode } from "react";

import { FilledChip } from "components/common/chip";
import {
  Pending,
  Executed,
  PartiallyExecuted,
  PartiallyCancelled,
} from "assets/icons/OrderStatus";
import OverlappedIcons from "assets/icons/OverlappedIcons";
import { OrderStatus } from "interfaces/orders/enums";
import { FilterOrderType } from "context/ActivityProvider";

import styles from "./styles.module.scss";

const OVERLAPPED_ICONS = [
  { name: "Pending", icon: <Pending /> },
  { name: "Partially cancelled", icon: <PartiallyCancelled /> },
  { name: "Partially executed", icon: <PartiallyExecuted /> },
];

type PillsType = {
  text: string;
  filter: FilterOrderType;
  icon?: ReactNode;
};

const PILLS: PillsType[] = [
  {
    text: "Todas",
    filter: "ALL",
  },
  {
    text: "Ejecutadas",
    filter: OrderStatus.EXECUTED,
    icon: (
      <div className={styles.pillExecuted}>
        <Executed />
      </div>
    ),
  },
  {
    text: "Pendientes",
    filter: OrderStatus.PENDING,
    icon: <OverlappedIcons icons={OVERLAPPED_ICONS} />,
  },
];

interface FilterPillsProps {
  activeFilter: FilterOrderType;
  setActiveFilter: (filter: FilterOrderType) => void;
}

const FilterPills = ({ setActiveFilter, activeFilter }: FilterPillsProps) => (
  <div className={styles.pillsContainer}>
    {PILLS.map(({ text, filter, icon }) => (
      <FilledChip
        key={filter}
        icon={icon}
        primaryText={text}
        isActive={filter === activeFilter}
        onClick={() => setActiveFilter(filter)}
      />
    ))}
  </div>
);

export default FilterPills;
