import API from "apis";
import { useAppQuery } from "apis/queryClient";
import { cardKeys } from "apis/queryKeyFactories";
import { cardService } from "apis/services";
import { CardPaymentMethods } from "interfaces/card/interface";

const HALF_HOUR = 1000 * 60 * 30;

const fetchCardPaymentMethodsData = async () => {
  const { data } = await API.get<CardPaymentMethods[]>(
    cardService.paymentMethod
  );

  return data;
};

export const useCardPaymentMethodsQuery = () => {
  return useAppQuery({
    options: {
      queryKey: cardKeys.cardPaymentMethods,
      queryFn: fetchCardPaymentMethodsData,
      retry: false,
      staleTime: HALF_HOUR,
      gcTime: HALF_HOUR,
    },
  });
};
