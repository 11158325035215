import { useEffect, useState } from "react";

import { useDeviceCheck } from "hooks/useDeviceCheck";
import CommonLayout from "layouts/common";
import { useParams } from "react-router-dom";
import { Spinner } from "components/common/spinner";
import { trackAction, trackScreen } from "utils/amplitude";
import { screenNames } from "router/routes";
import {
  setCurrentScreen,
  getCurrentScreen,
} from "features/global/globalSlice";
import { walletService } from "apis/services";
import API from "apis";
import {
  TickerDetails,
  TickerMovements as TickerMovementsData,
} from "interfaces/movements";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import TickerMovementsBody from "./components/movements-ticker-body";
import TickerMovementsHeader from "./components/movements-ticker-header";

import styles from "./styles.module.scss";

type MovementsParams = {
  instrument_code: string;
  instrument_type?: string;
};

const TickerMovements: React.FC = () => {
  const { isMobile } = useDeviceCheck();
  const dispatch = useAppDispatch();
  const { instrument_code } = useParams<MovementsParams>();
  const [tickerDetail, setTickerDetail] = useState<TickerDetails>();
  const [tickerData, setTickerData] = useState<TickerMovementsData[]>([]);

  const currentScreen = useAppSelector(getCurrentScreen);

  const getTickerMovements = async ({
    date_to,
    date_from,
  }: {
    date_to?: string;
    date_from?: string;
  }) => {
    try {
      const { data } = await API.get<TickerMovementsData[]>(
        `${walletService.tickerMovements}/${instrument_code}`,
        {
          params: {
            date_from,
            date_to,
          },
        }
      );
      setTickerData(data);
    } catch (error: any) {
      if (error?.response?.status !== 401) {
        ("[Movements] Error retrieving tickers_movements for this instrument");
      }
    }
  };

  useEffect(() => {
    if (!instrument_code) return;

    const getTickerDetails = async () => {
      try {
        const { data } = await API.get<TickerDetails>(
          `${walletService.tickerMovements}/${instrument_code}/details`
        );

        setTickerDetail(data);
      } catch (error: any) {
        if (error?.response?.status !== 401) {
          throw new Error(
            "[Movements] Error retrieving tickers_movements details for this instrument"
          );
        }
      }
    };
    getTickerDetails();
    Promise.all([getTickerDetails(), getTickerMovements({})]);
  }, []);

  const updateMovements = async (from: string, to: string) => {
    trackAction(`${currentScreen} - Apply date filter`);
    await getTickerMovements({ date_to: to, date_from: from });
  };

  useEffect(() => {
    trackScreen(screenNames.movementsDetail, currentScreen);
    dispatch(setCurrentScreen(screenNames.movementsDetail));
    if (!instrument_code) return;
  }, []);

  if (!instrument_code) return null;

  if (!isMobile)
    return (
      <div className={styles.tickerMovements}>
        <CommonLayout withHeader={false}>
          <TickerMovementsHeader
            instrument_code={instrument_code}
            tickersMovementsData={tickerData}
          />
          {tickerDetail ? (
            <TickerMovementsBody
              tickerData={tickerData}
              tickerDetails={tickerDetail}
              updateMovements={updateMovements}
              plainCard
            />
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "300px",
                justifyContent: "center",
              }}
            >
              <Spinner />
            </div>
          )}
        </CommonLayout>
      </div>
    );

  return (
    <div className={styles.tickerMovements}>
      <TickerMovementsHeader
        instrument_code={instrument_code}
        tickersMovementsData={tickerData}
      />
      {tickerDetail ? (
        <TickerMovementsBody
          tickerData={tickerData}
          tickerDetails={tickerDetail}
          updateMovements={updateMovements}
        />
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "300px",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default TickerMovements;
