import CommonLayout from "../../../layouts/common";
import ErrorContainer from "../error-modal";

import "./index.scss";

interface PropTypes {
  title: string;
  subtitle?: string;
  textPrimaryButton: string;
  textSecondaryButton?: string;
  urlPrimaryButton?: string;
  urlSecondaryButton?: string;
  onClickPrimaryButton?: () => void;
}

export const Error = (props: PropTypes) => {
  const {
    title,
    subtitle,
    textPrimaryButton,
    textSecondaryButton,
    urlPrimaryButton,
    urlSecondaryButton,
    onClickPrimaryButton,
  } = props;

  return (
    <CommonLayout hideBottomNavigation mobileHeaderTitle="">
      <div className="component-error">
        <ErrorContainer
          subtitle={subtitle}
          textPrimaryButton={textPrimaryButton}
          textSecondaryButton={textSecondaryButton}
          title={title}
          urlPrimaryButton={urlPrimaryButton}
          urlSecondaryButton={urlSecondaryButton}
          onClickPrimaryButton={onClickPrimaryButton}
        />
      </div>
    </CommonLayout>
  );
};
