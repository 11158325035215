import Heading, { HeadingVariants } from "components/common/Heading";
import { Info } from "lucide-react";
import px2rem from "utils/px2rem";

import GoldBenefit from "./Benefit";

import styles from "./styles.module.scss";

const GoldBenefitsList: React.FC = () => {
  const benefitsList = [
    {
      title: "Comunicación",
      description: "Directa y personalizada vía WhatsApp",
    },
    {
      title: "Seguimiento",
      description: "Monitoreo constante de tu cartera",
    },
    {
      title: "Informes",
      description: "Acceso a investigaciones y webinars",
    },
    {
      title: "Charlas",
      description: "Convocatoria a economistas reconocidos",
    },
    {
      title: "Operaciones",
      description: "Realizadas por vos sin costo adicional",
    },
    {
      title: "Reportes",
      description: "Rendimientos periódicos y otras métricas",
    },
  ];
  return (
    <div className={styles.goldBenefitsListContainer}>
      <div className={styles.title}>
        <Heading
          variant={HeadingVariants.RegularSubtitle}
          color="var(--slate800)"
          component="h3"
        >
          Qué está incluido
        </Heading>
        <Info size={px2rem(16)} color="var(--slate800)" />
      </div>
      <div className={styles.list}>
        {benefitsList.map(({ title, description }) => (
          <GoldBenefit title={title} description={description} key={title} />
        ))}
      </div>
    </div>
  );
};

export default GoldBenefitsList;
