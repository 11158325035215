import { CDN_URL } from "constants/index";

import type { ReactNode } from "react";

import {
  ArrowDownWideNarrow,
  ArrowUpWideNarrow,
  CreditCard,
  Minus,
  PlusIcon,
  QrCode,
  LucideIcon,
  Send,
} from "lucide-react";
import USFlag from "assets/icons/USFlag";
import {
  Cancelled,
  Executed,
  PartiallyCancelled,
  PartiallyExecuted,
  Pending,
  Rejected,
} from "assets/icons/OrderStatus";
import {
  OperationSide,
  OrderSource,
  OrderStatus,
} from "interfaces/orders/enums";
import dayjs from "dayjs";
import type {
  OrderDetailType,
  OrdersResponse,
} from "interfaces/orders/interface";
import type { FilterOrderType } from "context/ActivityProvider";
import { SpecialTickers } from "utils/constants";
import "dayjs/locale/es";

interface OrderStatusInfo {
  icon: ReactNode;
  text: string;
  color: string;
  lightColor: string;
}

export const formatDateToSpanish = (dateString: string): string => {
  return dayjs(dateString).locale("es").format("D [de] MMM");
};

export const formatLocalDate = (date: string, isIso: boolean = false) => {
  if (isIso) {
    const newDate = new Date(date);
    return new Intl.DateTimeFormat("es-AR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(newDate);
  }

  return date.split("-").reverse().join("/");
};

const OrderStatusMap: Record<OrderStatus, OrderStatusInfo> = {
  [OrderStatus.CANCELLED]: {
    icon: <Cancelled />,
    text: "Cancelada",
    color: "var(--slate900)",
    lightColor: "var(--slate200)",
  },
  [OrderStatus.PARTIALLY_CANCELLED]: {
    icon: <PartiallyCancelled />,
    text: "Cancelada parcialmente",
    color: "var(--slate900)",
    lightColor: "var(--slate200)",
  },
  [OrderStatus.EXECUTED]: {
    icon: <Executed />,
    text: "Ejecutada",
    color: "var(--green800)",
    lightColor: "var(--green200)",
  },
  [OrderStatus.PARTIALLY_EXECUTED]: {
    icon: <PartiallyExecuted />,
    text: "Ejecutada parcialmente",
    color: "var(--green800)",
    lightColor: "var(--green200)",
  },
  [OrderStatus.REJECTED]: {
    icon: <Rejected />,
    text: "Rechazada",
    color: "var(--red800)",
    lightColor: "var(--red200)",
  },
  [OrderStatus.PENDING]: {
    icon: <Pending />,
    text: "Pendiente de ejecución",
    color: "var(--orange800)",
    lightColor: "var(--orange200)",
  },
};

export const getOrderStatusMap = (status: OrderStatus): OrderStatusInfo => {
  return OrderStatusMap[status];
};

export const getOperationSideHeading = (
  oprationSide: OperationSide
): string => {
  if (oprationSide === OperationSide.BID) return "Licitación";
  if (oprationSide === OperationSide.BUY) return "Compra";
  if (oprationSide === OperationSide.SELL) return "Venta";
  if (oprationSide === OperationSide.REDEMPTION) return "Rescate";
  if (oprationSide === OperationSide.SUBSCRIPTION) return "Suscripción";
  if (oprationSide === OperationSide.WITHDRAWAL) return "Enviaste";
  if (oprationSide === OperationSide.TAKER) return "Tomador";
  if (oprationSide === OperationSide.SETTER) return "Colocador";

  return "Operación";
};

export const getSmallIcon = (
  side: OperationSide,
  source?: OrderSource
): LucideIcon => {
  if (source === OrderSource.CARD) return CreditCard;
  if (source === OrderSource.QR) return QrCode;
  if (side === OperationSide.SELL) return Minus;
  if (side === OperationSide.REDEMPTION) return ArrowDownWideNarrow;
  if (side === OperationSide.SUBSCRIPTION) return ArrowUpWideNarrow;
  if (side === OperationSide.WITHDRAWAL) return Send;

  return PlusIcon;
};

export const getSettlementDays = (settlementDays: number) => {
  if (settlementDays === 0) return "En C.I.";
  if (settlementDays === 1) return "En 24hs";
  if (settlementDays === 2) return "En 48hs";
  if (settlementDays === 3) return "En 72hs";

  return `A ${settlementDays} días`;
};

export const getMainIcon = (isMep: boolean, logo?: string) => {
  if (isMep) return <USFlag />;
  if (!logo) return <img src={`${CDN_URL}/logos/ARG.jpg`} />;

  return <img src={`${CDN_URL}/logos/${logo}`} />;
};

export const getFilteredOrders = (
  orderFilter: FilterOrderType,
  orders: OrderDetailType[]
) => {
  return orders.filter((item) => {
    if (orderFilter === OrderStatus.PENDING) {
      return (
        item.status === OrderStatus.PENDING ||
        item.status === OrderStatus.PARTIALLY_EXECUTED ||
        item.status === OrderStatus.PARTIALLY_CANCELLED
      );
    }
    if (orderFilter === OrderStatus.EXECUTED) {
      return item.status === OrderStatus.EXECUTED;
    }
    return item;
  });
};

export const getHasFilteredOrders = (
  orderFilter: FilterOrderType,
  ordersData?: OrdersResponse[]
) => {
  if (!ordersData) return false;

  return ordersData?.some(({ orders }) =>
    orders.some((order) => {
      if (orderFilter === OrderStatus.PENDING) {
        return (
          order.status === OrderStatus.PENDING ||
          order.status === OrderStatus.PARTIALLY_EXECUTED ||
          order.status === OrderStatus.PARTIALLY_CANCELLED
        );
      }
      if (orderFilter === OrderStatus.EXECUTED) {
        return order.status === OrderStatus.EXECUTED;
      }
      return true;
    })
  );
};

export const getTickerLabel = (ticker: string) => {
  if (ticker === SpecialTickers.caucion) return "Caución";

  if (ticker === SpecialTickers.closedMep || ticker === SpecialTickers.openMep)
    return "Dólar MEP";

  return ticker;
};
