import classNames from "classnames";
import HiddenValue from "components/common/HiddenValue";
import Text, { TextVariants } from "components/common/Text";
import { useAppState } from "context/AppStateProvider";
import { ThemeVariants } from "interfaces/theme";
import { Currencies } from "interfaces/wallet";
import { Eye, EyeOff } from "lucide-react";
import { formatPercentage, formatToCurrency, getCurrencySymbol } from "utils";
import { hapticsImpact } from "utils/haptics";
import px2rem from "utils/px2rem";
import { PaymentCurrencies } from "interfaces/pay/enums";

import styles from "./styles.module.scss";

interface AvailableLabelProps {
  available: number;
  withHideButton?: boolean;
  currency?: Currencies | PaymentCurrencies;
  variant?: ThemeVariants;
  hasPercentage?: boolean;
}

const AvailableLabel: React.FC<AvailableLabelProps> = ({
  available,
  withHideButton = true,
  currency = Currencies.ARS,
  variant = ThemeVariants.Crypto,
  hasPercentage = false,
}) => {
  const { isBalanceHidden, setIsBalanceHidden } = useAppState();

  const availableToString =
    formatToCurrency({ value: Math.abs(available) }) || "0,00";

  const availableSplitted = hasPercentage
    ? formatPercentage(available).split(",")
    : availableToString.split(",");

  const onClickButton = () => {
    hapticsImpact();
    setIsBalanceHidden(!isBalanceHidden);
  };

  const Icon = !isBalanceHidden ? Eye : EyeOff;
  const percentageColor = available >= 0 ? "var(--green800)" : "var(--red800)";

  return (
    <div className={styles.divContainer}>
      {!isBalanceHidden &&
        (hasPercentage ? (
          <Text
            variant={TextVariants.SemiboldTextXL}
            className={styles.availablePercentage}
            color={percentageColor}
          >
            {formatPercentage(available)}
          </Text>
        ) : (
          <Text
            variant={TextVariants.SemiboldText}
            className={`${styles.availableWrapper} ${styles.regularWeight}`}
          >
            {available < 0 && (
              <Text
                variant={TextVariants.SemiboldTextXL}
                component="span"
                color="var(--slate900)"
                className={styles.minusSign}
              >
                -
              </Text>
            )}
            <Text
              variant={TextVariants.RegularText}
              className={`${styles.regularWeight} ${styles.currency}`}
              component="span"
              color="var(--slate800)"
            >
              {getCurrencySymbol(currency)}
            </Text>
            <Text
              variant={TextVariants.SemiboldText}
              className={styles.integerPart}
              component="span"
              color="var(--slate900)"
            >
              {`${availableSplitted[0]},`}
            </Text>
            <Text
              variant={TextVariants.RegularText}
              className={styles.regularWeight}
              component="span"
              color="var(--slate800)"
            >
              {availableSplitted[1]}
            </Text>
          </Text>
        ))}
      {isBalanceHidden && (
        <Text
          variant={TextVariants.SemiboldText}
          className={`${styles.availableWrapper} ${styles.hidden} ${styles.regularWeight}`}
          color="var(--slate800)"
        >
          <HiddenValue
            className={styles.hiddenBalance}
            size={px2rem(14)}
            color="var(--slate900)"
          />
        </Text>
      )}
      {withHideButton && (
        <button onClick={onClickButton}>
          <Icon
            size={px2rem(24)}
            className={classNames(styles.eyeIcon, styles[variant])}
          />
        </button>
      )}
    </div>
  );
};

export default AvailableLabel;
