import FilledButton, {
  FilledButtonVariants,
} from "components/common/FilledButton";
import Divider from "components/common/Divider";
import { FileDown } from "lucide-react";
import Heading, { HeadingVariants } from "components/common/Heading";
import SegmentedControl from "components/common/SegmentedControl";
import {
  ActivityTabs,
  activityTabsOptions,
} from "components/page-activity/constants";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface HeaderProps {
  selectedTab?: ActivityTabs;
  setSelectedTab?: (value: ActivityTabs) => void;
}

const Header = ({ selectedTab, setSelectedTab }: HeaderProps) => {
  return (
    <div className={styles.topSectionWrapper}>
      <div className={styles.contentWrapper}>
        {!(selectedTab && setSelectedTab) ? (
          <Heading
            component="h1"
            variant={HeadingVariants.RegularTitle}
            color="var(--slate800)"
          >
            Órdenes
          </Heading>
        ) : (
          <div className={styles.switchWrapper}>
            <SegmentedControl
              selected={selectedTab}
              options={activityTabsOptions}
              onClick={(option) => setSelectedTab(option)}
              isFullWidth={false}
            />
          </div>
        )}
        {selectedTab === ActivityTabs.MOVEMENTS && (
          <FilledButton variant={FilledButtonVariants.Download}>
            Descarga de reportes <FileDown size={px2rem(16)} />
          </FilledButton>
        )}
      </div>
      <Divider className={styles.topDivider} />
    </div>
  );
};

export default Header;
