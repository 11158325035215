import { INSTRUMENTS } from "constants/instruments";

import React, { useState } from "react";

import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TransparentStar } from "assets/icons/favorite/transparent-star";
import { UIModalSlider } from "components/common/modal-slider";
import { getInstrumentTypePlaceholder } from "utils";
import { MarketSection, Sections } from "interfaces/markets";
import mainRoutes from "router/routes";
import MEPSelector from "components/common/MEPSelector";
import { ampli } from "ampli";

import "./index.scss";

interface ListModalProps {
  selected: string;
  open: boolean;
  onToggleDisplay: () => void;
}

const ListsModal: React.FC<ListModalProps> = ({
  open,
  selected,
  onToggleDisplay,
}) => {
  const [isMepOpen, setIsMepOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleClickList = (list: MarketSection) => (
    event: React.MouseEvent
  ) => {
    event.stopPropagation();
    if (selected !== list) {
      ampli.instrumentTypeChosen({ type: list });
      navigate(`${mainRoutes.market}/${list}`);
    }
    onToggleDisplay();
  };

  const handleClickMEP = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsMepOpen(true);
  };

  return (
    <>
      <UIModalSlider
        classes="lists-modal"
        open={open}
        onToggleDisplay={onToggleDisplay}
      >
        <Typography className="title" component="p" variant="textS_bold">
          Instrumentos
        </Typography>
        <div
          className={`list-row ${
            selected === Sections.FAVORITOS ? "selected" : ""
          }`}
          onClick={handleClickList(Sections.FAVORITOS)}
        >
          <div className="icon">
            <TransparentStar
              color={selected === "Favoritos" ? "#0062E1" : "#002C65"}
            />
          </div>
          <Typography className="border-left-selected" variant="textS">
            | Favoritos
          </Typography>
        </div>
        <div
          className={`list-row ${selected === "mep" ? "selected" : ""}`}
          onClick={handleClickMEP}
        >
          <div className="icon">
            <Typography variant="textXS_bold">DM</Typography>
          </div>
          <Typography className="border-left-selected" variant="textS">
            {" "}
            | Dólar MEP
          </Typography>
        </div>
        {INSTRUMENTS.map((instrument) => (
          <div
            key={instrument.instrument_type}
            className={`list-row ${
              selected === instrument.instrument_type ? "selected" : ""
            }`}
            onClick={handleClickList(instrument.instrument_type)}
          >
            <div className="icon">
              <Typography variant="textXS_bold">
                {getInstrumentTypePlaceholder(instrument.instrument_type)}
              </Typography>
            </div>
            <Typography className="border-left-selected" variant="textS">
              {" "}
              | {instrument.name}
            </Typography>
          </div>
        ))}
      </UIModalSlider>
      <MEPSelector isOpen={isMepOpen} onClose={() => setIsMepOpen(false)} />
    </>
  );
};

export default ListsModal;
