import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";
import { Operations } from "interfaces/crypto/enums";
import type { LucideIcon } from "lucide-react";
import { Currencies, BalanceCurrencies } from "interfaces/wallet";
import ARGFlag from "assets/icons/ARGFlag";
import { OperationSide } from "interfaces/orders/enums";
import StackedIcons from "components/common/StackedIcons";
import { getTickerLabel } from "components/page-orders/v2/utils";
import { SpecialTickers } from "utils/constants";
import PriceLabel, { PriceLabelSizes } from "components/common/PriceLabel";
import Amount, { AmountSizes } from "components/common/Amount";

import SecondaryInstrument from "./SecondaryInstrument";

import styles from "./styles.module.scss";

export type CapitalActivityCardProps = {
  amount: number;
  currency: BalanceCurrencies;
  heading: string;
  isBalanceHidden?: boolean;
  mainIcon: React.ReactNode;
  onClick: () => void;
  operationSide: OperationSide;
  quantity?: number;
  settlementDate?: string;
  smallIcon: LucideIcon;
  isMepOperation: boolean;
  ticker: string;
  usdAmount?: number;
};

const CapitalActivityCard: React.FC<CapitalActivityCardProps> = (props) => {
  const {
    amount,
    currency,
    heading,
    isBalanceHidden,
    isMepOperation,
    mainIcon = <ARGFlag />,
    onClick,
    operationSide,
    quantity,
    settlementDate,
    smallIcon,
    ticker,
    usdAmount,
  } = props;

  const isBid = operationSide === OperationSide.BID;
  const isWithdraw = operationSide === OperationSide.WITHDRAWAL;

  const isBuy =
    operationSide === OperationSide.BUY ||
    operationSide === OperationSide.TAKER ||
    isBid ||
    operationSide === OperationSide.SUBSCRIPTION;

  const isOpenMep = ticker === SpecialTickers.openMep;

  const shouldShowSecondaryInstrument =
    quantity && !isBid && !isOpenMep && !isMepOperation;

  return (
    <div className={styles.activityCardWrapper} onClick={onClick}>
      <div className={styles.iconsWrapper}>
        <StackedIcons
          withImage={false}
          icon={mainIcon}
          smallIcon={smallIcon}
          backgroundColor="var(--blue800)"
        />
      </div>
      <div className={styles.rowsWrapper}>
        <div className={styles.mainRow}>
          <Heading
            variant={HeadingVariants.RegularSubtitle}
            component="h3"
            color="var(--slate900)"
          >
            {heading}
          </Heading>
          <div className={styles.amountIcon}>
            <PriceLabel
              price={amount}
              currency={currency}
              isBalanceHidden={isBalanceHidden}
              size={PriceLabelSizes.Small}
            />
          </div>
        </div>
        <div className={styles.secondaryRow}>
          <div className={styles.secondaryTextWrapper}>
            <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
              {getTickerLabel(ticker)}
            </Text>
            {settlementDate && !isWithdraw && (
              <Text
                variant={TextVariants.RegularTextS}
                color="var(--slate800)"
                className={styles.settlementDivider}
              >
                {settlementDate}
              </Text>
            )}
          </div>
          {shouldShowSecondaryInstrument && (
            <SecondaryInstrument
              isCapital
              baseInstrument={ticker}
              baseAmount={quantity}
              operationType={isBuy ? Operations.SWAP : Operations.SELL}
              quoteAmount={quantity}
              isBalanceHidden={isBalanceHidden}
            />
          )}
          {isMepOperation && usdAmount && (
            <Amount
              amount={usdAmount}
              currency={Currencies.USD}
              secondaryColor="var(--slate800)"
              mainColor="var(--slate800)"
              size={AmountSizes.Small}
              withDecimals={false}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CapitalActivityCard;
