import { useActivity } from "context/ActivityProvider";
import EmptyStateWithOnboarding from "components/common/EmptyStateWithOnboarding";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { ThemeVariants } from "interfaces/theme";
import FirstInvestment from "assets/illustrations/FirstInvestment";
import { EmptyButtonTypes } from "components/common/EmptyState";
import mainRoutes from "router/routes";
import { useOrders } from "apis/activity/queries/useOrders";
import Skeleton from "react-loading-skeleton";
import FilledButton, {
  FilledButtonVariants,
} from "components/common/FilledButton";
import Text, { TextVariants } from "components/common/Text";
import { ListRestart } from "lucide-react";
import px2rem from "utils/px2rem";

import OrdersTable from "./OrdersTable";
import FilterPills from "../components/FilterPills";
import { getHasFilteredOrders } from "../utils";

import styles from "./styles.module.scss";

const OrdersContent = () => {
  const {
    isLoading,
    isFetching,
    data: ordersData,
    refetch: refetchOrders,
  } = useOrders();
  const { orderFilter, setOrderFilter } = useActivity();
  const navigate = useNavigate();
  const ICON_SIZE = px2rem(12);
  const color = "var(--blue900)";

  const hasFilteredOrders = getHasFilteredOrders(orderFilter, ordersData);

  const onClickRefreshList = () => {
    refetchOrders();
  };

  if (isLoading) return <Skeleton count={4} height={60} />;

  return (
    <div className={styles.desktopWrapper}>
      <div className={styles.desktopFiltersWrapper}>
        <FilterPills
          activeFilter={orderFilter}
          setActiveFilter={setOrderFilter}
        />
        <FilledButton
          isLoading={isFetching}
          className={styles.button}
          onClick={onClickRefreshList}
          variant={FilledButtonVariants.Download}
        >
          <Text variant={TextVariants.RegularTextS} color={color}>
            Actualizar lista
          </Text>
          <ListRestart size={ICON_SIZE} color={color} />
        </FilledButton>
      </div>
      {isEmpty(ordersData) || !ordersData || !hasFilteredOrders ? (
        <div className={styles.desktopEmptyStateWrapper}>
          <EmptyStateWithOnboarding
            extraEmptyState
            title="Hacé una inversión"
            subtitle="Visitá el mercado para invertir en Cedears, Acciones, Bonos Públicos, ONs, Letras, Fondos de Inversión y más."
            buttonText="Ir al mercado"
            onClick={() => navigate(mainRoutes.market)}
            illustration={<FirstInvestment />}
            buttonType={EmptyButtonTypes.BUTTON}
            buttonVariant={ThemeVariants.Capital}
          />
        </div>
      ) : (
        <OrdersTable orderFilter={orderFilter} orders={ordersData} />
      )}
    </div>
  );
};

export default OrdersContent;
