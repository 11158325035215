import { Calendar as DateRangeCalendar } from "react-date-range";
import { es } from "date-fns/locale";
import { subYears } from "date-fns";
import classNames from "classnames";

import styles from "./styles.module.scss";

interface CalendarProps {
  className?: string;
  date: Date;
  onChange: (date: Date) => void;
}

const Calendar = ({ className, date, onChange }: CalendarProps) => {
  const today = new Date();

  return (
    <DateRangeCalendar
      color="var(--slate900)"
      dateDisplayFormat="YYYY MM DD"
      className={classNames(styles.calendar, className)}
      classNames={{
        day: styles.day,
        dayDisabled: styles.dayDisabled,
        dayHovered: styles.dayHovered,
        dayPassive: styles.dayPassive,
        dayNumber: styles.dayNumber,
        month: styles.month,
        monthAndYearPickers: styles.monthAndYearPickers,
        monthName: styles.monthName,
        monthPicker: styles.pill,
        nextButton: styles.arrowButtons,
        prevButton: styles.arrowButtons,
        selected: styles.selected,
        weekDay: styles.weekDay,
        yearPicker: classNames(styles.pill, styles.pillMonth),
      }}
      date={date}
      maxDate={today}
      minDate={subYears(today, 3)}
      showPreview={false}
      weekStartsOn={0}
      locale={es}
      ranges={[{ startDate: today }]}
      onChange={onChange}
      months={1}
      direction="horizontal"
      showDateDisplay={false}
    />
  );
};

export default Calendar;
