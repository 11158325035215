import { CryptoTicker } from "interfaces/api-responses";
import { Operations } from "interfaces/crypto/enums";
import { Currencies } from "interfaces/wallet";

export const isValidOperation = (
  operation: Operations,
  baseTicker?: CryptoTicker | Currencies.ARS,
  quoteTicker?: CryptoTicker | Currencies.ARS
) => {
  const baseIsCrypto = baseTicker !== Currencies.ARS;
  const quoteIsCrypto = quoteTicker !== Currencies.ARS;

  const baseAllowed =
    baseIsCrypto && !!baseTicker?.allowed_operations?.includes(operation);
  const quoteAllowed =
    quoteIsCrypto && !!quoteTicker?.allowed_operations?.includes(operation);

  if (
    [Operations.RECEIVE, Operations.SEND, Operations.SELL].includes(
      operation
    ) &&
    baseIsCrypto
  ) {
    return baseAllowed;
  }

  if (operation === Operations.SWAP && baseIsCrypto && quoteIsCrypto) {
    return baseAllowed && quoteAllowed;
  }

  return quoteAllowed;
};
