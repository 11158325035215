import { useMutation } from "@tanstack/react-query";
import API from "apis";
import { queryClient } from "apis/queryClient";
import { ordersService } from "apis/services";
import type { ApiOrderResponse } from "interfaces/api-responses";
import type { ErrorMessage } from "interfaces/errors";
import { OrderType, OptionalOrder } from "interfaces/markets";

export const useFundOrderMutation = () => {
  return useMutation<ApiOrderResponse["Orden"], ErrorMessage, OptionalOrder>({
    mutationFn: async (order: OptionalOrder) => {
      const resp = await API.post<ApiOrderResponse>(ordersService.fciOrders, {
        type: order.type === OrderType.Buy ? "FUND" : "REDEMPTION",
        quantity: undefined,
        amount:
          order.type === OrderType.Sell && order.totalRedemption
            ? undefined
            : order.amount,
        long_ticker: order.long_ticker,
        total_redemption: order.totalRedemption,
      });

      if (!resp) {
        throw new Error("Error al generar la orden");
      }

      return resp.data.Orden;
    },
    onError: (err: ErrorMessage) => {
      return {
        message: err.message,
        status: 500,
      };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["portfolio"] });
      queryClient.invalidateQueries({ queryKey: ["fci"] });
    },
  });
};
