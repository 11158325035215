import { getCryptoElement } from "constants/cryptos";

import { useEffect, useState, ReactNode } from "react";

import CocosBrand, { CocosBrandVariants } from "assets/icons/CocosBrand";
import classNames from "classnames";
import Amount, { AmountSizes } from "components/common/Amount";
import HiddenValue from "components/common/HiddenValue";
import Text, { TextVariants } from "components/common/Text";
import { getNumberOfDecimals } from "components/page-cocos-portfolio/utils";
import { useAppState } from "context/AppStateProvider";
import { BalanceCurrencies } from "interfaces/wallet";
import { ArrowUpRight } from "lucide-react";
import { formatToCurrency } from "utils";
import px2rem from "utils/px2rem";
import Variation from "components/common/CurrentVariation";
import type { BookSocketResponse } from "interfaces/api-responses/socket/book";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { socket } from "socket";
import { Instruments } from "interfaces/markets";
import USFlag from "assets/icons/USFlag";
import { isInstrumentWithoutAmount } from "utils/isInstrumentWithoutAmount";

import styles from "./styles.module.scss";

export enum AssetBalanceVariants {
  PERFORMANCE = "performance",
  VALUE = "value",
}

interface AssetPerformanceProps {
  variant: AssetBalanceVariants.PERFORMANCE;
  amountVariation: number;
  amount: number;
}

interface AssetValueProps {
  variant: AssetBalanceVariants.VALUE;
  quantity: number;
  amount: number;
}

interface CryptoProps {
  isCrypto: true;
  quantity: number;
  image?: string;
}

interface CapitalProps {
  isCrypto?: false;
  image: string;
}

type AssetBalanceCardProps = {
  longName: string;
  longTicker?: string;
  shortName: string;
  onClick: (evt: any) => void;
  selectedCurrency: BalanceCurrencies;
  resultPercentage?: number;
  type: Instruments;
} & (AssetPerformanceProps | AssetValueProps) &
  (CapitalProps | CryptoProps);

const AssetBalanceCard: React.FC<AssetBalanceCardProps> = ({
  selectedCurrency,
  shortName,
  longName,
  longTicker,
  onClick,
  type,
  resultPercentage,
  ...props
}) => {
  const { isBalanceHidden } = useAppState();
  const { isMobile } = useDeviceCheck();
  const isPerformance = props.variant === AssetBalanceVariants.PERFORMANCE;
  const [
    socketVariationData,
    setSocketVariationData,
  ] = useState<BookSocketResponse>();

  const isAccumulated = ["Crypto", "Capital"].includes(shortName);
  const isInternationalLetter = type === Instruments.LETRA_INTERNACIONAL;

  const isCryptoAccumulated = shortName === "Crypto";

  const isAccumlatedColor =
    shortName === "Crypto" ? "var(--purple800)" : "var(--blue800)";

  const secondaryTextColor = "var(--slate800)";

  const getSymbolPerformance =
    isPerformance && props.amountVariation >= 0 ? "+" : "-";

  const renderIcon = () => {
    if (isAccumulated) {
      return (
        <CocosBrand
          variant={
            props.isCrypto
              ? CocosBrandVariants.CRYPTO
              : CocosBrandVariants.CAPITAL
          }
          size={px2rem(20)}
        />
      );
    }
    if (props.isCrypto) {
      const { icon: Icon } = getCryptoElement(shortName);
      return <Icon size={px2rem(20)} />;
    }

    if (isInternationalLetter) return <USFlag />;

    return <img src={props.image} loading="lazy" className={styles.image} />;
  };

  const formatQuantity = (num: number) => {
    let decimals = 2;

    if (props.isCrypto) {
      decimals = getNumberOfDecimals(num);
    }
    return formatToCurrency({
      value: num,
      splitIfInteger: true,
      numberOfDecimals: decimals,
    });
  };

  const getVariationColor = () => {
    if (!isPerformance) return "";

    if (props.amountVariation >= 0) return "var(--green800)";

    return "var(--red800)";
  };

  const renderWithHiddenValue = (item: ReactNode) =>
    isBalanceHidden ? (
      <HiddenValue size={7} count={4} color="var(--slate800)" />
    ) : (
      item
    );

  const handleOnClick = (evt: any) => {
    if ((!isMobile && isCryptoAccumulated) || isInstrumentWithoutAmount(type))
      return null;
    onClick(evt);
  };

  useEffect(() => {
    if (props.isCrypto || isPerformance) return;

    const messageHandler = (message: BookSocketResponse) => {
      if (
        message.long_ticker === longTicker &&
        message.variation !== socketVariationData?.variation
      ) {
        setSocketVariationData(message);
      }
    };

    socket.on("message", messageHandler);

    return () => {
      socket.off("message", messageHandler);
    };
  }, []);

  if (isPerformance && isInstrumentWithoutAmount(type)) return <></>;

  return (
    <div
      className={classNames(styles.cardWrapper, {
        [styles.pointer]: !isCryptoAccumulated,
      })}
      onClick={handleOnClick}
    >
      <div className={styles.row}>
        <div className={styles.brand}>
          {renderIcon()}
          <Text color="var(--slate900)" variant={TextVariants.RegularText}>
            {isPerformance ? shortName : longName}
          </Text>
        </div>
        {isPerformance ? (
          <div
            className={classNames(styles.variation, {
              [styles.hideBalances]: isBalanceHidden,
            })}
          >
            {!isBalanceHidden && (
              <Text
                color={getVariationColor()}
                variant={TextVariants.RegularText}
              >
                {getSymbolPerformance}
              </Text>
            )}
            <Amount
              isBalanceHidden={isBalanceHidden}
              size={AmountSizes.Default}
              amount={Math.abs(props.amountVariation)}
              currency={selectedCurrency}
              mainColor={getVariationColor()}
              secondaryColor={getVariationColor()}
            />
          </div>
        ) : props.amount && props.amount !== 0 ? (
          <Amount
            isBalanceHidden={isBalanceHidden}
            currency={selectedCurrency}
            amount={props.amount}
          />
        ) : undefined}
      </div>
      <div className={styles.row}>
        {isAccumulated && (
          <>
            <Text
              color={secondaryTextColor}
              variant={TextVariants.RegularTextS}
            >
              {longName}
            </Text>
            <ArrowUpRight color={isAccumlatedColor} size={px2rem(16)} />
          </>
        )}
        {!isAccumulated && (
          <>
            {!isPerformance &&
              renderWithHiddenValue(
                <Text
                  color={secondaryTextColor}
                  variant={TextVariants.RegularUpperValueS}
                >
                  {formatQuantity(props.quantity)} {shortName}
                </Text>
              )}
            <Text
              color={secondaryTextColor}
              variant={TextVariants.RegularTextS}
            >
              {isPerformance && longName}
              {socketVariationData?.variation !== undefined && (
                <Variation value={socketVariationData?.variation} />
              )}
            </Text>
            {isPerformance &&
              renderWithHiddenValue(
                <Variation value={resultPercentage ?? 0} />
              )}
          </>
        )}
      </div>
    </div>
  );
};

export default AssetBalanceCard;
