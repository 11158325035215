import { Cryptos, Network, getNetwork } from "constants/cryptos";

import { useEffect, useState } from "react";

import API from "apis";
import { cryptoService } from "apis/services";
import { ThemeVariants } from "interfaces/theme";
import ConfirmCodePage from "components/common/ConfirmCode";
import FilledButton from "components/common/FilledButton";
import PageHeader from "components/common/PageHeader";
import Selector from "components/common/Selector";
import Text, { TextVariants } from "components/common/Text";
import ConfirmationBottomSheet, {
  Step,
} from "components/page-crypto-send/ConfirmationBottomSheet";
import { SendMethod, useCryptoSend } from "context/CryptoSendProvider";
import {
  ConfirmSendOrderResponse,
  CreateSendOrderResponse,
  CryptoAvailableNetworks,
  CryptoPrice,
} from "interfaces/api-responses";
import { MFAErrorType, MFAType } from "interfaces/auth";
import { X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { cryptoScreenNames } from "router/routes";
import { Projects, trackAction } from "utils/amplitude";
import { queryClient } from "apis/queryClient";

import AmountSection from "./AmountSection";
import NetworkBottomSheet from "./NetworkBottomSheet";
import {
  CryptoWithdrawalErrors,
  CryptoWithdrawalPrevisualizationError,
} from "./utils";

import styles from "./styles.module.scss";

const AmountPage: React.FC = () => {
  const navigate = useNavigate();
  const {
    networks,
    sendMethod,
    selectedTag,
    setSelectedTag,
    selectedCrypto,
    selectedAccount,
    selectedNetwork,
    setSelectedCrypto,
    setSelectedNetwork,
  } = useCryptoSend();

  const [availableNetworks, setAvailableNetworks] = useState<
    CryptoAvailableNetworks[]
  >();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [messageError, setMessageError] = useState<string>("");
  const [confirmationError, setConfirmationError] = useState<
    CryptoWithdrawalErrors | MFAErrorType
  >();
  const [quantity, setQuantity] = useState<number>(0);

  const isTagSend = sendMethod === SendMethod.COCOSTAG;
  const isBlockchain = sendMethod === SendMethod.BLOCKCHAIN;

  const blockainIncomplete =
    (!selectedAccount || !selectedNetwork) && isBlockchain;
  const tagIncomplete = !selectedTag && isTagSend;

  const isDisabled =
    blockainIncomplete ||
    tagIncomplete ||
    !selectedCrypto ||
    !quantity ||
    quantity > selectedCrypto.quantity;

  const isMFAError = (error?: MFAErrorType | CryptoWithdrawalErrors) => {
    if (Object.values(MFAErrorType).includes(error as MFAErrorType)) {
      return error as MFAErrorType;
    }
    return;
  };

  const [order, setOrder] = useState<CreateSendOrderResponse>();
  const [isSheetOpen, setIsSheetOpen] = useState<boolean>(false);
  const [open2FA, setOpen2FA] = useState<boolean>(false);
  const [step, setStep] = useState<Step>(Step.PREVIEW);

  const onChangeQuantity = (q: number) => {
    setQuantity(q);
  };

  useEffect(() => {
    const filteredNetwork = networks.filter(
      (network) => network.name === selectedAccount?.network
    );
    setAvailableNetworks(filteredNetwork);
    if (filteredNetwork.length === 1) {
      const uniqueNetwork = filteredNetwork[0];
      const { icon } = getNetwork(uniqueNetwork.name);
      setSelectedNetwork({ ...uniqueNetwork, icon });
    }
  }, []);

  useEffect(() => {
    if (open2FA) {
      setConfirmationError(undefined);
    }
  }, [open2FA]);

  const handlePrevisualization = async () => {
    try {
      setMessageError("");
      setIsLoading(true);
      const postData = isBlockchain
        ? {
            idAddress: selectedAccount?.idAddress,
            network: selectedNetwork?.name,
          }
        : {
            tag: selectedTag?.name,
          };

      const { data } = await API.post<CreateSendOrderResponse>(
        cryptoService.createSendOrder,
        {
          ...postData,
          quantity: quantity,
          ticker: selectedCrypto?.ticker,
        }
      );
      setOrder(data);
      setIsSheetOpen(true);
    } catch (error: any) {
      const errorResponse = error.response?.data;

      let message =
        "No se pudo realizar la operación. Por favor volvé a intentarlo.";

      if (
        errorResponse?.message ===
        CryptoWithdrawalPrevisualizationError.SMALL_AMOUNT
      ) {
        const minValue = await getValuedPrice(errorResponse.minAmountUsd);
        minValue &&
          (message = `El monto mínimo de envío es de ${minValue} ${selectedCrypto?.ticker}`);
      }

      setMessageError(message);
    } finally {
      setIsLoading(false);
    }
  };

  const getValuedPrice = async (minAmountUsd: number = 5) => {
    try {
      const { data } = await API.get<CryptoPrice[]>(cryptoService.prices, {
        params: {
          baseTicker: selectedCrypto?.ticker,
          quoteTicker: Cryptos.USDT,
        },
      });

      return String((minAmountUsd / data[0].ask).toFixed(8));
    } catch (error) {
      return false;
    }
  };

  const onHandleConfirm = async (code?: string) => {
    try {
      trackAction(
        `${cryptoScreenNames.send} - Confirm send`,
        {
          ticker: selectedCrypto?.ticker,
          quantity: quantity,
          send_method: sendMethod,
        },
        Projects.CRYPTO
      );

      await API.post<ConfirmSendOrderResponse>(cryptoService.confirmSendOrder, {
        idOrder: order?.idOrder,
        confirmationCode: code,
      });

      setStep(Step.CONFIRMATION);
      setOpen2FA(false);

      return false;
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message;
      setConfirmationError(errorMessage);
      const missedTFA = errorMessage === MFAErrorType.INVALID_SECURITY_CODE;

      if (missedTFA && !confirmationError) {
        return true;
      }
      setStep(Step.ERROR);
      setOpen2FA(false);
      return true;
    } finally {
      queryClient.invalidateQueries({ queryKey: ["portfolio"] });
      setIsLoading(false);
    }
  };

  const handleOnClose = () => {
    setIsSheetOpen(false);
    setStep(Step.PREVIEW);
    setConfirmationError(undefined);
  };

  const handleOnClickNetwork = (value: Network) => {
    setSelectedNetwork(value);
  };

  if (blockainIncomplete && tagIncomplete) return null;

  const onClickSend = () => {
    if (isTagSend) {
      setIsLoading(true);
      onHandleConfirm();
      return;
    }
    setOpen2FA(true);
  };

  if (open2FA)
    return (
      <div className={styles.confirmCodeWrapper}>
        <ConfirmCodePage
          error={isMFAError(confirmationError)}
          factorType={MFAType.MAIL}
          onClickBack={() => setOpen2FA(false)}
          variant={ThemeVariants.Crypto}
          onClickContinue={async (code: string) => {
            return await onHandleConfirm(code);
          }}
        />
      </div>
    );

  return (
    <div className={styles.amountPageContainer}>
      <div className={styles.wrapper}>
        <PageHeader
          title={"Ingresá un monto"}
          onClick={() => navigate(-1)}
          icon={X}
          className={styles.sidePadding}
        />
        <div className={styles.operationContainer}>
          <section className={styles.sidePadding}>
            <AmountSection
              selectedCrypto={selectedCrypto}
              onChangeQuantity={onChangeQuantity}
            />
          </section>
          {isBlockchain && selectedCrypto && (
            <Selector
              sendMethod={sendMethod}
              ticker={selectedCrypto.ticker}
              network={selectedNetwork}
              onClickCrypto={() => setSelectedCrypto(undefined)}
              onClickNetwork={() => setSelectedNetwork(undefined)}
            />
          )}
          {isTagSend && selectedCrypto && (
            <Selector
              tag={selectedTag}
              ticker={selectedCrypto.ticker}
              sendMethod={SendMethod.COCOSTAG}
              onClickTag={() => setSelectedTag(undefined)}
              onClickCrypto={() => setSelectedCrypto(undefined)}
            />
          )}
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        {messageError && (
          <Text
            color="var(--red800)"
            className={styles.text}
            variant={TextVariants.RegularTextS}
          >
            {messageError}
          </Text>
        )}
        <FilledButton
          disabled={isDisabled}
          isLoading={isLoading}
          className={styles.button}
          onClick={handlePrevisualization}
          variant={ThemeVariants.Crypto}
        >
          Previsualizar envío
        </FilledButton>
      </div>

      {!selectedNetwork && isBlockchain && availableNetworks && (
        <NetworkBottomSheet
          networks={availableNetworks}
          onClick={handleOnClickNetwork}
        />
      )}
      {order && (
        <ConfirmationBottomSheet
          step={step}
          order={order}
          amount={quantity}
          isOpen={isSheetOpen}
          isLoading={isLoading}
          error={confirmationError}
          onClickSend={onClickSend}
          onToggleDisplay={handleOnClose}
        />
      )}
    </div>
  );
};

export default AmountPage;
