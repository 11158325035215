import { cryptoStatus } from "constants/cryptos";

import { ampli } from "ampli";
import classNames from "classnames";
import NavBarButton from "components/common/NavBarButton";
import SegmentedControl from "components/common/SegmentedControl";
import Avatar from "components/common/avatar";
import { useAppSelector } from "hooks/redux";
import { StatusOnboarding } from "interfaces/createAccount";
import { ThemeVariants } from "interfaces/theme";
import { Search } from "lucide-react";
import { Navigate, useNavigate } from "react-router-dom";
import mainRoutes, { CryptoRoutes } from "router/routes";

import styles from "./styles.module.scss";

interface HomeHeaderProps {
  variant: ThemeVariants.Capital | ThemeVariants.Crypto;
  setIsNoAccountSheetOpen: (isOpen: boolean) => void;
}

const HomeHeader: React.FC<HomeHeaderProps> = ({
  variant,
  setIsNoAccountSheetOpen,
}) => {
  const navigate = useNavigate();

  const statusOnboarding = useAppSelector(
    (state) => state.user.user?.statusOnboarding
  );

  const { user } = useAppSelector((state) => state.user);

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  const { latest_tnc_accepted, status_crypto } = user;

  const isDisabled = statusOnboarding !== StatusOnboarding.APPROVED;
  const isCapital = variant === ThemeVariants.Capital;

  const onClickControl = () => {
    if (isDisabled) {
      ampli.onboardingBottomsheetViewed({ referral: "crypto" });
      setIsNoAccountSheetOpen(true);
      return;
    }

    const cryptoRoute =
      latest_tnc_accepted && status_crypto === cryptoStatus.APPROVED
        ? CryptoRoutes.HOME
        : CryptoRoutes.TYC;

    if (isCapital) {
      ampli.homeExited({ referral: "crypto" });
    }

    const routeToNavigateTo = isCapital ? cryptoRoute : mainRoutes.home;

    navigate(routeToNavigateTo);
  };

  const onClickProfile = () => {
    const routeToNavigateTo = isCapital
      ? mainRoutes.profile
      : CryptoRoutes.PROFILE;

    if (isCapital) {
      ampli.homeExited({ referral: "profile" });
    }

    navigate(routeToNavigateTo);
  };

  return (
    <header
      className={classNames(styles.headerWrapper, {
        [styles.crypto]: !isCapital,
      })}
    >
      <div className={styles.container}>
        <Avatar onClick={onClickProfile} variant={variant} />
        <SegmentedControl
          isBrandType
          isFullWidth={false}
          onClick={onClickControl}
          selected={isCapital ? "Capital" : "Crypto"}
          options={[{ name: "Capital" }, { name: "Crypto" }]}
        />
        <div style={{ visibility: "hidden" }}>
          <NavBarButton icon={Search} variant={variant} />
          {/* Out of the first version for now, using opacity 0 to ensure the segmented control is centered in the header */}
        </div>
      </div>
    </header>
  );
};

export default HomeHeader;
