/**
 * @description returns css variable color for the string.
 */
export const getColor = (colorName: string) => {
  const style = getComputedStyle(document.body);
  const color = style.getPropertyValue(colorName);
  const defaultColor = style.getPropertyValue("--purple900");

  return color || defaultColor;
};
