import API from "apis";
import { useAppQuery } from "apis/queryClient";
import { userKeys } from "apis/queryKeyFactories";
import { userService } from "apis/services";
import { Tiers, TierResponse } from "interfaces/user";

const EIGHT_HOURS = 8 * 60 * 60 * 1000;

export const fetchAccountTier = async () => {
  const { data } = await API.get<TierResponse>(userService.accountTier);

  const goldRequested =
    data.tier === Tiers.FREE &&
    data.tierUpgrade.requested &&
    Tiers.GOLD_REQUESTED;

  const goldAvailable =
    !goldRequested &&
    data.tier !== Tiers.GOLD &&
    data.tierUpgrade.propose === "gold" &&
    Tiers.GOLD_AVAILABLE;

  return {
    tier: goldRequested || goldAvailable || data.tier,
    duty: data.aranceles,
  };
};

export const useAccountTierQuery = () => {
  return useAppQuery({
    options: {
      queryKey: userKeys.accountTier,
      queryFn: fetchAccountTier,
      staleTime: Infinity,
      gcTime: EIGHT_HOURS,
    },
  });
};
