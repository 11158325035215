import { CDN_URL } from "constants/index";
import ff from "constants/featureFlags";

import { useEffect, useState } from "react";

import { Divider, Typography } from "@mui/material";
import GoogleTagManager from "react-gtm-module";
import { InfoIcon } from "assets/icons/info";
import { Currency } from "components/common/currency";
import ErrorContainer from "components/common/error-modal";
import Success from "components/common/success";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { OrderType } from "interfaces/markets";
import mainRoutes, { screenNames } from "router/routes";
import {
  confirmOrder,
  setCreateOrderError,
  resetOrder,
} from "features/markets";
import ButtonUI, {
  ButtonSizes,
  ButtonVariants,
} from "components/common/button";
import CheckboxUI from "components/common/controls/checkbox";
import IconText from "components/common/icon-text";
import { getComisiones, getCurrencyLabel, getMessageError } from "utils";
import { trackAction } from "utils/amplitude";
import { getColor } from "utils/getColor";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import useFeatureFlag from "hooks/useFeatureFlag";

import "./index.scss";

interface PropTypes {
  onClose: () => void;
  onClickOrderDetail?: (orderId: string) => void;
  infoLabel?: string;
}

export const ConfirmFCIOrderContainer: React.FC<PropTypes> = ({
  onClose,
  onClickOrderDetail,
  infoLabel,
}) => {
  const dispatch = useAppDispatch();
  const order = useAppSelector((state) => state.markets.newOrder);
  const marketDetail = useAppSelector((state) => state.markets.marketDetail);
  const loading = useAppSelector((state) => state.markets.createOrderLoading);
  const getOrderLoading = useAppSelector(
    (state) => state.markets.getOrderLoading
  );
  const orderError = useAppSelector((state) => state.markets.createOrderError);
  const { isFeatureEnabled } = useFeatureFlag(ff.ENABLE_ORDERS_REDESIGN);
  const [step, setStep] = useState(1);
  const [confirmReglamento, setConfirmReglamento] = useState(false);
  const { isMobile } = useDeviceCheck();
  const [orderId, setOrderId] = useState<string | undefined>();
  const comisiones = getComisiones("FCI", null, order.type, order.amount);

  const isBuy = order.type === OrderType.Buy;

  const handleClickConfirm = async () => {
    const trackProps = {
      ...order,
      quantity: Math.floor(order.quantity || 0),
      ticker: order.instrument_code || order.long_ticker?.split("-")[0],
    };

    trackAction(`${screenNames.fci} - Send Order`, trackProps);

    dispatch(confirmOrder({ order: order, instrument_type: "FCI" }));
  };

  const handleClickCancel = async () => {
    onClose && onClose();
  };

  const handleClickOrderDetail = () => {
    if (onClickOrderDetail && orderId) {
      onClickOrderDetail(orderId);
    }
  };

  const trackingEvent = () => {
    GoogleTagManager.dataLayer({
      dataLayer: {
        event: "fci_order_success",
        ticker: order.long_ticker,
      },
    });
  };

  const getOrdersUrl = () => {
    if (isFeatureEnabled) {
      return `${mainRoutes.orders}?id=${order.id}`;
    }

    return `${mainRoutes.orderDetail}/${order.id}`;
  };

  useEffect(() => {
    if (order.id || orderError.message) {
      setOrderId(order.id);
      setStep(step + 1);
    }

    return () => {
      if (order.id && order.long_ticker) {
        dispatch(resetOrder({ long_ticker: order.long_ticker }));
      }
      dispatch(setCreateOrderError({ message: "", status: null }));
    };
  }, [order.id]);

  return (
    <div className="comp-confirm-fci-order-container">
      {step === 1 && (
        <div className="step-1">
          <Typography variant="textS_bold">Confirmar orden</Typography>
          <div className="box">
            <div>
              <Typography component={"p"}>Fondo</Typography>
              <Typography variant="textL_bold">{order.long_ticker}</Typography>
              &nbsp;
              <Typography variant="textS">
                {order.instrument_short_name}
              </Typography>
            </div>
            <div>
              <Typography component={"p"}>
                {isBuy ? "Monto a Invertir" : "Monto a recibir"}
              </Typography>
              <Typography component={"p"} variant="textL_bold">
                {`${getCurrencyLabel(order.currency)} `}
                <Currency>{order.amount}</Currency>
              </Typography>
            </div>
            <div>
              <Typography component={"p"}>Comisiones estimadas</Typography>
              <Typography component={"p"} variant="textL_bold">
                {`${getCurrencyLabel(order.currency)} `}
                <Currency>{comisiones[1]}</Currency>
              </Typography>
            </div>
            <Divider />
            <div>
              <Typography className="debit-text" component={"p"}>
                {isBuy ? "Estimado a debitar" : "Estimado a acreditar"}
              </Typography>
              <Typography component={"p"} variant="textL_bold">
                {`${getCurrencyLabel(order.currency)} `}
                <Currency>{comisiones[0]}</Currency>
              </Typography>
            </div>
          </div>
          {isBuy && (
            <CheckboxUI
              checked={confirmReglamento}
              label={
                <Typography variant="textM">
                  Acepto el&nbsp;
                  <b>
                    <a
                      href={`${CDN_URL}/reglamentos/${marketDetail?.rules_file_name}`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      Reglamento de gestión
                    </a>
                  </b>
                </Typography>
              }
              onChange={(newValue) => setConfirmReglamento(newValue)}
            />
          )}
          {infoLabel && (
            <IconText
              icon={
                <InfoIcon size={24} color={getColor("--darkblue-to-light")} />
              }
              label={<Typography variant="textS">{infoLabel}</Typography>}
            />
          )}
          <ButtonUI
            disabled={loading || (!confirmReglamento && isBuy)}
            size={ButtonSizes.Full}
            variant={ButtonVariants.Primary}
            onClick={handleClickConfirm}
            needReset={false}
            newClass={"confirm-button"}
          >
            <Typography variant="buttonL">Confirmar</Typography>
          </ButtonUI>
          <ButtonUI
            disabled={loading}
            size={ButtonSizes.Full}
            variant={ButtonVariants.Secondary}
            onClick={handleClickCancel}
            needReset={false}
          >
            <Typography variant="buttonL">Cancelar</Typography>
          </ButtonUI>
        </div>
      )}
      {step === 2 && order.id && (
        <div className="step-2">
          <Success
            loadingSecondaryButton={getOrderLoading}
            subtitle={
              <>
                Tu orden de {order.long_ticker?.split("-")[0]} por un monto
                estimado de {`${getCurrencyLabel(order.currency)} `}
                {<Currency>{order.amount}</Currency>}
                &nbsp;fue enviada con éxito.
              </>
            }
            textPrimaryButton="Ir a Mercado"
            textSecondaryButton="Ver estado de orden"
            title="¡Tu orden fue enviada con éxito!"
            urlPrimaryButton={
              window.location.pathname.includes("market")
                ? undefined
                : mainRoutes.market
            }
            urlSecondaryButton={isMobile ? getOrdersUrl() : undefined}
            onClickPrimaryButton={onClose}
            onClickSecondaryButton={handleClickOrderDetail}
            trackingEvent={trackingEvent}
          />
        </div>
      )}
      {step === 2 && orderError.message && !order.id && (
        <ErrorContainer
          subtitle={getMessageError(orderError.message)}
          textPrimaryButton={"Volver al activo"}
          title="Ups... Hubo un error"
          onClickPrimaryButton={onClose}
        />
      )}
    </div>
  );
};
