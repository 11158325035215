import classNames from "classnames";
import Text, { TextVariants } from "components/common/Text";

import styles from "./styles.module.scss";

interface TableRowHeaderProps {
  alignRight: boolean;
  label: string;
}

const TableRowHeader = ({ alignRight, label }: TableRowHeaderProps) => {
  return (
    <div className={classNames({ [styles.alignRight]: alignRight })}>
      <Text variant={TextVariants.RegularValueS} color="var(--slate800)">
        {label}
      </Text>
    </div>
  );
};

export default TableRowHeader;
