import HighlightedBanner from "components/common/HighlightedBanner";
import StatBlock from "components/common/StatBlock";
import { Currencies } from "interfaces/wallet";
import { CircleDollarSign } from "lucide-react";
import { formatPercentage, getCurrencySymbol } from "utils";

import styles from "./styles.module.scss";

interface HighlightedInvestmentProps {
  tir: number;
  settlement: string;
  currency: Currencies;
  onClick: () => void;
  title?: string;
  isTirEstimated?: boolean;
}

const HighlightedInvestment: React.FC<HighlightedInvestmentProps> = ({
  tir,
  onClick,
  currency,
  settlement,
  isTirEstimated,
  title,
}) => {
  const heading = title ?? "Nueva inversión destacada";
  const formattedTir = formatPercentage(tir / 100, false);
  const statBlockData = [
    {
      name: "Moneda",
      value: getCurrencySymbol(currency, true).replace("$", "S"),
    },
    {
      name: "Tasa",
      value: isTirEstimated ? `≈ ${formattedTir}` : formattedTir,
    },
    {
      name: "Plazo",
      value: settlement,
    },
  ];

  return (
    <HighlightedBanner
      color="green"
      onClick={onClick}
      icon={CircleDollarSign}
      heading={heading}
    >
      <div className={styles.statBlockContainer}>
        <StatBlock statBlockData={statBlockData} />
      </div>
    </HighlightedBanner>
  );
};

export default HighlightedInvestment;
