import API from "apis";
import { useAppQuery } from "apis/queryClient";
import { cardKeys } from "apis/queryKeyFactories";
import { cardService } from "apis/services";
import { PomeloData } from "interfaces/card/interface";

const HALF_HOUR = 1000 * 60 * 30;

const fetchCardUserData = async () => {
  const { data } = await API.get<PomeloData>(
    `${cardService.user}?isLight=true`
  );

  return data;
};

export const useCardUserQuery = () => {
  return useAppQuery({
    options: {
      queryKey: cardKeys.cardUser,
      queryFn: fetchCardUserData,
      retry: false,
      staleTime: HALF_HOUR,
      gcTime: HALF_HOUR,
    },
  });
};
