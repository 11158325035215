import { COPY_MESSAGE_TIME } from "constants/index";

import { useState } from "react";

import Text, { TextVariants } from "components/common/Text";
import { CheckCheckIcon, Copy } from "lucide-react";
import Divider from "components/common/Divider";
import { Clipboard } from "@capacitor/clipboard";
import type { OrderDetailType } from "interfaces/orders/interface";
import { OperationSide, OrderStatus, OrderType } from "interfaces/orders/enums";
import { formatDateToHour } from "utils/formatDate";
import Toast, { ToastVariants } from "components/common/Toast";
import PriceLabel, { PriceLabelSizes } from "components/common/PriceLabel";
import { SpecialTickers } from "utils/constants";
import { formatToCurrency } from "utils";

import {
  getSettlementDays,
  getOrderStatusMap,
  formatLocalDate,
} from "../../utils";

import styles from "./styles.module.scss";

interface OrderDetailRowProps {
  title: string;
  detail: string;
  isSecondaryBold?: boolean;
}

const OrderDetailRow = ({
  title,
  detail,
  isSecondaryBold,
}: OrderDetailRowProps) => (
  <div className={styles.orderDetailRow}>
    <Text color="var(--slate800)">{title}</Text>
    <Text
      variant={
        isSecondaryBold ? TextVariants.SemiboldText : TextVariants.RegularText
      }
      color="var(--slate900)"
    >
      {detail}
    </Text>
  </div>
);

const OrderDetailInfo = ({ orderDetail }: { orderDetail: OrderDetailType }) => {
  const {
    price,
    currency,
    childOrders,
    createdAt,
    settlementDate: orderSettlementDate,
    settlementDays,
    orderId,
    orderType,
    quantity,
    side,
    status,
    averagePrice,
  } = orderDetail;
  const [isCopied, setIsCopied] = useState(false);
  const { icon, text, color } = getOrderStatusMap(status);

  const isMepOrder = !!childOrders;
  const isOpenMep = orderDetail.ticker === SpecialTickers.openMep;
  const isCaucion = orderDetail.ticker === SpecialTickers.caucion;
  const isBid = side === OperationSide.BID;
  const isLimitOrder = orderType === OrderType.LIMIT;
  const isWithdraw = side === OperationSide.WITHDRAWAL;
  const isPending = status === OrderStatus.PENDING;
  const isCanceled = status === OrderStatus.CANCELLED;

  const shouldDisplayPrice =
    !isBid && !isOpenMep && !isWithdraw && !isPending && !isCanceled;

  const isSpecialOrder = isMepOrder || isCaucion || isBid || isOpenMep;

  const date = createdAt;
  const settlementDate =
    orderSettlementDate && formatLocalDate(orderSettlementDate);

  const operationTypeMap = {
    [OperationSide.TAKER]: "Tomador",
    [OperationSide.SETTER]: "Colocador",
    [OperationSide.BUY]: "Compra",
    [OperationSide.SELL]: "Venta",
    [OperationSide.REDEMPTION]: "Rescate",
    [OperationSide.SUBSCRIPTION]: "Suscripción",
    [OperationSide.BID]: "Licitación",
    [OperationSide.WITHDRAWAL]: "Retiro",
  };

  const getOperationType = (side: OperationSide): string => {
    return operationTypeMap[side] || "--";
  };

  const onClickCopy = async (value: string) => {
    try {
      await Clipboard.write({ string: value });
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, COPY_MESSAGE_TIME);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.orderDetailContainer}>
      <div className={styles.orderDetailRow}>
        <Text color="var(--slate800)">Estado</Text>
        <div className={styles.orderStatusDetail}>
          {icon}
          <Text color={color} variant={TextVariants.SemiboldText}>
            {text}
          </Text>
        </div>
      </div>
      <OrderDetailRow title="Operación" detail={getOperationType(side)} />
      {orderType && !isSpecialOrder && (
        <OrderDetailRow
          title="Tipo"
          detail={orderType === OrderType.LIMIT ? "Límite" : "Mercado"}
        />
      )}
      {quantity?.executed && !isSpecialOrder ? (
        <div className={styles.orderDetailRow}>
          <Text color="var(--slate800)">Ejecutado</Text>
          <div>
            <Text color="var(--slate800)">
              <b style={{ color: "var(--slate900)" }}>
                {formatToCurrency({
                  value: Math.abs(quantity?.executed),
                  splitIfInteger: true,
                })}{" "}
              </b>
              {orderDetail.ticker}
            </Text>
          </div>
        </div>
      ) : undefined}
      {!isBid && !isOpenMep && isLimitOrder && (
        <div className={styles.orderDetailRow}>
          <Text color="var(--slate800)">Precio límite</Text>
          {price ? (
            <PriceLabel
              price={price}
              currency={currency}
              size={PriceLabelSizes.Small}
            />
          ) : (
            <Text color="var(--slate900)">--</Text>
          )}
        </div>
      )}
      {shouldDisplayPrice && (
        <div className={styles.orderDetailRow}>
          <Text color="var(--slate800)">
            {isMepOrder ? "Cotización" : "Precio promedio"}
          </Text>
          {price ? (
            <PriceLabel
              price={averagePrice ?? price}
              currency={currency}
              size={PriceLabelSizes.Small}
            />
          ) : (
            <Text color="var(--slate900)">--</Text>
          )}
        </div>
      )}
      <OrderDetailRow
        title="Fecha"
        isSecondaryBold
        detail={formatDateToHour(date)}
      />

      {!isWithdraw && (
        <OrderDetailRow
          title="Plazo de liqui."
          detail={getSettlementDays(settlementDays)}
        />
      )}

      {settlementDate && (
        <OrderDetailRow
          title="Fecha de liqui."
          detail={settlementDate}
          isSecondaryBold
        />
      )}

      <Divider className={styles.orderDetailDivider} />

      <div className={styles.orderDetailRow}>
        <div className={styles.orderDetailIdRow}>
          <Text color="var(--slate800)">N° de orden</Text>
          <Text variant={TextVariants.SemiboldText} color="var(--slate900)">
            {orderId}
          </Text>
        </div>
        {isCopied ? (
          <>
            <div className={styles.toastWrapper}>
              <Toast
                variant={ToastVariants.Success}
                message="Copiado exitosamente"
              />
            </div>
            <CheckCheckIcon color="var(--green800)" />
          </>
        ) : (
          <Copy
            color="var(--slate800)"
            className={styles.copy}
            onClick={() => onClickCopy(orderId)}
          />
        )}
      </div>
    </div>
  );
};

export default OrderDetailInfo;
