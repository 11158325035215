import GoldBannerBenefit from "../Benefit";

import styles from "./styles.module.scss";

const GoldBannerBenefitsWrapper: React.FC = () => {
  return (
    <div className={styles.goldBannerBenefitsWrapperContainer}>
      <GoldBannerBenefit className={styles.first} text="Reportes periódicos" />
      <GoldBannerBenefit className={styles.second} text="Charlas exclusivas" />
      <GoldBannerBenefit
        className={styles.third}
        text="Atención personalizada"
      />
    </div>
  );
};

export default GoldBannerBenefitsWrapper;
