import { useState } from "react";

import { useTickersQuery } from "apis/crypto/queries/useTickers";

import FilterChip from "./FilterChip";

import styles from "./styles.module.scss";

interface ActivityFiltersProps {
  onClick: (ticker: string | undefined) => void;
}

const ActivityFilters: React.FC<ActivityFiltersProps> = ({ onClick }) => {
  const { data: cryptos } = useTickersQuery();

  const [selectedCrypto, setSelectedCrypto] = useState<string>();

  const handleClick = (ticker: string) => {
    let newCrypto;
    if (selectedCrypto !== ticker) newCrypto = ticker;
    setSelectedCrypto(newCrypto);
    onClick(newCrypto);
  };

  return (
    <div className={styles.activityFiltersWrapper}>
      <div className={styles.chipCarrousel}>
        {cryptos?.map(({ ticker }) => (
          <FilterChip
            key={ticker}
            crypto={ticker}
            onClick={handleClick}
            selected={ticker === selectedCrypto}
          />
        ))}
      </div>
    </div>
  );
};

export default ActivityFilters;
