import { CocosFunds } from "constants/instruments";

import { PaymentCurrencies } from "interfaces/pay/enums";
import { Currencies, BalanceCurrencies } from "interfaces/wallet";
import { getAnnualRate } from "utils/getAnnualRate";

export interface CocosFund {
  ticker: PaymentCurrencies;
  securityId: number;
  name: string;
  currency: BalanceCurrencies;
  rate: number;
  tyc: string;
}

export const CocosFundsList: CocosFund[] = [
  {
    ticker: CocosFunds.AHORRO,
    securityId: 103874928,
    name: "Cocos Ahorro",
    currency: Currencies.ARS,
    rate: getAnnualRate(CocosFunds.AHORRO),
    tyc:
      "https://assets.cocos.capital/cocos/reglamentos/2024-08-28 - TO RG Cocos Ahorro FCI - Cocos.pdf",
  },
  {
    ticker: CocosFunds.RMA,
    securityId: 62048633,
    name: "Cocos Daruma Renta Mixta",
    currency: Currencies.ARS,
    rate: getAnnualRate(CocosFunds.RMA),
    tyc:
      "https://assets.cocos.capital/cocos/reglamentos/2024-08-28 - TO RG Cocos Daruma Renta Mixta FCI - Cocos.pdf",
  },
  {
    ticker: CocosFunds.USD,
    securityId: 110714484,
    name: "Cocos Ahorro Dólares",
    currency: Currencies.USD,
    rate: getAnnualRate(CocosFunds.USD),
    tyc:
      "https://assets.cocos.capital/cocos/reglamentos/2024-08-28 - TO RG Cocos Ahorro Dólares FCI - Cocos.pdf",
  },
];

export const enum FundScreen {
  FUND_RETURNS = "Rendimientos",
  ADD_FUNDS = "Agregar",
  WITHDRAW_FUNDS = "Retirar",
}

export type AvailableFunds =
  | CocosFunds.AHORRO
  | CocosFunds.RMA
  | CocosFunds.USD;

export const isCocosFundRedesign = (ticker: string) => {
  return (
    ticker === CocosFunds.AHORRO ||
    ticker === CocosFunds.RMA ||
    ticker === CocosFunds.USD
  );
};

export const getCuotapartes = (values: any) => {
  let min;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id_instrument, id_account, ...availables } = values;

  if (Object.prototype.hasOwnProperty.call(values, "subyacente")) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { subyacente, ...availableValues } = availables;

    min = Math.min(
      ...Object.values(availableValues).map((val: any) => {
        return parseFloat(val);
      })
    );
  } else {
    min = Math.min(
      ...Object.values(availables).map((val: any) => {
        return parseFloat(val);
      })
    );
  }

  return min;
};
