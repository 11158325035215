import Variation from "components/common/CurrentVariation";
import StatBlock from "components/common/StatBlock";
import Divider from "components/common/Divider";
import Text, { TextVariants } from "components/common/Text";
import { formatToCurrency, getCurrencySymbol } from "utils";
import { Info } from "lucide-react";
import px2rem from "utils/px2rem";
import { PeriodTime } from "interfaces/portfolio";
import type { CocosFund } from "components/page-capital-funds/helpers";

import styles from "./styles.module.scss";

interface FundEarningsProps {
  amount: number;
  selectedFund: CocosFund;
  showEarnings: boolean;
}

const FundEarnings = ({
  amount,
  selectedFund,
  showEarnings,
}: FundEarningsProps) => {
  const { currency, rate } = selectedFund;

  const formatEarningNumber = (value: number, period: PeriodTime): string => {
    let days = 1;

    if (period === PeriodTime.MONTH) {
      days = 30;
    }
    if (period === PeriodTime.YEAR) {
      days = 360;
    }

    const earningsValue = ((1 + rate / 360) ** days - 1) * value;

    return `+${getCurrencySymbol(currency)}${formatToCurrency({
      value: Math.abs(earningsValue),
      withoutDecimals: true,
    })}`;
  };

  const earningsData = [
    {
      name: "Por día",
      value: formatEarningNumber(amount, PeriodTime.DAY),
    },
    {
      name: "Por mes",
      value: formatEarningNumber(amount, PeriodTime.MONTH),
    },
    {
      name: "Por año",
      value: formatEarningNumber(amount, PeriodTime.YEAR),
    },
  ];

  return (
    <>
      <div className={styles.anualRateContainer}>
        <Variation value={rate} withArrow={false} />
        <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
          Rendimiento anual estimado
        </Text>
        {showEarnings && (
          <>
            <Divider className={styles.earningsDivider} />
            <StatBlock
              valueColor="var(--green800)"
              statBlockData={earningsData}
            />
          </>
        )}
      </div>
      {showEarnings && (
        <div className={styles.earningsWrapper}>
          <Info size={px2rem(16)} color="var(--blue800)" />
          <Text variant={TextVariants.SemiboldTextS} color="var(--blue900)">
            Las ganancias son un estimado.
          </Text>
        </div>
      )}
    </>
  );
};

export default FundEarnings;
