import API from "apis";
import { App } from "@capacitor/app";
import { lt } from "semver";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { Preferences } from "@capacitor/preferences";
import { InstallReferrer } from "@togetherprice/capacitor-plugin-install-referrer";

interface MetaReferrerDetails {
  account_id: number;
  ad_id: number;
  ad_objective_name: string;
  adgroup_id: number;
  adgroup_name: string;
  campaign_group_id: number;
  campaign_group_name: string;
  campaign_id: number;
  campaign_name: string;
  is_an: boolean;
  is_instagram: boolean;
  platform_position: string;
  publisher_platform: string;
  source: string;
}

// We need to check the app version because InstallReferrer plugin is only available from version 2.3.0
const targetVersion = "2.3.0";

const getIsFirstOpen = async () => {
  const result = await Preferences.get({
    key: "isFirstOpen",
  });

  return result.value === null;
};

const sendMetaEncrypteData = async ({
  referrerUrl,
}: {
  referrerUrl: string;
}) => {
  const isMetaAd = ["apps.facebook.com", "apps.instagram.com"].some((source) =>
    referrerUrl.includes(`utm_source=${source}`)
  );

  if (isMetaAd) {
    try {
      const { data: referrerDetails } = await API.get<MetaReferrerDetails>(
        "/meta-decryption",
        {
          params: {
            installReferrer: referrerUrl,
            decryptionKey: import.meta.env.VITE_FB_DECRYPTION_KEY,
          },
        }
      );

      if (referrerDetails) {
        const eventParams = {
          campaign_id: `${referrerDetails.campaign_group_id}`,
          campaign: `${referrerDetails.campaign_group_name}`,
          source: `${referrerDetails.source}`,
          medium: "cpc",
          term: `${referrerDetails.campaign_id}`,
          content: `${referrerDetails.adgroup_id}`,
        };

        FirebaseAnalytics.logEvent({
          name: "campaign_details",
          params: eventParams,
        });

        FirebaseAnalytics.logEvent({
          name: "first_open_meta",
          params: eventParams,
        });
      }
    } catch (e: any) {
      console.error(e);
    }
  }
};

export const metaDecryption = async () => {
  const { version } = await App.getInfo();

  if (lt(version, targetVersion)) {
    return null;
  }

  if (await getIsFirstOpen()) {
    try {
      const { referrerUrl } = await InstallReferrer.getReferrerDetails();
      if (!referrerUrl) return;

      sendMetaEncrypteData({ referrerUrl });
    } catch (e: any) {
      console.error(e);
    } finally {
      Preferences.set({
        key: "isFirstOpen",
        value: "false",
      });
    }
  }
};
