import ff from "constants/featureFlags";

import { useEffect, useState } from "react";

import { CircularProgress, Typography } from "@mui/material";
import { Currency } from "components/common/currency";
import Success from "components/common/success";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { Method, OrderType, Instruments } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";
import {
  confirmOrder,
  setCreateOrderError,
  resetOrder,
} from "features/markets";
import { getCurrentScreen } from "features/global/globalSlice";
import ButtonUI, {
  ButtonSizes,
  ButtonVariants,
} from "components/common/button";
import { getComisiones, getCurrencyLabel, getFormattedNumber } from "utils";
import { trackAction } from "utils/amplitude";
import { calculateNetAmount } from "utils/index";
import mainRoutes from "router/routes";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import useFeatureFlag from "hooks/useFeatureFlag";

import Error from "./Error";

import "./index.scss";

interface PropTypes {
  onClose: () => void;
  onClickOrderDetail?: (orderId: string) => void;
}

export const ConfirmOrderContainer = (props: PropTypes) => {
  const { onClickOrderDetail, onClose } = props;
  const order = useAppSelector((state) => state.markets.newOrder);
  const marketDetail = useAppSelector((state) => state.markets.marketDetail);
  const loading = useAppSelector((state) => state.markets.createOrderLoading);
  const orderError = useAppSelector((state) => state.markets.createOrderError);
  const getOrderLoading = useAppSelector(
    (state) => state.markets.getOrderLoading
  );
  const dispatch = useAppDispatch();
  const { isFeatureEnabled } = useFeatureFlag(ff.ENABLE_ORDERS_REDESIGN);

  const isHardcodedMessage =
    order.currency === Currencies.USD &&
    marketDetail?.instrument_type !== Instruments.FCI;

  const [step, setStep] = useState(1);
  const { isMobile } = useDeviceCheck();
  const [orderId, setOrderId] = useState<string | undefined>();
  const comisiones = getComisiones(
    marketDetail?.instrument_type,
    marketDetail?.instrument_subtype,
    order.type,
    order.price && order.quantity
      ? calculateNetAmount(
          order.price,
          order.quantity,
          marketDetail?.contract_size,
          marketDetail?.price_factor
        )
      : calculateNetAmount(
          ((order.amount || 0) / (order.quantity || 1)) *
            (marketDetail?.price_factor || 1),
          order.quantity || 1,
          marketDetail?.contract_size,
          marketDetail?.price_factor
        )
  );
  const currentScreen = useAppSelector(getCurrentScreen);

  const getOrdersUrl = () => {
    if (isFeatureEnabled) {
      return `${mainRoutes.orders}?id=${order.id}`;
    }

    return `${mainRoutes.orderDetail}/${order.id}`;
  };

  useEffect(() => {
    if (order.id || orderError.message) {
      setStep(2);
    }
  }, [order.id, orderError]);

  useEffect(() => {
    if (order.id) {
      setOrderId(order.id);
    }

    return () => {
      if (order.id && order.long_ticker) {
        dispatch(resetOrder({ long_ticker: order.long_ticker }));
      }
      dispatch(setCreateOrderError({ message: "", status: null }));
    };
  }, [order.id]);

  const handleClickConfirm = async () => {
    const trackProps = {
      ...order,
      quantity: Math.floor(order.quantity || 0),
      ticker: order.instrument_code || order.long_ticker?.split("-")[0],
    };

    trackAction(`${currentScreen} - Send Order`, trackProps);

    dispatch(confirmOrder({ order }));
  };

  const handleClickOrderDetail = () => {
    if (onClickOrderDetail && orderId) {
      onClickOrderDetail(orderId);
    }
  };

  return (
    <div className="orders-confirm market-order-container-component">
      {step === 1 && (
        <div className="step-1">
          <Typography className="title-confirm" variant="textS_bold">
            {order.type === OrderType.Buy
              ? "Confirmar orden de compra"
              : "Confirmar orden de venta"}
          </Typography>
          <div className="box">
            <div>
              <Typography component={"p"}>Especie</Typography>
              <Typography variant="textL_bold">
                {order.long_ticker?.split("-")[0]}{" "}
              </Typography>
              {order.instrument_short_name && (
                <Typography variant="textS">
                  | {order.instrument_short_name}
                </Typography>
              )}
            </div>
            <div>
              <Typography component={"p"}>Plazo de liquidación</Typography>
              <Typography component={"p"} variant="textL_bold">
                {order.term}
              </Typography>
            </div>
            <div>
              <Typography component={"p"}>
                {order.method === Method.LIMIT ? "Precio límite" : "Precio"}
              </Typography>
              <Typography component={"p"} variant="textL_bold">
                {order.method === Method.LIMIT
                  ? getFormattedNumber(
                      order.price,
                      marketDetail?.id_tick_size_rule,
                      marketDetail?.tick_size
                    )
                  : "Mercado"}
              </Typography>
            </div>
            <div>
              <Typography component={"p"}>Cantidad de Nominales</Typography>
              <Typography component={"p"} variant="textL_bold">
                {order.quantity ? Math.floor(order.quantity) : ""}
              </Typography>
            </div>
            <div>
              <Typography component={"p"}>
                {order.type === OrderType.Buy
                  ? "Monto a Invertir"
                  : "Monto a recibir"}
              </Typography>
              <Typography component={"p"} variant="textL_bold">
                {`${getCurrencyLabel(order.currency)} `}
                {order.price && order.quantity ? (
                  <Currency>
                    {calculateNetAmount(
                      order.price,
                      order.quantity,
                      marketDetail?.contract_size,
                      marketDetail?.price_factor
                    )}
                  </Currency>
                ) : (
                  <Currency>
                    {calculateNetAmount(
                      ((order.amount || 0) / (order.quantity || 1)) *
                        (marketDetail?.price_factor || 1),
                      order.quantity || 1,
                      marketDetail?.contract_size,
                      marketDetail?.price_factor
                    )}
                  </Currency>
                )}
              </Typography>
            </div>
            {!isHardcodedMessage && (
              <div>
                <Typography component={"p"}>Comisiones estimadas</Typography>
                <Typography component={"p"} variant="textL_bold">
                  {`${getCurrencyLabel(order.currency)} `}
                  <Currency>{comisiones[1]}</Currency>
                </Typography>
              </div>
            )}
            <div>
              <Typography className="debit-text" component={"p"}>
                {order.type === OrderType.Buy
                  ? "Estimado a debitar"
                  : "Estimado a acreditar"}
              </Typography>
              <Typography component={"p"} variant="textL_bold">
                {`${getCurrencyLabel(order.currency)} `}
                <Currency>{comisiones[0]}</Currency>
                {order.currency === "USD" ? " USD" : ""}
              </Typography>
            </div>
          </div>
          <ButtonUI
            disabled={loading}
            size={ButtonSizes.Full}
            variant={ButtonVariants.Primary}
            onClick={handleClickConfirm}
            needReset={false}
          >
            <Typography variant="buttonL">
              {loading ? <CircularProgress size={16} /> : "Confirmar"}
            </Typography>
          </ButtonUI>
        </div>
      )}
      {step === 2 && order.id && (
        <div className="step-2">
          <Success
            loadingSecondaryButton={getOrderLoading}
            subtitle="Para ver el estado de la orden podes ingresar a movimientos o desde el activo que operaste"
            textPrimaryButton="Ir a Mercado"
            textSecondaryButton="Ver estado de orden"
            title="¡Tu orden fue enviada con éxito!"
            urlPrimaryButton={
              window.location.pathname.includes("market")
                ? undefined
                : mainRoutes.market
            }
            urlSecondaryButton={isMobile ? getOrdersUrl() : undefined}
            onClickPrimaryButton={onClose}
            onClickSecondaryButton={handleClickOrderDetail}
          />
        </div>
      )}
      {step === 2 && orderError.message && !order.id && (
        <Error message={orderError.message} onClose={onClose} />
      )}
    </div>
  );
};
