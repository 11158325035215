import { IconProps } from "../../BTC";

const LTCIcon: React.FC<IconProps> = ({ size = 24, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <circle cx="12.1" cy="11.9" r="10.7" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12C0 5.37258 5.37256 0 11.9999 0H12.0697C15.243 0.0092338 18.2827 1.27871 20.5201 3.52915C22.7574 5.77959 24.0092 8.82665 23.9999 12C23.9999 18.6274 18.6273 24 11.9999 24C5.37256 24 0 18.6274 0 12ZM10.9539 16.6199L12.2033 12.4068L14.1501 11.8547L14.5568 10.46L12.6392 11.0412L14.6731 4.12591V4.0155C14.67 3.92609 14.6316 3.84155 14.5662 3.78051C14.5008 3.71947 14.4138 3.68694 14.3244 3.69007H11.7384C11.537 3.68386 11.3572 3.81569 11.3026 4.00969L8.891 12.2034L6.97333 12.7845L6.5375 14.1211L8.45517 13.54L6.74089 19.3801H16.9684C17.1706 19.3885 17.3515 19.2558 17.4043 19.0605L17.9854 17.0557V16.9453C17.9824 16.8559 17.9439 16.7713 17.8785 16.7103C17.8131 16.6493 17.7261 16.6167 17.6367 16.6199H10.9539Z"
      fill="#345D9D"
    />
  </svg>
);

export default LTCIcon;
