import { IconProps } from "../../BTC";

const PAXGIcon: React.FC<IconProps> = ({ size = 24, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <circle cx="12" cy="12" r="12" fill="#E7EFF3" />
    <path
      d="M8.80856 6.96558C9.08256 6.65358 9.33456 6.38958 9.57456 6.16558C5.52256 6.03558 6.70256 8.96558 5.37456 11.9396C3.85456 15.3676 5.55456 16.6736 8.08656 17.0816C7.73297 16.6602 7.44444 16.1883 7.23056 15.6816C5.59656 13.6156 6.25856 9.84958 8.80856 6.96558Z"
      fill="#EFE812"
    />
    <path
      d="M18.5586 10.6176C18.0801 11.8694 17.3878 13.0287 16.5126 14.0436C15.8109 14.8963 14.9705 15.6245 14.0266 16.1976C12.9304 16.8922 11.6563 17.2542 10.3586 17.2396C9.60093 17.2464 8.84393 17.1936 8.09457 17.0816C8.51062 17.5786 9.02643 17.9826 9.60857 18.2676C13.2726 20.0396 13.5026 16.7496 17.3786 15.0796C19.5546 14.1416 19.5626 12.3096 18.5566 10.6176"
      fill="#EDE70A"
    />
    <path
      d="M14.0266 7.19758C14.557 7.29042 15.0681 7.47161 15.5386 7.73358C16.7711 8.42656 17.8096 9.4183 18.5586 10.6176C19.2186 8.75758 18.9106 7.29758 17.4686 7.03358C15.8806 6.74358 14.6406 6.64958 13.4486 5.81758C11.9806 4.79758 10.6926 5.12958 9.57657 6.16758L9.81857 6.17758C11.3666 6.26958 12.8466 6.57758 14.0186 7.19358"
      fill="#EDE70A"
    />
    <path
      d="M8.80858 6.96559C6.25858 9.84959 5.59658 13.6156 7.23858 15.6816C6.46844 13.8633 6.5996 11.7884 7.59258 10.0816C8.97458 7.77759 11.7486 6.77759 14.0266 7.19759C12.8466 6.57359 11.3666 6.27359 9.82658 6.18159L9.58458 6.16959C9.33658 6.39759 9.08458 6.65359 8.80858 6.96559Z"
      fill="#DEC21C"
    />
    <path
      d="M15.5386 7.72959C15.0679 7.469 14.5568 7.28918 14.0266 7.19759C15.5786 8.01359 16.6086 9.39759 16.6086 11.5856C16.5903 13.4625 15.6171 15.2009 14.0266 16.1976C14.9709 15.6251 15.8115 14.8968 16.5126 14.0436C17.3878 13.0287 18.0801 11.8694 18.5586 10.6176C17.8101 9.41684 16.7716 8.42369 15.5386 7.72959Z"
      fill="#E5CB22"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.59256 10.0716C8.97456 7.77758 11.7486 6.77758 14.0266 7.19758C15.5786 8.01358 16.6086 9.39758 16.6086 11.5856C16.5907 13.4626 15.6174 15.2011 14.0266 16.1976C12.9303 16.8922 11.6563 17.2542 10.3586 17.2396C9.60092 17.2464 8.84391 17.1936 8.09456 17.0816C7.74028 16.6607 7.45168 16.1887 7.23856 15.6816C6.46411 13.8606 6.59535 11.7808 7.59256 10.0716ZM8.54656 14.0336C9.68197 15.7819 12.0166 16.2842 13.7706 15.1576C15.5246 14.0356 15.7626 11.8496 14.8966 9.95759C14.0086 8.01559 11.5726 7.48359 9.67256 8.83359C7.97456 10.0336 7.41456 12.2876 8.54656 14.0336Z"
      fill="#CCA727"
    />
  </svg>
);

export default PAXGIcon;
