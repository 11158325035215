import { IconProps } from "../../BTC";

const BCHIcon: React.FC<IconProps> = ({ size = 24, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <circle cx="12" cy="12" r="12" fill="#8DC351" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9104 7.95821C15.3097 6.46568 13.8832 6.39105 12.1564 6.68956L11.5558 4.60001L10.2795 4.97314L10.8801 6.98807C10.7584 7.0183 10.6245 7.06078 10.4831 7.10559C10.2756 7.17138 10.0523 7.24218 9.829 7.28657L9.22837 5.19702L7.95204 5.57016L8.55267 7.65971C8.40251 7.69702 8.27112 7.73433 8.13973 7.77165C8.00835 7.80896 7.87696 7.84627 7.72681 7.88359L6 8.4806L6.37539 9.82389C6.37539 9.82389 7.35141 9.52538 7.27633 9.52538C7.80188 9.37613 8.02712 9.60001 8.17728 9.89851L8.85298 12.2866H9.00314L8.85298 12.3612L9.829 15.7194C9.829 15.8687 9.829 16.1672 9.45361 16.3164L8.55267 16.6149L8.70283 18.1821L10.3546 17.7343L11.2555 17.5105L11.8561 19.6L13.1325 19.2269L12.5318 17.1373C12.9072 17.0627 13.2075 16.9881 13.5829 16.8388L14.1836 18.9284L15.4599 18.5552L14.8593 16.4657C16.9615 15.7194 18.3129 14.7493 17.9375 12.6597C17.6372 11.0179 16.6611 10.4955 15.3097 10.5702C16.0605 9.82389 16.3608 9.07762 15.9104 7.95821ZM15.4599 13.0328C15.857 14.4143 13.7454 15.0419 12.6223 15.3757C12.4714 15.4206 12.3383 15.4602 12.2315 15.4955L11.4057 12.6597C11.5241 12.6303 11.6723 12.5867 11.8417 12.5369L11.8417 12.5369C12.961 12.2076 15.0034 11.6068 15.4599 13.0328ZM11.2396 11.331C12.1372 11.0774 14.0723 10.5308 13.658 9.22687C13.3184 7.87667 11.4428 8.48076 10.5323 8.77401C10.4363 8.80492 10.3511 8.83238 10.2795 8.85373L11.0303 11.391C11.0906 11.3731 11.1611 11.3531 11.2396 11.331Z"
      fill="white"
    />
  </svg>
);

export default BCHIcon;
