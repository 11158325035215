import BasicWrapper from "components/common/BasicWrapper";
import { PortfolioScreen, usePortfolio } from "context/PortfolioProvider";
import CommonLayout from "layouts/common";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import { getLoggedUser } from "store/selectors/user.selector";
import { ThemeVariants } from "interfaces/theme";

import CurrencyBalanceDetail from "../CurrencyBalanceDetail";
import PortfolioContent from "../PortfolioContent";
import PortfolioError from "../PortfolioError";

import styles from "./styles.module.scss";

const CapitalPortfolioPage = () => {
  const {
    selectedCurrencyDetail,
    selectedCurrency,
    portfolioScreen,
  } = usePortfolio();
  const user = useSelector(getLoggedUser);
  const navigate = useNavigate();

  const isPortfolioScreen = portfolioScreen === PortfolioScreen.PORTFOLIO;

  const portfolioHasError = portfolioScreen === PortfolioScreen.PORTFOLIO_ERROR;

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  if (portfolioHasError)
    return (
      <PortfolioError
        onCloseError={() => navigate(mainRoutes.home)}
        variant={ThemeVariants.Capital}
      />
    );

  return (
    <div className={styles.pagesPortfolio}>
      <CommonLayout withHeader={false}>
        {isPortfolioScreen ? (
          <BasicWrapper className={styles.capitalPortfolioWrapper}>
            <PortfolioContent user={user} selectedCurrency={selectedCurrency} />
          </BasicWrapper>
        ) : (
          <CurrencyBalanceDetail
            selectedCurrencyDetail={selectedCurrencyDetail}
          />
        )}
      </CommonLayout>
    </div>
  );
};

export default CapitalPortfolioPage;
