export const Cancelled = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      d="M1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6Z"
      fill="var(--slate900)"
    />
    <path
      d="M1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6Z"
      stroke="var(--slate900)"
      strokeWidth="2"
    />
  </svg>
);

export const Pending = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      d="M5.144 0.00150364C4.13666 0.148415 3.18344 0.551397 2.37563 1.17187L3.54397 2.55485C4.05498 2.18947 4.65072 1.93529 5.29594 1.82763L5.144 0.00150364Z"
      fill="var(--orange800)"
    />
    <path
      d="M6.69426 1.82592C7.34066 1.9322 7.93766 2.18547 8.4499 2.55033L9.61841 1.16715C8.80924 0.547278 7.85466 0.14534 6.84617 0L6.69426 1.82592Z"
      fill="var(--orange800)"
    />
    <path
      d="M9.44099 3.53973L10.8216 2.36966C11.4431 3.17965 11.8468 4.13603 11.9939 5.14684L10.1707 5.29902C10.0627 4.65084 9.80766 4.05254 9.44099 3.53973Z"
      fill="var(--orange800)"
    />
    <path
      d="M10.1706 6.7011C10.0627 7.34926 9.80761 7.94755 9.44094 8.46035L10.8481 9.65297C11.4657 8.83473 11.8625 7.87056 12 6.85379L10.1706 6.7011Z"
      fill="var(--orange800)"
    />
    <path
      d="M8.44984 9.44971L9.61835 10.8329C8.80919 11.4527 7.85463 11.8547 6.84616 12L6.69425 10.1741C7.34063 10.0678 7.93761 9.81456 8.44984 9.44971Z"
      fill="var(--orange800)"
    />
    <path
      d="M5.29594 10.1725C4.65072 10.0648 4.05498 9.81066 3.54397 9.44527L2.37563 10.8283C3.18344 11.4487 4.13666 11.8517 5.144 11.9986L5.29594 10.1725Z"
      fill="var(--orange800)"
    />
    <path
      d="M2.55536 8.45505L1.14803 9.64779C0.532541 8.83043 0.137049 7.86789 0 6.85296L1.82934 6.70027C1.93682 7.34652 2.19057 7.94321 2.55536 8.45505Z"
      fill="var(--orange800)"
    />
    <path
      d="M1.82934 5.29985C1.93682 4.6536 2.19057 4.05691 2.55536 3.54507L1.1746 2.37485C0.555132 3.18396 0.15281 4.13871 0.00614499 5.14767L1.82934 5.29985Z"
      fill="var(--orange800)"
    />
  </svg>
);

export const Rejected = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
    <g clipPath="url(#clip0_18805_174971)">
      <path
        d="M11.6674 4.0267L1.45752 9.92141C3.30154 12.0622 6.46323 12.6605 8.99998 11.1959C11.5367 9.73134 12.5994 6.69406 11.6674 4.0267Z"
        fill="var(--red800)"
      />
      <path
        d="M10.5424 2.07815C8.69843 -0.0626203 5.53673 -0.660969 2.99998 0.803625C0.463231 2.26822 -0.599431 5.3055 0.33252 7.97285L10.5424 2.07815Z"
        fill="var(--red800)"
      />
    </g>
    <defs>
      <clipPath id="clip0_18805_174971">
        <path
          d="M0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6Z"
          fill="var(--slate100)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const PartiallyExecuted = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      d="M1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6Z"
      stroke="var(--green800)"
      strokeWidth="2"
    />
    <path
      d="M10 6C10 8.20914 8.20914 10 6 10C3.79086 10 2 8.20914 2 6H10Z"
      fill="var(--green800)"
    />
  </svg>
);

export const PartiallyCancelled = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      d="M1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6Z"
      stroke="var(--slate900)"
      strokeWidth="2"
    />
    <path
      d="M10 6C10 8.20914 8.20914 10 6 10C3.79086 10 2 8.20914 2 6H10Z"
      fill="var(--slate900)"
    />
  </svg>
);

export const Executed = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      d="M1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6Z"
      fill="var(--green800)"
    />
    <path
      d="M1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6Z"
      stroke="var(--green800)"
      strokeWidth="2"
    />
  </svg>
);
