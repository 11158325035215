/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 30
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/cocos/Cocos%20Capital/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'default';

export const ApiKey: Record<Environment, string> = {
  default: ''
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '30',
    branch: 'main',
    source: 'web',
    versionId: 'ccc7c1bb-fe81-44a4-ae21-16c00d2689ed'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * The build version. On iOS it's the CFBundleVersion. On Android it's the versionCode.
   */
  app_build?: string;
  /**
   * This is a Firebase property to identify the device and user and it is used in the GA4 integration
   */
  app_instance_id?: string;
  /**
   * The app version. On iOS it's the CFBundleShortVersionString. On Android it's package's versionName.
   */
  app_version?: string;
  card_status?: string;
  /**
   * Whether the user has Crypto product enabled
   */
  crypto_enabled?: boolean;
  /**
   * Possible values are:
   *  DELETED
   *
   * PENDING
   *
   * PENDING_DOCUMENTS
   *
   * PENDING_ADDRESSES
   *
   * APPROVED
   *
   * BANNED
   */
  crypto_status?: string;
  /**
   * The manufacturer of the device.
   */
  device_manufacturer?: string;
  /**
   * The device model. For example, "iPhone13,4".
   */
  device_model?: string;
  /**
   * The operating system of the device.
   */
  device_os?: string;
  /**
   * The version of the device OS.
   */
  device_os_version?: string;
  device_platform?: string;
  /**
   * User email
   */
  email?: string;
  /**
   * Boolean: determina si el número de teléfono del usuario está o no verificado.
   */
  is_phone_verified?: boolean;
  /**
   * Account number owned by the user
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   */
  owned_account_number?: number;
  /**
   * Whether the user has Cocos Pay enabled
   */
  pay_enabled?: boolean;
  /**
   * Número de teléfono del usuario.
   */
  phone?: string;
  tier?: string;
  /**
   * Current MobileApp version used by the user
   */
  version?: string;
}

export interface AgendaNewAccountAddedErrorProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  type: string;
}

export interface AssetScreenViewedProperties {
  instrument_type: string;
  referral: string;
}

export interface BankAccountAddedProperties {
  referral: string;
}

export interface CardChangePaymentMethodProperties {
  card_type: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  method_changed: number;
}

export interface CardCreationConfirmPaymentProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amount_ars: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  method?: string;
}

export interface CardCreationPaymentMethodsProperties {
  status: string;
}

export interface CardDeletedProperties {
  card_type: string;
}

export interface CardDeliveryConfirmProperties {
  province: string;
}

export interface CardErrorCreationFailedProperties {
  card_type: string;
}

export interface CardGetDataProperties {
  card_type: string;
}

export interface CardHomeScreenProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  card_methods: string[];
  card_status: string;
  card_type: string;
}

export interface CardLimitModifiedProperties {
  card_limit: string;
  card_type: string;
}

export interface CardLimitRequestIncreaseProperties {
  card_type: string;
}

export interface CardLostPausedProperties {
  card_type: string;
}

export interface CardMovProblemDeletedProperties {
  card_type: string;
}

export interface CardMovProblemSupportProperties {
  card_type: string;
}

export interface CardMovementsProperties {
  card_type: string;
}

export interface CardPauseProperties {
  card_status: string;
  card_type: string;
}

export interface CardPaymentMethodChangedProperties {
  card_type: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  method?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  method_changed: number;
}

export interface CardProblemsMovementsOptionProperties {
  mov_problem_option: string;
}

export interface CardProblemsOptionProperties {
  problems_option: string;
}

export interface CardProductPromotionSupportProperties {
  card_type: string;
}

export interface CardRequestCardProperties {
  card_type: string;
}

export interface CardSettingsProperties {
  card_type: string;
}

export interface CardSettingsSelectedOptionProperties {
  card_type: string;
  settings_option: string;
}

export interface CardStolenDeletedProperties {
  card_type: string;
}

export interface CclCryptoAddressProperties {
  referral: string;
}

export interface CclCryptoDataCopiedProperties {
  item: string;
}

export interface CclCryptoExitProperties {
  referral: string;
}

export interface CclCryptoMoreInfoProperties {
  item: string;
}

export interface CclCryptoOnboardingProperties {
  referral: string;
  status: string;
}

export interface CclCryptoPaymentConfirmedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  method?: string;
}

export interface CclCryptoPaymentMethodsProperties {
  available_methods: string;
}

export interface CclCryptoSupportProperties {
  referral: string;
}

export interface CclScreenPaywallProperties {
  is_button_active: boolean;
}

export interface ChangePasswordSuccessProperties {
  referral: string;
}

export interface CocostagBannerClickedProperties {
  referral: string;
}

export interface CocostagExitProperties {
  referral: string;
}

export interface CocostagUsernameSharedProperties {
  referral: string;
}

export interface CryptoActivityBottomsheetProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  base_quantity?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  base_ticker?: string;
  date: string;
  operation_number: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  operation_type?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  quote_quantity?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  quote_ticker?: string;
  referral: string;
}

export interface CryptoBannerClickedProperties {
  referral: string;
  tapped_button: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  type: string;
}

export interface CryptoOperationsConfirmProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  base_quantity?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  base_ticker?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  exchange_rate?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  operation_type?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  quote_quantity?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  quote_ticker?: string;
}

export interface CryptoOperationsFailProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  base_quantity?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  base_ticker?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  exchange_rate?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  operation_type?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  quote_quantity?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  quote_ticker?: string;
}

export interface CryptoOperationsPreviewProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  base_quantity?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  base_ticker?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  exchange_rate?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  operation_type?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  quote_quantity?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  quote_ticker?: string;
}

export interface CryptoOperationsSuccessProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  base_quantity?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  base_ticker?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  exchange_rate?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  operation_type?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  quote_quantity?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  quote_ticker?: string;
}

export interface CryptoOperationsUpdateProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  base_quantity?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  base_ticker?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  exchange_rate?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  operation_type?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  quote_quantity?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  quote_ticker?: string;
}

export interface CryptoReceiveBottomsheetProperties {
  referral: string;
}

export interface CryptoScreenActivityProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  previous_screen?: string;
}

export interface CryptoScreenAgendaProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  previous_screen?: string;
}

export interface CryptoScreenHomeProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  previous_screen?: string;
}

export interface CryptoScreenLimitsProperties {
  daily_limit_blockchain: string;
  daily_limit_tag: string;
  weekly_limit_blockchain: string;
  weekly_limit_tag: string;
}

export interface CryptoScreenMarketProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  previous_screen?: string;
}

export interface CryptoScreenMarketDetailProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  previous_screen?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  ticker?: string;
}

export interface CryptoScreenOperationsProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  previous_screen?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  previousScreen?: string;
  tapped_button: string;
}

export interface CryptoScreenPerformanceProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  previous_screen?: string;
}

export interface CryptoScreenPortfolioProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  previous_screen?: string;
}

export interface CryptoScreenProfileProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  previous_screen?: string;
}

export interface CryptoScreenSendAgendaProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  previous_screen?: string;
}

export interface CryptoScreenTycProperties {
  new_user?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  previous_screen?: string;
}

export interface CryptoSendClickedPreviewProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  method?: string;
  network: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  quantity?: number;
  send_to: string;
  ticker_name: string;
}

export interface CryptoSendConfirmProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  method?: string;
  network: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  quantity?: number;
  send_to: string;
  ticker_name: string;
}

export interface CryptoSendExitProperties {
  referral: string;
}

export interface CryptoSendFailProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  method?: string;
  network: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  quantity?: number;
  send_to: string;
  ticker_name: string;
}

export interface CryptoSendSelectedMethodProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  method?: string;
}

export interface CryptoSendSelectedTickerProperties {
  ticker_name: string;
}

export interface CryptoSendSuccessProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  method?: string;
  network: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  quantity?: number;
  send_to: string;
  ticker_name: string;
}

export interface CryptoTycContinueProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  created_account?: string;
  new_user?: boolean;
}

export interface CryptoTycExitProperties {
  referral: string;
}

export interface CurrencyBalanceScreenViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  currency?: string;
}

export interface DonationOrderConfirmedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amount?: number;
}

export interface DonationOrderErrorProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amount?: number;
}

export interface DonationOrderSuccessProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amount?: number;
}

export interface FciOrderConfirmedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  currency?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  side?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  ticker?: string;
}

export interface FciOrderErrorProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  currency?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  side?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  ticker?: string;
}

export interface FciOrderScreenViewedProperties {
  real_time_returns?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  side?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  ticker?: string;
}

export interface FciOrderSuccessProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  currency?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  side?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  ticker?: string;
}

export interface FciScreenViewedProperties {
  tab: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  ticker?: string;
}

export interface ForgotPasswordErrorProperties {
  message: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  type: string;
}

export interface HomeBannerClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  type: string;
}

export interface HomeClickPayProperties {
  referral: string;
}

export interface HomeExitedProperties {
  referral: string;
}

export interface HomeTapCardProperties {
  referral: string;
}

export interface InstrumentTypeChosenProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  type: string;
}

export interface MarketCurrencyChangedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  currency?: string;
}

export interface MarketOrderSentProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  currency?: string;
  instrument_type: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  method?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  settlement?: string;
}

export interface MarketSettlementChangedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  settlement?: string;
}

export interface MepBannerClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  type: string;
}

export interface MepOrderErrorProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  arsAmount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  side?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  type: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  usdAmount?: number;
}

export interface MepOrderReviewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  arsAmount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  side?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  type: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  usdAmount?: number;
}

export interface MepOrderSuccessProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  arsAmount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  side?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  type: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  usdAmount?: number;
}

export interface MepScreenViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  type: string;
}

export interface MepTypeChosenProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  type: string;
}

export interface ObStartCamaraProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  type: string;
}

export interface OnboardingBottomsheetViewedProperties {
  referral: string;
}

export interface OrdersScreenViewedProperties {
  referral: string;
}

export interface PayAmountContinueProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amount?: number;
}

export interface PayClickConfirmProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  exchange_rate?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  method?: string;
  paid_to?: string;
}

export interface PayDownloadReceiptProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amount?: number;
  date: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  method?: string;
  paid_to?: string;
  receipt_number: string;
  referral: string;
  time: string;
}

export interface PayExitProperties {
  referral: string;
}

export interface PayFailedRetryProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amount?: number;
  paid_to?: string;
  receipt_number: string;
}

export interface PayScanFailedProperties {
  failed_reason: string;
}

export interface PayScanQrProperties {
  country_code: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  type: string;
}

export interface PayScanRetryProperties {
  referral: string;
}

export interface PayScreenAccessCamaraProperties {
  camara_status: string;
}

export interface PayScreenFailedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amount?: number;
  country_code: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  exchange_rate?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  method?: string;
  paid_to: string;
  receipt_number: string;
}

export interface PayScreenMethodProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amount_ars: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  amount_usd: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  balance_ars: number;
  balance_ok: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  balance_usd: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  exchange_rate?: number;
}

export interface PayScreenScanQrProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  previous_screen?: string;
}

export interface PayScreenSuccessProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amount?: number;
  country_code: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  exchange_rate?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  method?: string;
  paid_to?: string;
  receipt_number: string;
}

export interface PaySelectMethodProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  method?: string;
}

export interface PhoneNumberScreenViewedProperties {
  referral: string;
}

export interface PhoneVerificationCodeScreenViewedProperties {
  referral: string;
}

export interface PhoneVerificationCodeSuccessProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  account?: string;
  referral: string;
}

export interface PortfolioChartRangeSwitchedProperties {
  range: string;
  tab: string;
}

export interface PortfolioCurrencySwitchedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  currency?: string;
  tab: string;
}

export interface PortfolioExitedProperties {
  referral: string;
}

export interface PortfolioScreenViewedProperties {
  tab: string;
}

export interface ReceiveAliasCopiedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  currency?: string;
}

export interface ReceiveCclInformationCopiedProperties {
  item: string;
}

export interface ReceiveCurrencySelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  currency?: string;
}

export interface ReceiveExitProperties {
  referral: string;
}

export interface ReceiveScreenViewedProperties {
  referral: string;
}

export interface ReceiveSelectedAssetTypeProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  type: string;
}

export interface ScreenCocosGoldProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  previous_screen?: string;
}

export interface SendAmountConfirmedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  currency?: string;
}

export interface SendOrderCanceledProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  currency?: string;
}

export interface SendOrderConfirmedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  currency?: string;
}

export interface SendOrderErrorProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  currency?: string;
}

export interface SendOrderSuccessProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  currency?: string;
}

export interface SendScreenViewedProperties {
  referral: string;
}

export interface SignupEmailScreenErrorProperties {
  message: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  type: string;
}

export interface SignupEmailValidationErrorProperties {
  message: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  type: string;
}

export interface WithdrawAmountConfirmedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  currency?: string;
}

export interface WithdrawOrderCanceledProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  currency?: string;
}

export interface WithdrawOrderConfirmedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  currency?: string;
}

export interface WithdrawOrderErrorProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  currency?: string;
}

export interface WithdrawOrderSuccessProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Session Replay ID"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  currency?: string;
}

export interface WithdrawScreenViewedProperties {
  referral: string;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AgendaAccountDeleted implements BaseEvent {
  event_type = 'agenda_account_deleted';
}

export class AgendaAccountSendMoney implements BaseEvent {
  event_type = 'agenda_account_send_money';
}

export class AgendaAddNewAccount implements BaseEvent {
  event_type = 'agenda_add_new_account';
}

export class AgendaNewAccountAddedError implements BaseEvent {
  event_type = 'agenda_new_account_added_error';

  constructor(
    public event_properties: AgendaNewAccountAddedErrorProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AssetScreenViewed implements BaseEvent {
  event_type = 'asset_screen_viewed';

  constructor(
    public event_properties: AssetScreenViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BankAccountAdded implements BaseEvent {
  event_type = 'bank_account_added';

  constructor(
    public event_properties: BankAccountAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class Card2FaInactive implements BaseEvent {
  event_type = 'card_2fa_inactive';
}

export class Card2FaSuccess implements BaseEvent {
  event_type = 'card_2fa_success';
}

export class CardActivateCard implements BaseEvent {
  event_type = 'card_activate_card';
}

export class CardActivationActivate implements BaseEvent {
  event_type = 'card_activation_activate';
}

export class CardActivationSuccess implements BaseEvent {
  event_type = 'card_activation_success';
}

export class CardAtmSuccess implements BaseEvent {
  event_type = 'card_atm_success';
}

export class CardChangePaymentMethod implements BaseEvent {
  event_type = 'card_change_payment_method';

  constructor(
    public event_properties: CardChangePaymentMethodProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CardCreationConfirmPayment implements BaseEvent {
  event_type = 'card_creation_confirm_payment';

  constructor(
    public event_properties: CardCreationConfirmPaymentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CardCreationPaymentMethods implements BaseEvent {
  event_type = 'card_creation_payment_methods';

  constructor(
    public event_properties: CardCreationPaymentMethodsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CardCreationSteps implements BaseEvent {
  event_type = 'card_creation_steps';
}

export class CardCreationWithoutDelivery implements BaseEvent {
  event_type = 'card_creation_without_delivery';
}

export class CardDeleted implements BaseEvent {
  event_type = 'card_deleted';

  constructor(
    public event_properties: CardDeletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CardDeliveryConfirm implements BaseEvent {
  event_type = 'card_delivery_confirm';

  constructor(
    public event_properties: CardDeliveryConfirmProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CardDeliveryContinue implements BaseEvent {
  event_type = 'card_delivery_continue';
}

export class CardErrorCreationFailed implements BaseEvent {
  event_type = 'card_error_creation_failed';

  constructor(
    public event_properties: CardErrorCreationFailedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CardGetData implements BaseEvent {
  event_type = 'card_get_data';

  constructor(
    public event_properties: CardGetDataProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CardHomeScreen implements BaseEvent {
  event_type = 'card_home_screen';

  constructor(
    public event_properties: CardHomeScreenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CardIntroExit implements BaseEvent {
  event_type = 'card_intro_exit';
}

export class CardIntroTapGetCard implements BaseEvent {
  event_type = 'card_intro_tap_get_card';
}

export class CardLimitModified implements BaseEvent {
  event_type = 'card_limit_modified';

  constructor(
    public event_properties: CardLimitModifiedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CardLimitRequestIncrease implements BaseEvent {
  event_type = 'card_limit_request_increase';

  constructor(
    public event_properties: CardLimitRequestIncreaseProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CardLostPaused implements BaseEvent {
  event_type = 'card_lost_paused';

  constructor(
    public event_properties: CardLostPausedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CardMovProblemDeleted implements BaseEvent {
  event_type = 'card_mov_problem_deleted';

  constructor(
    public event_properties: CardMovProblemDeletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CardMovProblemSupport implements BaseEvent {
  event_type = 'card_mov_problem_support';

  constructor(
    public event_properties: CardMovProblemSupportProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CardMovements implements BaseEvent {
  event_type = 'card_movements';

  constructor(
    public event_properties: CardMovementsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CardNoReceivePhysicalCard implements BaseEvent {
  event_type = 'card_no_receive_physical_card';
}

export class CardOutOfStock implements BaseEvent {
  event_type = 'card_out_of_stock';
}

export class CardPause implements BaseEvent {
  event_type = 'card_pause';

  constructor(
    public event_properties: CardPauseProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CardPaymentMethodChanged implements BaseEvent {
  event_type = 'card_payment_method_changed';

  constructor(
    public event_properties: CardPaymentMethodChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CardPhysicalCreationFailed implements BaseEvent {
  event_type = 'card_physical_creation_failed';
}

export class CardPhysicalCreationReady implements BaseEvent {
  event_type = 'card_physical_creation_ready';
}

export class CardProblemsMovementsOption implements BaseEvent {
  event_type = 'card_problems_movements_option';

  constructor(
    public event_properties: CardProblemsMovementsOptionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CardProblemsOption implements BaseEvent {
  event_type = 'card_problems_option';

  constructor(
    public event_properties: CardProblemsOptionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CardProductPromotionSupport implements BaseEvent {
  event_type = 'card_product_promotion_support';

  constructor(
    public event_properties: CardProductPromotionSupportProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CardRequestCard implements BaseEvent {
  event_type = 'card_request_card';

  constructor(
    public event_properties: CardRequestCardProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CardScreenDelivery implements BaseEvent {
  event_type = 'card_screen_delivery';
}

export class CardSet2Fa implements BaseEvent {
  event_type = 'card_set_2fa';
}

export class CardSettings implements BaseEvent {
  event_type = 'card_settings';

  constructor(
    public event_properties: CardSettingsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CardSettingsSelectedOption implements BaseEvent {
  event_type = 'card_settings_selected_option';

  constructor(
    public event_properties: CardSettingsSelectedOptionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CardStolenDeleted implements BaseEvent {
  event_type = 'card_stolen_deleted';

  constructor(
    public event_properties: CardStolenDeletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CardVirtualCreationSuccess implements BaseEvent {
  event_type = 'card_virtual_creation_success';
}

export class CclCryptoAddress implements BaseEvent {
  event_type = 'ccl_crypto_address';

  constructor(
    public event_properties: CclCryptoAddressProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CclCryptoDataCopied implements BaseEvent {
  event_type = 'ccl_crypto_data_copied';

  constructor(
    public event_properties: CclCryptoDataCopiedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CclCryptoDataShared implements BaseEvent {
  event_type = 'ccl_crypto_data_shared';
}

export class CclCryptoDelayed implements BaseEvent {
  event_type = 'ccl_crypto_delayed';
}

export class CclCryptoExit implements BaseEvent {
  event_type = 'ccl_crypto_exit';

  constructor(
    public event_properties: CclCryptoExitProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CclCryptoFailed implements BaseEvent {
  event_type = 'ccl_crypto_failed';
}

export class CclCryptoMoreInfo implements BaseEvent {
  event_type = 'ccl_crypto_more_info';

  constructor(
    public event_properties: CclCryptoMoreInfoProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CclCryptoOnboarding implements BaseEvent {
  event_type = 'ccl_crypto_onboarding';

  constructor(
    public event_properties: CclCryptoOnboardingProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CclCryptoPaymentConfirmed implements BaseEvent {
  event_type = 'ccl_crypto_payment_confirmed';

  constructor(
    public event_properties?: CclCryptoPaymentConfirmedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CclCryptoPaymentMethods implements BaseEvent {
  event_type = 'ccl_crypto_payment_methods';

  constructor(
    public event_properties: CclCryptoPaymentMethodsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CclCryptoPaywallAccepted implements BaseEvent {
  event_type = 'ccl_crypto_paywall_accepted';
}

export class CclCryptoSupport implements BaseEvent {
  event_type = 'ccl_crypto_support';

  constructor(
    public event_properties: CclCryptoSupportProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CclScreenPaywall implements BaseEvent {
  event_type = 'ccl_screen_paywall';

  constructor(
    public event_properties: CclScreenPaywallProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChangePasswordSuccess implements BaseEvent {
  event_type = 'change_password_success';

  constructor(
    public event_properties: ChangePasswordSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CocormaFromWithdraw implements BaseEvent {
  event_type = 'cocorma_from_withdraw';
}

export class CocostagBannerClicked implements BaseEvent {
  event_type = 'cocostag_banner_clicked';

  constructor(
    public event_properties: CocostagBannerClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CocostagExit implements BaseEvent {
  event_type = 'cocostag_exit';

  constructor(
    public event_properties: CocostagExitProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CocostagTycAccepted implements BaseEvent {
  event_type = 'cocostag_tyc_accepted';
}

export class CocostagUsernameCreationSuccess implements BaseEvent {
  event_type = 'cocostag_username_creation_success';
}

export class CocostagUsernameShared implements BaseEvent {
  event_type = 'cocostag_username_shared';

  constructor(
    public event_properties: CocostagUsernameSharedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CryptoActivityBottomsheet implements BaseEvent {
  event_type = 'crypto_activity_bottomsheet';

  constructor(
    public event_properties: CryptoActivityBottomsheetProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CryptoBannerClicked implements BaseEvent {
  event_type = 'crypto_banner_clicked';

  constructor(
    public event_properties: CryptoBannerClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CryptoOperationsConfirm implements BaseEvent {
  event_type = 'crypto_operations_confirm';

  constructor(
    public event_properties?: CryptoOperationsConfirmProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CryptoOperationsExit implements BaseEvent {
  event_type = 'crypto_operations_exit';
}

export class CryptoOperationsFail implements BaseEvent {
  event_type = 'crypto_operations_fail';

  constructor(
    public event_properties?: CryptoOperationsFailProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CryptoOperationsPreview implements BaseEvent {
  event_type = 'crypto_operations_preview';

  constructor(
    public event_properties?: CryptoOperationsPreviewProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CryptoOperationsSuccess implements BaseEvent {
  event_type = 'crypto_operations_success';

  constructor(
    public event_properties?: CryptoOperationsSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CryptoOperationsUpdate implements BaseEvent {
  event_type = 'crypto_operations_update';

  constructor(
    public event_properties?: CryptoOperationsUpdateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CryptoReceiveBottomsheet implements BaseEvent {
  event_type = 'crypto_receive_bottomsheet';

  constructor(
    public event_properties: CryptoReceiveBottomsheetProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CryptoScreenActivity implements BaseEvent {
  event_type = 'crypto_screen_activity';

  constructor(
    public event_properties?: CryptoScreenActivityProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CryptoScreenAgenda implements BaseEvent {
  event_type = 'crypto_screen_agenda';

  constructor(
    public event_properties?: CryptoScreenAgendaProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CryptoScreenAllocation implements BaseEvent {
  event_type = 'crypto_screen_allocation';
}

export class CryptoScreenEducation implements BaseEvent {
  event_type = 'crypto_screen_education';
}

export class CryptoScreenHome implements BaseEvent {
  event_type = 'crypto_screen_home';

  constructor(
    public event_properties?: CryptoScreenHomeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CryptoScreenLegal implements BaseEvent {
  event_type = 'crypto_screen_legal';
}

export class CryptoScreenLimits implements BaseEvent {
  event_type = 'crypto_screen_limits';

  constructor(
    public event_properties: CryptoScreenLimitsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CryptoScreenMarket implements BaseEvent {
  event_type = 'crypto_screen_market';

  constructor(
    public event_properties?: CryptoScreenMarketProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CryptoScreenMarketDetail implements BaseEvent {
  event_type = 'crypto_screen_market_detail';

  constructor(
    public event_properties?: CryptoScreenMarketDetailProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CryptoScreenOperations implements BaseEvent {
  event_type = 'crypto_screen_operations';

  constructor(
    public event_properties: CryptoScreenOperationsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CryptoScreenPerformance implements BaseEvent {
  event_type = 'crypto_screen_performance';

  constructor(
    public event_properties?: CryptoScreenPerformanceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CryptoScreenPortfolio implements BaseEvent {
  event_type = 'crypto_screen_portfolio';

  constructor(
    public event_properties?: CryptoScreenPortfolioProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CryptoScreenProfile implements BaseEvent {
  event_type = 'crypto_screen_profile';

  constructor(
    public event_properties?: CryptoScreenProfileProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CryptoScreenSecurity implements BaseEvent {
  event_type = 'crypto_screen_security';
}

export class CryptoScreenSendAgenda implements BaseEvent {
  event_type = 'crypto_screen_send_agenda';

  constructor(
    public event_properties?: CryptoScreenSendAgendaProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CryptoScreenSettings implements BaseEvent {
  event_type = 'crypto_screen_settings';
}

export class CryptoScreenTyc implements BaseEvent {
  event_type = 'crypto_screen_tyc';

  constructor(
    public event_properties?: CryptoScreenTycProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CryptoSendAddAdress implements BaseEvent {
  event_type = 'crypto_send_add_adress';
}

export class CryptoSendClickedPreview implements BaseEvent {
  event_type = 'crypto_send_clicked_preview';

  constructor(
    public event_properties: CryptoSendClickedPreviewProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CryptoSendConfirm implements BaseEvent {
  event_type = 'crypto_send_confirm';

  constructor(
    public event_properties: CryptoSendConfirmProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CryptoSendExit implements BaseEvent {
  event_type = 'crypto_send_exit';

  constructor(
    public event_properties: CryptoSendExitProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CryptoSendFail implements BaseEvent {
  event_type = 'crypto_send_fail';

  constructor(
    public event_properties: CryptoSendFailProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CryptoSendPreviewBack implements BaseEvent {
  event_type = 'crypto_send_preview_back';
}

export class CryptoSendSelectedCocostag implements BaseEvent {
  event_type = 'crypto_send_selected_cocostag';
}

export class CryptoSendSelectedMethod implements BaseEvent {
  event_type = 'crypto_send_selected_method';

  constructor(
    public event_properties?: CryptoSendSelectedMethodProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CryptoSendSelectedTicker implements BaseEvent {
  event_type = 'crypto_send_selected_ticker';

  constructor(
    public event_properties: CryptoSendSelectedTickerProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CryptoSendSuccess implements BaseEvent {
  event_type = 'crypto_send_success';

  constructor(
    public event_properties: CryptoSendSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CryptoTycContinue implements BaseEvent {
  event_type = 'crypto_tyc_continue';

  constructor(
    public event_properties?: CryptoTycContinueProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CryptoTycExit implements BaseEvent {
  event_type = 'crypto_tyc_exit';

  constructor(
    public event_properties: CryptoTycExitProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CryptoTycFailed implements BaseEvent {
  event_type = 'crypto_tyc_failed';
}

export class CryptoTycSupport implements BaseEvent {
  event_type = 'crypto_tyc_support';
}

export class CurrencyBalanceScreenViewed implements BaseEvent {
  event_type = 'currency_balance_screen_viewed';

  constructor(
    public event_properties?: CurrencyBalanceScreenViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DonationOrderConfirmed implements BaseEvent {
  event_type = 'donation_order_confirmed';

  constructor(
    public event_properties?: DonationOrderConfirmedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DonationOrderError implements BaseEvent {
  event_type = 'donation_order_error';

  constructor(
    public event_properties?: DonationOrderErrorProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DonationOrderSuccess implements BaseEvent {
  event_type = 'donation_order_success';

  constructor(
    public event_properties?: DonationOrderSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DonationScreenViewed implements BaseEvent {
  event_type = 'donation_screen_viewed';
}

export class FciOrderConfirmed implements BaseEvent {
  event_type = 'fci_order_confirmed';

  constructor(
    public event_properties?: FciOrderConfirmedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FciOrderError implements BaseEvent {
  event_type = 'fci_order_error';

  constructor(
    public event_properties?: FciOrderErrorProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FciOrderScreenViewed implements BaseEvent {
  event_type = 'fci_order_screen_viewed';

  constructor(
    public event_properties?: FciOrderScreenViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FciOrderSuccess implements BaseEvent {
  event_type = 'fci_order_success';

  constructor(
    public event_properties?: FciOrderSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FciScreenViewed implements BaseEvent {
  event_type = 'fci_screen_viewed';

  constructor(
    public event_properties: FciScreenViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ForgotPasswordCheckEmail implements BaseEvent {
  event_type = 'forgot_password_check_email';
}

export class ForgotPasswordError implements BaseEvent {
  event_type = 'forgot_password_error';

  constructor(
    public event_properties: ForgotPasswordErrorProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ForgotPasswordOpenEmail implements BaseEvent {
  event_type = 'forgot_password_open_email';
}

export class ForgotPasswordResendEmail implements BaseEvent {
  event_type = 'forgot_password_resend_email';
}

export class ForgotPasswordScreenViewed implements BaseEvent {
  event_type = 'forgot_password_screen_viewed';
}

export class HomeBannerClicked implements BaseEvent {
  event_type = 'home_banner_clicked';

  constructor(
    public event_properties: HomeBannerClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HomeClickPay implements BaseEvent {
  event_type = 'home_click_pay';

  constructor(
    public event_properties: HomeClickPayProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HomeExited implements BaseEvent {
  event_type = 'home_exited';

  constructor(
    public event_properties: HomeExitedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HomeFciDisclaimerOpened implements BaseEvent {
  event_type = 'home_fci_disclaimer_opened';
}

export class HomeScreenViewed implements BaseEvent {
  event_type = 'home_screen_viewed';
}

export class HomeTapCard implements BaseEvent {
  event_type = 'home_tap_card';

  constructor(
    public event_properties: HomeTapCardProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InstrumentTypeChosen implements BaseEvent {
  event_type = 'instrument_type_chosen';

  constructor(
    public event_properties: InstrumentTypeChosenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MarketCurrencyChanged implements BaseEvent {
  event_type = 'market_currency_changed';

  constructor(
    public event_properties?: MarketCurrencyChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MarketOrderReviewed implements BaseEvent {
  event_type = 'market_order_reviewed';
}

export class MarketOrderSent implements BaseEvent {
  event_type = 'market_order_sent';

  constructor(
    public event_properties: MarketOrderSentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MarketSettlementChanged implements BaseEvent {
  event_type = 'market_settlement_changed';

  constructor(
    public event_properties?: MarketSettlementChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MepBannerClicked implements BaseEvent {
  event_type = 'mep_banner_clicked';

  constructor(
    public event_properties: MepBannerClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MepBottomsheetViewed implements BaseEvent {
  event_type = 'mep_bottomsheet_viewed';
}

export class MepOrderError implements BaseEvent {
  event_type = 'mep_order_error';

  constructor(
    public event_properties: MepOrderErrorProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MepOrderReviewed implements BaseEvent {
  event_type = 'mep_order_reviewed';

  constructor(
    public event_properties: MepOrderReviewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MepOrderSuccess implements BaseEvent {
  event_type = 'mep_order_success';

  constructor(
    public event_properties: MepOrderSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MepScreenViewed implements BaseEvent {
  event_type = 'mep_screen_viewed';

  constructor(
    public event_properties: MepScreenViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MepTypeChosen implements BaseEvent {
  event_type = 'mep_type_chosen';

  constructor(
    public event_properties: MepTypeChosenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ObStartCamara implements BaseEvent {
  event_type = 'ob_start_camara';

  constructor(
    public event_properties: ObStartCamaraProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OnboardingBottomsheetViewed implements BaseEvent {
  event_type = 'onboarding_bottomsheet_viewed';

  constructor(
    public event_properties: OnboardingBottomsheetViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OrderBookOpened implements BaseEvent {
  event_type = 'order_book_opened';
}

export class OrdersScreenViewed implements BaseEvent {
  event_type = 'orders_screen_viewed';

  constructor(
    public event_properties: OrdersScreenViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PayAmountContinue implements BaseEvent {
  event_type = 'pay_amount_continue';

  constructor(
    public event_properties?: PayAmountContinueProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PayClickConfirm implements BaseEvent {
  event_type = 'pay_click_confirm';

  constructor(
    public event_properties?: PayClickConfirmProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PayDeposit implements BaseEvent {
  event_type = 'pay_deposit';
}

export class PayDownloadReceipt implements BaseEvent {
  event_type = 'pay_download_receipt';

  constructor(
    public event_properties: PayDownloadReceiptProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PayExit implements BaseEvent {
  event_type = 'pay_exit';

  constructor(
    public event_properties: PayExitProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PayFailedRetry implements BaseEvent {
  event_type = 'pay_failed_retry';

  constructor(
    public event_properties: PayFailedRetryProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PayScanContinue implements BaseEvent {
  event_type = 'pay_scan_continue';
}

export class PayScanFailed implements BaseEvent {
  event_type = 'pay_scan_failed';

  constructor(
    public event_properties: PayScanFailedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PayScanQr implements BaseEvent {
  event_type = 'pay_scan_qr';

  constructor(
    public event_properties: PayScanQrProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PayScanRetry implements BaseEvent {
  event_type = 'pay_scan_retry';

  constructor(
    public event_properties: PayScanRetryProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PayScreenAccessCamara implements BaseEvent {
  event_type = 'pay_screen_access_camara';

  constructor(
    public event_properties: PayScreenAccessCamaraProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PayScreenAmount implements BaseEvent {
  event_type = 'pay_screen_amount';
}

export class PayScreenFailed implements BaseEvent {
  event_type = 'pay_screen_failed';

  constructor(
    public event_properties: PayScreenFailedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PayScreenMethod implements BaseEvent {
  event_type = 'pay_screen_method';

  constructor(
    public event_properties: PayScreenMethodProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PayScreenScanQr implements BaseEvent {
  event_type = 'pay_screen_scan_qr';

  constructor(
    public event_properties?: PayScreenScanQrProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PayScreenSuccess implements BaseEvent {
  event_type = 'pay_screen_success';

  constructor(
    public event_properties: PayScreenSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PaySelectMethod implements BaseEvent {
  event_type = 'pay_select_method';

  constructor(
    public event_properties?: PaySelectMethodProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PhoneNumberScreenViewed implements BaseEvent {
  event_type = 'phone_number_screen_viewed';

  constructor(
    public event_properties: PhoneNumberScreenViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PhoneVerificationCodeScreenViewed implements BaseEvent {
  event_type = 'phone_verification_code_screen_viewed';

  constructor(
    public event_properties: PhoneVerificationCodeScreenViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PhoneVerificationCodeSuccess implements BaseEvent {
  event_type = 'phone_verification_code_success';

  constructor(
    public event_properties: PhoneVerificationCodeSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PhysicalCardNotStarted implements BaseEvent {
  event_type = 'physical_card_not_started';
}

export class PortfolioChartRangeSwitched implements BaseEvent {
  event_type = 'portfolio_chart_range_switched';

  constructor(
    public event_properties: PortfolioChartRangeSwitchedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PortfolioCurrencySwitched implements BaseEvent {
  event_type = 'portfolio_currency_switched';

  constructor(
    public event_properties: PortfolioCurrencySwitchedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PortfolioExited implements BaseEvent {
  event_type = 'portfolio_exited';

  constructor(
    public event_properties: PortfolioExitedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PortfolioReturnsScreenViewed implements BaseEvent {
  event_type = 'portfolio_returns_screen_viewed';
}

export class PortfolioScreenViewed implements BaseEvent {
  event_type = 'portfolio_screen_viewed';

  constructor(
    public event_properties: PortfolioScreenViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PredefinedAmountSelected implements BaseEvent {
  event_type = 'predefined_amount_selected';
}

export class ReceiveAliasCopied implements BaseEvent {
  event_type = 'receive_alias_copied';

  constructor(
    public event_properties?: ReceiveAliasCopiedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReceiveCclBottomsheetContinued implements BaseEvent {
  event_type = 'receive_ccl_bottomsheet_continued';
}

export class ReceiveCclInformationCopied implements BaseEvent {
  event_type = 'receive_ccl_information_copied';

  constructor(
    public event_properties: ReceiveCclInformationCopiedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReceiveCurrencySelected implements BaseEvent {
  event_type = 'receive_currency_selected';

  constructor(
    public event_properties?: ReceiveCurrencySelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReceiveExit implements BaseEvent {
  event_type = 'receive_exit';

  constructor(
    public event_properties: ReceiveExitProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReceiveScreenViewed implements BaseEvent {
  event_type = 'receive_screen_viewed';

  constructor(
    public event_properties: ReceiveScreenViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReceiveSelectedAssetType implements BaseEvent {
  event_type = 'receive_selected_asset_type';

  constructor(
    public event_properties: ReceiveSelectedAssetTypeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RequestContactCocosGold implements BaseEvent {
  event_type = 'request_contact_cocos_gold';
}

export class ScreenCocosGold implements BaseEvent {
  event_type = 'screen_cocos_gold';

  constructor(
    public event_properties?: ScreenCocosGoldProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SendAmountConfirmed implements BaseEvent {
  event_type = 'send_amount_confirmed';

  constructor(
    public event_properties?: SendAmountConfirmedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SendAmountExceededError implements BaseEvent {
  event_type = 'send_amount_exceeded_error';
}

export class SendBankAccountSelected implements BaseEvent {
  event_type = 'send_bank_account_selected';
}

export class SendOrderCanceled implements BaseEvent {
  event_type = 'send_order_canceled';

  constructor(
    public event_properties?: SendOrderCanceledProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SendOrderConfirmed implements BaseEvent {
  event_type = 'send_order_confirmed';

  constructor(
    public event_properties?: SendOrderConfirmedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SendOrderError implements BaseEvent {
  event_type = 'send_order_error';

  constructor(
    public event_properties?: SendOrderErrorProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SendOrderSuccess implements BaseEvent {
  event_type = 'send_order_success';

  constructor(
    public event_properties?: SendOrderSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SendScreenViewed implements BaseEvent {
  event_type = 'send_screen_viewed';

  constructor(
    public event_properties: SendScreenViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SignupBiometricsActivated implements BaseEvent {
  event_type = 'signup_biometrics_activated';
}

export class SignupBiometricsScreenViewed implements BaseEvent {
  event_type = 'signup_biometrics_screen_viewed';
}

export class SignupEmailScreenBack implements BaseEvent {
  event_type = 'signup_email_screen_back';
}

export class SignupEmailScreenError implements BaseEvent {
  event_type = 'signup_email_screen_error';

  constructor(
    public event_properties: SignupEmailScreenErrorProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SignupEmailValidationError implements BaseEvent {
  event_type = 'signup_email_validation_error';

  constructor(
    public event_properties: SignupEmailValidationErrorProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SignupEmailVerificationResendEmail implements BaseEvent {
  event_type = 'signup_email_verification_resend_email';
}

export class SignupEmailVerificationScreenViewed implements BaseEvent {
  event_type = 'signup_email_verification_screen_viewed';
}

export class SignupEmailVerificationSuccess implements BaseEvent {
  event_type = 'signup_email_verification_success';
}

export class SignupPjBannerClicked implements BaseEvent {
  event_type = 'signup_pj_banner_clicked';
}

export class SignupStarted implements BaseEvent {
  event_type = 'signup_started';
}

export class SignupSuccess implements BaseEvent {
  event_type = 'signup_success';
}

export class VirtualCardNotStarted implements BaseEvent {
  event_type = 'virtual_card_not_started';
}

export class WithdrawAmountConfirmed implements BaseEvent {
  event_type = 'withdraw_amount_confirmed';

  constructor(
    public event_properties?: WithdrawAmountConfirmedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WithdrawAmountExceededError implements BaseEvent {
  event_type = 'withdraw_amount_exceeded_error';
}

export class WithdrawBankAccountSelected implements BaseEvent {
  event_type = 'withdraw_bank_account_selected';
}

export class WithdrawOrderCanceled implements BaseEvent {
  event_type = 'withdraw_order_canceled';

  constructor(
    public event_properties?: WithdrawOrderCanceledProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WithdrawOrderConfirmed implements BaseEvent {
  event_type = 'withdraw_order_confirmed';

  constructor(
    public event_properties?: WithdrawOrderConfirmedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WithdrawOrderError implements BaseEvent {
  event_type = 'withdraw_order_error';

  constructor(
    public event_properties?: WithdrawOrderErrorProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WithdrawOrderSuccess implements BaseEvent {
  event_type = 'withdraw_order_success';

  constructor(
    public event_properties?: WithdrawOrderSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WithdrawScreenViewed implements BaseEvent {
  event_type = 'withdraw_screen_viewed';

  constructor(
    public event_properties: WithdrawScreenViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * agenda_account_deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/agenda_account_deleted)
   *
   * Se trackea cuando el usuario elimina con éxito una cuenta bancaria de su lista de cuentas de la agenda.
   *
   * @param options Amplitude event options.
   */
  agendaAccountDeleted(
    options?: EventOptions,
  ) {
    return this.track(new AgendaAccountDeleted(), options);
  }

  /**
   * agenda_account_send_money
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/agenda_account_send_money)
   *
   * Se trackea cuando el usuario tapea el botón de "Enviar dinero" en el detalle de una cuenta bancaria de la agenda.
   *
   * @param options Amplitude event options.
   */
  agendaAccountSendMoney(
    options?: EventOptions,
  ) {
    return this.track(new AgendaAccountSendMoney(), options);
  }

  /**
   * agenda_add_new_account
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/agenda_add_new_account)
   *
   * Se trackea cuando el usuario tapea el botón de "Agregar una cuenta" en la agenda del perfil.
   *
   * @param options Amplitude event options.
   */
  agendaAddNewAccount(
    options?: EventOptions,
  ) {
    return this.track(new AgendaAddNewAccount(), options);
  }

  /**
   * agenda_new_account_added_error
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/agenda_new_account_added_error)
   *
   * Se trackea cuando el usuario intenta agregar una cuenta bancaria y ve un error.
   *
   * @param properties The event's properties (e.g. type)
   * @param options Amplitude event options.
   */
  agendaNewAccountAddedError(
    properties: AgendaNewAccountAddedErrorProperties,
    options?: EventOptions,
  ) {
    return this.track(new AgendaNewAccountAddedError(properties), options);
  }

  /**
   * asset_screen_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/asset_screen_viewed)
   *
   * Se dispara cuando el usuario entra a la pantalla de un activo.
   *
   * @param properties The event's properties (e.g. instrument_type)
   * @param options Amplitude event options.
   */
  assetScreenViewed(
    properties: AssetScreenViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AssetScreenViewed(properties), options);
  }

  /**
   * bank_account_added
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/bank_account_added)
   *
   * Se trackea cuando el usuario agrega con éxito una nueva cuenta bancaria. Porperties: referral (se manda "bank_accounts" si el usuario agregó una cuenta bancaria desde el perfil-agenda. Se manda send si el usuario agregó una cuenta bancaria desde el flujo de retirar dinero).
   *
   * @param properties The event's properties (e.g. referral)
   * @param options Amplitude event options.
   */
  bankAccountAdded(
    properties: BankAccountAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BankAccountAdded(properties), options);
  }

  /**
   * card_2fa_inactive
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_2fa_inactive)
   *
   * Se trackea cuando el usuario ve la pantalla de requerimiento de activación de 2FA
   *
   * Owner: Baltasar Parra
   *
   * @param options Amplitude event options.
   */
  card2FaInactive(
    options?: EventOptions,
  ) {
    return this.track(new Card2FaInactive(), options);
  }

  /**
   * card_2fa_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_2fa_success)
   *
   * Se trackea cuando un usuario configura correctamente 2FA e ingresa exitosamente a tarjetas
   *
   * Owner: Baltasar Parra
   *
   * @param options Amplitude event options.
   */
  card2FaSuccess(
    options?: EventOptions,
  ) {
    return this.track(new Card2FaSuccess(), options);
  }

  /**
   * card_activate_card
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_activate_card)
   *
   * Se trackea cuando el usuario tapea "Activar mi tarjeta" en la home de tarjeta
   *
   * Owner: Baltasar Parra
   *
   * @param options Amplitude event options.
   */
  cardActivateCard(
    options?: EventOptions,
  ) {
    return this.track(new CardActivateCard(), options);
  }

  /**
   * card_activation_activate
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_activation_activate)
   *
   * Se trackea cuando el usuario tapea "activar" en la pantalla de activación (iframe pomelo) luego de completar los datos (numeros de tarjeta y creacion de PIN)
   *
   * Owner: Baltasar Parra
   *
   * @param options Amplitude event options.
   */
  cardActivationActivate(
    options?: EventOptions,
  ) {
    return this.track(new CardActivationActivate(), options);
  }

  /**
   * card_activation_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_activation_success)
   *
   * Se trackea cuando completa la activacion y ve el toast de tarjeta creada en la home
   *
   * Owner: Baltasar Parra
   *
   * @param options Amplitude event options.
   */
  cardActivationSuccess(
    options?: EventOptions,
  ) {
    return this.track(new CardActivationSuccess(), options);
  }

  /**
   * card_atm_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_atm_success)
   *
   * Se trackea cuando el usuario crea exitosamente la clave de cajero
   *
   * Owner: Baltasar Parra
   *
   * @param options Amplitude event options.
   */
  cardAtmSuccess(
    options?: EventOptions,
  ) {
    return this.track(new CardAtmSuccess(), options);
  }

  /**
   * card_change_payment_method
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_change_payment_method)
   *
   * Se trackea cuando el usuario tapea un metodo de pago para modificarlo o agregar uno nuevo para su tarjeta fisica/virtual. 
   *  El method_changed se refiere a la ubicacion del metodo que se cambio.
   *
   * Owner: Baltasar Parra
   *
   * @param properties The event's properties (e.g. card_type)
   * @param options Amplitude event options.
   */
  cardChangePaymentMethod(
    properties: CardChangePaymentMethodProperties,
    options?: EventOptions,
  ) {
    return this.track(new CardChangePaymentMethod(properties), options);
  }

  /**
   * card_creation_confirm_payment
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_creation_confirm_payment)
   *
   * Se trackea cuando el usuario confirma el pago en la creación de tarjeta fisica
   *
   * Owner: Baltasar Parra
   *
   * @param properties The event's properties (e.g. amount_ars)
   * @param options Amplitude event options.
   */
  cardCreationConfirmPayment(
    properties: CardCreationConfirmPaymentProperties,
    options?: EventOptions,
  ) {
    return this.track(new CardCreationConfirmPayment(properties), options);
  }

  /**
   * card_creation_payment_methods
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_creation_payment_methods)
   *
   * Se trackea cuando el usuario llega a la pantalla con los posibles metodos de pago para la creacion de tarjeta fisica. status nok si no tiene fondos.
   *
   * Owner: Baltasar Parra
   *
   * @param properties The event's properties (e.g. status)
   * @param options Amplitude event options.
   */
  cardCreationPaymentMethods(
    properties: CardCreationPaymentMethodsProperties,
    options?: EventOptions,
  ) {
    return this.track(new CardCreationPaymentMethods(properties), options);
  }

  /**
   * card_creation_steps
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_creation_steps)
   *
   * Se trackea cuando el usuario ve la pantalla con los pasos a seguir para la creacion de la tarjeta fisica
   *
   * Owner: Baltasar Parra
   *
   * @param options Amplitude event options.
   */
  cardCreationSteps(
    options?: EventOptions,
  ) {
    return this.track(new CardCreationSteps(), options);
  }

  /**
   * card_creation_without_delivery
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_creation_without_delivery)
   *
   * Se trackea cuando el usuario tapea que ya recibió su tarjeta y desea activarla, en la pantalla de pasos a seguir en creación de tarjeta fisica
   *
   * Owner: Baltasar Parra
   *
   * @param options Amplitude event options.
   */
  cardCreationWithoutDelivery(
    options?: EventOptions,
  ) {
    return this.track(new CardCreationWithoutDelivery(), options);
  }

  /**
   * card_deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_deleted)
   *
   * Se trackea cuando el usuario confirma la baja de su tarjeta fisica/virtual
   *
   * Owner: Baltasar Parra
   *
   * @param properties The event's properties (e.g. card_type)
   * @param options Amplitude event options.
   */
  cardDeleted(
    properties: CardDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CardDeleted(properties), options);
  }

  /**
   * card_delivery_confirm
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_delivery_confirm)
   *
   * Se trackea cuando el usuario confirma los datos de envio de tarjeta fisica
   *
   * Owner: Baltasar Parra
   *
   * @param properties The event's properties (e.g. province)
   * @param options Amplitude event options.
   */
  cardDeliveryConfirm(
    properties: CardDeliveryConfirmProperties,
    options?: EventOptions,
  ) {
    return this.track(new CardDeliveryConfirm(properties), options);
  }

  /**
   * card_delivery_continue
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_delivery_continue)
   *
   * Se trackea cuando el usuario tapea continuar luego de llenar el formulario de datos de envio de tarjeta fisica
   *
   * Owner: Baltasar Parra
   *
   * @param options Amplitude event options.
   */
  cardDeliveryContinue(
    options?: EventOptions,
  ) {
    return this.track(new CardDeliveryContinue(), options);
  }

  /**
   * card_error_creation_failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_error_creation_failed)
   *
   * Se trackea cuando el usuario ve la pantalla "Coinag Failed". Mismo nombre que en figma.
   *
   * Owner: Baltasar Parra
   *
   * @param properties The event's properties (e.g. card_type)
   * @param options Amplitude event options.
   */
  cardErrorCreationFailed(
    properties: CardErrorCreationFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CardErrorCreationFailed(properties), options);
  }

  /**
   * card_get_data
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_get_data)
   *
   * Se trackea cuanto el usuario tapea la opcion para ver los datos de su tarjeta fisica/virtual en la home de tarjeta
   *
   * Owner: Baltasar Parra
   *
   * @param properties The event's properties (e.g. card_type)
   * @param options Amplitude event options.
   */
  cardGetData(
    properties: CardGetDataProperties,
    options?: EventOptions,
  ) {
    return this.track(new CardGetData(properties), options);
  }

  /**
   * card_home_screen
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_home_screen)
   *
   * Se trackea cuando el usuario ve la home de su tarjeta
   *
   * Owner: Baltasar Parra
   *
   * @param properties The event's properties (e.g. card_methods)
   * @param options Amplitude event options.
   */
  cardHomeScreen(
    properties: CardHomeScreenProperties,
    options?: EventOptions,
  ) {
    return this.track(new CardHomeScreen(properties), options);
  }

  /**
   * card_intro_exit
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_intro_exit)
   *
   * Se trackea cuando un usuario presiona exit en la intro de tarjeta
   *
   * Owner: Baltasar Parra
   *
   * @param options Amplitude event options.
   */
  cardIntroExit(
    options?: EventOptions,
  ) {
    return this.track(new CardIntroExit(), options);
  }

  /**
   * card_intro_tap_get_card
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_intro_tap_get_card)
   *
   * Se trackea cuando el usuario tapea "quiero mi Cocos Card" en la intro de tarjetas
   *
   * Owner: Baltasar Parra
   *
   * @param options Amplitude event options.
   */
  cardIntroTapGetCard(
    options?: EventOptions,
  ) {
    return this.track(new CardIntroTapGetCard(), options);
  }

  /**
   * card_limit_modified
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_limit_modified)
   *
   * Se trackea cuando el usuario modifica exitosamente el limite de su tarjeta fisica/virtual. El card_limit deberia ser cuanto eligio sobre el total, por ejemplo: 1.000.000/1.500.000 (literalmente ese número, no la division). Esto lo tenemos trackeado asi con los limites de crypto tambien para ver cuanto lleva consumido
   *
   * Owner: Baltasar Parra
   *
   * @param properties The event's properties (e.g. card_limit)
   * @param options Amplitude event options.
   */
  cardLimitModified(
    properties: CardLimitModifiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CardLimitModified(properties), options);
  }

  /**
   * card_limit_request_increase
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_limit_request_increase)
   *
   * Se trackea cuando el usuario solicita un aumento en su limite disponible para tarjeta fisica o virtual
   *
   * Owner: Baltasar Parra
   *
   * @param properties The event's properties (e.g. card_type)
   * @param options Amplitude event options.
   */
  cardLimitRequestIncrease(
    properties: CardLimitRequestIncreaseProperties,
    options?: EventOptions,
  ) {
    return this.track(new CardLimitRequestIncrease(properties), options);
  }

  /**
   * card_lost_paused
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_lost_paused)
   *
   * Se trackea cuando el usuario pausa su tarjeta por pérdida
   *
   * Owner: Baltasar Parra
   *
   * @param properties The event's properties (e.g. card_type)
   * @param options Amplitude event options.
   */
  cardLostPaused(
    properties: CardLostPausedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CardLostPaused(properties), options);
  }

  /**
   * card_mov_problem_deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_mov_problem_deleted)
   *
   * Se trackea cuando el usuario da de baja la tarjeta por desconocer un movimiento
   *
   * Owner: Baltasar Parra
   *
   * @param properties The event's properties (e.g. card_type)
   * @param options Amplitude event options.
   */
  cardMovProblemDeleted(
    properties: CardMovProblemDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CardMovProblemDeleted(properties), options);
  }

  /**
   * card_mov_problem_support
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_mov_problem_support)
   *
   * Se trackea cuando el usuario pide soporte por desconocer un movimiento
   *
   * Owner: Baltasar Parra
   *
   * @param properties The event's properties (e.g. card_type)
   * @param options Amplitude event options.
   */
  cardMovProblemSupport(
    properties: CardMovProblemSupportProperties,
    options?: EventOptions,
  ) {
    return this.track(new CardMovProblemSupport(properties), options);
  }

  /**
   * card_movements
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_movements)
   *
   * Se trackea cuando el usuario tapea la sección de movimientos de su tarjeta fisica/virtual
   *
   * Owner: Baltasar Parra
   *
   * @param properties The event's properties (e.g. card_type)
   * @param options Amplitude event options.
   */
  cardMovements(
    properties: CardMovementsProperties,
    options?: EventOptions,
  ) {
    return this.track(new CardMovements(properties), options);
  }

  /**
   * card_no_receive_physical_card
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_no_receive_physical_card)
   *
   * Se trackea cuando el usuario tapea "No recibí mi tarjeta"
   *
   * Owner: Baltasar Parra
   *
   * @param options Amplitude event options.
   */
  cardNoReceivePhysicalCard(
    options?: EventOptions,
  ) {
    return this.track(new CardNoReceivePhysicalCard(), options);
  }

  /**
   * card_out_of_stock
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_out_of_stock)
   *
   * Se trackea cuando un usuario ve la pantalla de tarjetas fisicas agotadas
   *
   * Owner: Baltasar Parra
   *
   * @param options Amplitude event options.
   */
  cardOutOfStock(
    options?: EventOptions,
  ) {
    return this.track(new CardOutOfStock(), options);
  }

  /**
   * card_pause
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_pause)
   *
   * Se trackea cuando el usuario tapea el boton para pausar su tarjeta fisica
   *
   * Owner: Baltasar Parra
   *
   * @param properties The event's properties (e.g. card_status)
   * @param options Amplitude event options.
   */
  cardPause(
    properties: CardPauseProperties,
    options?: EventOptions,
  ) {
    return this.track(new CardPause(properties), options);
  }

  /**
   * card_payment_method_changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_payment_method_changed)
   *
   * Se trackea cuando el usuario elige el metodo de pago nuevo a utilizar. method_change: indica si es el primero, segundo o tercero.
   *
   * Owner: Baltasar Parra
   *
   * @param properties The event's properties (e.g. card_type)
   * @param options Amplitude event options.
   */
  cardPaymentMethodChanged(
    properties: CardPaymentMethodChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CardPaymentMethodChanged(properties), options);
  }

  /**
   * card_physical_creation_failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_physical_creation_failed)
   *
   * Se trackea cuando el usuario ve la pantalla de error al crear su tarjeta fisica
   *
   * Owner: Baltasar Parra
   *
   * @param options Amplitude event options.
   */
  cardPhysicalCreationFailed(
    options?: EventOptions,
  ) {
    return this.track(new CardPhysicalCreationFailed(), options);
  }

  /**
   * card_physical_creation_ready
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_physical_creation_ready)
   *
   * Se trackea cuando el usuario ve la screen de tarjeta en camino.
   *
   * Owner: Baltasar Parra
   *
   * @param options Amplitude event options.
   */
  cardPhysicalCreationReady(
    options?: EventOptions,
  ) {
    return this.track(new CardPhysicalCreationReady(), options);
  }

  /**
   * card_problems_movements_option
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_problems_movements_option)
   *
   * Se trackea cuando el usuario elige una opción en el menú de problemas con la tarjeta debido a "hay movimientos que no hice"
   *
   * Owner: Baltasar Parra
   *
   * @param properties The event's properties (e.g. mov_problem_option)
   * @param options Amplitude event options.
   */
  cardProblemsMovementsOption(
    properties: CardProblemsMovementsOptionProperties,
    options?: EventOptions,
  ) {
    return this.track(new CardProblemsMovementsOption(properties), options);
  }

  /**
   * card_problems_option
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_problems_option)
   *
   * Se trackea cuando el usuario elige una opción en el menú de problemas con la tarjeta. 
   *  problems_option: lost, stolen, movements
   *
   * Owner: Baltasar Parra
   *
   * @param properties The event's properties (e.g. problems_option)
   * @param options Amplitude event options.
   */
  cardProblemsOption(
    properties: CardProblemsOptionProperties,
    options?: EventOptions,
  ) {
    return this.track(new CardProblemsOption(properties), options);
  }

  /**
   * card_product_promotion_support
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_product_promotion_support)
   *
   * Se trackea cuando el usuario pide soporte por problemas con producto o promocion
   *
   * Owner: Baltasar Parra
   *
   * @param properties The event's properties (e.g. card_type)
   * @param options Amplitude event options.
   */
  cardProductPromotionSupport(
    properties: CardProductPromotionSupportProperties,
    options?: EventOptions,
  ) {
    return this.track(new CardProductPromotionSupport(properties), options);
  }

  /**
   * card_request_card
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_request_card)
   *
   * Se trackea cuando el usuario tapea "pedir tarjeta fisica/virtual"
   *
   * Owner: Baltasar Parra
   *
   * @param properties The event's properties (e.g. card_type)
   * @param options Amplitude event options.
   */
  cardRequestCard(
    properties: CardRequestCardProperties,
    options?: EventOptions,
  ) {
    return this.track(new CardRequestCard(properties), options);
  }

  /**
   * card_screen_delivery
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_screen_delivery)
   *
   * Se trackea cuando el usuario ve la pantalla donde debe ingresar los datos para el envio de su tarjeta fisica
   *
   * Owner: Baltasar Parra
   *
   * @param options Amplitude event options.
   */
  cardScreenDelivery(
    options?: EventOptions,
  ) {
    return this.track(new CardScreenDelivery(), options);
  }

  /**
   * card_set_2fa
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_set_2fa)
   *
   * Se trackea cuando un usuario tapea "configurar Google Authenticator" en la intro de tarjeta
   *
   * Owner: Baltasar Parra
   *
   * @param options Amplitude event options.
   */
  cardSet2Fa(
    options?: EventOptions,
  ) {
    return this.track(new CardSet2Fa(), options);
  }

  /**
   * card_settings
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_settings)
   *
   * Se trackea cuando el usuario tapea el boton de configuracion de su tarjeta fisica/virtual
   *
   * Owner: Baltasar Parra
   *
   * @param properties The event's properties (e.g. card_type)
   * @param options Amplitude event options.
   */
  cardSettings(
    properties: CardSettingsProperties,
    options?: EventOptions,
  ) {
    return this.track(new CardSettings(properties), options);
  }

  /**
   * card_settings_selected_option
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_settings_selected_option)
   *
   * Se trackea cuando selecciona una opcion en el menu de settings
   *
   * Owner: Baltasar Parra
   *
   * @param properties The event's properties (e.g. card_type)
   * @param options Amplitude event options.
   */
  cardSettingsSelectedOption(
    properties: CardSettingsSelectedOptionProperties,
    options?: EventOptions,
  ) {
    return this.track(new CardSettingsSelectedOption(properties), options);
  }

  /**
   * card_stolen_deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_stolen_deleted)
   *
   * Se trackea cuando el usuario da de baja la tarjeta por robo
   *
   * Owner: Baltasar Parra
   *
   * @param properties The event's properties (e.g. card_type)
   * @param options Amplitude event options.
   */
  cardStolenDeleted(
    properties: CardStolenDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CardStolenDeleted(properties), options);
  }

  /**
   * card_virtual_creation_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/card_virtual_creation_success)
   *
   * Se trackea cuando el usuario crea exitosamente su tarjeta virtual
   *
   * Owner: Baltasar Parra
   *
   * @param options Amplitude event options.
   */
  cardVirtualCreationSuccess(
    options?: EventOptions,
  ) {
    return this.track(new CardVirtualCreationSuccess(), options);
  }

  /**
   * ccl_crypto_address
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/ccl_crypto_address)
   *
   * se trackea cunado el usuario ve la pantalla con su address
   *
   * @param properties The event's properties (e.g. referral)
   * @param options Amplitude event options.
   */
  cclCryptoAddress(
    properties: CclCryptoAddressProperties,
    options?: EventOptions,
  ) {
    return this.track(new CclCryptoAddress(properties), options);
  }

  /**
   * ccl_crypto_data_copied
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/ccl_crypto_data_copied)
   *
   * se trackea cuando el usuario clickea el boton de copiar en cualquiera de los items de la pantalla address
   *
   *
   * Event properties: 
   *  item: bank_name, bank_address, zip_code, routing_number, account_number
   *
   * @param properties The event's properties (e.g. item)
   * @param options Amplitude event options.
   */
  cclCryptoDataCopied(
    properties: CclCryptoDataCopiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CclCryptoDataCopied(properties), options);
  }

  /**
   * ccl_crypto_data_shared
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/ccl_crypto_data_shared)
   *
   * se trackea cuando el usuario clickea el boton de compartir detalles en la pantalla address
   *
   * @param options Amplitude event options.
   */
  cclCryptoDataShared(
    options?: EventOptions,
  ) {
    return this.track(new CclCryptoDataShared(), options);
  }

  /**
   * ccl_crypto_delayed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/ccl_crypto_delayed)
   *
   * se trackea cuando el usuario ve la pantalla de creación en proceso
   *
   * @param options Amplitude event options.
   */
  cclCryptoDelayed(
    options?: EventOptions,
  ) {
    return this.track(new CclCryptoDelayed(), options);
  }

  /**
   * ccl_crypto_exit
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/ccl_crypto_exit)
   *
   * Se trackea cuando el usuario hace exit de una pantalla del flujo de CCL, sea con una cruz o un CTA de "Ir al inicio". 
   *
   *  Event property: 
   *  referral: onboarding, paywall, tyc, failed, delayed, address
   *
   * Owner: Baltasar Parra
   *
   * @param properties The event's properties (e.g. referral)
   * @param options Amplitude event options.
   */
  cclCryptoExit(
    properties: CclCryptoExitProperties,
    options?: EventOptions,
  ) {
    return this.track(new CclCryptoExit(properties), options);
  }

  /**
   * ccl_crypto_failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/ccl_crypto_failed)
   *
   * se trackea cuando el usuario ve la pantalla de error en la creación de su cuenta
   *
   * @param options Amplitude event options.
   */
  cclCryptoFailed(
    options?: EventOptions,
  ) {
    return this.track(new CclCryptoFailed(), options);
  }

  /**
   * ccl_crypto_more_info
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/ccl_crypto_more_info)
   *
   * se trackea cuando el usuario clickea el boton de información en cualquiera de los items de la pantalla address
   *
   *
   * Event property: 
   *  item: bank_name, routing_number, account_number
   *
   * @param properties The event's properties (e.g. item)
   * @param options Amplitude event options.
   */
  cclCryptoMoreInfo(
    properties: CclCryptoMoreInfoProperties,
    options?: EventOptions,
  ) {
    return this.track(new CclCryptoMoreInfo(properties), options);
  }

  /**
   * ccl_crypto_onboarding
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/ccl_crypto_onboarding)
   *
   * Trackeamos la pantalla de onboarding con la property status para saber si ve la pantalla de ob, error o creacion de cuenta pendiente. Si es la primera vez que entra siempre va a ver la de ob, si ya hizo el proceso puede ver alguna de las dos. Una vez que la cuenta esta creada no debería ver nunca más este evento. 
   *
   *
   * Event property: status: onboarding, delayed, failed
   *
   * @param properties The event's properties (e.g. referral)
   * @param options Amplitude event options.
   */
  cclCryptoOnboarding(
    properties: CclCryptoOnboardingProperties,
    options?: EventOptions,
  ) {
    return this.track(new CclCryptoOnboarding(properties), options);
  }

  /**
   * ccl_crypto_payment_confirmed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/ccl_crypto_payment_confirmed)
   *
   * trackeamos cuando confirma el pago para la creación de la cuenta en bridge
   *
   * @param properties The event's properties (e.g. method)
   * @param options Amplitude event options.
   */
  cclCryptoPaymentConfirmed(
    properties?: CclCryptoPaymentConfirmedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CclCryptoPaymentConfirmed(properties), options);
  }

  /**
   * ccl_crypto_payment_methods
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/ccl_crypto_payment_methods)
   *
   * trackeamos cuando llega  a la pantalla con los metodos de pago para la apertura de cuenta en bridge
   *
   * @param properties The event's properties (e.g. available_methods)
   * @param options Amplitude event options.
   */
  cclCryptoPaymentMethods(
    properties: CclCryptoPaymentMethodsProperties,
    options?: EventOptions,
  ) {
    return this.track(new CclCryptoPaymentMethods(properties), options);
  }

  /**
   * ccl_crypto_paywall_accepted
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/ccl_crypto_paywall_accepted)
   *
   * Se trackea cuando el usuario clickea "Si, estoy de acuerdo" en la pantalla de paywall
   *
   * @param options Amplitude event options.
   */
  cclCryptoPaywallAccepted(
    options?: EventOptions,
  ) {
    return this.track(new CclCryptoPaywallAccepted(), options);
  }

  /**
   * ccl_crypto_support
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/ccl_crypto_support)
   *
   * se trackea cuando el usuario clickea contactar a soporte en la pantalla de error al crear la address o en la pantalla de error de la intro
   *
   * Event property: 
   *  referral: intro, failed
   *
   * @param properties The event's properties (e.g. referral)
   * @param options Amplitude event options.
   */
  cclCryptoSupport(
    properties: CclCryptoSupportProperties,
    options?: EventOptions,
  ) {
    return this.track(new CclCryptoSupport(properties), options);
  }

  /**
   * ccl_screen_paywall
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/ccl_screen_paywall)
   *
   * trackeamos este evento cuando el usuario ve con los terminos y condiciones para la creación de cuenta en bridge
   *
   * @param properties The event's properties (e.g. is_button_active)
   * @param options Amplitude event options.
   */
  cclScreenPaywall(
    properties: CclScreenPaywallProperties,
    options?: EventOptions,
  ) {
    return this.track(new CclScreenPaywall(properties), options);
  }

  /**
   * change_password_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/change_password_success)
   *
   * Se trackea cuando el usuario cambia con éxito su contraseña.
   *
   * @param properties The event's properties (e.g. referral)
   * @param options Amplitude event options.
   */
  changePasswordSuccess(
    properties: ChangePasswordSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChangePasswordSuccess(properties), options);
  }

  /**
   * cocorma_from_withdraw
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/cocorma_from_withdraw)
   *
   * Se trackea cuando el usuario clickea la opcion de suscribir al fondo COCORMA en la pantalla de extracción de ARS.
   *
   * Owner: Baltasar Parra
   *
   * @param options Amplitude event options.
   */
  cocormaFromWithdraw(
    options?: EventOptions,
  ) {
    return this.track(new CocormaFromWithdraw(), options);
  }

  /**
   * cocostag_banner_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/cocostag_banner_clicked)
   *
   * trackeamos este evento cuando se clickea "quiero mi cocostag" tanto en la home como en profile.
   *
   * Referral: home, profile.
   *
   * @param properties The event's properties (e.g. referral)
   * @param options Amplitude event options.
   */
  cocostagBannerClicked(
    properties: CocostagBannerClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CocostagBannerClicked(properties), options);
  }

  /**
   * cocostag_exit
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/cocostag_exit)
   *
   * trackeamos este evento cuando un usuario toca exit en cualquier pantalla del flujo de creacion de cocostag.
   *
   * referral: tyc, username_creation, success_screen
   *
   * @param properties The event's properties (e.g. referral)
   * @param options Amplitude event options.
   */
  cocostagExit(
    properties: CocostagExitProperties,
    options?: EventOptions,
  ) {
    return this.track(new CocostagExit(properties), options);
  }

  /**
   * cocostag_tyc_accepted
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/cocostag_tyc_accepted)
   *
   * trackeamos este evento cuando el usuario presiona continuar en la pantalla de TyC de cocostag
   *
   * @param options Amplitude event options.
   */
  cocostagTycAccepted(
    options?: EventOptions,
  ) {
    return this.track(new CocostagTycAccepted(), options);
  }

  /**
   * cocostag_username_creation_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/cocostag_username_creation_success)
   *
   * trackeamos este evento cuando el usuario ingresa un cocostag valido y presiona contiunar.
   *
   * @param options Amplitude event options.
   */
  cocostagUsernameCreationSuccess(
    options?: EventOptions,
  ) {
    return this.track(new CocostagUsernameCreationSuccess(), options);
  }

  /**
   * cocostag_username_shared
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/cocostag_username_shared)
   *
   * trackeamos este evento cuando un usuario clickea "Compartir mi cocostag".
   *
   * referral: profile, cocostag_creation_success
   *
   * @param properties The event's properties (e.g. referral)
   * @param options Amplitude event options.
   */
  cocostagUsernameShared(
    properties: CocostagUsernameSharedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CocostagUsernameShared(properties), options);
  }

  /**
   * crypto_activity_bottomsheet
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_activity_bottomsheet)
   *
   * Se trackea cuando el usuario visualiza el bottomsheet con detalles de un movimiento
   *
   * Event Properties: 
   *  - operation_type: {buy/sell/swap) 
   *  - base_ticker: {ticker_name} 
   *  - quote_ticker: {ticker_name} 
   *  - base_amount: {amount} 
   *  - base_quantity: {amount} 
   *  - quote_amount: {amount} 
   *  - quote_quantity: {amount} 
   *  - date: {date} 
   *  - operation_number: {number} 
   *  - referral: home, activity, marketDetail
   *
   * @param properties The event's properties (e.g. base_quantity)
   * @param options Amplitude event options.
   */
  cryptoActivityBottomsheet(
    properties: CryptoActivityBottomsheetProperties,
    options?: EventOptions,
  ) {
    return this.track(new CryptoActivityBottomsheet(properties), options);
  }

  /**
   * crypto_banner_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_banner_clicked)
   *
   * Se trackea cuando el usuario tappea un banner de crypto (ya sea tag o ccl) tanto del lado de crypto como del lado de capital. Tambien se trackea si tapeo ingresar o dismiss. 
   *  Event Properties 
   *  referral: {crypto,capital), type: {ccl, tag (a futuro puede haber mas)}, tapped_button: {open, dismiss}
   *
   * @param properties The event's properties (e.g. referral)
   * @param options Amplitude event options.
   */
  cryptoBannerClicked(
    properties: CryptoBannerClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CryptoBannerClicked(properties), options);
  }

  /**
   * crypto_operations_confirm
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_operations_confirm)
   *
   * se trackea cuando el usuario clickea confirmar en la previsualizacion de una operación 
   *
   *  - operation_type: {buy/sell/swap) 
   *  - base_ticker: {ticker_name} 
   *  - quote_ticker: {ticker_name} 
   *  - base_quantity: {amount} 
   *  - quote_quantity: {amount} 
   *  - exchange_amount: {amount}
   *
   * @param properties The event's properties (e.g. base_quantity)
   * @param options Amplitude event options.
   */
  cryptoOperationsConfirm(
    properties?: CryptoOperationsConfirmProperties,
    options?: EventOptions,
  ) {
    return this.track(new CryptoOperationsConfirm(properties), options);
  }

  /**
   * crypto_operations_exit
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_operations_exit)
   *
   * Se trackea cuando el usuario hace exit de una pantalla del flujo de operaciones, sea con una cruz o un CTA de "Ir al inicio".
   *
   * @param options Amplitude event options.
   */
  cryptoOperationsExit(
    options?: EventOptions,
  ) {
    return this.track(new CryptoOperationsExit(), options);
  }

  /**
   * crypto_operations_fail
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_operations_fail)
   *
   * se trackea cuando la operación falla y el usuario visualiza la pantalla de error 
   *
   *  - operation_type: {buy/sell/swap) 
   *  - base_ticker: {ticker_name} 
   *  - quote_ticker: {ticker_name} 
   *  - base_quantity: {amount} 
   *  - quote_quantity: {amount} 
   *  - exchange_amount: {amount}
   *
   * @param properties The event's properties (e.g. base_quantity)
   * @param options Amplitude event options.
   */
  cryptoOperationsFail(
    properties?: CryptoOperationsFailProperties,
    options?: EventOptions,
  ) {
    return this.track(new CryptoOperationsFail(properties), options);
  }

  /**
   * crypto_operations_preview
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_operations_preview)
   *
   * se trackea cuando el usuario clickea previsualizar compra/venta/conversion 
   *
   *  - operation_type: {buy/sell/swap) 
   *  - base_ticker: {ticker_name} 
   *  - quote_ticker: {ticker_name} 
   *  - base_quantity: {amount} 
   *  - quote_quantity: {amount} 
   *  - exchange_amount: {amount}
   *
   * @param properties The event's properties (e.g. base_quantity)
   * @param options Amplitude event options.
   */
  cryptoOperationsPreview(
    properties?: CryptoOperationsPreviewProperties,
    options?: EventOptions,
  ) {
    return this.track(new CryptoOperationsPreview(properties), options);
  }

  /**
   * crypto_operations_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_operations_success)
   *
   * se trackea cuando la operación es exitosa y el usuario visualiza la pantalla de confirmación 
   *
   *  - operation_type: {buy/sell/swap) 
   *  - base_ticker: {ticker_name} 
   *  - quote_ticker: {ticker_name} 
   *  - base_quantity: {amount} 
   *  - quote_quantity: {amount} 
   *  - exchange_amount: {amount}
   *
   * @param properties The event's properties (e.g. base_quantity)
   * @param options Amplitude event options.
   */
  cryptoOperationsSuccess(
    properties?: CryptoOperationsSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new CryptoOperationsSuccess(properties), options);
  }

  /**
   * crypto_operations_update
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_operations_update)
   *
   * se trackea cuando el usuario clickea actualizar en la previsualizacion de una operación 
   *
   *  - operation_type: {buy/sell/swap) 
   *  - base_ticker: {ticker_name} 
   *  - quote_ticker: {ticker_name} 
   *  - base_quantity: {amount} 
   *  - quote_quantity: {amount} 
   *  - exchange_amount: {amount}
   *
   * @param properties The event's properties (e.g. base_quantity)
   * @param options Amplitude event options.
   */
  cryptoOperationsUpdate(
    properties?: CryptoOperationsUpdateProperties,
    options?: EventOptions,
  ) {
    return this.track(new CryptoOperationsUpdate(properties), options);
  }

  /**
   * crypto_receive_bottomsheet
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_receive_bottomsheet)
   *
   * Se trackea cuando el usuario visualiza el bottomsheet para elegir entre recibir crypto y usd  
   *
   *  Event Property: 
   *  Referral
   *
   * @param properties The event's properties (e.g. referral)
   * @param options Amplitude event options.
   */
  cryptoReceiveBottomsheet(
    properties: CryptoReceiveBottomsheetProperties,
    options?: EventOptions,
  ) {
    return this.track(new CryptoReceiveBottomsheet(properties), options);
  }

  /**
   * crypto_screen_activity
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_screen_activity)
   *
   * Se trackea cuando el usuario visualiza la pantalla de actividad 
   *  Event Property: 
   *  previousScreen
   *
   * @param properties The event's properties (e.g. previous_screen)
   * @param options Amplitude event options.
   */
  cryptoScreenActivity(
    properties?: CryptoScreenActivityProperties,
    options?: EventOptions,
  ) {
    return this.track(new CryptoScreenActivity(properties), options);
  }

  /**
   * crypto_screen_agenda
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_screen_agenda)
   *
   * Se trackea cuando el usuario visualiza la pantalla de agenda desde profile
   *
   * Event property: previousScreen
   *
   * @param properties The event's properties (e.g. previous_screen)
   * @param options Amplitude event options.
   */
  cryptoScreenAgenda(
    properties?: CryptoScreenAgendaProperties,
    options?: EventOptions,
  ) {
    return this.track(new CryptoScreenAgenda(properties), options);
  }

  /**
   * crypto_screen_allocation
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_screen_allocation)
   *
   * Se trackea cuando el usuario visualiza la pantalla de distribución de su wallet
   *
   * @param options Amplitude event options.
   */
  cryptoScreenAllocation(
    options?: EventOptions,
  ) {
    return this.track(new CryptoScreenAllocation(), options);
  }

  /**
   * crypto_screen_education
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_screen_education)
   *
   * Se trackea cuando el usuario visualiza la pantalla de educación
   *
   * @param options Amplitude event options.
   */
  cryptoScreenEducation(
    options?: EventOptions,
  ) {
    return this.track(new CryptoScreenEducation(), options);
  }

  /**
   * crypto_screen_home
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_screen_home)
   *
   * Se trackea cuando el usuario visualiza la home de crypto 
   *
   *  Event Property: 
   *  previousScreen
   *
   * @param properties The event's properties (e.g. previous_screen)
   * @param options Amplitude event options.
   */
  cryptoScreenHome(
    properties?: CryptoScreenHomeProperties,
    options?: EventOptions,
  ) {
    return this.track(new CryptoScreenHome(properties), options);
  }

  /**
   * crypto_screen_legal
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_screen_legal)
   *
   * Se trackea cuando el usuario visualiza la pantalla de legales
   *
   * @param options Amplitude event options.
   */
  cryptoScreenLegal(
    options?: EventOptions,
  ) {
    return this.track(new CryptoScreenLegal(), options);
  }

  /**
   * crypto_screen_limits
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_screen_limits)
   *
   * Se trackea cuando el usuario visualiza la pantalla de limites de extracción. Las properties tienen que traer el total enviado/el limite. Ej: si alguien tiene limite semanal 1000 y ya envió 500, deberia guardar 500/1000
   *
   * Event Properties:  
   *  -daily_limit_tag 
   *  -daily_limit_blockchain 
   *  -weekly_limit_tag 
   *  -weekly_limit_blockchain
   *
   * @param properties The event's properties (e.g. daily_limit_blockchain)
   * @param options Amplitude event options.
   */
  cryptoScreenLimits(
    properties: CryptoScreenLimitsProperties,
    options?: EventOptions,
  ) {
    return this.track(new CryptoScreenLimits(properties), options);
  }

  /**
   * crypto_screen_market
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_screen_market)
   *
   * Se trackea cuando el usuario visualiza la pantalla de mercado 
   *  Event Property: 
   *  previousScreen
   *
   * @param properties The event's properties (e.g. previous_screen)
   * @param options Amplitude event options.
   */
  cryptoScreenMarket(
    properties?: CryptoScreenMarketProperties,
    options?: EventOptions,
  ) {
    return this.track(new CryptoScreenMarket(properties), options);
  }

  /**
   * crypto_screen_market_detail
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_screen_market_detail)
   *
   * Se trackea cuando el usuario visualiza la pantalla de detalle de mercado 
   *  Event Porperties: 
   *  previousScreen 
   *  ticker
   *
   * @param properties The event's properties (e.g. previous_screen)
   * @param options Amplitude event options.
   */
  cryptoScreenMarketDetail(
    properties?: CryptoScreenMarketDetailProperties,
    options?: EventOptions,
  ) {
    return this.track(new CryptoScreenMarketDetail(properties), options);
  }

  /**
   * crypto_screen_operations
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_screen_operations)
   *
   * Se trackea cuando el usuario visualiza la pantalla de compra/venta/swap
   *
   * Event properties: 
   *  - previousScreen {home/marketDetail} 
   *  - tappedButton: {buy/swap}
   *
   * @param properties The event's properties (e.g. previous_screen)
   * @param options Amplitude event options.
   */
  cryptoScreenOperations(
    properties: CryptoScreenOperationsProperties,
    options?: EventOptions,
  ) {
    return this.track(new CryptoScreenOperations(properties), options);
  }

  /**
   * crypto_screen_performance
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_screen_performance)
   *
   * Se trackea cuando el usuario visualiza la pantalla de rendimiento 
   *
   *  Event Porperty: 
   *  previousScreen
   *
   * @param properties The event's properties (e.g. previous_screen)
   * @param options Amplitude event options.
   */
  cryptoScreenPerformance(
    properties?: CryptoScreenPerformanceProperties,
    options?: EventOptions,
  ) {
    return this.track(new CryptoScreenPerformance(properties), options);
  }

  /**
   * crypto_screen_portfolio
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_screen_portfolio)
   *
   * Se trackea cuando el usuario visualiza la pantalla de portfolio 
   *  Event Property: 
   *  previousScreen
   *
   * @param properties The event's properties (e.g. previous_screen)
   * @param options Amplitude event options.
   */
  cryptoScreenPortfolio(
    properties?: CryptoScreenPortfolioProperties,
    options?: EventOptions,
  ) {
    return this.track(new CryptoScreenPortfolio(properties), options);
  }

  /**
   * crypto_screen_profile
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_screen_profile)
   *
   * Se trackea cuando el usuario visualiza la pantalla de perfil
   *
   *
   * Event Property: 
   *  previousScreen
   *
   * @param properties The event's properties (e.g. previous_screen)
   * @param options Amplitude event options.
   */
  cryptoScreenProfile(
    properties?: CryptoScreenProfileProperties,
    options?: EventOptions,
  ) {
    return this.track(new CryptoScreenProfile(properties), options);
  }

  /**
   * crypto_screen_security
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_screen_security)
   *
   * Se trackea cuando el usuario visualiza la pantalla de seguridad
   *
   * @param options Amplitude event options.
   */
  cryptoScreenSecurity(
    options?: EventOptions,
  ) {
    return this.track(new CryptoScreenSecurity(), options);
  }

  /**
   * crypto_screen_send_agenda
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_screen_send_agenda)
   *
   * Se trackea cuando el usuario visualiza la pantalla de agenda desde el flujo de enviar 
   *  Event property: previousScreen
   *
   * @param properties The event's properties (e.g. previous_screen)
   * @param options Amplitude event options.
   */
  cryptoScreenSendAgenda(
    properties?: CryptoScreenSendAgendaProperties,
    options?: EventOptions,
  ) {
    return this.track(new CryptoScreenSendAgenda(properties), options);
  }

  /**
   * crypto_screen_settings
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_screen_settings)
   *
   * Se trackea cuando el usuario visualiza la pantalla de configuración
   *
   * @param options Amplitude event options.
   */
  cryptoScreenSettings(
    options?: EventOptions,
  ) {
    return this.track(new CryptoScreenSettings(), options);
  }

  /**
   * crypto_screen_tyc
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_screen_tyc)
   *
   * Se trackea cuando el usuario visualiza la pantalla de TyC. 
   *
   *  Event Property:  
   *  previousScreen
   *
   * @param properties The event's properties (e.g. new_user)
   * @param options Amplitude event options.
   */
  cryptoScreenTyc(
    properties?: CryptoScreenTycProperties,
    options?: EventOptions,
  ) {
    return this.track(new CryptoScreenTyc(properties), options);
  }

  /**
   * crypto_send_add_adress
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_send_add_adress)
   *
   * se trackea cuando el usuario tapea el boton tapea "agendar una cuenta" en el flujo de envio
   *
   * @param options Amplitude event options.
   */
  cryptoSendAddAdress(
    options?: EventOptions,
  ) {
    return this.track(new CryptoSendAddAdress(), options);
  }

  /**
   * crypto_send_clicked_preview
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_send_clicked_preview)
   *
   * se trackea cuando el usuario clickea previsualizar envío.
   *
   * Event properties:
   *
   * \- ticker_name: {ticker_name} 
   *  - quantity: {amount} 
   *  - network: {crypto_network} 
   *  - send_to: {address_name} 
   *  -method: {method}
   *
   * @param properties The event's properties (e.g. method)
   * @param options Amplitude event options.
   */
  cryptoSendClickedPreview(
    properties: CryptoSendClickedPreviewProperties,
    options?: EventOptions,
  ) {
    return this.track(new CryptoSendClickedPreview(properties), options);
  }

  /**
   * crypto_send_confirm
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_send_confirm)
   *
   * se trackea cuando el usuario tapea "confirmar" en la pantalla de previsualizacion.
   *
   * Event properties: 
   *  - ticker_name: {ticker_name} 
   *  - quantity: {amount} 
   *  - network: {crypto_network} 
   *  - send_to: {address_name} 
   *  -method: {method}
   *
   * @param properties The event's properties (e.g. method)
   * @param options Amplitude event options.
   */
  cryptoSendConfirm(
    properties: CryptoSendConfirmProperties,
    options?: EventOptions,
  ) {
    return this.track(new CryptoSendConfirm(properties), options);
  }

  /**
   * crypto_send_exit
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_send_exit)
   *
   * Se trackea cuando el usuario hace exit de una pantalla del flujo de envío, sea con una cruz o un CTA de "Ir al inicio".
   *
   * Event properties:
   *
   * referral: select_asset, select_address, select_cocostag, add_address, preview
   *
   * @param properties The event's properties (e.g. referral)
   * @param options Amplitude event options.
   */
  cryptoSendExit(
    properties: CryptoSendExitProperties,
    options?: EventOptions,
  ) {
    return this.track(new CryptoSendExit(properties), options);
  }

  /**
   * crypto_send_fail
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_send_fail)
   *
   * se trackea cuando el usuario ve la pantalla de envio fallido.
   *
   * Event properties:
   *
   * \- ticker_name: {ticker_name} 
   *  - quantity: {amount} 
   *  - network: {crypto_network} 
   *  - send_to: {address_name} 
   *  -method: {method}
   *
   * @param properties The event's properties (e.g. method)
   * @param options Amplitude event options.
   */
  cryptoSendFail(
    properties: CryptoSendFailProperties,
    options?: EventOptions,
  ) {
    return this.track(new CryptoSendFail(properties), options);
  }

  /**
   * crypto_send_preview_back
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_send_preview_back)
   *
   * se trackea cuando el usuario clickea "Volver" en la previsualización del envío.
   *
   * @param options Amplitude event options.
   */
  cryptoSendPreviewBack(
    options?: EventOptions,
  ) {
    return this.track(new CryptoSendPreviewBack(), options);
  }

  /**
   * crypto_send_selected_cocostag
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_send_selected_cocostag)
   *
   * se trackea cuando el usuario selecciona un cocostag para enviar
   *
   * @param options Amplitude event options.
   */
  cryptoSendSelectedCocostag(
    options?: EventOptions,
  ) {
    return this.track(new CryptoSendSelectedCocostag(), options);
  }

  /**
   * crypto_send_selected_method
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_send_selected_method)
   *
   * se trackea cunado el usuario elige si enviar por blockchain o cocostag.
   *
   * Event properties:
   *
   * method: blockchain, cocostag
   *
   * @param properties The event's properties (e.g. method)
   * @param options Amplitude event options.
   */
  cryptoSendSelectedMethod(
    properties?: CryptoSendSelectedMethodProperties,
    options?: EventOptions,
  ) {
    return this.track(new CryptoSendSelectedMethod(properties), options);
  }

  /**
   * crypto_send_selected_ticker
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_send_selected_ticker)
   *
   * se trackea cuando el usuario elige un token en la primer pantalla de envio.
   *
   * Event properties:
   *
   * ticker_name: \[ticker_name\]
   *
   * @param properties The event's properties (e.g. ticker_name)
   * @param options Amplitude event options.
   */
  cryptoSendSelectedTicker(
    properties: CryptoSendSelectedTickerProperties,
    options?: EventOptions,
  ) {
    return this.track(new CryptoSendSelectedTicker(properties), options);
  }

  /**
   * crypto_send_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_send_success)
   *
   * se trackea cuando el usuario ve la pantalla de envio exitoso.
   *
   * Event properties:
   *
   * \- ticker_name: {ticker_name} 
   *  - quantity: {amount} 
   *  - network: {crypto_network} 
   *  - send_to: {address_name} 
   *  -method: {method}
   *
   * @param properties The event's properties (e.g. method)
   * @param options Amplitude event options.
   */
  cryptoSendSuccess(
    properties: CryptoSendSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new CryptoSendSuccess(properties), options);
  }

  /**
   * crypto_tyc_continue
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_tyc_continue)
   *
   * se trackea cuando un usuario tapea el boton de ingresar a cocos crypto.
   *
   * @param properties The event's properties (e.g. created_account)
   * @param options Amplitude event options.
   */
  cryptoTycContinue(
    properties?: CryptoTycContinueProperties,
    options?: EventOptions,
  ) {
    return this.track(new CryptoTycContinue(properties), options);
  }

  /**
   * crypto_tyc_exit
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_tyc_exit)
   *
   * se trackea cuando el usuario tapea la cruz para salir de los Tyc de crypto o pagos.
   *
   * Event properties: 
   *  referral: crypto, pay
   *
   * Owner: Baltasar Parra
   *
   * @param properties The event's properties (e.g. referral)
   * @param options Amplitude event options.
   */
  cryptoTycExit(
    properties: CryptoTycExitProperties,
    options?: EventOptions,
  ) {
    return this.track(new CryptoTycExit(properties), options);
  }

  /**
   * crypto_tyc_failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_tyc_failed)
   *
   * se trackea cuando el usuario acepta los terminos pero la creación de cuenta falla y se muestra la pantalla de error.
   *
   * @param options Amplitude event options.
   */
  cryptoTycFailed(
    options?: EventOptions,
  ) {
    return this.track(new CryptoTycFailed(), options);
  }

  /**
   * crypto_tyc_support
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/crypto_tyc_support)
   *
   * se trackea en la pantalla de error de TyC cuando el usuario tapea contactar a soporte
   *
   * @param options Amplitude event options.
   */
  cryptoTycSupport(
    options?: EventOptions,
  ) {
    return this.track(new CryptoTycSupport(), options);
  }

  /**
   * currency_balance_screen_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/currency_balance_screen_viewed)
   *
   * Se trackea cuando el usuario entra a ver la pantalla donde está el balance de una currency desglosado por plazos de liquidación.
   *
   * @param properties The event's properties (e.g. currency)
   * @param options Amplitude event options.
   */
  currencyBalanceScreenViewed(
    properties?: CurrencyBalanceScreenViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CurrencyBalanceScreenViewed(properties), options);
  }

  /**
   * donation_order_confirmed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/donation_order_confirmed)
   *
   * Se trackea cuando el usuario confirma en el bottomsheet el monto a donar.
   *
   * @param properties The event's properties (e.g. amount)
   * @param options Amplitude event options.
   */
  donationOrderConfirmed(
    properties?: DonationOrderConfirmedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DonationOrderConfirmed(properties), options);
  }

  /**
   * donation_order_error
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/donation_order_error)
   *
   * Se trackea cuando el usuario ve pantalla de error al hacer una donación.
   *
   * @param properties The event's properties (e.g. amount)
   * @param options Amplitude event options.
   */
  donationOrderError(
    properties?: DonationOrderErrorProperties,
    options?: EventOptions,
  ) {
    return this.track(new DonationOrderError(properties), options);
  }

  /**
   * donation_order_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/donation_order_success)
   *
   * Se trackea cuando el usuario ve pantalla de success al hacer una donación.
   *
   * @param properties The event's properties (e.g. amount)
   * @param options Amplitude event options.
   */
  donationOrderSuccess(
    properties?: DonationOrderSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new DonationOrderSuccess(properties), options);
  }

  /**
   * donation_screen_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/donation_screen_viewed)
   *
   * Se trackea cuando un usuario entra a la pantalla de donaciones desde el banner de la home.
   *
   * @param options Amplitude event options.
   */
  donationScreenViewed(
    options?: EventOptions,
  ) {
    return this.track(new DonationScreenViewed(), options);
  }

  /**
   * fci_order_confirmed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/fci_order_confirmed)
   *
   * Se trackea cuando el usuario confirma la orden de suscripción o rescate en el bottomsheet de confirmación.
   *
   * @param properties The event's properties (e.g. amount)
   * @param options Amplitude event options.
   */
  fciOrderConfirmed(
    properties?: FciOrderConfirmedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FciOrderConfirmed(properties), options);
  }

  /**
   * fci_order_error
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/fci_order_error)
   *
   * Se trackea cuando el usuario ve la pantalla de error de envío de su orden de suscripción o rescate.
   *
   * @param properties The event's properties (e.g. amount)
   * @param options Amplitude event options.
   */
  fciOrderError(
    properties?: FciOrderErrorProperties,
    options?: EventOptions,
  ) {
    return this.track(new FciOrderError(properties), options);
  }

  /**
   * fci_order_screen_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/fci_order_screen_viewed)
   *
   * Se trackea cuando el usuario ve la pantalla para ingresar un monto a suscribir o rescatar un fondo.
   *
   * @param properties The event's properties (e.g. real_time_returns)
   * @param options Amplitude event options.
   */
  fciOrderScreenViewed(
    properties?: FciOrderScreenViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FciOrderScreenViewed(properties), options);
  }

  /**
   * fci_order_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/fci_order_success)
   *
   * Se trackea cuando el usuario ve la pantalla de éxito de envío de su orden de suscripción o rescate.
   *
   * @param properties The event's properties (e.g. amount)
   * @param options Amplitude event options.
   */
  fciOrderSuccess(
    properties?: FciOrderSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new FciOrderSuccess(properties), options);
  }

  /**
   * fci_screen_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/fci_screen_viewed)
   *
   * Se trackea cuando el usuario ve la pantalla de rendimientos o de información de un fondo.
   *
   * @param properties The event's properties (e.g. tab)
   * @param options Amplitude event options.
   */
  fciScreenViewed(
    properties: FciScreenViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FciScreenViewed(properties), options);
  }

  /**
   * forgot_password_check_email
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/forgot_password_check_email)
   *
   * Se trackea cuando el usuario se envía el mail para resetear la contraseña.
   *
   * @param options Amplitude event options.
   */
  forgotPasswordCheckEmail(
    options?: EventOptions,
  ) {
    return this.track(new ForgotPasswordCheckEmail(), options);
  }

  /**
   * forgot_password_error
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/forgot_password_error)
   *
   * Se trackea cuando el usuario ve un error al intentar cambiar su contraseña
   *
   * @param properties The event's properties (e.g. message)
   * @param options Amplitude event options.
   */
  forgotPasswordError(
    properties: ForgotPasswordErrorProperties,
    options?: EventOptions,
  ) {
    return this.track(new ForgotPasswordError(properties), options);
  }

  /**
   * forgot_password_open_email
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/forgot_password_open_email)
   *
   * Se trackea cuando el usuario tapea el CTA para abrir su app de mail desde Cocos.
   *
   * @param options Amplitude event options.
   */
  forgotPasswordOpenEmail(
    options?: EventOptions,
  ) {
    return this.track(new ForgotPasswordOpenEmail(), options);
  }

  /**
   * forgot_password_resend_email
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/forgot_password_resend_email)
   *
   * Se trackea cuando el usuario tapea el CTA para reenviar el mail de password recovery.
   *
   * @param options Amplitude event options.
   */
  forgotPasswordResendEmail(
    options?: EventOptions,
  ) {
    return this.track(new ForgotPasswordResendEmail(), options);
  }

  /**
   * forgot_password_screen_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/forgot_password_screen_viewed)
   *
   * Se trackea cuando el usuario ve la pantalla para ingresar el mail para el cual quiere cambiar la contraseña.
   *
   * @param options Amplitude event options.
   */
  forgotPasswordScreenViewed(
    options?: EventOptions,
  ) {
    return this.track(new ForgotPasswordScreenViewed(), options);
  }

  /**
   * home_banner_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/home_banner_clicked)
   *
   * Cuando tapeo el banner del dashboard que me lleva a la primer pantalla de cable.
   *
   * @param properties The event's properties (e.g. type)
   * @param options Amplitude event options.
   */
  homeBannerClicked(
    properties: HomeBannerClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HomeBannerClicked(properties), options);
  }

  /**
   * home_click_pay
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/home_click_pay)
   *
   * se trackea cuando el usuario tapea el boton "pagar" en la home 
   *  referral:{capital/crypto}
   *
   * @param properties The event's properties (e.g. referral)
   * @param options Amplitude event options.
   */
  homeClickPay(
    properties: HomeClickPayProperties,
    options?: EventOptions,
  ) {
    return this.track(new HomeClickPay(properties), options);
  }

  /**
   * home_exited
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/home_exited)
   *
   * Se trackea cuando el usuario navega a una pantalla desde la Home.
   *
   * @param properties The event's properties (e.g. referral)
   * @param options Amplitude event options.
   */
  homeExited(
    properties: HomeExitedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HomeExited(properties), options);
  }

  /**
   * home_fci_disclaimer_opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/home_fci_disclaimer_opened)
   *
   * Se trackea cuando un usuario tapea el ícono de información en la sección de Fondos de la Home.
   *
   * @param options Amplitude event options.
   */
  homeFciDisclaimerOpened(
    options?: EventOptions,
  ) {
    return this.track(new HomeFciDisclaimerOpened(), options);
  }

  /**
   * home_screen_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/home_screen_viewed)
   *
   * Se trackea cuando un usuario ve la home.
   *
   * @param options Amplitude event options.
   */
  homeScreenViewed(
    options?: EventOptions,
  ) {
    return this.track(new HomeScreenViewed(), options);
  }

  /**
   * home_tap_card
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/home_tap_card)
   *
   * Se trackea cuando el usuario toca la sección de card desde la home (tanto de capital como de crypto). 
   *  El referral muestra si viene de capital o de crypto.
   *
   * @param properties The event's properties (e.g. referral)
   * @param options Amplitude event options.
   */
  homeTapCard(
    properties: HomeTapCardProperties,
    options?: EventOptions,
  ) {
    return this.track(new HomeTapCard(properties), options);
  }

  /**
   * instrument_type_chosen
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/instrument_type_chosen)
   *
   * Se dispara cuando el usuario elige un tipo de instrumento del listado del desplegable, al lado del search.
   *
   * @param properties The event's properties (e.g. type)
   * @param options Amplitude event options.
   */
  instrumentTypeChosen(
    properties: InstrumentTypeChosenProperties,
    options?: EventOptions,
  ) {
    return this.track(new InstrumentTypeChosen(properties), options);
  }

  /**
   * market_currency_changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/market_currency_changed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. currency)
   * @param options Amplitude event options.
   */
  marketCurrencyChanged(
    properties?: MarketCurrencyChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MarketCurrencyChanged(properties), options);
  }

  /**
   * market_order_reviewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/market_order_reviewed)
   *
   * Se dispara cuando el usuario ve la pantalla de confirmación de una orden
   *
   * @param options Amplitude event options.
   */
  marketOrderReviewed(
    options?: EventOptions,
  ) {
    return this.track(new MarketOrderReviewed(), options);
  }

  /**
   * market_order_sent
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/market_order_sent)
   *
   * Se dispara cuando se envía una orden al mercado, independientemente de si fue success o no.
   *
   * @param properties The event's properties (e.g. currency)
   * @param options Amplitude event options.
   */
  marketOrderSent(
    properties: MarketOrderSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new MarketOrderSent(properties), options);
  }

  /**
   * market_settlement_changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/market_settlement_changed)
   *
   * Se dispara cuando el usuario cambia el plazo de liquidación del listado del mercado
   *
   * @param properties The event's properties (e.g. settlement)
   * @param options Amplitude event options.
   */
  marketSettlementChanged(
    properties?: MarketSettlementChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MarketSettlementChanged(properties), options);
  }

  /**
   * mep_banner_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/mep_banner_clicked)
   *
   * Se trackea cuando un usuario tapea un banner en la pantalla de success de MEP.
   *
   * @param properties The event's properties (e.g. type)
   * @param options Amplitude event options.
   */
  mepBannerClicked(
    properties: MepBannerClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MepBannerClicked(properties), options);
  }

  /**
   * mep_bottomsheet_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/mep_bottomsheet_viewed)
   *
   * Evento que se trackea cuando un usuario ve el bottomsheet de elección de cotización de MEP.
   *
   * @param options Amplitude event options.
   */
  mepBottomsheetViewed(
    options?: EventOptions,
  ) {
    return this.track(new MepBottomsheetViewed(), options);
  }

  /**
   * mep_order_error
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/mep_order_error)
   *
   * Se trackea cuando la orden de MEP no se puede insertar y tiramos un error.
   *
   * @param properties The event's properties (e.g. arsAmount)
   * @param options Amplitude event options.
   */
  mepOrderError(
    properties: MepOrderErrorProperties,
    options?: EventOptions,
  ) {
    return this.track(new MepOrderError(properties), options);
  }

  /**
   * mep_order_reviewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/mep_order_reviewed)
   *
   * Se trackea cuando el usuario tapea el CTA de Confirmar en el bottomsheet de confirmación de la orden de MEP.
   *
   * @param properties The event's properties (e.g. arsAmount)
   * @param options Amplitude event options.
   */
  mepOrderReviewed(
    properties: MepOrderReviewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MepOrderReviewed(properties), options);
  }

  /**
   * mep_order_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/mep_order_success)
   *
   * Se trackea cuando la orden de MEP se manda con éxito.
   *
   * @param properties The event's properties (e.g. arsAmount)
   * @param options Amplitude event options.
   */
  mepOrderSuccess(
    properties: MepOrderSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new MepOrderSuccess(properties), options);
  }

  /**
   * mep_screen_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/mep_screen_viewed)
   *
   * Se trackea cuando el usuario ve la pantalla de compra de MEP.
   *
   * @param properties The event's properties (e.g. type)
   * @param options Amplitude event options.
   */
  mepScreenViewed(
    properties: MepScreenViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MepScreenViewed(properties), options);
  }

  /**
   * mep_type_chosen
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/mep_type_chosen)
   *
   * Se trackea cuando el usuario elige dólar mep estimado o asegurado del bottomsheet de dólar mep.
   *
   * @param properties The event's properties (e.g. type)
   * @param options Amplitude event options.
   */
  mepTypeChosen(
    properties: MepTypeChosenProperties,
    options?: EventOptions,
  ) {
    return this.track(new MepTypeChosen(properties), options);
  }

  /**
   * ob_start_camara
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/ob_start_camara)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. type)
   * @param options Amplitude event options.
   */
  obStartCamara(
    properties: ObStartCamaraProperties,
    options?: EventOptions,
  ) {
    return this.track(new ObStartCamara(properties), options);
  }

  /**
   * onboarding_bottomsheet_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/onboarding_bottomsheet_viewed)
   *
   * Se trackea cuando un usuario que no tiene comitente intenta navegar a una feature que requiere tener OB aprobado, y por lo tanto se levanta un bottomsheet que solicita hacer el OB.
   *
   * @param properties The event's properties (e.g. referral)
   * @param options Amplitude event options.
   */
  onboardingBottomsheetViewed(
    properties: OnboardingBottomsheetViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingBottomsheetViewed(properties), options);
  }

  /**
   * order_book_opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/order_book_opened)
   *
   * Se dispara cuando el usuario despliega la caja de puntas en la pantalla de un activo
   *
   * @param options Amplitude event options.
   */
  orderBookOpened(
    options?: EventOptions,
  ) {
    return this.track(new OrderBookOpened(), options);
  }

  /**
   * orders_screen_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/orders_screen_viewed)
   *
   * Se trackea cuando un usuario accede a la pantalla de órdenes del día desde la Home o desde la pantalla de success de una operación. En la property referral mandamos "home" si accedió desde la home o "success_screen" si accedió desde una operación.
   *
   * @param properties The event's properties (e.g. referral)
   * @param options Amplitude event options.
   */
  ordersScreenViewed(
    properties: OrdersScreenViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OrdersScreenViewed(properties), options);
  }

  /**
   * pay_amount_continue
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/pay_amount_continue)
   *
   * se trackea cuando el usuario ingresa un monto y tapea continuar 
   *
   *  amount
   *
   * @param properties The event's properties (e.g. amount)
   * @param options Amplitude event options.
   */
  payAmountContinue(
    properties?: PayAmountContinueProperties,
    options?: EventOptions,
  ) {
    return this.track(new PayAmountContinue(properties), options);
  }

  /**
   * pay_click_confirm
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/pay_click_confirm)
   *
   * se trackea cuando el usuario confirma el pago
   *
   * \-amount -method -paid_to
   *
   * @param properties The event's properties (e.g. amount)
   * @param options Amplitude event options.
   */
  payClickConfirm(
    properties?: PayClickConfirmProperties,
    options?: EventOptions,
  ) {
    return this.track(new PayClickConfirm(properties), options);
  }

  /**
   * pay_deposit
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/pay_deposit)
   *
   * se trackea cuando el usuario tapea "ingresar dinero" en la pantalla de elegir metodo cuando no tiene saldo suficiente con ningun metodo
   *
   * @param options Amplitude event options.
   */
  payDeposit(
    options?: EventOptions,
  ) {
    return this.track(new PayDeposit(), options);
  }

  /**
   * pay_download_receipt
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/pay_download_receipt)
   *
   * se trackea cuando el usuario tapea el boton "descargar comprobante".
   *
   * Event properties:
   *
   * \-paid_to 
   *  -amount 
   *  -date 
   *  -time 
   *  -method 
   *  -receipt_number 
   *  -referral: {success/receipt/movements}
   *
   * @param properties The event's properties (e.g. amount)
   * @param options Amplitude event options.
   */
  payDownloadReceipt(
    properties: PayDownloadReceiptProperties,
    options?: EventOptions,
  ) {
    return this.track(new PayDownloadReceipt(properties), options);
  }

  /**
   * pay_exit
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/pay_exit)
   *
   * Se trackea cuando el usuario hace exit de una pantalla del flujo de pago, sea con una cruz o un CTA de "Ir al inicio". 
   *
   *  referral: scan_qr, method, amount, success, failed
   *
   * @param properties The event's properties (e.g. referral)
   * @param options Amplitude event options.
   */
  payExit(
    properties: PayExitProperties,
    options?: EventOptions,
  ) {
    return this.track(new PayExit(properties), options);
  }

  /**
   * pay_failed_retry
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/pay_failed_retry)
   *
   * se trackea cuando luego de que falle el pago el usuario tapea el boton reintentar 
   *
   *  -paid_to 
   *  - amount 
   *  - receipt_number
   *
   * @param properties The event's properties (e.g. amount)
   * @param options Amplitude event options.
   */
  payFailedRetry(
    properties: PayFailedRetryProperties,
    options?: EventOptions,
  ) {
    return this.track(new PayFailedRetry(properties), options);
  }

  /**
   * pay_scan_continue
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/pay_scan_continue)
   *
   * se trackea cuando el usuario tapea continuar luego del aviso de pago ya aprobado. Esto es cuando sale el bottom sheet con el dibujo amarillo avisando que ese qr ya esta pago
   *
   * @param options Amplitude event options.
   */
  payScanContinue(
    options?: EventOptions,
  ) {
    return this.track(new PayScanContinue(), options);
  }

  /**
   * pay_scan_failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/pay_scan_failed)
   *
   * se trackea cuando falla el escaneo de QR
   *
   *
   * failed_reason:{scan_failed/invalid_qr/already_paid}
   *
   * @param properties The event's properties (e.g. failed_reason)
   * @param options Amplitude event options.
   */
  payScanFailed(
    properties: PayScanFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PayScanFailed(properties), options);
  }

  /**
   * pay_scan_qr
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/pay_scan_qr)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. country_code)
   * @param options Amplitude event options.
   */
  payScanQr(
    properties: PayScanQrProperties,
    options?: EventOptions,
  ) {
    return this.track(new PayScanQr(properties), options);
  }

  /**
   * pay_scan_retry
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/pay_scan_retry)
   *
   * se trackea cuando el usuario tapea el boton de reintentar luego de un escaneo fallido 
   *  referral: scan_failed, invalid_qr
   *
   * @param properties The event's properties (e.g. referral)
   * @param options Amplitude event options.
   */
  payScanRetry(
    properties: PayScanRetryProperties,
    options?: EventOptions,
  ) {
    return this.track(new PayScanRetry(properties), options);
  }

  /**
   * pay_screen_access_camara
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/pay_screen_access_camara)
   *
   * se trackea cuando el usuario ve la pantalla que pide acceso a la camara
   *
   * @param properties The event's properties (e.g. camara_status)
   * @param options Amplitude event options.
   */
  payScreenAccessCamara(
    properties: PayScreenAccessCamaraProperties,
    options?: EventOptions,
  ) {
    return this.track(new PayScreenAccessCamara(properties), options);
  }

  /**
   * pay_screen_amount
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/pay_screen_amount)
   *
   * se trackea cuando el usuario ve la pantalla de elegir monto a pagar
   *
   * @param options Amplitude event options.
   */
  payScreenAmount(
    options?: EventOptions,
  ) {
    return this.track(new PayScreenAmount(), options);
  }

  /**
   * pay_screen_failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/pay_screen_failed)
   *
   * trackea cuando el usuario ve la pantalla de pago fallido Event Properties: -paid_to -amount -date -time -method -receipt_number
   *
   * @param properties The event's properties (e.g. amount)
   * @param options Amplitude event options.
   */
  payScreenFailed(
    properties: PayScreenFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PayScreenFailed(properties), options);
  }

  /**
   * pay_screen_method
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/pay_screen_method)
   *
   * se trackea cuando el usuario ve la pantalla de elegir metodo de pago 
   *
   *  Event properites: 
   *  -balance_ok: true/false 
   *  -amount_ars: {amount_ars} 
   *  -amount_usd:{amount_usd} 
   *  -balance_ars: {balance_ars} 
   *  -balance_usd: {balance_usd} 
   *  -exchange_rate:{tipo de cambio}
   *
   * @param properties The event's properties (e.g. amount_ars)
   * @param options Amplitude event options.
   */
  payScreenMethod(
    properties: PayScreenMethodProperties,
    options?: EventOptions,
  ) {
    return this.track(new PayScreenMethod(properties), options);
  }

  /**
   * pay_screen_scan_qr
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/pay_screen_scan_qr)
   *
   * se trackea cuando el usuario ve la pantalla de scanear QR.
   *
   * Event properties: 
   *  previous_screen: capital/screen
   *
   * @param properties The event's properties (e.g. previous_screen)
   * @param options Amplitude event options.
   */
  payScreenScanQr(
    properties?: PayScreenScanQrProperties,
    options?: EventOptions,
  ) {
    return this.track(new PayScreenScanQr(properties), options);
  }

  /**
   * pay_screen_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/pay_screen_success)
   *
   * se trackea cuando el usuario ve la pantalla de pago exitoso
   *
   * Event Properties: 
   *  -paid_to 
   *  -amount 
   *  -date 
   *  -time 
   *  -method 
   *  -receipt_number
   *
   * @param properties The event's properties (e.g. amount)
   * @param options Amplitude event options.
   */
  payScreenSuccess(
    properties: PayScreenSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new PayScreenSuccess(properties), options);
  }

  /**
   * pay_select_method
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/pay_select_method)
   *
   * se trackea cuando el usuario elige un metodo de pago 
   *  method: {payment_method}
   *
   * @param properties The event's properties (e.g. method)
   * @param options Amplitude event options.
   */
  paySelectMethod(
    properties?: PaySelectMethodProperties,
    options?: EventOptions,
  ) {
    return this.track(new PaySelectMethod(properties), options);
  }

  /**
   * phone_number_screen_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/phone_number_screen_viewed)
   *
   * Show de la pantalla para elegir un prefijo e ingresar un número de teléfono.
   *
   * @param properties The event's properties (e.g. referral)
   * @param options Amplitude event options.
   */
  phoneNumberScreenViewed(
    properties: PhoneNumberScreenViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PhoneNumberScreenViewed(properties), options);
  }

  /**
   * phone_verification_code_screen_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/phone_verification_code_screen_viewed)
   *
   * Show de la pantalla para verificar el código SMS.
   *
   * @param properties The event's properties (e.g. referral)
   * @param options Amplitude event options.
   */
  phoneVerificationCodeScreenViewed(
    properties: PhoneVerificationCodeScreenViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PhoneVerificationCodeScreenViewed(properties), options);
  }

  /**
   * phone_verification_code_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/phone_verification_code_success)
   *
   * Evento que se dispara cuando un usuario verifica con éxito el código enviado por SMS.
   *
   * @param properties The event's properties (e.g. account)
   * @param options Amplitude event options.
   */
  phoneVerificationCodeSuccess(
    properties: PhoneVerificationCodeSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new PhoneVerificationCodeSuccess(properties), options);
  }

  /**
   * physical_card_not_started
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/physical_card_not_started)
   *
   * Se trackea cuando el usuario ve la pantalla de tarjeta fisica y aun no tiene usuario en pomelo (no pidió ninguna tarjeta)
   *
   * Owner: Baltasar Parra
   *
   * @param options Amplitude event options.
   */
  physicalCardNotStarted(
    options?: EventOptions,
  ) {
    return this.track(new PhysicalCardNotStarted(), options);
  }

  /**
   * portfolio_chart_range_switched
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/portfolio_chart_range_switched)
   *
   * Se trackea cuando el usuario cambia de rango temporal el gráfico del balance del portfolio.
   *
   * @param properties The event's properties (e.g. range)
   * @param options Amplitude event options.
   */
  portfolioChartRangeSwitched(
    properties: PortfolioChartRangeSwitchedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PortfolioChartRangeSwitched(properties), options);
  }

  /**
   * portfolio_currency_switched
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/portfolio_currency_switched)
   *
   * Se trackea cuando el usuario switchea la currency del portfolio. En la property trackeamos la currency a la que cambió.
   *
   * @param properties The event's properties (e.g. currency)
   * @param options Amplitude event options.
   */
  portfolioCurrencySwitched(
    properties: PortfolioCurrencySwitchedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PortfolioCurrencySwitched(properties), options);
  }

  /**
   * portfolio_exited
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/portfolio_exited)
   *
   * Se trackea cuando el usuario abandona la pantalla del portfolio para navegar a un segundo layer.
   *
   * @param properties The event's properties (e.g. referral)
   * @param options Amplitude event options.
   */
  portfolioExited(
    properties: PortfolioExitedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PortfolioExited(properties), options);
  }

  /**
   * portfolio_returns_screen_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/portfolio_returns_screen_viewed)
   *
   * Se dispara cuando el usuario navega a la tab de rendimientos del porfolio
   *
   * @param options Amplitude event options.
   */
  portfolioReturnsScreenViewed(
    options?: EventOptions,
  ) {
    return this.track(new PortfolioReturnsScreenViewed(), options);
  }

  /**
   * portfolio_screen_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/portfolio_screen_viewed)
   *
   * Se trackea cuando el usuario entra al portfolio.
   *
   * @param properties The event's properties (e.g. tab)
   * @param options Amplitude event options.
   */
  portfolioScreenViewed(
    properties: PortfolioScreenViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PortfolioScreenViewed(properties), options);
  }

  /**
   * predefined_amount_selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/predefined_amount_selected)
   *
   * Se dispara cuando el usuario tapea alguna de las pills con un porcentaje predefinido “25%”, “50%”, etc.
   *
   * @param options Amplitude event options.
   */
  predefinedAmountSelected(
    options?: EventOptions,
  ) {
    return this.track(new PredefinedAmountSelected(), options);
  }

  /**
   * receive_alias_copied
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/receive_alias_copied)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. currency)
   * @param options Amplitude event options.
   */
  receiveAliasCopied(
    properties?: ReceiveAliasCopiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReceiveAliasCopied(properties), options);
  }

  /**
   * receive_ccl_bottomsheet_continued
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/receive_ccl_bottomsheet_continued)
   *
   * Se trackea cuando el usuario tapea el Continuar en el bottomsheet inicial del flujo de dolar cable
   *
   * @param options Amplitude event options.
   */
  receiveCclBottomsheetContinued(
    options?: EventOptions,
  ) {
    return this.track(new ReceiveCclBottomsheetContinued(), options);
  }

  /**
   * receive_ccl_information_copied
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/receive_ccl_information_copied)
   *
   * Se trackea cuando el usuario copia algún dato de la pantalla con los datos de JPM de Cocos.
   *
   * @param properties The event's properties (e.g. item)
   * @param options Amplitude event options.
   */
  receiveCclInformationCopied(
    properties: ReceiveCclInformationCopiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReceiveCclInformationCopied(properties), options);
  }

  /**
   * receive_currency_selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/receive_currency_selected)
   *
   * Se trackea cada vez que el usuario cambia de tab de currency en la pantalla de receive.
   *
   * @param properties The event's properties (e.g. currency)
   * @param options Amplitude event options.
   */
  receiveCurrencySelected(
    properties?: ReceiveCurrencySelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReceiveCurrencySelected(properties), options);
  }

  /**
   * receive_exit
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/receive_exit)
   *
   * Se trackea cuando el usuario tapea el Ir al inicio o el Back en cualquier pantalla de receive.
   *
   * Owner: Baltasar Parra
   *
   * @param properties The event's properties (e.g. referral)
   * @param options Amplitude event options.
   */
  receiveExit(
    properties: ReceiveExitProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReceiveExit(properties), options);
  }

  /**
   * receive_screen_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/receive_screen_viewed)
   *
   * Se trackea cuando el usuario entra a la pantalla de ingresar dinero.
   *
   * @param properties The event's properties (e.g. referral)
   * @param options Amplitude event options.
   */
  receiveScreenViewed(
    properties: ReceiveScreenViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReceiveScreenViewed(properties), options);
  }

  /**
   * receive_selected_asset_type
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/receive_selected_asset_type)
   *
   * Se trackea cuando el usuario elige si quiere recibir crypto o dolares.
   *
   *
   * Event properties: 
   *  type: usd, crypto
   *
   * @param properties The event's properties (e.g. type)
   * @param options Amplitude event options.
   */
  receiveSelectedAssetType(
    properties: ReceiveSelectedAssetTypeProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReceiveSelectedAssetType(properties), options);
  }

  /**
   * request_contact_cocos_gold
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/request_contact_cocos_gold)
   *
   * Cuando se tapea el botón de quiero que me contacte un asesor
   *
   * Owner: Baltasar Parra
   *
   * @param options Amplitude event options.
   */
  requestContactCocosGold(
    options?: EventOptions,
  ) {
    return this.track(new RequestContactCocosGold(), options);
  }

  /**
   * screen_cocos_gold
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/screen_cocos_gold)
   *
   * show de la pantalla de cocos gold. La property es para ver desde dónde llegó. En mobile solo esta en profile, pero en desktop esta en la home también.
   *
   * Owner: Baltasar Parra
   *
   * @param properties The event's properties (e.g. previous_screen)
   * @param options Amplitude event options.
   */
  screenCocosGold(
    properties?: ScreenCocosGoldProperties,
    options?: EventOptions,
  ) {
    return this.track(new ScreenCocosGold(properties), options);
  }

  /**
   * send_amount_confirmed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/send_amount_confirmed)
   *
   * Se trackea cuando el usuario confirma el monto que quiere retirar y pasa a la pantalla para seleccionar cuentas bancarias.
   *
   * @param properties The event's properties (e.g. amount)
   * @param options Amplitude event options.
   */
  sendAmountConfirmed(
    properties?: SendAmountConfirmedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SendAmountConfirmed(properties), options);
  }

  /**
   * send_amount_exceeded_error
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/send_amount_exceeded_error)
   *
   * Se trackea cuando el usuario ve un error en la pantalla de withdraw porque intenta retirar un monto mayor al que tiene en su balance.
   *
   * @param options Amplitude event options.
   */
  sendAmountExceededError(
    options?: EventOptions,
  ) {
    return this.track(new SendAmountExceededError(), options);
  }

  /**
   * send_bank_account_selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/send_bank_account_selected)
   *
   * Se trackea cuando el usuario elige la cuenta bancaria a donde quiere retirar.
   *
   * @param options Amplitude event options.
   */
  sendBankAccountSelected(
    options?: EventOptions,
  ) {
    return this.track(new SendBankAccountSelected(), options);
  }

  /**
   * send_order_canceled
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/send_order_canceled)
   *
   * Se trackea cuando el usuario hace back en el bottomsheet de confirmación.
   *
   * @param properties The event's properties (e.g. amount)
   * @param options Amplitude event options.
   */
  sendOrderCanceled(
    properties?: SendOrderCanceledProperties,
    options?: EventOptions,
  ) {
    return this.track(new SendOrderCanceled(properties), options);
  }

  /**
   * send_order_confirmed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/send_order_confirmed)
   *
   * Se trackea cuando el usuario confirma en el bottomsheet de confirmación.
   *
   * @param properties The event's properties (e.g. amount)
   * @param options Amplitude event options.
   */
  sendOrderConfirmed(
    properties?: SendOrderConfirmedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SendOrderConfirmed(properties), options);
  }

  /**
   * send_order_error
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/send_order_error)
   *
   * Se trackea cuando la orden de extracción falla y el usuario ve pantalla de error.
   *
   * @param properties The event's properties (e.g. amount)
   * @param options Amplitude event options.
   */
  sendOrderError(
    properties?: SendOrderErrorProperties,
    options?: EventOptions,
  ) {
    return this.track(new SendOrderError(properties), options);
  }

  /**
   * send_order_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/send_order_success)
   *
   * Se trackea cuando la orden de extracción se hace con éxito y el usuario ve pantalla de success.
   *
   * @param properties The event's properties (e.g. amount)
   * @param options Amplitude event options.
   */
  sendOrderSuccess(
    properties?: SendOrderSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new SendOrderSuccess(properties), options);
  }

  /**
   * send_screen_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/send_screen_viewed)
   *
   * Se trackea cuando el usuario ve la pantalla para ingresar un monto a extraer. 
   *  Properties: referral (se manda "bank_accounts" si el usuario accedió a la pantalla desde la sección de cuentas bancarias del perfil. Se manda "portfolio" si el usuario accedió a la pantalla desde el portfolio).
   *
   * @param properties The event's properties (e.g. referral)
   * @param options Amplitude event options.
   */
  sendScreenViewed(
    properties: SendScreenViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SendScreenViewed(properties), options);
  }

  /**
   * signup_biometrics_activated
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/signup_biometrics_activated)
   *
   * Se trackea cuando el usuario Acepta en la pantalla de datos biométricos.
   *
   * @param options Amplitude event options.
   */
  signupBiometricsActivated(
    options?: EventOptions,
  ) {
    return this.track(new SignupBiometricsActivated(), options);
  }

  /**
   * signup_biometrics_screen_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/signup_biometrics_screen_viewed)
   *
   * Show de la pantalla para activar datos biométricos.
   *
   * @param options Amplitude event options.
   */
  signupBiometricsScreenViewed(
    options?: EventOptions,
  ) {
    return this.track(new SignupBiometricsScreenViewed(), options);
  }

  /**
   * signup_email_screen_back
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/signup_email_screen_back)
   *
   * Se trackea cuando el usuario hace back en la primer pantalla del flujo de sign up.
   *
   * @param options Amplitude event options.
   */
  signupEmailScreenBack(
    options?: EventOptions,
  ) {
    return this.track(new SignupEmailScreenBack(), options);
  }

  /**
   * signup_email_screen_error
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/signup_email_screen_error)
   *
   * Se trackea cuando el usuario elige mail y contraseña en el sign up y no puede avanzar porque aparece un error.
   *
   * @param properties The event's properties (e.g. message)
   * @param options Amplitude event options.
   */
  signupEmailScreenError(
    properties: SignupEmailScreenErrorProperties,
    options?: EventOptions,
  ) {
    return this.track(new SignupEmailScreenError(properties), options);
  }

  /**
   * signup_email_validation_error
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/signup_email_validation_error)
   *
   * Se trackea cuando un usuario abre el link del mail para validar su correo y aparece un error.
   *
   * @param properties The event's properties (e.g. message)
   * @param options Amplitude event options.
   */
  signupEmailValidationError(
    properties: SignupEmailValidationErrorProperties,
    options?: EventOptions,
  ) {
    return this.track(new SignupEmailValidationError(properties), options);
  }

  /**
   * signup_email_verification_resend_email
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/signup_email_verification_resend_email)
   *
   * Se trackea si el usuario tapea el CTA que le permite reenviarse el mail para validar su correo. Solo se trackea si el CTA está habilitado.
   *
   * @param options Amplitude event options.
   */
  signupEmailVerificationResendEmail(
    options?: EventOptions,
  ) {
    return this.track(new SignupEmailVerificationResendEmail(), options);
  }

  /**
   * signup_email_verification_screen_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/signup_email_verification_screen_viewed)
   *
   * Show de la pantalla de verificar mail.
   *
   * @param options Amplitude event options.
   */
  signupEmailVerificationScreenViewed(
    options?: EventOptions,
  ) {
    return this.track(new SignupEmailVerificationScreenViewed(), options);
  }

  /**
   * signup_email_verification_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/signup_email_verification_success)
   *
   * Evento que se dispara cuando se verifica el mail con éxito durante el sign up.
   *
   * @param options Amplitude event options.
   */
  signupEmailVerificationSuccess(
    options?: EventOptions,
  ) {
    return this.track(new SignupEmailVerificationSuccess(), options);
  }

  /**
   * signup_pj_banner_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/signup_pj_banner_clicked)
   *
   * Se trackea cuando un usuario navega a la web de onboarding de personas jurídicas desde el sign up de Cocos.
   *
   * @param options Amplitude event options.
   */
  signupPjBannerClicked(
    options?: EventOptions,
  ) {
    return this.track(new SignupPjBannerClicked(), options);
  }

  /**
   * signup_started
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/signup_started)
   *
   * Show de la pantalla para ingresar un mail y contraseña. Se trackea cuando el usuario tapea el botón "No tengo cuenta" en el landing de la app.
   *
   * @param options Amplitude event options.
   */
  signupStarted(
    options?: EventOptions,
  ) {
    return this.track(new SignupStarted(), options);
  }

  /**
   * signup_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/signup_success)
   *
   * Se trackea cuando el usuario termina el flujo completo de sign up y entra a la home.
   *
   * @param options Amplitude event options.
   */
  signupSuccess(
    options?: EventOptions,
  ) {
    return this.track(new SignupSuccess(), options);
  }

  /**
   * virtual_card_not_started
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/virtual_card_not_started)
   *
   * Se trackea cuando el usuario ve la pantalla de tarjeta virtual y aun no tiene usuario en pomelo (no pidió ninguna tarjeta)
   *
   * Owner: Baltasar Parra
   *
   * @param options Amplitude event options.
   */
  virtualCardNotStarted(
    options?: EventOptions,
  ) {
    return this.track(new VirtualCardNotStarted(), options);
  }

  /**
   * withdraw_amount_confirmed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/withdraw_amount_confirmed)
   *
   * Se trackea cuando el usuario confirma el monto que quiere retirar y pasa a la pantalla para seleccionar cuentas bancarias.
   *
   * @param properties The event's properties (e.g. amount)
   * @param options Amplitude event options.
   */
  withdrawAmountConfirmed(
    properties?: WithdrawAmountConfirmedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WithdrawAmountConfirmed(properties), options);
  }

  /**
   * withdraw_amount_exceeded_error
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/withdraw_amount_exceeded_error)
   *
   * se trackea cuando el usuario ve un error en la pantalla de withdraw porque intenta retirar un monto mayor al que tiene en su balance.
   *
   * @param options Amplitude event options.
   */
  withdrawAmountExceededError(
    options?: EventOptions,
  ) {
    return this.track(new WithdrawAmountExceededError(), options);
  }

  /**
   * withdraw_bank_account_selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/withdraw_bank_account_selected)
   *
   * Se trackea cuando el usuario elige la cuenta bancaria a donde quiere retirar.
   *
   * @param options Amplitude event options.
   */
  withdrawBankAccountSelected(
    options?: EventOptions,
  ) {
    return this.track(new WithdrawBankAccountSelected(), options);
  }

  /**
   * withdraw_order_canceled
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/withdraw_order_canceled)
   *
   * se trackea cuando el usuario hace back en el bottosheet de confirmación.
   *
   * @param properties The event's properties (e.g. amount)
   * @param options Amplitude event options.
   */
  withdrawOrderCanceled(
    properties?: WithdrawOrderCanceledProperties,
    options?: EventOptions,
  ) {
    return this.track(new WithdrawOrderCanceled(properties), options);
  }

  /**
   * withdraw_order_confirmed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/withdraw_order_confirmed)
   *
   * se trackea cuando el usuario confirma en el bottomsheet de confirmación.
   *
   * @param properties The event's properties (e.g. amount)
   * @param options Amplitude event options.
   */
  withdrawOrderConfirmed(
    properties?: WithdrawOrderConfirmedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WithdrawOrderConfirmed(properties), options);
  }

  /**
   * withdraw_order_error
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/withdraw_order_error)
   *
   * se trackea cuadno la orden de extracción falla y el usuario ve pantalla de error.
   *
   * @param properties The event's properties (e.g. amount)
   * @param options Amplitude event options.
   */
  withdrawOrderError(
    properties?: WithdrawOrderErrorProperties,
    options?: EventOptions,
  ) {
    return this.track(new WithdrawOrderError(properties), options);
  }

  /**
   * withdraw_order_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/withdraw_order_success)
   *
   * se trackea cuando la orden de extracción se hace con éxito y el usuario ve pantalla de success.
   *
   * @param properties The event's properties (e.g. [Amplitude] Session Replay ID)
   * @param options Amplitude event options.
   */
  withdrawOrderSuccess(
    properties?: WithdrawOrderSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new WithdrawOrderSuccess(properties), options);
  }

  /**
   * withdraw_screen_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/cocos/Cocos%20Capital/events/main/latest/withdraw_screen_viewed)
   *
   * se trackea cuando el usuario ve la pantalla para ingresar un monto a extraer.
   *
   * @param properties The event's properties (e.g. referral)
   * @param options Amplitude event options.
   */
  withdrawScreenViewed(
    properties: WithdrawScreenViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WithdrawScreenViewed(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
