import { Currency } from "components/common/currency";
import { DecimalRound } from "components/common/decimal-round-rules";
import dayjs from "dayjs";
import { useAppSelector } from "hooks/redux";
import {
  IntervalParameter,
  MovementStatus,
  OrderStatusTypes,
  TickersRulesResponse,
} from "interfaces/api-responses";
import { SettlementTerms } from "interfaces/markets";
import { OrderStatusDescription, OrderStatusLabel } from "interfaces/orders";
import { PaymentCurrencies } from "interfaces/pay/enums";
import { Currencies } from "interfaces/wallet";
import floor from "lodash/floor";
import { getUserDuty } from "store/selectors/user.selector";

export const removeNullOfString = (word: string) => {
  if (!word) return "";

  return word
    .toLowerCase()
    .replace(/\bnull\b/g, "")
    .trim();
};

/**
 * @description returns user full name with middle name included.
 */
export const getUserFullName = (
  first_name?: string | null,
  middle_name?: string | null,
  last_name?: string | null
) => {
  let full_name = removeNullOfString(first_name || "");
  if (middle_name) {
    full_name += ` ${removeNullOfString(middle_name)}`;
  }
  if (last_name) {
    full_name += ` ${removeNullOfString(last_name)}`;
  }
  return capitalizeEachWord(full_name).replace(/  +/g, " ");
};

/**
 * @description returns order status label based on order status types.
 */
export const getOrderStatus = (status: OrderStatusTypes) => {
  const statusMap: {
    [key in OrderStatusTypes]: { label: string; description: string };
  } = {
    [OrderStatusTypes.PENDING]: {
      label: OrderStatusLabel.PENDING,
      description: OrderStatusDescription.PENDING,
    },
    [OrderStatusTypes.CANCELLED]: {
      label: OrderStatusLabel.CANCELLED,
      description: OrderStatusDescription.CANCELLED,
    },
    [OrderStatusTypes.PARTIALLY_CANCELLED]: {
      label: OrderStatusLabel.CANCELLED,
      description: OrderStatusDescription.CANCELLED,
    },
    [OrderStatusTypes.EXECUTED]: {
      label: OrderStatusLabel.EXECUTED,
      description: OrderStatusDescription.EXECUTED,
    },
    [OrderStatusTypes.PARTIALLY_EXECUTED]: {
      label: OrderStatusLabel.PARTIALLY_EXECUTED,
      description: OrderStatusDescription.PARTIALLY_EXECUTED,
    },
    [OrderStatusTypes.MARKET]: {
      label: OrderStatusLabel.MARKET,
      description: OrderStatusDescription.MARKET,
    },
    [OrderStatusTypes.REJECTED]: {
      label: OrderStatusLabel.REJECTED,
      description: OrderStatusDescription.REJECTED,
    },
  };
  if (!statusMap[status]) return { label: "", description: "" };
  return statusMap[status];
};

export const refreshPage = () => {
  window.location.reload();
};

/**
 * @description returns text with all words capitalized. It works in uppercase texts too.
 */
export const capitalizeEachWord = (text: string) => {
  return text
    .toLowerCase()
    .replace("_", " ")
    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
};

/**
 * @description returns initials from instrument type for markets logos.
 */
export const getInstrumentTypePlaceholder = (text?: string | null) => {
  if (!text) {
    return "VA";
  }
  if (text === "FCI") {
    return text;
  }
  if (text === "WITHDRAWAL") {
    return "EX";
  }

  return text.substring(0, 2);
};

export const getMaxAvailable = (
  instrument_type: string | undefined,
  instrument_subtype: string | undefined | null,
  operation_type: string,
  available: number | null | undefined,
  isExcluded: boolean = false
) => {
  const aranceles = useAppSelector(getUserDuty);
  if (
    aranceles.length > 0 &&
    aranceles[0] !== null &&
    !isExcluded &&
    instrument_type
  ) {
    const arancel = aranceles.find(
      (e: any) =>
        e.instrument_type === instrument_type &&
        (e.instrument_subtype === instrument_subtype ||
          e.instrument_subtype === null) &&
        (e.operation_type === operation_type || e.operation_type === null)
    );
    if (arancel !== undefined && available) {
      let comision;
      let finalAvailable;
      if (arancel.monto_minimo > 0) {
        const comiProvisoria = available * arancel.comision_porcentual;
        comision =
          comiProvisoria < arancel.monto_minimo
            ? arancel.monto_minimo
            : comiProvisoria;
        finalAvailable = available - comision;
      } else {
        finalAvailable = available / (1 + arancel.comision_porcentual);
      }
      return finalAvailable;
    } else {
      return available ?? 0;
    }
  } else {
    return available ?? 0;
  }
};

export const getComisiones = (
  instrument_type: string | undefined,
  instrument_subtype: string | undefined | null,
  operation_type: string,
  price: number | null | undefined,
  isExcluded: boolean = false
) => {
  const aranceles = useAppSelector(getUserDuty);
  if (
    aranceles.length > 0 &&
    aranceles[0] !== null &&
    !isExcluded &&
    instrument_type
  ) {
    const arancel = aranceles.find(
      (e: any) =>
        e.instrument_type === instrument_type &&
        (e.instrument_subtype === instrument_subtype ||
          e.instrument_subtype === null) &&
        (e.operation_type === operation_type || e.operation_type === null)
    );
    if (arancel !== undefined && price) {
      let comision;
      if (arancel.monto_minimo > 0) {
        const comiProvisoria = price * arancel.comision_porcentual;
        comision =
          comiProvisoria < arancel.monto_minimo
            ? arancel.monto_minimo
            : comiProvisoria;
      } else {
        comision = price * arancel.comision_porcentual;
      }
      let precioFinal;
      if (operation_type === "BUY") {
        precioFinal = price + comision;
      } else {
        precioFinal = price - comision;
      }
      return [precioFinal, comision];
    } else {
      return [price, 0];
    }
  } else {
    return [price, 0];
  }
};

/**
 * @description Adds + or - symbol, fixed 2 decimals an % symbol.
 */
export const formatPercentage = (
  percentage: number,
  withPositive: boolean = true
) => {
  const formattedVariation = (percentage * 100).toFixed(2);

  return `${
    withPositive && percentage > 0 ? "+" : ""
  }${formattedVariation.replace(".", ",")}%`;
};

/**
 * @description Large numbers are compacted with abbreviation.
 */
export const compactNumber = (
  value: number,
  numOfDecimals?: number
): string => {
  const formatter = Intl.NumberFormat("es", {
    notation: "compact",
    maximumFractionDigits: numOfDecimals,
  });
  const compactedValue = formatter.format(value);

  return compactedValue;
};

/**
 * @description Adds + or - symbol, thousand separator and decimal comma.
 */
export const formatNumber = (num?: number | null) => {
  if (num === undefined || num === null) {
    return "";
  }
  const formattedNum = num
    .toString()
    .replace(".", ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  if (num <= 0) {
    return formattedNum;
  }

  return `${num > 0 ? "+" : ""}${formattedNum}`;
};

export const formatNumberToLocale = (num: number) => {
  const parts = num.toString().split(".");
  const formattedInteger = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  const formattedNum =
    parts.length === 2 ? formattedInteger + "," + parts[1] : formattedInteger;
  return formattedNum;
};

/**
 * @description returns instrument type ID.
 */
export const getIdSegment = (type: string | undefined) => {
  switch (type) {
    case "FCI":
      return "FCI";
    case "CAUCION":
      return "U";
    default:
      return "C";
  }
};

/**
 * @description return intervals parameters for line chart.
 */
export const getIntervalParameters = (
  interval: string | undefined
): IntervalParameter => {
  switch (interval) {
    case "7D":
      return {
        value: 7,
        frame: "day",
      };
    case "1M":
      return {
        value: 1,
        frame: "month",
      };
    case "3M":
      return {
        value: 3,
        frame: "month",
      };
    case "1A":
      return {
        value: 1,
        frame: "year",
      };
    case "3A":
      return {
        value: 3,
        frame: "year",
      };
    default:
      return {
        value: 1,
        frame: "year",
      };
  }
};

/**
 * @description returns "CI, 24hs" terms based on term numbers.
 */
export const getSettlementDays = (
  settlement_days: number | null | undefined
) => {
  switch (settlement_days) {
    case 0:
      return SettlementTerms.CI;
    default:
      return SettlementTerms._24;
  }
};

/**
 * @description returns status label for movements.
 */
export const getStatusLabel = (status: MovementStatus) => {
  if (status === MovementStatus.COMPLETED) return "Completado";

  if (status === MovementStatus.PENDING) return "Pendiente";

  if (status === MovementStatus.ERROR) return "Falló";

  return "Pendiente";
};

export const getCryptoStatusLabel = (status: MovementStatus) => {
  if (status === MovementStatus.COMPLETED) return "Aprobado";

  if (status === MovementStatus.PENDING) return "Pendiente";

  if (status === MovementStatus.ERROR) return "Falló";

  return "Pendiente";
};

/**
 * @description returns 0, 1, 2 terms based on settlement string.
 */
export const getTerm = (settlement_days: SettlementTerms | undefined) => {
  switch (settlement_days) {
    case SettlementTerms.CI:
      return 0;
    case SettlementTerms._24:
      return 1;
    default:
      return 2;
  }
};

export const getSettlementByTicker = (long_ticker: string) => {
  const separatedTicker = long_ticker.split("-");
  const settlementValue = +separatedTicker[1].slice(-1);
  return getSettlementDays(settlementValue - 1);
};

/**
 * @description Formats decimals with tick size rule or currency comp.
 */
export function getFormattedNumber(
  num?: number | null,
  id_tick_size_rule?: string | null,
  tick_size?: number | null
) {
  if (!num) {
    return "-";
  }

  return id_tick_size_rule || tick_size ? (
    <DecimalRound ruleId={id_tick_size_rule} tickSize={tick_size}>
      {num}
    </DecimalRound>
  ) : (
    <Currency>{num}</Currency>
  );
}

/**
 * @description returns currency description based on currency values
 */
export const getCurrencyDescription = (currency: string | undefined) => {
  switch (currency) {
    case "ARS":
      return "pesos argentinos";
    case "USD":
      return "dólares";
    case "CABLE":
      return "dólares cable";
    default:
      return "";
  }
};

/**
 * @description returns currency symbol based on currency values
 */
export const getCurrencyLabel = (currency: string | undefined) => {
  switch (currency) {
    case "ARS":
      return "AR$";
    case "USD":
      return "US$";
    case "EXT":
      return "US$ C";
    default:
      return "$";
  }
};

const currencySymbols: { [key: string]: string } = {
  ARS: "AR$",
  USD: "US$",
  EXT: "US$ C",
  ACCIONES: "acciones",
  default: "$",
};

/**
 * @description returns currency symbol and value based in currency.
 */
export const getCurrencyFormatted = (
  currency: Currencies | "ACCIONES",
  value?: number | null
) => {
  const isAsset = currency === "ACCIONES";
  const currencyValue = currencySymbols[currency] ?? currencySymbols.default;
  const formattedValue = formatToCurrency({ value, withoutDecimals: isAsset });

  if (isAsset) {
    return `${formattedValue} ${currencyValue}`;
  }

  return `${currencyValue} ${formattedValue}`;
};

export const getCurrencySymbol = (
  currency: Currencies | PaymentCurrencies,
  isArsSymbol?: boolean
) => {
  const currencySymbols: { [key: string]: string } = {
    [Currencies.ARS]: isArsSymbol ? "AR$" : "$",
    [Currencies.USD]: "US$",
    [Currencies.EXT]: "US$ C",
    [Currencies.BRL]: "R$",
  };
  return currencySymbols[currency] || currency;
};

export const getCurrenciesLabels = (currency: Currencies) => {
  if (currency === Currencies.USD) return "Dólares";
  if (currency === Currencies.ARS) return "Pesos";
  if (currency === Currencies.EXT) return "Cable";
  return "";
};

/**
 * @description returns a smaller font size when the amount has more than 7 characters
 */
export const currencyResponsive = (amount: number) => {
  const amountToString = amount.toString();

  if (amountToString.length > 7) {
    return "textM_bold";
  } else {
    return "heading3_bold";
  }
};

/**
 * @description Calculates estimated amount based on price, quantity, price factor and contract size.
 */
export const calculateNetAmount = (
  price: number,
  quantity: number,
  contractSize?: number,
  priceFactor?: number
) => {
  const localContractSize = contractSize || 1;
  const localPriceFactor = priceFactor || 1;

  return (price * Math.floor(quantity) * localContractSize) / localPriceFactor;
}; //if Price = amount / quantity * price factor

/**
 * @description formats quantity to 1.000,00 with decimals if needed
 */
export const formatQuantity = (amount: number) => {
  return new Intl.NumberFormat("es-EN").format(amount);
};

/**
 * @description formats number to currency and returns the formatted string or null
 */
export const formatToCurrency = ({
  value,
  withoutDecimals,
  withCurrencySign,
  numberOfDecimals = 2,
  splitIfInteger,
}: {
  value?: number | string | null;
  withoutDecimals?: boolean;
  withCurrencySign?: boolean;
  numberOfDecimals?: number;
  splitIfInteger?: boolean;
}) => {
  if (value === undefined || value === null) {
    return null;
  }

  const decimalValue = withoutDecimals ? 0 : numberOfDecimals;

  const numberParsed = floor(Number(value), decimalValue);

  const numberToString = numberParsed.toFixed(decimalValue);

  const simpleNumber = numberToString.replace(".", ",");

  const splitted = simpleNumber.split(",");

  const intPart = splitted[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  let newNumber;

  if (withoutDecimals) {
    newNumber = intPart;
  } else {
    newNumber = `${intPart},${splitted[1] ?? 0}`;
  }

  if (withCurrencySign) {
    let sign = "";
    if (numberParsed < 0) sign = "-";
    else if (numberParsed > 0) sign = "+";

    return `${sign}$${newNumber.replace("-", "")}`;
  }

  if (splitIfInteger) {
    const split = newNumber.split(",");
    if (split[1] === "00") {
      return split[0];
    }
  }

  return newNumber;
};

export const formatRoundAmount = (currency: Currencies, amount: number) => {
  if (currency === Currencies.USD) {
    return amount > -0.01 && amount < 0 ? -0.01 : amount;
  }

  return amount > -0.99 && amount < 0 ? -1 : amount;
};

export const formatToCurrencyWithSymbol = (
  num: number | string | undefined | null,
  currency?: Currencies,
  withoutDecimals?: boolean,
  withCurrencySign?: boolean,
  numberOfDecimals: number = 2,
  sameDecimals: boolean = false
) => {
  if (num === undefined || num === null) {
    return null;
  }

  const decimalValue = withoutDecimals ? 0 : numberOfDecimals;

  const numberParsed = floor(Number(num), decimalValue);

  let numberToString = numberParsed.toFixed(decimalValue);

  if (sameDecimals) numberToString = numberParsed.toString();

  const simpleNumber = numberToString.replace(".", ",");

  const splitted = simpleNumber.split(",");

  const intPart = splitted[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  const newNumber = `${intPart},${splitted[1] ?? 0}`;

  const symbol = currency ? getCurrencySymbol(currency) : "*$";

  if (withCurrencySign) {
    let sign = "";
    if (numberParsed < 0) sign = "-";
    else if (numberParsed > 0) sign = "+";

    return `${sign}${symbol}${newNumber.replace("-", "")}`;
  }

  if (currency) return `${symbol}${newNumber}`;

  return newNumber;
};

/**
 * @description formats number to currency and returns the formatted string or null quiting unnecesary 0 from decimal part
 */
export const formatDynamicCurrency = (num: number | undefined | null) => {
  if (num === undefined || num === null) {
    return null;
  }

  return Number(num.toFixed(4))
    .toString()
    .replace(".", ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

/**
 * @description return class positive or negative to get styles, remember add the classname at the component
 */
export const getPositiveOrNegativeClass = (num: number) => {
  if (num !== 0) {
    return `${num > 0 ? "positive" : "negative"}`;
  }

  return "";
};

export const getDecimalScaleByRule = (
  num: number | string | undefined | null,
  ruleId?: string | null,
  tickSize?: number | null,
  tickerRules?: TickersRulesResponse
) => {
  if (Number.isNaN(Number(num))) return null;
  if (!tickerRules) return null;

  if (!ruleId) {
    return Math.ceil(Math.abs(Math.log10(Number(tickSize))));
  } else {
    const rule = tickerRules[ruleId].find(
      (r) => Number(num) >= r.price_from && Number(num) <= r.price_to
    );

    if (rule) {
      return rule.num_decimals;
    } else return 1;
  }
};

const defaultErrorMessage = "Lamentablemente no pudimos realizar tu operación";

const errorMessages: { [key: string]: string } = {
  ERROR_RULE_USD_PURCHASE:
    "Vendiste Bonares y/o Globales contra dólares en los últimos 30 días.",
  ERROR_RULE_USD_PURCHASE_11:
    "No podés realizar esta operación ya que vendiste AL/GD en USD en los últimos 30 días.",
  ERROR_RULE_USD_PURCHASE_12:
    "No podés realizar esta operación ya que vendiste AL/GD en USD en los últimos 15 días.",
  ERROR_RULE_USD_PURCHASE_21:
    "No podés realizar esta operación ya que operaste otro instrumento en USD en los últimos 30 días.",
  ERROR_RULE_USD_PURCHASE_22:
    "No podés realizar esta operación ya que operaste otro instrumento en USD en los últimos 15 días.",
  ERROR_RULE_USD_PURCHASE_31:
    "No se puede comprar este título en dólares en CI. Podés comprarlo en 48hs, o si querés vender dólar MEP, podes usar nuestro botón para vender a un click.",
  "CBU or CVU format invalid": "El CBU/CVU ingresado es inválido",
  ERROR_RULE_USD_PURCHASE_41:
    "No podés realizar esta operación ya que vendiste AL/GD en USD en los ultimos 30 dias.",
  ERROR_RULE_USD_PURCHASE_42:
    "No podés realizar esta operación ya que vendiste AL/GD en USD en los ultimos 15 dias.",
  ERROR_RULE_USD_PURCHASE_51:
    "No podés realizar esta operación ya que compraste AL/GD en USD en los plazos de CI y/o 24hs en los ultimos 30 dias.",
  ERROR_RULE_USD_PURCHASE_52:
    "No podés realizar esta operación ya que compraste AL/GD en USD en los plazos de CI y/o 24hs en los ultimos 15 dias.",
  ERROR_RULE_USD_PURCHASE_61:
    "Operación rechazada. Está operación supera el límite establecido por la RG 701",
  ERROR_RULE_USD_PURCHASE_62:
    "Operación rechazada. Está operación supera el límite establecido por la RG 701",
  ERROR_RULE_FCI_1: "Por el momento no se puede suscribir este fondo.",
  ERROR_WITHDRAW_INVALID_BANK:
    "No se pudo realizar la transferencia a la cuenta indicada.",
  ERROR_WITHDRAW_ASSET_DEBT:
    "Tu cuenta tiene saldo negativo en algún activo. Por favor regularizá la situación para poder retirar tus fondos.",
  ERROR_WITHDRAW_INSUFFICIENT_FUNDS:
    "Tu cuenta no tiene saldo suficiente para la extracción.",
  default: defaultErrorMessage,
};

/**
 * @description return message error
 */

export const getMessageError = (messageError: string) => {
  return errorMessages[messageError] || errorMessages.default;
};

export const checkShowBanner = (seen: boolean, supressUntil: string | null) => {
  if (seen) {
    return false;
  }

  if (!supressUntil) {
    return true;
  }

  const until = dayjs(supressUntil).format("DD/MM/YYYY");
  const today = dayjs().format("DD/MM/YYYY");

  if (until > today) {
    return false;
  }
  return true;
};

export const getEnvironment = () => {
  const apiUrl = import.meta.env.VITE_API_URL;
  let environment = "prod";
  if (apiUrl.includes("preprod")) {
    environment = "preprod";
  }

  if (apiUrl.includes("staging")) {
    environment = "staging";
  }

  return { environment, isProd: environment === "prod" };
};
