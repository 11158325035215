import classNames from "classnames";
import Text, { TextVariants } from "components/common/Text";
import { Check } from "lucide-react";

import styles from "./styles.module.scss";

interface GoldBannerBenefitProps {
  text: string;
  className?: string;
}

const GoldBannerBenefit: React.FC<GoldBannerBenefitProps> = ({
  text,
  className,
}) => {
  return (
    <div className={classNames(styles.goldBannerBenefitContainer, className)}>
      <div className={styles.icon}>
        <Check size={11.6} color="var(--white-to-black)" />
      </div>
      <Text variant={TextVariants.SemiboldTextXS} color="var(--slate600)">
        {text}
      </Text>
    </div>
  );
};

export default GoldBannerBenefit;
