import { useDeviceCheck } from "hooks/useDeviceCheck";

import OrdersDesktop from "./desktop";
import OrdersMobile from "./mobile";

const Orders = () => {
  const { isDesktop } = useDeviceCheck();

  if (isDesktop) return <OrdersDesktop />;

  return <OrdersMobile />;
};

export default Orders;
