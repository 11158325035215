import CommonLayout from "layouts/common";

import OrdersContent from "./OrdersContent";
import ActivityHeader from "./Header";

import styles from "./styles.module.scss";

const OrdersDesktop = () => {
  return (
    <CommonLayout withHeader={false}>
      <div className={styles.desktop}>
        <ActivityHeader />
        <OrdersContent />
      </div>
    </CommonLayout>
  );
};

export default OrdersDesktop;
