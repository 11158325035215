import { ReactNode, createContext, useContext, useMemo, useState } from "react";

import { OrderStatus } from "interfaces/orders/enums";

export type FilterOrderType =
  | OrderStatus.PENDING
  | OrderStatus.EXECUTED
  | "ALL";

interface Context {
  orderFilter: FilterOrderType;
  setOrderFilter: (filter: FilterOrderType) => void;
}

const ActivityContext = createContext<Context | null>(null);

export const ActivityProvider = ({ children }: { children: ReactNode }) => {
  const [orderFilter, setOrderFilter] = useState<FilterOrderType>("ALL");

  const memoizedValues = useMemo(() => {
    return {
      orderFilter,
      setOrderFilter,
    };
  }, [orderFilter, setOrderFilter]);

  return (
    <ActivityContext.Provider value={memoizedValues}>
      {children}
    </ActivityContext.Provider>
  );
};

export default ActivityProvider;

export const useActivity = () => {
  const context = useContext(ActivityContext);
  if (!context) {
    throw new Error("[ActivityContext] Missing context");
  }
  return context;
};
