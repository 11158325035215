import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import {
  ActivityTabs,
  activityTabsOptions,
} from "components/page-activity/constants";
import NavigationBar from "components/common/NavigationBar";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import DesktopHeader from "components/page-orders/v2/desktop/Header";

interface HeaderProps {
  selectedTab: ActivityTabs;
  setSelectedTab: (tab: ActivityTabs) => void;
}

const Header: React.FC<HeaderProps> = ({ selectedTab, setSelectedTab }) => {
  const { isDesktop } = useDeviceCheck();
  const navigate = useNavigate();

  const onClickProfile = () => {
    navigate(mainRoutes.profile);
  };

  if (isDesktop)
    return (
      <DesktopHeader
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
    );

  return (
    <div>
      <NavigationBar
        title="Actividad"
        onClickLeft={onClickProfile}
        segmentedControlProps={{
          selected: selectedTab,
          options: activityTabsOptions,
          onClick: (option) => setSelectedTab(option),
        }}
      />
    </div>
  );
};

export default Header;
