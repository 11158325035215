import React from "react";

import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import {
  OrderDetailResponse,
  OrderStatusTypes,
} from "interfaces/api-responses";
import { OrderType, Instruments } from "interfaces/markets";
import { setOrderDetail } from "features/markets/marketsSlice";
import {
  InstrumentLogoUI,
  InstrumentLogoVariants,
} from "components/common/instument-logo";
import { getCurrencyLabel, formatToCurrency } from "utils";
import { OrderStatusBadge } from "components/page-orders/OrdersList/components/OrderStatusBadge";
import { getOrderName } from "components/page-orders/utils";
import mainRoutes from "router/routes";
import { useAppDispatch } from "hooks/redux";

import { ACTIVE_BID } from "../bid/constants";

import styles from "./styles.module.scss";

interface OrderRowProps {
  order: OrderDetailResponse;
  onClickOrderRow?: (orderId: string) => void;
}

const OrderRow: React.FC<OrderRowProps> = ({ order, onClickOrderRow }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    status,
    result_amount,
    set_amount,
    result_quantity,
    set_quantity,
    result_price,
    set_price,
    order_id,
    order_type,
    logo_file_name,
    ticker,
    order_type_label,
    currency,
    instrument_type,
    instrument_short_name,
  } = order;

  const isCloseMep = instrument_short_name === "Operatoria Dólar MEP";
  const isStatusExecuted = status === OrderStatusTypes.EXECUTED;
  const orderTotal = isStatusExecuted ? result_amount : set_amount;
  const orderQuantity = isStatusExecuted ? result_quantity : set_quantity;
  const orderPrice = isStatusExecuted ? result_price : set_price;

  const isActiveBid = ticker === ACTIVE_BID.instrument_code;

  const handleClickRow = (event: React.MouseEvent) => {
    event.stopPropagation();

    dispatch(setOrderDetail(order));
    if (onClickOrderRow) {
      onClickOrderRow(order_id);
    } else {
      navigate(`${mainRoutes.orderDetail}/${order_id}`);
    }
  };

  return (
    <div className={styles.rowContainer} onClick={handleClickRow}>
      <div className={styles.operationInfo}>
        <InstrumentLogoUI
          logoFileName={logo_file_name}
          placeholderStyle={
            order_type === OrderType.Buy ? "positive" : "negative"
          }
          type={instrument_type}
          variant={InstrumentLogoVariants.Secondary}
        />

        <div className={styles.operationText}>
          <Typography component="p" variant="textS_bold">
            {getOrderName(
              isCloseMep ? instrument_short_name : ticker || currency
            )}
          </Typography>
          <Typography component="p" variant="textXS">
            {order_type_label}
          </Typography>
        </div>
      </div>

      <div>
        {isActiveBid ? (
          <Typography component="p" variant="textXS_bold" textAlign="right">
            {"-"}
          </Typography>
        ) : (
          <Typography component="p" variant="textXS_bold" textAlign="right">
            {orderQuantity}
          </Typography>
        )}

        {isActiveBid ? (
          <Typography component="p" variant="textXS" textAlign="right">
            {"-"}
          </Typography>
        ) : (
          <Typography component="p" variant="textXS" textAlign="right">
            {`${getCurrencyLabel(currency)} `}
            {formatToCurrency({ value: orderPrice })}
          </Typography>
        )}
      </div>

      <div className={styles.totalAmount}>
        <Typography variant="textXS_bold" textAlign="right">
          {`${getCurrencyLabel(currency)} `}
          {instrument_type === Instruments.FCI
            ? formatToCurrency({ value: Math.abs(set_amount) })
            : formatToCurrency({ value: orderTotal || 0 })}
        </Typography>
        <OrderStatusBadge status={status} />
      </div>
    </div>
  );
};

export default OrderRow;
