import { PortfolioTabs, portfolioTabOptions } from "constants/portfolio";

import NavigationHeader from "components/common/NavigationHeader";
import SegmentedControl from "components/common/SegmentedControl";
import Avatar from "components/common/avatar";
import { ThemeVariants } from "interfaces/theme";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import { ampli } from "ampli";
import { getTabName } from "components/page-cocos-portfolio/utils";
import Heading, { HeadingVariants } from "components/common/Heading";
import { Tooltip } from "@mui/material";
import { FileDown, Info } from "lucide-react";
import px2rem from "utils/px2rem";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import FilledButton, {
  FilledButtonVariants,
} from "components/common/FilledButton";
import { useAppState } from "context/AppStateProvider";

import PortfolioCurrencySwitch from "../CurrencySwtich";
import DownloadPortfolioReport from "../../Desktop/DownloadPortfolioReport";

import styles from "./styles.module.scss";

interface HeaderProps {
  variant?: ThemeVariants;
  selectedTab: PortfolioTabs;
  setSelectedTab: (tab: PortfolioTabs) => void;
}

const Header: React.FC<HeaderProps> = ({
  variant,
  selectedTab,
  setSelectedTab,
}) => {
  const navigate = useNavigate();
  const { isDesktop } = useDeviceCheck();
  const { openRightModalContent } = useAppState();

  const onDownloadReportClick = () => {
    openRightModalContent(<DownloadPortfolioReport />);
  };

  const handleTabChange = (option: PortfolioTabs) => {
    setSelectedTab(option);

    ampli.portfolioScreenViewed({ tab: getTabName(option) });
  };

  if (isDesktop)
    return (
      <div className={styles.portfolioDesktop}>
        <Heading
          variant={HeadingVariants.RegularTitle}
          color="var(--slate800)"
          component="h1"
        >
          Portfolio
        </Heading>
        <div className={styles.currencyWrapper}>
          <FilledButton
            onClick={onDownloadReportClick}
            variant={FilledButtonVariants.Download}
          >
            Descargar Portfolio <FileDown size={px2rem(16)} />
          </FilledButton>
          <PortfolioCurrencySwitch />
          <Tooltip
            title={`Al seleccionar "USD", el precio, el costo y el resultado de tus instrumentos permanecerán en Pesos para priorizar la precisión de los cálculos.`}
          >
            <Info
              className={styles.icon}
              color="var(--slate900)"
              size={px2rem(16)}
            />
          </Tooltip>
        </div>
      </div>
    );

  return (
    <div className={styles.header}>
      <NavigationHeader
        variant={variant}
        title="Portfolio"
        onClick={() => navigate(mainRoutes.profile)}
        leftIcon={<Avatar variant={variant} />}
        withCloseIcon={false}
      />
      <div className={styles.tabsContainer}>
        <SegmentedControl
          options={portfolioTabOptions}
          selected={selectedTab}
          onClick={(option) => handleTabChange(option)}
        />
      </div>
    </div>
  );
};

export default Header;
