import { useMemo } from "react";

import { useTickersQuery } from "apis/crypto/queries/useTickers";

export function useCryptoByTicker(ticker: string) {
  const { data: cryptoList } = useTickersQuery();

  const crypto = useMemo(() => {
    if (!cryptoList) return;

    return cryptoList.find(
      (crypto) => crypto.ticker.toLowerCase() === ticker.toLowerCase()
    );
  }, [cryptoList, ticker]);

  return crypto;
}
