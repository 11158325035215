import Text, { TextVariants } from "components/common/Text";
import { BalanceCurrencies, Currencies } from "interfaces/wallet";
import { TrendingDown, TrendingUp } from "lucide-react";
import { formatPercentage, formatToCurrency, getCurrencySymbol } from "utils";

import HiddenValue from "../HiddenValue";

import styles from "./styles.module.scss";

interface VariationProps {
  value?: number;
  amount?: number;
  withArrow?: boolean;
  text?: string;
  hideBalances?: boolean;
  currency?: BalanceCurrencies;
}

const Variation: React.FC<VariationProps> = ({
  value,
  amount,
  withArrow = true,
  text = "",
  currency = Currencies.ARS,
  hideBalances = false,
}) => {
  const isNegative = (value || amount || 0) < 0;

  const variationColor = isNegative ? "var(--red800)" : "var(--green800)";

  const amountVariation = isNegative ? "-" : "+";
  const amountCurrencyAndPrice = `${getCurrencySymbol(
    currency
  )}${formatToCurrency({ value: Math.abs(Number(amount)) })}`;

  const amountLabel =
    amount !== undefined && `(${amountVariation}${amountCurrencyAndPrice})`;

  const Arrow = isNegative ? TrendingDown : TrendingUp;

  return (
    <div className={styles.variationContainer}>
      {hideBalances ? (
        <HiddenValue size={8} color="var(--slate800)" count={5} />
      ) : (
        <>
          {withArrow && <Arrow color={variationColor} size={16} />}
          <Text
            variant={TextVariants.SemiboldUpperValueS}
            color={variationColor}
          >
            {value !== undefined && formatPercentage(value, false)}{" "}
            {amountLabel}
          </Text>
          {text && (
            <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
              {text}
            </Text>
          )}
        </>
      )}
    </div>
  );
};

export default Variation;
