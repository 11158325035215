import { getCryptoElement } from "constants/cryptos";

import SendAsset from "components/page-crypto-send/SendAsset";
import { CryptoHolding, CryptoTicker } from "interfaces/api-responses";
import { Currencies } from "interfaces/wallet";
import { Operations } from "interfaces/crypto/enums";
import Banner, { BannerVariants } from "components/common/Banner";
import { useTickersQuery } from "apis/crypto/queries/useTickers";

import styles from "./styles.module.scss";

interface AssetListProps {
  assets: CryptoHolding[];
  onClickAsset?: (asset: CryptoTicker) => void;
}

const AssetList: React.FC<AssetListProps> = ({ assets, onClickAsset }) => {
  const { data: cryptoList } = useTickersQuery();

  const tickerNotIncludesSend = (crypto?: CryptoTicker) => {
    return (
      !crypto?.allowed_operations?.includes(Operations.SEND) &&
      !crypto?.allowed_operations?.includes(Operations.SEND_TAG)
    );
  };

  const isSomeHoldingDisable = assets.some(({ ticker }) =>
    cryptoList?.some(
      (crypto) => crypto.ticker === ticker && tickerNotIncludesSend(crypto)
    )
  );

  return (
    <div className={styles.wrapper}>
      {isSomeHoldingDisable && (
        <Banner
          className={styles.banner}
          variant={BannerVariants.INFO_CRYPTO}
          text="Recordá que no es posible realizar esta operación con todos los activos."
        />
      )}
      {assets.map(({ ticker, quantity }) => {
        if (ticker === Currencies.ARS) return null;
        const { icon } = getCryptoElement(ticker);

        const crypto = cryptoList?.find((crypto) => crypto.ticker === ticker);

        const isDisabled = tickerNotIncludesSend(crypto);

        return (
          <SendAsset
            icon={icon}
            name={crypto?.name || ""}
            ticker={ticker}
            price={quantity}
            key={ticker}
            onClick={onClickAsset}
            disabled={isDisabled}
          />
        );
      })}
    </div>
  );
};

export default AssetList;
