import { SettlementIndex } from "constants/portfolio";

import React, { useEffect, useState } from "react";

import {
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { InfoIcon } from "assets/icons/info";
import NoCtte from "components/common/NoCtte";
import ButtonUI, {
  ButtonSizes,
  ButtonVariants,
} from "components/common/button";
import { ToggleSizes, ToggleUI } from "components/common/controls/toggle";
import { Currency } from "components/common/currency";
import IconText from "components/common/icon-text";
import {
  AmountInputSizes,
  UIAmountInput,
} from "components/common/input/amount";
import { updateOrderOptions } from "features/markets/marketsSlice";
import RetryButton from "components/common/retry-button";
import { OrderType } from "interfaces/markets";
import { Currencies, PortfolioFromType } from "interfaces/wallet";
import GoogleTagManager from "react-gtm-module";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { Navigate } from "react-router-dom";
import mainRoutes, { screenNames } from "router/routes";
import { getSellingPower, getWallet } from "features/wallet/walletSlice";
import { RootState } from "store/store";
import { getLoggedUser } from "store/selectors/user.selector";
import {
  formatToCurrency,
  getCurrencyLabel,
  getFormattedNumber,
  getPositiveOrNegativeClass,
  getSettlementDays,
} from "utils";
import { trackAction } from "utils/amplitude";
import { getColor } from "utils/getColor";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { usePortfolioQuery } from "apis/portfolio/queries/usePortfolioQuery";

import { HeaderCif } from "./header";
import { FCIHoldingRow } from "./holding-row";

import "./index.scss";

interface FCIMarketDetailTradeProps {
  onClickContinueTrade: (arg?: boolean) => void;
}

const FCIMarketDetailTrade: React.FC<FCIMarketDetailTradeProps> = ({
  onClickContinueTrade,
}) => {
  const dispatch = useAppDispatch();
  const { marketDetail } = useAppSelector((state: RootState) => state.markets);

  const DISABLED_FCIS = ["TRTINFA AR", "TORRTOA AR"];

  const wallet = useAppSelector((state: RootState) => state.wallet.wallet);
  const { sellingPower } = useAppSelector((state: RootState) => state.wallet);
  const loadingWallet = useAppSelector(
    (state: RootState) => state.wallet.loading
  );
  const order = useAppSelector((state: RootState) => state.markets.newOrder);
  const marketHistoricData = useAppSelector(
    (state: RootState) => state.markets.marketHistoricData
  );
  const currency =
    marketDetail?.currency === Currencies.ARS ? Currencies.ARS : Currencies.USD;
  const { data: holdings } = usePortfolioQuery(
    currency,
    PortfolioFromType.BROKER
  );

  const user = useAppSelector(getLoggedUser);

  const getAvailableBuy = () => {
    return (
      wallet &&
      order &&
      order.term &&
      Math.min(
        Number(wallet.CI[marketDetail?.currency === "ARS" ? "ars" : "usd"]),
        Number(wallet["24hs"][marketDetail?.currency === "ARS" ? "ars" : "usd"])
      )
    );
  };

  const getCuotapartes = (values: any) => {
    let min;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id_instrument, id_account, ...availables } = values;

    if (Object.prototype.hasOwnProperty.call(values, "subyacente")) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { subyacente, ...availableValues } = availables;

      min = Math.min(
        ...Object.values(availableValues).map((val: any) => {
          return parseFloat(val);
        })
      );
    } else {
      min = Math.min(
        ...Object.values(availables).map((val: any) => {
          return parseFloat(val);
        })
      );
    }

    return min;
  };

  const getAvailableSell = () => {
    if (marketHistoricData && sellingPower?.available) {
      const lastIndex = marketHistoricData?.prices.length - 1;
      const lastPrice = marketHistoricData.prices[lastIndex].close;

      const availableToSell =
        (lastPrice / 1000) * getCuotapartes(sellingPower.available);

      return availableToSell;
    }

    return 0;
  };

  const [availableBuy, setAvailableBuy] = useState(getAvailableBuy());
  const [availableSell, setAvailableSell] = useState(getAvailableSell());

  useEffect(() => {
    if (!wallet) {
      dispatch(getWallet());
    }

    dispatch(
      updateOrderOptions({
        ...order,
        long_ticker: marketDetail?.long_ticker,
        instrument_short_name: marketDetail?.instrument_short_name,
        price: marketDetail?.last,
        instrument_code: marketDetail?.instrument_code,
      })
    );
  }, []);

  useEffect(() => {
    if (marketDetail?.short_ticker) {
      dispatch(getSellingPower(marketDetail?.short_ticker));
    }
  }, [marketDetail?.short_ticker]);

  useEffect(() => {
    if (wallet && holdings && order && order.term) {
      setAvailableBuy(getAvailableBuy());
    }
  }, [wallet, holdings, order.currency, order.term]);

  useEffect(() => {
    if (holdings && sellingPower) {
      setAvailableSell(getAvailableSell());
    }
  }, [sellingPower, holdings]);

  useEffect(() => {
    GoogleTagManager.dataLayer({
      dataLayer: {
        event: "fci_screen_viewed",
        ticker: marketDetail?.long_ticker,
      },
    });
  }, []);

  if (!marketDetail) return null;

  const tenencia =
    holdings &&
    holdings?.holdings.find((t) => t.ticker === marketDetail.short_ticker)
      ?.settlements[SettlementIndex.INF];

  const insufficientActivesToSell = () => {
    if (order.type === OrderType.Sell) {
      if (order?.amount) {
        return order.totalRedemption
          ? !(
              order.quantity &&
              tenencia?.quantity &&
              sellingPower?.available &&
              (marketDetail.currency === "USD"
                ? getCuotapartes(sellingPower?.available) >= order.quantity
                : tenencia.quantity >= order.quantity)
            )
          : (availableSell || 0) < order.amount;
      }
    }

    return false;
  };

  const amountNearTotal =
    (order.amount &&
      order.type === OrderType.Sell &&
      !order.totalRedemption &&
      !insufficientActivesToSell() &&
      order.amount / availableSell >= 0.95) ||
    false;

  const handleSuscribe = (
    event: React.MouseEvent<HTMLElement>,
    value: OrderType
  ) => {
    if (value && value !== order.type) {
      if (value === OrderType.Buy) {
        const { totalRedemption, ...orderWithoutTotalRedemption } = order;

        dispatch(
          updateOrderOptions({
            ...orderWithoutTotalRedemption,
            price: marketDetail.last,
            type: value,
            amount: undefined,
            quantity: undefined,
          })
        );
        return;
      }

      dispatch(
        updateOrderOptions({
          ...order,
          price: marketDetail.last,
          totalRedemption: false,
          type: value,
          amount: undefined,
          quantity: undefined,
        })
      );
    }
  };

  const formatPercentage = (percentage: number) => {
    const formattedVariation = (percentage * 100).toFixed(2);

    return `${percentage > 0 ? "+" : ""}${formattedVariation}%`;
  };

  const insufficientAmount = () => {
    if (
      !order.amount ||
      (order.type === OrderType.Buy &&
        order.amount < (marketDetail.min_lot_size || 1000))
    ) {
      return true;
    }
  };

  const insufficientToBuy = () => {
    if (order.type === OrderType.Buy && order?.amount) {
      return (availableBuy || 0) < order?.amount;
    }

    return false;
  };

  const handleChangeAmountValue = ({ floatValue }: NumberFormatValues) => {
    if (floatValue !== undefined && !order.totalRedemption) {
      const priceFactor = marketDetail.price_factor || 1000;
      const contractSize = marketDetail.contract_size || 1;
      const price = marketDetail.last || marketDetail.close;

      dispatch(
        updateOrderOptions({
          ...order,
          amount: floatValue,
          quantity: price
            ? (floatValue * priceFactor) / (price * contractSize)
            : 0,
        })
      );
    }
  };

  const handleChangeTotalRedemption = (ev: any) => {
    ev.stopPropagation();
    const trackProps = {
      enabled: ev.target.checked,
    };
    trackAction(`${screenNames.fci} - Click Rescue All`, trackProps);

    dispatch(
      updateOrderOptions({
        ...order,
        totalRedemption: ev.target.checked,
        amount: ev.target.checked
          ? marketDetail.currency === "USD"
            ? availableSell
            : tenencia?.amount
          : order.amount,
        quantity: ev.target.checked
          ? marketDetail.currency === "USD"
            ? getCuotapartes(sellingPower?.available)
            : tenencia?.quantity
          : undefined,
      })
    );
  };

  const retryGetAvailableBuy = () => {
    setAvailableBuy(getAvailableBuy());
  };

  const retryGetAvailableSell = () => {
    setAvailableSell(getAvailableSell());
  };

  const retryGetSellingPower = () => {
    dispatch(getSellingPower(marketDetail.long_ticker));
  };

  const onClickRescueReview = () => {
    if (amountNearTotal) {
      dispatch(
        updateOrderOptions({
          ...order,
          amount: availableSell,
          totalRedemption: true,
        })
      );
    }
    const trackProps = {
      side: order.type,
      totalAmount: order.amount,
    };
    onClickContinueTrade(amountNearTotal);

    trackAction(`${screenNames.fci} - Review Order`, trackProps);
  };

  const isFCIBanned =
    DISABLED_FCIS.includes(marketDetail.long_ticker) &&
    order.type === OrderType.Buy;

  const isButtonDisabled =
    insufficientAmount() ||
    insufficientActivesToSell() ||
    insufficientToBuy() ||
    isFCIBanned;

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  const { hasAccount } = user;

  return (
    <div className="cif">
      <div className="cif-content">
        <div className="header-cif">
          <HeaderCif />
          <div className="info">
            <Typography variant="textS_bold">
              {getSettlementDays(
                typeof marketDetail.redemption_settlement_days === "number"
                  ? marketDetail.redemption_settlement_days
                  : 0
              )}
            </Typography>
            <Typography variant="textS_bold">
              {getCurrencyLabel(marketDetail.currency)}{" "}
            </Typography>
            <Typography variant="textS_bold">{`Clase ${
              marketDetail.share_class || "A"
            }`}</Typography>
            <div
              className={`variation ${getPositiveOrNegativeClass(
                marketDetail.returns_perc_last_month || 0
              )}`}
            >
              <Typography variant="textXS_bold">
                {marketDetail.returns_perc_last_month !== undefined
                  ? formatPercentage(marketDetail.returns_perc_last_month || 0)
                  : "-"}
              </Typography>
            </div>
          </div>
          <Divider />
          <div>
            <span
              className={`amount-text ${
                order.type === OrderType.Buy ? "bottom-space" : ""
              }`}
            >
              <Typography variant="textS">Saldo disponible:</Typography>
              <Typography variant="textS_bold">
                {`${getCurrencyLabel(marketDetail.currency)} `}
                {order.type === OrderType.Buy ? (
                  availableBuy || availableBuy === 0 ? (
                    <Currency>{availableBuy}</Currency>
                  ) : (
                    <RetryButton
                      loading={loadingWallet}
                      onClick={retryGetAvailableBuy}
                    />
                  )
                ) : availableSell || availableSell === 0 ? (
                  <Currency>{availableSell}</Currency>
                ) : (
                  <RetryButton
                    loading={loadingWallet}
                    onClick={retryGetAvailableSell}
                  />
                )}
              </Typography>
            </span>
          </div>
          {order.type === OrderType.Sell && (
            <span className="quantity-text">
              <Typography variant="textS">
                Cuotapartes disponible según plazo:{" "}
              </Typography>
              <Typography variant="textS_bold">
                {sellingPower || sellingPower === 0 ? (
                  <>{getFormattedNumber(+(sellingPower.available.inf || 0))}</>
                ) : (
                  <>
                    <RetryButton
                      loading={loadingWallet}
                      onClick={retryGetSellingPower}
                    />
                  </>
                )}
              </Typography>
            </span>
          )}
        </div>
        <NoCtte />
        {hasAccount && (
          <div className="cif-subcontent">
            <div className="subcontent">
              <ToggleButtonGroup
                exclusive
                aria-label="text section"
                className="ui-currency-toggle"
                value={order.type}
                onChange={handleSuscribe}
              >
                <ToggleButton className="subscription" value={OrderType.Buy}>
                  Suscribir
                </ToggleButton>
                <ToggleButton className="rescue" value={OrderType.Sell}>
                  Rescatar
                </ToggleButton>
              </ToggleButtonGroup>
              <Typography className="amount" variant="textS">
                {order.type === OrderType.Buy
                  ? "Monto a invertir"
                  : "Monto a rescatar"}
              </Typography>
              <div className={`number-format ${order.type.toLowerCase()}`}>
                <UIAmountInput
                  disableDecimals
                  classes={marketDetail.currency === "USD" ? "usd" : undefined}
                  currencyValue={marketDetail.currency ?? "ARS"}
                  disabled={order.totalRedemption}
                  size={AmountInputSizes.Full}
                  value={order.amount?.toString()}
                  onChangeValue={handleChangeAmountValue}
                />
                <Typography
                  className="amount-subtitle"
                  color="GrayText"
                  variant="textM"
                >
                  {(insufficientActivesToSell() || insufficientToBuy()) && (
                    <Typography
                      className="not-enough"
                      component="p"
                      variant="textM"
                    >
                      No tienes saldo suficiente
                    </Typography>
                  )}
                  {!(insufficientActivesToSell() || insufficientToBuy()) &&
                    order.type === OrderType.Buy &&
                    `Mínimo a invertir: ${getCurrencyLabel(
                      marketDetail.currency ?? "ARS"
                    )}  ${formatToCurrency({
                      value: marketDetail.min_lot_size,
                      withoutDecimals: true,
                    })}`}
                  {order.type !== OrderType.Buy && (
                    <div className="toggle-wrapper">
                      <NumberFormat
                        decimalScale={2}
                        decimalSeparator=","
                        displayType="text"
                        prefix={"Cantidad de cuotapartes: "}
                        suffix=" "
                        thousandSeparator="."
                        value={order.quantity ?? 0}
                      />
                      <div className="toggle-format">
                        <Typography variant="textM">Rescatar todo</Typography>
                        <ToggleUI
                          checked={order.totalRedemption}
                          disabled={!availableSell || !sellingPower}
                          size={ToggleSizes.Medium}
                          onClick={handleChangeTotalRedemption}
                        />
                      </div>
                    </div>
                  )}
                </Typography>
              </div>
              {amountNearTotal &&
                order.type === OrderType.Sell &&
                !order.totalRedemption && (
                  <IconText
                    icon={
                      <InfoIcon
                        size={24}
                        color={getColor("--darkblue-to-light")}
                      />
                    }
                    label={
                      <Typography variant="textS">
                        Dado el monto ingresado, se realizará un rescate por el
                        total de cuotapartes.
                      </Typography>
                    }
                  />
                )}
              {isFCIBanned && (
                <Typography className="error" variant="textS_bold">
                  Por el momento no se puede suscribir este fondo.
                </Typography>
              )}
              <ButtonUI
                disabled={isButtonDisabled}
                size={ButtonSizes.Full}
                variant={ButtonVariants.Primary}
                onClick={onClickRescueReview}
              >
                <Typography variant="textS_bold">
                  {order.type === OrderType.Buy
                    ? "Revisar Suscripción"
                    : "Revisar Rescate"}
                </Typography>
              </ButtonUI>
            </div>
            {tenencia &&
              marketDetail.currency &&
              marketDetail.short_ticker &&
              marketDetail.variation && (
                <div className="tenencia">
                  <Typography className="tenuery-text" variant="textS_bold">
                    Tenencia total
                  </Typography>
                  <FCIHoldingRow
                    currency={marketDetail.currency}
                    label={marketDetail.short_ticker}
                    last={
                      marketDetail.currency === Currencies.USD
                        ? availableSell
                        : tenencia.amount
                    }
                    variation={marketDetail.variation}
                  />
                </div>
              )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FCIMarketDetailTrade;
