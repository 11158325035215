import { useEffect, useState } from "react";

import { ampli } from "ampli";
import { ThemeVariants } from "interfaces/theme";
import FilledButton from "components/common/FilledButton";
import Footer from "components/common/Footer";
import PageHeader from "components/common/PageHeader";
import TransactionInput from "components/common/TransactionInput";
import { useCocosPay } from "context/CocosPayProvider";
import { PaySteps } from "interfaces/pay/enums";
import { ArrowLeft } from "lucide-react";
import { Currencies } from "interfaces/wallet";

import { MIN_BRL_TO_PAY } from "../utils";
import PayLegals from "../Legals";

import styles from "./styles.module.scss";

const PayAmount: React.FC = () => {
  const {
    setStep,
    changeQuantity,
    paymentData,
    goToPaymentMethods,
  } = useCocosPay();

  const { quantity, transactionCurrency, currency } = paymentData;

  const isPIX = transactionCurrency === Currencies.BRL;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isDisabled = !quantity || (quantity < MIN_BRL_TO_PAY && isPIX);

  useEffect(() => {
    ampli.payScreenAmount();
  }, []);

  const onChangeQuantity = (quantity: number) => {
    changeQuantity(quantity);
  };

  const onClickContinue = () => {
    ampli.payAmountContinue({ amount: paymentData.quantity });
    setIsLoading(true);
    goToPaymentMethods(paymentData.idPayment, paymentData.quantity);
  };

  const onPressEnter = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.key === "Enter" && !isDisabled) onClickContinue();
  };

  const onClickBack = () => {
    setStep(paymentData.isChave ? PaySteps.CODE : PaySteps.QR);
  };

  return (
    <div className={styles.PayAmountContainer}>
      <PageHeader
        icon={ArrowLeft}
        title={"Ingresá el monto"}
        onClick={onClickBack}
        iconColor="var(--black-to-white)"
      />
      <div className={styles.wrapper}>
        <TransactionInput
          onChange={onChangeQuantity}
          onKeyDown={onPressEnter}
          currency={currency}
          minToBuy={quantity < MIN_BRL_TO_PAY}
          minLimit={MIN_BRL_TO_PAY}
        />
        <Footer>
          <div className={styles.buttonsWrapper}>
            <PayLegals />
            <FilledButton
              variant={ThemeVariants.Pay}
              disabled={isDisabled}
              onClick={onClickContinue}
              isLoading={isLoading}
            >
              Continuar
            </FilledButton>
          </div>
        </Footer>
      </div>
    </div>
  );
};

export default PayAmount;
