import { CDN_URL } from "constants/index";

import classNames from "classnames";
import PriceLabel, { PriceLabelSizes } from "components/common/PriceLabel";
import Text, { TextVariants } from "components/common/Text";
import type { OrderDetailType } from "interfaces/orders/interface";
import { LucideIcon } from "lucide-react";
import ARGFlag from "assets/icons/ARGFlag";
import USFlag from "assets/icons/USFlag";
import { OperationSide, OrderStatus } from "interfaces/orders/enums";
import { formatToCurrency } from "utils";
import { SpecialTickers } from "utils/constants";

import {
  formatLocalDate,
  getOperationSideHeading,
  getOrderStatusMap,
  getSmallIcon,
  getTickerLabel,
} from "../utils";

import styles from "./styles.module.scss";

interface StatusIconProps {
  icon: LucideIcon;
}

interface OrderTableRowProps {
  order: OrderDetailType;
  lastRow: boolean;
  onClick: (orderId: string) => void;
}

const StatusIcon = ({ icon: Icon }: StatusIconProps) => {
  return (
    <div className={styles.statusIcon}>
      <Icon color="var(--slate200)" size={12} />
    </div>
  );
};

const OrderTableRow = ({ order, lastRow, onClick }: OrderTableRowProps) => {
  const {
    orderId,
    amount,
    createdAt,
    logo,
    price,
    quantity,
    side,
    status,
    source,
    ticker,
    childOrders,
    averagePrice,
    currency,
  } = order;

  const isCanceled = status === OrderStatus.CANCELLED;
  const isCaucion = ticker === SpecialTickers.caucion;
  const isOpenMep = ticker === SpecialTickers.openMep;
  const isPending = status === OrderStatus.PENDING;
  const isBid = side === OperationSide.BID;

  const getLogoIcon = (logo?: string) => {
    if (childOrders) {
      return <USFlag />;
    }
    if (logo) {
      return <img src={`${CDN_URL}/logos/${logo}`} />;
    }

    return <ARGFlag />;
  };

  const displayedPrice = averagePrice || price;

  const shouldDisplayPrice =
    displayedPrice && !isBid && !isOpenMep && !isPending && !isCanceled;

  const getDisplayedQuantity = () => {
    if (!quantity || isCaucion || isBid || isOpenMep) {
      return "--";
    }

    const formatedExecuted = formatToCurrency({
      value: Math.abs(quantity?.executed),
      splitIfInteger: true,
    });
    const formatedTotal = formatToCurrency({
      value: Math.abs(quantity?.total),
      splitIfInteger: true,
    });

    return `${formatedExecuted}/${formatedTotal}`;
  };

  return (
    <div
      onClick={() => onClick(orderId)}
      className={classNames(
        styles.desktopTableRow,
        styles.desktopTableRowData,
        { [styles.lastRow]: lastRow }
      )}
    >
      <Text variant={TextVariants.RegularTextS} color="var(--slate900)">
        {formatLocalDate(createdAt, true)}
      </Text>
      <div className={styles.imageRow}>
        {getOrderStatusMap(status).icon}
        <Text variant={TextVariants.RegularTextS} color="var(--slate900)">
          {getOrderStatusMap(status).text}
        </Text>
      </div>
      <div className={styles.imageRow}>
        <StatusIcon icon={getSmallIcon(side, source)} />
        <Text variant={TextVariants.RegularTextS} color="var(--slate900)">
          {getOperationSideHeading(side)}
        </Text>
      </div>
      <div className={styles.imageRow}>
        <div className={styles.imgContainer}>{getLogoIcon(logo)}</div>
        <Text variant={TextVariants.RegularTextS} color="var(--slate900)">
          {getTickerLabel(ticker)}
        </Text>
      </div>
      <div className={styles.alignRight}>
        <Text variant={TextVariants.SemiboldTextS} color="var(--slate900)">
          {getDisplayedQuantity()}
        </Text>
      </div>
      <div className={styles.alignRight}>
        {shouldDisplayPrice ? (
          <PriceLabel
            currency={currency}
            price={displayedPrice}
            size={PriceLabelSizes.Xsmall}
          />
        ) : (
          <Text variant={TextVariants.RegularTextS} color="var(--slate900)">
            --
          </Text>
        )}
      </div>
      <div className={styles.alignRight}>
        <PriceLabel
          price={amount}
          currency={currency}
          size={PriceLabelSizes.Xsmall}
        />
      </div>
    </div>
  );
};

export default OrderTableRow;
