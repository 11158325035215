import React from "react";

import { ZendeskChat } from "components/common/zendesk";
import CocosGoldPage from "components/page-cocos-gold";
import { useProfile } from "context/ProfileProvider";
import { PROFILE_SECTIONS } from "interfaces/profile";
import CommonLayout from "layouts/common";

import ProfileConfig from "../page-config";
import ProfileHelp from "../page-help";
import ProfileLearn from "../page-learn";
import ProfileLegals from "../page-legals";
import { PersonalDataPage } from "../page-personal-data";
import ProfileSecurity from "../page-security";
import ProfileMenu from "./Menu";
import ProfileSectionPreview from "./SectionPreview";

import styles from "./styles.module.scss";

const ProfileDesktop: React.FC = () => {
  const {
    selectedSection,
    sections,
    breadcrumbList,
    onClickBreadcrumb,
  } = useProfile();

  const getRender = (title: PROFILE_SECTIONS) => {
    const profileComponents: { [key in string]: React.ReactNode } = {
      [PROFILE_SECTIONS.GOLD]: <CocosGoldPage />,
      [PROFILE_SECTIONS.LEGALS]: <ProfileLegals />,
      [PROFILE_SECTIONS.CONFIG]: <ProfileConfig />,
      [PROFILE_SECTIONS.SECURITY]: <ProfileSecurity />,
      [PROFILE_SECTIONS.LEARN]: <ProfileLearn />,
      [PROFILE_SECTIONS.HELP]: <ProfileHelp />,
      [PROFILE_SECTIONS.PERSONAL_DATA]: <PersonalDataPage />,
    };

    return profileComponents[title];
  };

  return (
    <CommonLayout
      mobileHeaderTitle=""
      classes={styles.profile}
      withHeader={false}
    >
      <ProfileMenu options={sections} />
      <ProfileSectionPreview
        title={selectedSection.title}
        detail={selectedSection.detail}
        subtitleList={breadcrumbList}
        onClickBreadcrumb={onClickBreadcrumb}
      >
        {getRender(selectedSection.title)}
      </ProfileSectionPreview>
      <ZendeskChat defer zendeskKey={import.meta.env.VITE_ZENDESK_API_KEY} />
    </CommonLayout>
  );
};

export default ProfileDesktop;
