import { CDN_URL } from "constants/index";

import type { ReactNode } from "react";

import PriceLabel from "components/common/PriceLabel";
import Text from "components/common/Text";
import USFlag from "assets/icons/USFlag";
import ARGFlag from "assets/icons/ARGFlag";
import { ArrowRight } from "lucide-react";
import type { OrderDetailType } from "interfaces/orders/interface";
import { Currencies } from "interfaces/wallet";
import { OperationSide } from "interfaces/orders/enums";
import { formatToCurrency } from "utils";
import { SpecialTickers } from "utils/constants";

import { getTickerLabel } from "../../utils";

import styles from "./styles.module.scss";

interface OrderDetailHeaderProps {
  orderDetail: OrderDetailType;
  isMepDetail: boolean;
}

const OrderDetailHeader = ({
  orderDetail,
  isMepDetail,
}: OrderDetailHeaderProps) => {
  const {
    amount,
    quantity,
    currency,
    ticker,
    side,
    childOrders,
    logo,
    usdAmount,
  } = orderDetail;

  const ICON_SIZE = 32;
  const logoIcon =
    currency === Currencies.ARS ? (
      <ARGFlag size={ICON_SIZE} />
    ) : (
      <USFlag size={ICON_SIZE} />
    );

  const isMepOrder = !!childOrders;
  const isBuy =
    side === OperationSide.BUY ||
    side === OperationSide.SUBSCRIPTION ||
    side === OperationSide.BID;
  const isOpenMep = ticker === SpecialTickers.openMep;
  const isCaucion = ticker === SpecialTickers.caucion;
  const isWithdraw = side === OperationSide.WITHDRAWAL;

  const getOrderIcons = (logo?: string): ReactNode[] => {
    const mepIcons = [
      <ARGFlag key="arg-flag" size={ICON_SIZE} />,
      <USFlag key="us-flag" size={ICON_SIZE} />,
    ];

    if (isMepOrder || isMepDetail) {
      return isBuy ? mepIcons : mepIcons.reverse();
    }

    const baseIcons: ReactNode[] = [
      <div key="currency-icon" className={styles.detailOrderLogoImg}>
        {logoIcon}
      </div>,
      <div key="instrument-logo" className={styles.detailOrderLogoIcon}>
        {logo ? (
          <img src={`${CDN_URL}/logos/${logo}`} alt="instrument logo" />
        ) : (
          <ARGFlag key="arg-flag" size={ICON_SIZE} />
        )}
      </div>,
    ];

    return isBuy ? baseIcons : baseIcons.reverse();
  };

  const detailText = `${
    quantity?.total && !isOpenMep
      ? `${formatToCurrency({ value: quantity?.total, splitIfInteger: true })} `
      : ""
  }${getTickerLabel(ticker)}`;

  return (
    <div className={styles.orderDetailHeaderWrapper}>
      <div className={styles.arrowIcons}>
        {getOrderIcons(logo)[0]}
        <ArrowRight size={16} color="var(--slate800)" />
        {getOrderIcons(logo)[1]}
      </div>
      <div className={styles.orderPriceLabel}>
        <PriceLabel price={amount} currency={currency} />
        {!isCaucion && !isWithdraw && (
          <Text color="var(--slate800)">
            {!isBuy && "-"}
            {isMepOrder && usdAmount
              ? `US$${formatToCurrency({ value: Math.abs(usdAmount) })}`
              : detailText}
          </Text>
        )}
      </div>
    </div>
  );
};

export default OrderDetailHeader;
