import { CocosFunds } from "constants/instruments";

import Divider from "components/common/Divider";
import Heading, { HeadingVariants } from "components/common/Heading";
import { Currencies } from "interfaces/wallet";
import MovementHeader from "components/common/MovementHeader";
import { useAppState } from "context/AppStateProvider";
import ActivityCard from "components/common/ActivityList/ActivityCard";
import { ThemeVariants } from "interfaces/theme";
import { MovementStatus } from "interfaces/api-responses";
import CocosBrand from "assets/icons/CocosBrand";
import px2rem from "utils/px2rem";
import { getFCIIconColor } from "components/page-cocos-pay/utils";
import { FundsDailyMovements } from "interfaces/fci";

import styles from "./styles.module.scss";

interface FundsMovementsProps {
  dailyMovements: FundsDailyMovements[];
}

const FundsMovements = ({ dailyMovements }: FundsMovementsProps) => {
  const { isBalanceHidden } = useAppState();

  return (
    <div className={styles.activityWrapper}>
      <Heading
        variant={HeadingVariants.RegularTitle}
        component="h2"
        color="var(--slate800)"
      >
        Actividad
      </Heading>
      {dailyMovements.map(({ date, amount, movements }) => (
        <>
          <MovementHeader
            key={date}
            date={date}
            text="Total invertido"
            currency={amount.currency}
            amount={amount.value}
            isBalanceHidden={isBalanceHidden}
            className={styles.movementHeaderRow}
          />
          <div className={styles.fundMovementRow}>
            {movements.map(({ amount, ticker, operation, quantity }, index) => (
              <>
                <ActivityCard
                  key={index}
                  isBalanceHidden={isBalanceHidden}
                  currency={amount.currency}
                  operation={operation}
                  variant={ThemeVariants.Capital}
                  quoteTicker={ticker}
                  baseTicker={ticker}
                  capitalIcon={
                    <CocosBrand
                      color={getFCIIconColor(
                        amount.currency === Currencies.ARS
                          ? CocosFunds.RMA
                          : CocosFunds.USD
                      )}
                      size={px2rem(24)}
                    />
                  }
                  baseQuantity={amount.value}
                  quoteQuantity={Math.abs(quantity)}
                  status={MovementStatus.COMPLETED}
                  onClick={() => console.log("click")}
                />
                {index !== movements.length - 1 && (
                  <Divider className={styles.fundsDivider} />
                )}
              </>
            ))}
          </div>
        </>
      ))}
    </div>
  );
};

export default FundsMovements;
