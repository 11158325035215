import { useState } from "react";

import Text from "components/common/Text";
import ActivityProvider from "context/ActivityProvider";
import CommonLayout from "layouts/common";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import classNames from "classnames";

import { ActivityTabs } from "./constants";
import Header from "./Header";
import Orders from "./Orders";

import styles from "./styles.module.scss";

const ActivityPage: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<ActivityTabs>(
    ActivityTabs.ORDERS
  );
  const { isDesktop } = useDeviceCheck();

  return (
    <CommonLayout>
      <div className={classNames({ [styles.desktopWrapper]: isDesktop })}>
        <Header selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        <div>
          {selectedTab === ActivityTabs.MOVEMENTS && <Text>Movements Tab</Text>}
          {selectedTab === ActivityTabs.ORDERS && (
            <ActivityProvider>
              <Orders />
            </ActivityProvider>
          )}
        </div>
      </div>
    </CommonLayout>
  );
};

export default ActivityPage;
