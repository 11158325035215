import { ReactNode } from "react";

import { Plus } from "lucide-react";
import { ThemeVariants } from "interfaces/theme";
import classNames from "classnames";
import { useAppState } from "context/AppStateProvider";
import { useCocosCard } from "context/CocosCardProvider";
import { useAppSelector } from "hooks/redux";
import { RootState } from "store/store";
import LoadingSpinner from "components/common/LoadingSpinner";
import HiddenValue from "components/common/HiddenValue";
import Text, { TextVariants } from "components/common/Text";
import { formatToCurrency } from "utils";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

type PaymentMethodProps = {
  isEmpty?: boolean;
  isDisabled?: boolean;
} & {
  amount?: number;
  icon?: ReactNode;
  position?: number;
  onClick?: () => void;
};

const PaymentMethod: React.FC<PaymentMethodProps> = ({
  icon,
  amount,
  position,
  isEmpty,
  onClick,
  isDisabled,
}) => {
  const { isBalanceHidden } = useAppState();
  const isDarkMode = useAppSelector(
    (state: RootState) => state.user.user?.dark_theme
  );
  const { isLoading } = useCocosCard();

  const PaymentMethodCurrency = isEmpty ? (
    <Text variant={TextVariants.SemiboldTextXS} color="var(--slate800)">
      Agregar
    </Text>
  ) : (
    <>
      <Text variant={TextVariants.RegularValueXS} color="var(--slate800)">
        $
      </Text>
      <Text variant={TextVariants.SemiboldValueXS} color="var(--slate900)">
        {isBalanceHidden ? (
          <HiddenValue
            className={styles.hiddenValue}
            size={px2rem(4)}
            color="var(--slate900)"
          />
        ) : (
          formatToCurrency({ value: amount, withoutDecimals: true })
        )}
      </Text>
    </>
  );

  return (
    <div
      className={classNames(styles.paymentMethodContainer, {
        [styles.disabled]: isDisabled,
      })}
      onClick={onClick}
    >
      <div
        className={classNames(styles.iconWrapper, {
          [styles.dark]: isDarkMode,
        })}
        style={{ padding: isLoading ? 0 : px2rem(8) }}
      >
        {isLoading ? (
          <LoadingSpinner size={44} variant={ThemeVariants.Pay} />
        ) : isEmpty ? (
          <Plus size={24} color="var(--slate600)" />
        ) : (
          icon
        )}
        <div className={styles.position}>
          <Text
            variant={TextVariants.SemiboldValueXS}
            color="var(--white-to-black)"
          >
            {position}
          </Text>
        </div>
      </div>
      <div className={styles.line} />
      <div className={styles.amount}>
        {isLoading ? "-" : PaymentMethodCurrency}
      </div>
    </div>
  );
};

export default PaymentMethod;
