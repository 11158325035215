import Text, { TextVariants } from "components/common/Text";
import dayjs from "dayjs";
import { BondFlow, FlowAnalysis } from "interfaces/markets";
import { formatToCurrency, getCurrencyLabel } from "utils";

import styles from "./styles.module.scss";

interface BondTicketBottomProps {
  analysis: FlowAnalysis;
}

const BondTicketBottom: React.FC<BondTicketBottomProps> = ({ analysis }) => {
  const getTotal = () => {
    let total = 0;

    analysis.flow.map((row: BondFlow, index: number) => {
      if (index > 0) {
        total += row.cashFlow;
      }
    });
    const receive = `${getCurrencyLabel(
      analysis.flow[1].id_currency
    )} ${formatToCurrency({ value: total })}`;

    return receive;
  };
  return (
    <div className={styles.bottomCard}>
      <div className={styles.bottomCardRow}>
        <Text variant={TextVariants.RegularText}>Inversión inicial</Text>
        <Text variant={TextVariants.RegularText}>
          ${formatToCurrency({ value: analysis.price })}
        </Text>
      </div>
      <div className={styles.bottomCardRow}>
        <Text variant={TextVariants.RegularText}>TIR</Text>
        <Text variant={TextVariants.RegularText}>
          {formatToCurrency({ value: analysis.tir })}%
        </Text>
      </div>
      <div className={styles.bottomCardRow}>
        <Text variant={TextVariants.RegularText}>Duración</Text>
        <Text variant={TextVariants.RegularText}>
          {formatToCurrency({ value: analysis.duration })}
        </Text>
      </div>
      <div className={styles.bottomCardRow}>
        <Text variant={TextVariants.RegularText}>Fecha de liquidación</Text>
        <Text variant={TextVariants.RegularText}>
          {dayjs(analysis.expiration_date).format("DD-MM-YYYY")}
        </Text>
      </div>
      <div className={styles.bottomCardRow}>
        <Text variant={TextVariants.RegularText}>Total a recibir</Text>
        <Text variant={TextVariants.RegularText} className={styles.rowValue}>
          {getTotal()}
        </Text>
      </div>
    </div>
  );
};

export default BondTicketBottom;
