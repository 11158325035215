import { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import BondCalculator from "components/common/BondCalculator";
import CommonLayout from "layouts/common";
import { getMarketsTypesLists } from "features/markets/marketsActions";
import { RootState } from "store/store";
import { DesktopOrTablet, Mobile } from "utils/responsive";
import { Instruments, SettlementTerms } from "interfaces/markets";
import mainRoutes from "router/routes";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import "./index.scss";

const CalculadoraPage = () => {
  const dispatch = useAppDispatch();
  const bonoInicial = useAppSelector(
    (state: RootState) => state.markets.marketDetail
  );

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      getMarketsTypesLists({
        type: bonoInicial
          ? bonoInicial.instrument_type
          : Instruments.BONOS_PUBLICOS,
        currency: undefined,
        settlement_days: SettlementTerms._24,
        subtype: bonoInicial
          ? bonoInicial.instrument_type === Instruments.BONOS_PUBLICOS
            ? "BONOSP"
            : "BONOSC"
          : "BONOSP",
      })
    );
  }, []);

  return (
    <AnimatePresence>
      <motion.div
        className="page-calculadora"
        layoutId={"cal"}
        transition={{ duration: 0.1 }}
      >
        <CommonLayout
          hideBottomNavigation
          mobileHeaderTitle="Calculadora"
          withHeader
          goBack={() => navigate(mainRoutes.market)}
        >
          <Mobile>
            <BondCalculator />
          </Mobile>
          <DesktopOrTablet>
            <BondCalculator />
          </DesktopOrTablet>
        </CommonLayout>
      </motion.div>
    </AnimatePresence>
  );
};

export default CalculadoraPage;
