import { useEffect, useState } from "react";

import { Capacitor } from "@capacitor/core";
import { CapacitorUpdater } from "@capgo/capacitor-updater";
import CocosCapFullIcon from "assets/icons/CocosCapitalIcon";
import CocosGoldBanner from "components/common/CocosGoldBanner";
import PageHeader from "components/common/PageHeader";
import Text, { TextVariants } from "components/common/Text";
import { useProfile } from "context/ProfileProvider";
import { MOBILE_SUBSECTIONS, ProfileSection } from "interfaces/profile";
import { LogOut, X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import px2rem from "utils/px2rem";

import ProfileCard from "../components/ProfileCard";
import ProfileList from "../components/ProfileList";
import SectionList from "../components/SectionTemplate/SectionList";

import styles from "./styles.module.scss";

const ProfileMobile: React.FC = () => {
  const navigate = useNavigate();
  const { sections } = useProfile();

  const [version, setVersion] = useState<string>();

  const [accountOptions, helpOptions] = sections.reduce<
    [ProfileSection[], ProfileSection[]]
  >(
    (acc, item) => {
      item.subSection === MOBILE_SUBSECTIONS.ACCOUNT && acc[0].push(item);
      item.subSection === MOBILE_SUBSECTIONS.HELP && acc[1].push(item);
      return acc;
    },
    [[], []]
  );

  const sessionOptions = [
    {
      icon: LogOut,
      iconColor: "var(--blue900)",
      label: "Cerrar sesión",
      onClick: () => navigate(mainRoutes.logout),
    },
  ];

  useEffect(() => {
    const checkVersion = async () => {
      if (!Capacitor.isNativePlatform()) return;
      setVersion((await CapacitorUpdater.current()).bundle.version);
    };

    checkVersion();
  }, []);

  return (
    <div className={styles.profile}>
      <div className={styles.wrapper}>
        <PageHeader
          icon={X}
          iconColor="var(--blue900)"
          onClick={() => navigate(-1)}
        />
        <ProfileCard />
        <CocosGoldBanner />
      </div>

      <ProfileList
        title={MOBILE_SUBSECTIONS.ACCOUNT}
        options={accountOptions}
      />
      <ProfileList title={MOBILE_SUBSECTIONS.HELP} options={helpOptions} />
      <SectionList
        title={MOBILE_SUBSECTIONS.SESSION}
        options={sessionOptions}
      />
      <div className={styles.brand}>
        <CocosCapFullIcon
          color="var(--slate500)"
          width={px2rem(80)}
          height={px2rem(56)}
        />
        {version && (
          <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
            Versión {version}
          </Text>
        )}
      </div>
    </div>
  );
};

export default ProfileMobile;
