import ff from "constants/featureFlags";

import { useState } from "react";

import { FileCheck2 } from "lucide-react";
import useFeatureFlag from "hooks/useFeatureFlag";
import { ThemeVariants } from "interfaces/theme";
import { CardSections } from "interfaces/card/enums";
import { useCocosCard } from "context/CocosCardProvider";
import OutOfStock from "components/page-cocos-card/CardSelection/OutOfStock";
import Heading, { HeadingVariants } from "components/common/Heading";
import FilledButton from "components/common/FilledButton";

import ShippingDescription from "./ShippingDescription";
import InfoRow from "../../components/InfoRow";

import styles from "./styles.module.scss";

const PhysicalEmptyContent: React.FC = () => {
  const { setSelectedSection } = useCocosCard();

  const { isFeatureEnabled: isCardOutOfStock } = useFeatureFlag(
    ff.ENABLE_CARD_OUT_OF_STOCK
  );

  const [isOutOfStockOpen, setIsOutOfStockOpen] = useState<boolean>(false);

  const onClickContinue = () => {
    if (isCardOutOfStock) {
      return setIsOutOfStockOpen(true);
    }

    return setSelectedSection(CardSections.PHYSICAL_INTRO);
  };

  return (
    <div className={styles.emptyCardContent}>
      <Heading
        variant={HeadingVariants.RegularTitle}
        color="var(--slate900)"
        component="h1"
        className={styles.titleHeader}
      >
        Para hacerlo vas a tener que:
      </Heading>
      <div>
        <InfoRow
          icon={FileCheck2}
          title="Completar los datos del envío"
          subtitle="Necesitamos conocer tu dirección fiscal para poder enviarte tu tarjeta física."
        />
        <ShippingDescription />
      </div>
      <FilledButton variant={ThemeVariants.Pay} onClick={onClickContinue}>
        Empezar
      </FilledButton>

      {isOutOfStockOpen && (
        <OutOfStock
          open={isOutOfStockOpen}
          onClose={() => setIsOutOfStockOpen(false)}
          onContinue={() => setIsOutOfStockOpen(false)}
          isEmptyCard
        />
      )}
    </div>
  );
};

export default PhysicalEmptyContent;
