import { IconProps } from "../../BTC";

const UNIIcon: React.FC<IconProps> = ({ size = 24, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M14.8999 0.359116L14.8999 0.359131L14.8999 0.359116ZM14.8999 0.359116L14.8999 0.359097M14.8999 0.359116L14.8999 0.359097M14.8999 0.359097L14.8999 0.359081L14.8999 0.359097ZM23.25 14.8062L23.236 14.8624C21.6629 21.0432 15.3881 24.7976 9.19181 23.2526C2.97948 21.7034 -0.802433 15.4089 0.747491 9.19518L0.747515 9.19508C2.29609 2.9801 8.59015 -0.802028 14.8032 0.747197L14.8032 0.747203C21.0177 2.29644 24.7994 8.59158 23.25 14.8062Z"
      fill="url(#paint0_linear_4200_2000)"
      stroke="#FFD9EC"
      strokeWidth="0.8"
    />
    <path
      d="M10.1061 7.41156C9.94361 7.38835 9.93588 7.38061 10.0132 7.37288C10.1602 7.34967 10.5006 7.38061 10.7404 7.43477C11.2974 7.56628 11.8002 7.90666 12.334 8.50233L12.4733 8.66478L12.6744 8.63384C13.5331 8.49459 14.415 8.60289 15.1499 8.94327C15.351 9.03611 15.6682 9.22177 15.7069 9.26818C15.7224 9.28366 15.7456 9.38422 15.761 9.48479C15.8152 9.84838 15.792 10.1191 15.6759 10.328C15.6141 10.444 15.6141 10.475 15.6527 10.5756C15.6837 10.6529 15.7765 10.7071 15.8616 10.7071C16.0473 10.7071 16.2407 10.4131 16.3335 10.0031L16.3722 9.84064L16.4418 9.918C16.8363 10.359 17.1458 10.9701 17.1922 11.4033L17.2077 11.5193L17.138 11.4188C17.022 11.2409 16.9137 11.1248 16.7667 11.0242C16.5037 10.8463 16.2252 10.7922 15.4903 10.7535C14.825 10.7148 14.4459 10.6607 14.0746 10.5369C13.4403 10.328 13.1154 10.0573 12.365 9.05931C12.0323 8.61837 11.8234 8.37855 11.6146 8.17742C11.1582 7.73647 10.7017 7.50439 10.1061 7.41156Z"
      fill="#FF007A"
    />
    <path
      d="M15.8694 8.39402C15.8848 8.10005 15.9235 7.90665 16.0086 7.72872C16.0395 7.6591 16.0705 7.59721 16.0782 7.59721C16.086 7.59721 16.0705 7.65137 16.0473 7.71325C15.9854 7.88344 15.9777 8.12326 16.0163 8.39402C16.0705 8.74213 16.0937 8.78855 16.465 9.16761C16.6352 9.34554 16.8364 9.56988 16.9137 9.66271L17.0452 9.8329L16.9137 9.70913C16.7513 9.55441 16.3799 9.26044 16.2948 9.22176C16.2407 9.19082 16.2329 9.19082 16.1943 9.2295C16.1633 9.26044 16.1556 9.30686 16.1556 9.5312C16.1479 9.87932 16.1014 10.0959 15.9854 10.3203C15.9235 10.4363 15.9158 10.4131 15.9699 10.2816C16.0086 10.181 16.0163 10.1346 16.0163 9.80196C16.0163 9.12893 15.939 8.96648 15.4671 8.69572C15.351 8.62609 15.1499 8.52553 15.0339 8.47138C14.9101 8.41722 14.8173 8.37081 14.825 8.37081C14.8405 8.35534 15.2969 8.48685 15.4748 8.56421C15.7456 8.67251 15.792 8.68025 15.8229 8.67251C15.8461 8.6493 15.8616 8.58741 15.8694 8.39402Z"
      fill="#FF007A"
    />
    <path
      d="M10.4232 9.54668C10.0983 9.09799 9.88947 8.40176 9.93588 7.88345L9.95135 7.72099L10.0287 7.73646C10.168 7.75967 10.4078 7.8525 10.5238 7.92213C10.8333 8.10779 10.9725 8.36308 11.104 8.99742C11.1427 9.18309 11.1968 9.39969 11.22 9.46932C11.2587 9.58536 11.4057 9.85611 11.5295 10.0263C11.6146 10.1501 11.5604 10.212 11.367 10.1965C11.0731 10.1656 10.6785 9.89479 10.4232 9.54668Z"
      fill="#FF007A"
    />
    <path
      d="M15.4748 12.9118C13.9431 12.2929 13.4016 11.7591 13.4016 10.854C13.4016 10.7225 13.4093 10.6142 13.4093 10.6142C13.4171 10.6142 13.4712 10.6606 13.5408 10.7148C13.8503 10.9623 14.1984 11.0706 15.1654 11.2099C15.7301 11.295 16.055 11.3569 16.349 11.4574C17.285 11.7669 17.8652 12.4012 18.0045 13.2599C18.0431 13.5075 18.0199 13.9794 17.9581 14.2269C17.9039 14.4203 17.7492 14.7762 17.7105 14.7839C17.7028 14.7839 17.6873 14.7452 17.6873 14.6833C17.6718 14.3584 17.5094 14.049 17.2386 13.8092C16.9137 13.5307 16.496 13.3218 15.4748 12.9118Z"
      fill="#FF007A"
    />
    <path
      d="M14.3918 13.1671C14.3763 13.0511 14.3376 12.9041 14.3144 12.8422L14.2758 12.7262L14.3454 12.8112C14.4459 12.9273 14.5233 13.0665 14.5929 13.2599C14.6471 13.4069 14.6471 13.4533 14.6471 13.6931C14.6471 13.9252 14.6393 13.9794 14.5929 14.1109C14.5156 14.3198 14.4227 14.4667 14.268 14.6292C13.9895 14.9154 13.6259 15.0701 13.1076 15.1398C13.0148 15.1475 12.7518 15.1707 12.5197 15.1862C11.9395 15.2171 11.5527 15.279 11.2046 15.4028C11.1582 15.4183 11.1117 15.4337 11.104 15.426C11.0885 15.4105 11.3284 15.2713 11.5218 15.1784C11.7925 15.0469 12.071 14.9773 12.6821 14.869C12.9838 14.8226 13.2933 14.7607 13.3706 14.7298C14.1365 14.4899 14.5156 13.8943 14.3918 13.1671Z"
      fill="#FF007A"
    />
    <path
      d="M15.0957 14.4126C14.8946 13.9716 14.8482 13.5539 14.9565 13.1594C14.972 13.1207 14.9874 13.082 15.0029 13.082C15.0184 13.082 15.0648 13.1052 15.1112 13.1362C15.2041 13.198 15.3975 13.3063 15.8926 13.5771C16.5192 13.9175 16.875 14.1805 17.1226 14.4822C17.3392 14.7452 17.4707 15.0469 17.5326 15.4183C17.5713 15.6271 17.548 16.13 17.4939 16.3388C17.3237 16.9964 16.9369 17.5224 16.3722 17.8241C16.2871 17.8706 16.2175 17.9015 16.2097 17.9015C16.202 17.9015 16.2329 17.8241 16.2793 17.7313C16.465 17.3368 16.4882 16.9577 16.349 16.5322C16.2639 16.2692 16.086 15.952 15.7301 15.4183C15.3046 14.7994 15.2041 14.6369 15.0957 14.4126Z"
      fill="#FF007A"
    />
    <path
      d="M9.33247 16.7798C9.90493 16.3002 10.6089 15.9598 11.2587 15.8515C11.5372 15.8051 12.0014 15.8205 12.2567 15.8901C12.6667 15.9985 13.038 16.2305 13.2314 16.5168C13.417 16.7953 13.5021 17.0351 13.5872 17.5688C13.6182 17.7777 13.6569 17.9943 13.6646 18.0407C13.7265 18.3192 13.8503 18.5358 14.005 18.6519C14.2448 18.8298 14.6625 18.8375 15.0725 18.6828C15.1422 18.6596 15.2041 18.6364 15.2041 18.6441C15.2195 18.6596 15.0107 18.7989 14.8714 18.8685C14.678 18.9691 14.5233 19 14.3144 19C13.9431 19 13.6259 18.8066 13.3706 18.4198C13.3165 18.3424 13.2082 18.1181 13.1153 17.9092C12.8446 17.2826 12.7053 17.097 12.3882 16.8881C12.1097 16.7102 11.7538 16.6715 11.4831 16.803C11.1272 16.9732 11.0344 17.4296 11.2819 17.7081C11.3825 17.8241 11.5682 17.917 11.7229 17.9402C12.0091 17.9789 12.2567 17.7545 12.2567 17.4683C12.2567 17.2826 12.187 17.1743 12.0014 17.0892C11.7538 16.9809 11.4831 17.1047 11.4908 17.3445C11.4908 17.4451 11.5372 17.507 11.6378 17.5534C11.6997 17.5843 11.6997 17.5843 11.6533 17.5766C11.4289 17.5302 11.3748 17.2517 11.5527 17.0737C11.7693 16.8571 12.2257 16.95 12.3804 17.2517C12.4423 17.3754 12.4501 17.623 12.3959 17.7777C12.2644 18.1181 11.8931 18.296 11.514 18.1955C11.2587 18.1258 11.1504 18.0562 10.841 17.739C10.2995 17.1821 10.0906 17.0737 9.317 16.9577L9.17001 16.9345L9.33247 16.7798Z"
      fill="#FF007A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.26337 4.33265C7.06584 6.52192 9.84305 9.92573 9.98229 10.1114C10.0983 10.2661 10.0519 10.4131 9.85852 10.5214C9.75022 10.5833 9.52587 10.6452 9.41757 10.6452C9.29379 10.6452 9.14681 10.5833 9.04624 10.4827C8.97662 10.4131 8.67492 9.97215 7.99416 8.91233C7.47585 8.10005 7.0349 7.42702 7.02716 7.41929C6.99622 7.40382 6.99622 7.40382 7.94 9.09025C8.53567 10.1501 8.72907 10.5291 8.72907 10.5756C8.72907 10.6761 8.69813 10.7303 8.57435 10.8695C8.36548 11.1016 8.27265 11.3646 8.20303 11.9139C8.12567 12.525 7.9168 12.9582 7.32113 13.6931C6.97301 14.1264 6.91886 14.2037 6.83376 14.3816C6.72546 14.5982 6.69452 14.722 6.67904 15.0005C6.66357 15.2945 6.69452 15.4801 6.77961 15.7586C6.85697 16.0062 6.94207 16.1686 7.15094 16.4858C7.32886 16.7643 7.43717 16.9732 7.43717 17.0505C7.43717 17.1124 7.45264 17.1124 7.73113 17.0505C8.39642 16.8958 8.94568 16.6328 9.24738 16.3079C9.43304 16.1068 9.47946 15.9985 9.47946 15.72C9.47946 15.542 9.47172 15.5034 9.42531 15.395C9.34795 15.2249 9.20096 15.0856 8.88379 14.869C8.46605 14.5828 8.28812 14.3507 8.24171 14.0413C8.20303 13.7782 8.24944 13.6003 8.48152 13.1129C8.72133 12.6101 8.78322 12.4012 8.8219 11.8907C8.84511 11.5658 8.88379 11.4342 8.97662 11.3337C9.07719 11.2254 9.16228 11.1867 9.4021 11.1558C9.79663 11.1016 10.0519 11.001 10.2531 10.8076C10.431 10.6452 10.5083 10.4827 10.5161 10.2429L10.5238 10.065L10.4232 9.95668C10.0597 9.53894 5.02355 4 5.00034 4C4.99261 4 5.11638 4.14698 5.26337 4.33265ZM7.6383 15.3332C7.7234 15.1862 7.67698 15.0005 7.53774 14.9077C7.40622 14.8226 7.20509 14.8613 7.20509 14.9773C7.20509 15.0083 7.22056 15.0392 7.26698 15.0547C7.3366 15.0933 7.34434 15.132 7.29019 15.2171C7.23603 15.3022 7.23603 15.3796 7.30566 15.4337C7.41396 15.5188 7.56094 15.4724 7.6383 15.3332Z"
      fill="#FF007A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7714 11.2641C10.5857 11.3182 10.4078 11.5193 10.3536 11.7205C10.3227 11.8443 10.3381 12.0686 10.3923 12.1382C10.4774 12.2465 10.5548 12.2775 10.7714 12.2775C11.1968 12.2775 11.5604 12.0918 11.5991 11.8675C11.6378 11.6818 11.4753 11.4265 11.251 11.3105C11.1349 11.2486 10.8951 11.2254 10.7714 11.2641ZM11.2664 11.6508C11.3283 11.558 11.3051 11.4574 11.1891 11.3878C10.9802 11.2563 10.663 11.3646 10.663 11.5658C10.663 11.6663 10.8255 11.7746 10.9802 11.7746C11.0808 11.7746 11.22 11.7127 11.2664 11.6508Z"
      fill="#FF007A"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4200_2000"
        x1="0"
        y1="0"
        x2="0"
        y2="24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FEF3F9" />
        <stop offset="1" stopColor="#FDE5F2" />
      </linearGradient>
    </defs>
  </svg>
);

export default UNIIcon;
