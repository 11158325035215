import { Currencies, BalanceCurrencies } from "interfaces/wallet";

interface ActiveBidType {
  bannerTitle: string;
  long_ticker: string;
  price: number;
  tir: number;
  monto_minimo: number;
  name: string;
  lamina_minima: number;
  lamina_incremental: number;
  logo_file_name: string;
  instrument_code: string;
  expiration: string;
  settlement_date: string;
  settlement_days: string;
  settlement: string;
  tasa: number;
  amort_title: string;
  currency: BalanceCurrencies;
}

export const ACTIVE_BID: ActiveBidType = {
  bannerTitle: "Licitación River Plate",
  long_ticker: "CARPS1-0002-C-CT-ARS",
  price: 1,
  tir: 32.5,
  monto_minimo: 10000,
  name: "ON CARP Serie 1",
  lamina_minima: 0,
  lamina_incremental: 1,
  logo_file_name: "",
  instrument_code: "CARPS1",
  expiration: "Febrero 2027",
  settlement_date: "27/02/2025",
  settlement_days: "48hs",
  settlement: "24 meses",
  tasa: 32.5,
  amort_title: "Amortización",
  currency: Currencies.ARS,
};
