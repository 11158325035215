import API from "apis";
import { useAppQuery } from "apis/queryClient";
import { marketKeys } from "apis/queryKeyFactories";
import { marketService } from "apis/services";
import { MarketData } from "interfaces/api-responses";
import { Sections, SettlementTerms } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";

const EIGHT_HOURS = 8 * 60 * 60 * 1000;

export const fetchHomeInstruments = async () => {
  const { data } = await API.get<MarketData>(marketService.tickers, {
    params: {
      currency: Currencies.ARS,
      instrument_type: Sections.HOME,
      instrumnet_subtype: Sections.HOME,
      settlement_days: SettlementTerms._24,
      segment: "C",
    },
  });

  return data.items;
};

export const useHomeTickersQuery = () => {
  return useAppQuery({
    options: {
      queryKey: marketKeys.homeTickers,
      queryFn: () => fetchHomeInstruments(),
      staleTime: Infinity,
      gcTime: EIGHT_HOURS,
    },
  });
};
