import { CocosFunds } from "constants/instruments";

import GoogleTagManager from "react-gtm-module";
import TradingContainer from "components/common/trading-container";
import { trackAnalyticsEvent, AnalyticsEvents } from "utils/firebaseAnalytics";
import { trackFbEvent, FbEvents } from "utils/fbTracking";
import { InstrumentData } from "interfaces/api-responses";
import { Instruments, MarketSection } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";
import LayoutV2 from "layouts/v2";
import { useNavigate } from "react-router-dom";
import {
  cleanHistoricalData,
  clearShortTickerMarketData,
  resetMarketDataDetail,
  resetOrder,
  updateOrderOptions,
} from "features/markets/marketsSlice";
import { clearSellingPower } from "features/wallet/walletSlice";
import { RootState } from "store/store";
import { getCurrentScreen } from "features/global/globalSlice";
import { getSettlementDays } from "utils";
import { trackAction } from "utils/amplitude";
import mainRoutes from "router/routes";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useAppState } from "context/AppStateProvider";
import CapitalFunds from "components/page-capital-funds";
import { openMarketDetailSidebar } from "utils/openMarketDetail";
import { isCocosFundRedesign } from "components/page-capital-funds/helpers";
import { ampli } from "ampli";

import RowsWrapper from "./Wrapper";

interface RowsProps {
  markets: InstrumentData[];
  hasCurrency?: boolean;
  selectedInstrumentType?: MarketSection;
  setSelectedMarket: (v: InstrumentData) => void;
  handleToggleDisplayModal: () => void;
}

export const getAnnualRate = (
  fci: string | undefined,
  variation: number | null | undefined
): number => {
  const ANNUAL_RATE: Record<string, number> = {
    COCOA: 0.38,
    COCOAUSD: 0.041,
    COCORMA: 0.3215,
  };

  if (fci && ANNUAL_RATE[fci] !== undefined) {
    return ANNUAL_RATE[fci];
  }

  return variation ? variation : 0;
};

const Rows: React.FC<RowsProps> = ({
  markets,
  hasCurrency,
  selectedInstrumentType,
  setSelectedMarket,
  handleToggleDisplayModal,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { openRightModalContent } = useAppState();

  const currentScreen = useAppSelector(getCurrentScreen);
  const { newOrder } = useAppSelector((state: RootState) => state.markets);

  const handleOpenMarketOptions = (market: InstrumentData) => {
    setSelectedMarket(market);
    handleToggleDisplayModal();
  };

  const redirectToTrading = (
    type: Instruments | undefined,
    code: string,
    market: InstrumentData
  ) => {
    navigate(`${mainRoutes.market}/${type}/${code}`, { state: market });
  };

  const handleClickMarketRow = (market: InstrumentData) => {
    const isFCI =
      market.instrument_type && market.instrument_type === Instruments.FCI;

    if (market.instrument_code === CocosFunds.USD) {
      GoogleTagManager.dataLayer({
        dataLayer: { event: "wapp_clic_cocoa_usd" },
      });

      trackAnalyticsEvent(AnalyticsEvents.ClIC_COCOA_USD);
      trackFbEvent(FbEvents.ClIC_COCOA_USD);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.fbq("track", "wapp_clic_cocoa_usd");
    }

    trackAction(`${currentScreen} - Select Instrument`, {
      type: market.instrument_type,
      ticker: market.short_ticker,
    });
    if (
      newOrder &&
      market.instrument_code &&
      !newOrder.instrument_code?.includes(market.instrument_code)
    ) {
      dispatch(
        resetOrder({
          instrument_code: market.instrument_code,
          price: market.last,
          long_ticker: market.long_ticker,
          term: getSettlementDays(market.settlement_days),
          currency:
            market.currency === Currencies.ARS ||
            market.currency === Currencies.USD
              ? market.currency
              : Currencies.ARS,
        })
      );
      dispatch(clearSellingPower());
      dispatch(clearShortTickerMarketData());
      dispatch(resetMarketDataDetail());
      dispatch(cleanHistoricalData());
    } else {
      dispatch(
        updateOrderOptions({
          ...newOrder,
          instrument_code: market.instrument_code,
          price: market.last,
          long_ticker: market.long_ticker,
          quantity: 0,
          amount: 0,
          term: getSettlementDays(market.settlement_days), // ci, 24 y 48
          currency:
            market.currency === Currencies.ARS ||
            market.currency === Currencies.USD
              ? market.currency
              : Currencies.ARS,
        })
      );
    }
    if (!isFCI) {
      ampli.assetScreenViewed({
        referral: "market_list",
        instrument_type: market.instrument_type || "",
      });

      openRightModalContent(
        <LayoutV2
          title={market.short_ticker ?? ""}
          withSearch
          quickOperation
          onClickExtended={() =>
            redirectToTrading(
              market.instrument_type,
              market.instrument_code ?? "",
              market
            )
          }
          instrument={market}
        >
          <TradingContainer instrumentProp={market} />
        </LayoutV2>
      );
    } else {
      if (
        market.instrument_code &&
        isCocosFundRedesign(market.instrument_code)
      ) {
        openRightModalContent(
          <CapitalFunds shortName={market.instrument_code} />
        );
      } else {
        openMarketDetailSidebar(
          market.instrument_code ?? "",
          market,
          dispatch,
          openRightModalContent
        );
      }
    }
  };

  return (
    <>
      {markets.map((marketData: InstrumentData) => {
        return (
          <RowsWrapper
            key={marketData.long_ticker}
            selectedInstrumentType={selectedInstrumentType}
            marketData={marketData}
            hasCurrency={hasCurrency}
            handleClickMarketRow={() => handleClickMarketRow(marketData)}
            handleOpenMarketOptions={() => handleOpenMarketOptions(marketData)}
          />
        );
      })}
    </>
  );
};

export default Rows;
