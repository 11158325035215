import ff from "constants/featureFlags";

import { useEffect, useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import API from "apis";
import { ArrowTopIcon } from "assets/icons/arrow-top";
import { CircleCheckIcon } from "assets/icons/circle-check";
import { ClockIcon } from "assets/icons/clock";
import { DownloadIcon } from "assets/icons/download";
import { ErrorIcon } from "assets/icons/error";
import { ShareIcon } from "assets/icons/share";
import { ACTIVE_BID } from "components/common/bid/constants";
import ButtonUI, {
  ButtonSizes,
  ButtonVariants,
} from "components/common/button";
import { Currency } from "components/common/currency";
import ErrorContainer from "components/common/error-modal";
import Success from "components/common/success";
import WithdrawError from "components/page-orders/WithdrawError";
import dayjs from "dayjs";
import * as htmlToImage from "html-to-image";
import {
  ApiOrderResponse,
  OrderDetailResponse,
  OrderStatusTypes,
} from "interfaces/api-responses";
import {
  clearOrderDetail,
  getOrders,
  setCancelOrderError,
  setCancelOrderLoading,
} from "features/markets";
import { RootState } from "store/store";
import { getCurrencyLabel, getOrderStatus, getSettlementDays } from "utils";
import { getColor } from "utils/getColor";
import mainRoutes from "router/routes";
import { ordersService } from "apis/services";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import useFeatureFlag from "hooks/useFeatureFlag";
import { Navigate } from "react-router-dom";

import "./index.scss";

interface PropTypes {
  id: string;
  onCloseSidebar: () => void;
}

export const OrderDetailSidebarDesktop = (props: PropTypes) => {
  const { onCloseSidebar } = props;
  const dispatch = useAppDispatch();
  const iconColor = getColor("--darkblue-to-light");

  const orderLoading = useAppSelector(
    (state: RootState) => state.markets.getOrderLoading
  );
  const { orderDetail } = useAppSelector((state: RootState) => state.markets);
  const orderError = useAppSelector(
    (state: RootState) => state.markets.getOrderError
  );
  const [cancelSucceeded, setCancelSucceeded] = useState<boolean | undefined>(
    undefined
  );
  const cancelOrderLoading = useAppSelector(
    (state: RootState) => state.markets.cancelOrderLoading
  );

  const { isFeatureEnabled: isActivityRedesignEnabled } = useFeatureFlag(
    ff.ENABLE_ACTIVITY_REDESIGN
  );
  const { isFeatureEnabled: isOrdersRedesignEnabled } = useFeatureFlag(
    ff.ENABLE_ORDERS_REDESIGN
  );

  const activityRoute = isActivityRedesignEnabled
    ? mainRoutes.activity
    : mainRoutes.movements;

  useEffect(() => {
    return () => {
      dispatch(clearOrderDetail());
    };
  }, []);

  const today = dayjs();
  const isOrderExecutedToday = dayjs(orderDetail?.set_date).isSame(
    today,
    "day"
  );
  const showCancelButton =
    orderDetail?.order_type !== "WITHDRAWAL" &&
    (orderDetail?.status === OrderStatusTypes.PENDING ||
      orderDetail?.status === OrderStatusTypes.PARTIALLY_EXECUTED ||
      orderDetail?.status === OrderStatusTypes.MARKET) &&
    isOrderExecutedToday;

  const cancelOrder = async (order: OrderDetailResponse) => {
    try {
      dispatch(setCancelOrderError({ message: "", status: null }));
      dispatch(setCancelOrderLoading(true));
      await API.delete<ApiOrderResponse>(
        `${ordersService.orders}/${order.order_id}`,
        {
          data: { ticker: order.ticker, instrument: order.instrument },
        }
      );

      dispatch(getOrders());
      dispatch(clearOrderDetail());
      setCancelSucceeded(true);
      dispatch(setCancelOrderLoading(false));
    } catch (error: any) {
      if (error?.response?.status !== 401) {
        dispatch(setCancelOrderError(error));
        setCancelSucceeded(false);
        dispatch(setCancelOrderLoading(false));
      }
    }
  };

  const handleCancelOrder = async () => {
    if (orderDetail) {
      cancelOrder(orderDetail);
    }
  };

  const handleClickGoBack = () => {
    dispatch(setCancelOrderError({ message: "", status: null }));
    onCloseSidebar();
  };

  const handleDownload = () => {
    const div = document.getElementById("order-container");

    if (div) {
      htmlToImage
        .toPng(div, { skipAutoScale: true, height: 500 })
        .then(function (dataUrl) {
          const link = document.createElement("a");

          link.download = `Orden ${orderDetail?.order_id} - ${today.format(
            "DD-MM-YYYY HH:mm"
          )}.png`;
          link.href = dataUrl;
          link.click();
        });
    }
  };

  const handleShare = () => {
    const div = document.getElementById("order-container");

    if (div) {
      htmlToImage
        .toBlob(div, { skipAutoScale: true, height: 500 })
        .then(async function (blob) {
          if (navigator && blob) {
            navigator.share({
              files: [
                new File(
                  [blob],
                  `Orden ${orderDetail?.order_id} - ${today.format(
                    "DD-MM-YYYY HH:mm"
                  )}.jpeg`,
                  {
                    type: "image/jpeg",
                    lastModified: new Date().getTime(),
                  }
                ),
              ],
            });
          } else if (blob) {
            navigator.clipboard.write([
              new ClipboardItem({
                [blob.type]: blob,
              }),
            ]);
          }
        });
    }
  };

  if (isOrdersRedesignEnabled) {
    return <Navigate to={`${mainRoutes.orders}?id=${props.id}`} />;
  }

  if (orderError.message && !orderLoading && !orderDetail) {
    return (
      <ErrorContainer
        textPrimaryButton="Ir a movimientos"
        title="Ups...Algo salió mal"
        urlPrimaryButton={activityRoute}
        onClickPrimaryButton={onCloseSidebar}
      />
    );
  }

  if (cancelSucceeded) {
    return (
      <Success
        subtitle="Tu orden fue cancelada con éxito, si se concretó parcialmente, vas a tener dos órdenes en tus movimientos del día."
        textPrimaryButton="Ir a Mercado"
        title="Orden cancelada con éxito"
        urlPrimaryButton={
          window.location.pathname.includes("market")
            ? undefined
            : mainRoutes.market
        }
        onClickPrimaryButton={onCloseSidebar}
      />
    );
  }

  if (orderLoading && !orderDetail) {
    return (
      <div className="comps-desktop-order-detail">
        {orderLoading && <CircularProgress />}
      </div>
    );
  }

  const isActiveBid = orderDetail?.ticker === ACTIVE_BID.instrument_code;

  return (
    <div className="comps-desktop-order-detail">
      {orderDetail && !orderError.message && cancelSucceeded === undefined && (
        <div className="order-detail-desktop-sidebar">
          <Typography variant="heading3">Detalle de la orden</Typography>
          <div className="order-wrapper">
            <IconButton
              aria-label="menu"
              className="button-icon"
              color="inherit"
              edge="start"
              onClick={handleShare}
            >
              <ShareIcon color={iconColor} />
            </IconButton>
            <IconButton
              aria-label="menu"
              className="button-icon"
              color="inherit"
              edge="start"
              onClick={handleDownload}
              id="download-order-button"
            >
              <DownloadIcon color={iconColor} />
            </IconButton>
            <div className="order-container" id="order-container">
              <div className="icons-wrapper">
                {(orderDetail.status === OrderStatusTypes.PENDING ||
                  orderDetail.status ===
                    OrderStatusTypes.PARTIALLY_EXECUTED) && (
                  <div className="status-order">
                    <ClockIcon size={20} />
                    <Typography className="status-text">
                      {getOrderStatus(orderDetail.status).label}
                    </Typography>
                  </div>
                )}
                {orderDetail.status === OrderStatusTypes.EXECUTED && (
                  <div className="status-order">
                    <CircleCheckIcon size={20} thickness={4} />
                    <Typography className="status-text">
                      {getOrderStatus(orderDetail.status).label}
                    </Typography>
                  </div>
                )}
                {(orderDetail.status === OrderStatusTypes.CANCELLED ||
                  orderDetail.status === OrderStatusTypes.REJECTED) && (
                  <div className="status-order">
                    <ErrorIcon size={20} />
                    <Typography>
                      {getOrderStatus(orderDetail.status).label}
                    </Typography>
                  </div>
                )}
              </div>
              <div className="box-ticker">
                <div className="ticker-row-data">
                  <div className="ticker">
                    <Typography className="ticker-name" variant="textM_bold">
                      {orderDetail.ticker}
                    </Typography>
                    {(orderDetail.order_type !== "WITHDRAWAL" ||
                      !orderDetail.error) && (
                      <Typography className="ticker-type" variant="textXS">
                        {orderDetail.order_type_label}
                      </Typography>
                    )}
                    {orderDetail.order_type === "WITHDRAWAL" &&
                      orderDetail.error && (
                        <Typography variant="textL_bold">Extraccion</Typography>
                      )}
                  </div>
                  {orderDetail.settlement_days !== 0 && (
                    <Typography className="settlement-days" variant="captionS">
                      {isActiveBid
                        ? ACTIVE_BID.settlement_days
                        : getSettlementDays(orderDetail.settlement_days)}
                    </Typography>
                  )}
                </div>
                {orderDetail.order_type !== "FUND" &&
                  orderDetail.order_type !== "REDEMPTION" &&
                  orderDetail.order_type !== "WITHDRAWAL" && (
                    <>
                      <Typography className="short-name" variant="textXS">
                        {orderDetail.instrument_short_name}
                      </Typography>

                      <div className="price-row">
                        <Typography className="limit-price" variant="textS">
                          Precio
                        </Typography>
                        {isActiveBid ? (
                          "-"
                        ) : (
                          <>
                            <Typography variant="textS_bold">
                              {`${getCurrencyLabel(orderDetail.currency)} `}
                              <Currency>
                                {orderDetail.set_price ||
                                  orderDetail.result_price}
                              </Currency>
                            </Typography>
                          </>
                        )}
                      </div>
                    </>
                  )}
                <div className="total-row">
                  <Typography variant="textS">Monto total</Typography>
                  <Typography variant="textM_bold">
                    {`${getCurrencyLabel(orderDetail.currency)} `}
                    {orderDetail.order_type !== "FUND" &&
                    orderDetail.order_type !== "REDEMPTION" ? (
                      <Currency>
                        {orderDetail.result_amount || orderDetail.set_amount}
                      </Currency>
                    ) : (
                      <Currency>{orderDetail.set_amount}</Currency>
                    )}
                  </Typography>
                </div>
              </div>
              <div className="accordion">
                {orderDetail.order_type !== "FUND" &&
                  orderDetail.order_type !== "REDEMPTION" &&
                  orderDetail.order_type !== "WITHDRAWAL" && (
                    <Accordion>
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        expandIcon={<ArrowTopIcon color={iconColor} />}
                        id="panel1a-header"
                      >
                        <Typography
                          className="quantity-text"
                          variant="textS_bold"
                        >
                          Cantidad
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="accordion-data">
                          <Typography variant="textS">Ejecutadas</Typography>
                          <Typography variant="textS">
                            {orderDetail.result_quantity
                              ? orderDetail.result_quantity
                              : "0"}
                          </Typography>
                        </div>
                        <div className="accordion-data">
                          <Typography variant="textS">En mercado</Typography>
                          <Typography variant="textS">
                            {orderDetail.set_quantity &&
                            orderDetail.result_quantity
                              ? orderDetail.set_quantity -
                                orderDetail.result_quantity
                              : "0"}
                          </Typography>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  )}
                {!orderDetail.error && orderDetail.order_type !== "WITHDRAWAL" && (
                  <div className="box-data-ticker">
                    <Typography variant="textS_bold">Fecha</Typography>
                    <Typography variant="textS_bold">
                      {dayjs(orderDetail.set_date).format("DD/MM/YYYY")}
                    </Typography>
                  </div>
                )}
                {orderDetail.error &&
                  orderDetail.order_type === "WITHDRAWAL" && (
                    <WithdrawError error={orderDetail.error} />
                  )}
              </div>
            </div>
          </div>

          {showCancelButton && (
            <div className="buttons-wrapper order">
              <ButtonUI
                disabled={orderLoading || cancelOrderLoading}
                size={ButtonSizes.Full}
                variant={ButtonVariants.Primary}
                onClick={handleCancelOrder}
              >
                {orderLoading || cancelOrderLoading ? (
                  <CircularProgress />
                ) : (
                  <Typography variant="buttonL">Cancelar orden</Typography>
                )}
              </ButtonUI>
            </div>
          )}
        </div>
      )}
      {cancelSucceeded === false && (
        <ErrorContainer
          textPrimaryButton="Volver al detalle de orden"
          title="Tu orden no pudo ser cancelada"
          onClickPrimaryButton={handleClickGoBack}
        />
      )}
    </div>
  );
};
