import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Bid } from "interfaces/api-responses/socket/book";
import _ from "lodash";
import { formatToCurrency } from "utils";
import { getColor } from "utils/getColor";

import styles from "./styles.module.scss";

interface PropTypes {
  data: Bid[];
  total: number;
  isBid?: boolean;
  numOfDecimals: number;
}

export const OrderBookSide = (props: PropTypes) => {
  const { data, total, isBid = true, numOfDecimals } = props;

  const redColor = getColor("--red-bg");
  const greenColor = getColor("--green-bg");

  const newData = _.sortBy(data, "position");

  const tableHeaders = isBid
    ? [
        { label: "Cantidad", class: styles.bidQuantityHeader },
        { label: "P. Compra", class: styles.bidPriceHeader },
      ]
    : [
        { label: "P. Venta", class: styles.askPriceHeader },
        { label: "Cantidad", class: styles.askQuantityHeader },
      ];

  const getRows = () => {
    let acumulatedSize = 0;
    if (isBid) {
      return newData.map((row: Bid, index: number) => {
        acumulatedSize += row.size;
        const colorPercentage = (acumulatedSize / total) * 100;
        return (
          <TableRow key={`bid ${index}`} className={styles.bodyRow}>
            <TableCell align="left" className={styles.sizeRow}>
              {formatToCurrency({ value: row.size, withoutDecimals: true })}
            </TableCell>
            <TableCell
              align="right"
              className={styles.priceBidRow}
              sx={{
                background: `linear-gradient(270deg, ${greenColor} ${colorPercentage}%, transparent 0%, transparent 100%)`,
              }}
            >
              {formatToCurrency({
                value: row.price,
                numberOfDecimals: numOfDecimals,
              })}
              &nbsp;
            </TableCell>
          </TableRow>
        );
      });
    }
    return newData.map((row: Bid, index: number) => {
      acumulatedSize += row.size;
      const colorPercentage = (acumulatedSize / total) * 100;
      return (
        <TableRow key={`ask ${index}`} className={styles.bodyRow}>
          <TableCell
            align="left"
            className={styles.priceAskRow}
            sx={{
              backgroundImage: `linear-gradient(90deg, ${redColor} ${colorPercentage}%, transparent 0%, transparent 100%)`,
            }}
          >
            &nbsp;
            {formatToCurrency({
              value: row.price,
              numberOfDecimals: numOfDecimals,
            })}
          </TableCell>
          <TableCell align="right" className={styles.sizeRow}>
            {formatToCurrency({ value: row.size, withoutDecimals: true })}
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow className={styles.bodyRow}>
            {tableHeaders.map((header) => (
              <TableCell key={header.label} className={header.class}>
                {header.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{getRows()}</TableBody>
      </Table>
    </TableContainer>
  );
};
