import OrdersPage from "components/page-orders";
import ActivityProvider from "context/ActivityProvider";

const Orders: React.FC = () => (
  <ActivityProvider>
    <OrdersPage />
  </ActivityProvider>
);

export default Orders;
