import { IconProps } from "../../BTC";

const MANAIcon: React.FC<IconProps> = ({ size = 24, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <circle cx="12" cy="12" r="12" fill="url(#paint0_linear_4200_1835)" />
    <path
      d="M8.502 7.8V16.8H16.002L8.502 7.8Z"
      fill="url(#paint1_linear_4200_1835)"
    />
    <path d="M1.002 16.8H8.502V7.8L1.002 16.8Z" fill="white" />
    <path
      d="M2.4 19.2C3.084 20.106 3.894 20.916 4.8 21.6H19.2C20.106 20.916 20.916 20.106 21.6 19.2H2.4Z"
      fill="#FC9965"
    />
    <path
      d="M4.8 21.6C6.804 23.106 9.3 24 12 24C14.7 24 17.196 23.106 19.2 21.6H4.8Z"
      fill="#FF2D55"
    />
    <path
      d="M16.098 12.6V19.2H21.6L16.098 12.6Z"
      fill="url(#paint2_linear_4200_1835)"
    />
    <path
      d="M16.098 16.8H1.002C1.374 17.658 1.848 18.462 2.4 19.2H16.104V16.8H16.098Z"
      fill="#FFBC5B"
    />
    <path d="M10.602 19.2H16.098V12.6L10.602 19.2Z" fill="white" />
    <circle cx="16.098" cy="7.8" r="3" fill="#FFC95B" />
    <circle cx="8.502" cy="4.5" r="1.5" fill="#FFC95B" />
    <defs>
      <linearGradient
        id="paint0_linear_4200_1835"
        x1="12"
        y1="-4.9704"
        x2="-4.9704"
        y2="12"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF2D55" />
        <stop offset="1" stopColor="#FFBC5B" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4200_1835"
        x1="8.49945"
        y1="7.8"
        x2="8.49945"
        y2="16.8"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A524B3" />
        <stop offset="1" stopColor="#FF2D55" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4200_1835"
        x1="16.0961"
        y1="12.6"
        x2="16.0961"
        y2="19.2"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A524B3" />
        <stop offset="1" stopColor="#FF2D55" />
      </linearGradient>
    </defs>
  </svg>
);

export default MANAIcon;
