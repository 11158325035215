enum FeatureFlags {
  ENABLE_BID = "bid_enabled",
  ENABLE_CARD_OUT_OF_STOCK = "card_out_of_stock",
  ENABLE_ORDERS_REDESIGN = "enable_orders_redesign",
  ENABLE_ACTIVITY_REDESIGN = "enable_activity_redesign",
  ENABLE_DONATION = "donation_enabled",
  ENABLE_FCI_INFO_TAB = "enable_fci_info_tab",
}

export default FeatureFlags;
