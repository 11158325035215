import AAVEIcon from "assets/icons/cryptos/AAVE";
import ADAIcon from "assets/icons/cryptos/ADA";
import AVAXIcon from "assets/icons/cryptos/AVAX";
import BCHIcon from "assets/icons/cryptos/BCH";
import BTCIcon, { IconProps } from "assets/icons/BTC";
import CocosBrand from "assets/icons/CocosBrand";
import DAIIcon from "assets/icons/DAI";
import DOTIcon from "assets/icons/cryptos/DOT";
import ETHIcon from "assets/icons/ETH";
import LINKIcon from "assets/icons/LINK";
import LTCIcon from "assets/icons/cryptos/LTC";
import MANAIcon from "assets/icons/cryptos/MANA";
import MKRIcon from "assets/icons/cryptos/MKR";
import PAXGIcon from "assets/icons/cryptos/PAXG";
import POLYGONIcon from "assets/icons/Polygon";
import SOLIcon from "assets/icons/cryptos/SOL";
import UNIIcon from "assets/icons/cryptos/UNI";
import USDCIcon from "assets/icons/USDC";
import USDTIcon from "assets/icons/USDT";
import XRPIcon from "assets/icons/cryptos/XRP";

export enum Cryptos {
  AAVE = "AAVE",
  ADA = "ADA",
  AVAX = "AVAX",
  BCH = "BCH",
  BTC = "BTC",
  DAI = "DAI",
  DOT = "DOT",
  ETH = "ETH",
  LINK = "LINK",
  LTC = "LTC",
  MANA = "MANA",
  MATIC = "MATIC",
  MKR = "MKR",
  PAXG = "PAXG",
  POL = "POL",
  SOL = "SOL",
  UNI = "UNI",
  USDT = "USDT",
  USDC = "USDC",
  XRP = "XRP",
}

const CRYPTOS: Record<Cryptos, CryptoUI> = {
  AAVE: {
    name: "AAVE",
    icon: AAVEIcon,
    color: "var(--crypto-eth)",
  },
  ADA: {
    name: "ADA",
    icon: ADAIcon,
    color: "var(--crypto-eth)",
  },
  AVAX: {
    name: "Avalanche",
    icon: AVAXIcon,
    color: "var(--crypto-eth)",
  },
  BCH: {
    name: "Bitcoin Cash",
    icon: BCHIcon,
    color: "var(--crypto-eth)",
  },
  BTC: {
    name: "Bitcoin",
    icon: BTCIcon,
    color: "var(--crypto-btc)",
  },
  DAI: {
    name: "Dai",
    icon: DAIIcon,
    color: "var(--crypto-dai)",
  },
  DOT: {
    name: "Polkadot",
    icon: DOTIcon,
    color: "var(--crypto-eth)",
  },
  ETH: {
    name: "Ethereum",
    icon: ETHIcon,
    color: "var(--crypto-eth)",
  },
  LINK: {
    name: "Chainlink",
    icon: LINKIcon,
    color: "var(--crypto-link)",
  },
  LTC: {
    name: "Litecoin",
    icon: LTCIcon,
    color: "var(--crypto-eth)",
  },
  MANA: {
    name: "Decentraland",
    icon: MANAIcon,
    color: "var(--crypto-eth)",
  },
  MATIC: {
    name: "Matic",
    icon: POLYGONIcon,
    color: "var(--crypto-pol)",
  },
  MKR: {
    name: "Maker",
    icon: MKRIcon,
    color: "var(--crypto-eth)",
  },
  PAXG: {
    name: "PAX Gold",
    icon: PAXGIcon,
    color: "var(--crypto-eth)",
  },
  POL: {
    name: "Polygon",
    icon: POLYGONIcon,
    color: "var(--crypto-pol)",
  },
  SOL: {
    name: "Solana",
    icon: SOLIcon,
    color: "var(--crypto-eth)",
  },
  UNI: {
    name: "Uniswap",
    icon: UNIIcon,
    color: "var(--crypto-eth)",
  },
  USDT: {
    name: "Tether",
    icon: USDTIcon,
    color: "var(--crypto-usdt)",
  },
  USDC: {
    name: "USD Coin",
    icon: USDCIcon,
    color: "var(--crypto-usdc)",
  },
  XRP: {
    name: "XRP",
    icon: XRPIcon,
    color: "var(--crypto-eth)",
  },
};

export interface CryptoUI {
  name: string;
  icon: React.FC<IconProps>;
  color: string;
}

export const getCryptoElement = (ticker: string) => {
  const defaultElement = {
    name: ticker,
    icon: CocosBrand,
    color: "var(--slate600)",
  };
  return CRYPTOS[ticker as Cryptos] || defaultElement;
};

export enum Networks {
  POLYGON = "Polygon",
  ETH = "Ethereum",
  BTC = "Bitcoin",
}
export interface Network {
  icon: React.FC<IconProps>;
  name: Networks;
}

export const NETWORKS: Network[] = [
  {
    icon: ETHIcon,
    name: Networks.ETH,
  },
  {
    icon: POLYGONIcon,
    name: Networks.POLYGON,
  },
  {
    icon: BTCIcon,
    name: Networks.BTC,
  },
];

export const getNetwork = (value: Networks | string) => {
  const foundNetwork = NETWORKS.find(({ name }) => name === value);

  return foundNetwork || NETWORKS[0];
};

export enum cryptoStatus {
  PENDING = "PENDING",
  PENDING_DOCUMENTS = "PENDING_DOCUMENTS",
  PENDING_ADDRESSES = "PENDING_ADDRESSES",
  APPROVED = "APPROVED",
}

export const stableCoins = [Cryptos.DAI, Cryptos.USDT, Cryptos.USDC];
