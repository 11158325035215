import { useState, useEffect } from "react";

import { ampli } from "ampli";
import API from "apis";
import { cardService } from "apis/services";
import { useShippingPaymentMethodsQuery } from "apis/card/queries/useShippingPaymentMethods";
import { FileCheck2, Receipt, BadgePercent } from "lucide-react";
import { ThemeVariants } from "interfaces/theme";
import { CardSections, CardTypes, UserStatus } from "interfaces/card/enums";
import { useCocosCard } from "context/CocosCardProvider";
import Heading, { HeadingVariants } from "components/common/Heading";
import BasicWrapper from "components/common/BasicWrapper";

import InfoRow from "../components/InfoRow";
import LoadingScreen from "../Loading";
import CardErrorScreen from "../Error";

import styles from "./styles.module.scss";

const PhysicalIntro: React.FC = () => {
  const {
    setSelectedSection,
    pomeloData,
    getPomeloData,
    userStatus,
  } = useCocosCard();
  const {
    isLoading: shippingLoading,
    data: shippingData,
  } = useShippingPaymentMethodsQuery();

  const [isCardRequested, setIsCardRequested] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const createPomeloUser = async () => {
    ampli.cardCreationWithoutDelivery();
    setIsLoading(true);

    if (pomeloData) {
      setSelectedSection(CardSections.PHYSICAL_ACTIVATION);
      return;
    }

    try {
      const requestCardCreation = [CardTypes.VIRTUAL];
      await API.post(cardService.user, { requestCardCreation });

      setIsCardRequested(true);
    } catch (error: any) {
      ampli.cardErrorCreationFailed({
        card_type: CardTypes.VIRTUAL,
      });
      setError(true);
    }
  };

  const onClose = () =>
    userStatus === UserStatus.ACTIVE
      ? setSelectedSection(CardSections.HOME)
      : setSelectedSection(CardSections.CARD_SELECTION);

  useEffect(() => {
    if (isCardRequested) {
      if (userStatus === UserStatus.ACTIVE) {
        setIsLoading(false);
        setSelectedSection(CardSections.PHYSICAL_ACTIVATION);
      } else if (userStatus === UserStatus.FAILED) {
        setIsLoading(false);
        setError(true);
      }

      const interval = setInterval(() => {
        getPomeloData();
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [userStatus, isCardRequested]);

  useEffect(() => {
    ampli.cardCreationSteps();
  }, []);

  if (isLoading || shippingLoading) {
    return (
      <LoadingScreen
        title={
          shippingData?.isFreeShipping ? undefined : "Configurando tu cuenta..."
        }
        subtitle={
          shippingData?.isFreeShipping
            ? undefined
            : "Esto puede tardar unos segundos."
        }
      />
    );
  }

  if (error) return <CardErrorScreen />;

  return (
    <BasicWrapper
      themeVariant={ThemeVariants.Pay}
      navigationHeaderProps={{
        title: "Tarjeta física",
        onClick: () => onClose(),
        withCloseIcon: false,
      }}
      className={styles.physicalIntroContainer}
      primaryButtonProps={{
        children: "Continuar",
        onClick: () => setSelectedSection(CardSections.SHIPPING_INFO),
      }}
      secondaryButtonProps={{
        children: "¿Ya recibiste tu tarjeta? Activala",
        onClick: () => createPomeloUser(),
      }}
    >
      <Heading
        variant={HeadingVariants.RegularTitle}
        color="var(--slate900)"
        component="h1"
        className={styles.titleHeader}
      >
        A continuación vas a tener que:
      </Heading>
      <div>
        <InfoRow
          icon={FileCheck2}
          title="Completar los datos del envío"
          subtitle="Necesitamos conocer tu dirección fiscal para poder enviarte tu tarjeta física."
        />
        {shippingData?.isFreeShipping ? (
          <InfoRow
            icon={BadgePercent}
            title="Tu tarjeta está 100% bonificada"
            subtitle="Por tiempo limitado, pedí tu tarjeta completamente gratis."
          />
        ) : (
          <InfoRow
            icon={Receipt}
            title="Abonar el costo del envío"
            subtitle="Solo te vamos a cobrar el costo del envío, tu primera tarjeta es sin cargo."
          />
        )}
      </div>
    </BasicWrapper>
  );
};

export default PhysicalIntro;
