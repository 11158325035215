import Text, { TextVariants } from "components/common/Text";
import { BondFlow } from "interfaces/markets";
import { formatToCurrency } from "utils";

import styles from "./styles.module.scss";

interface FlowBodyRowProps {
  flowRow: BondFlow;
  isMobile: boolean;
  index: number;
}

const FlowBodyRow: React.FC<FlowBodyRowProps> = ({
  flowRow,
  isMobile,
  index,
}) => {
  const { date, capital, cashFlow, interes, acumulated } = flowRow;

  return (
    <>
      <div className={styles.flowColumn}>
        <Text variant={TextVariants.RegularTextS} className={styles.alignLeft}>
          {date}
        </Text>
      </div>
      <div className={styles.flowColumn}>
        <Text variant={TextVariants.RegularTextS}>
          {index === 0 ? "-" : formatToCurrency({ value: capital })}
        </Text>
      </div>
      <div className={styles.flowColumn}>
        <Text variant={TextVariants.RegularTextS}>
          {index === 0 ? "-" : formatToCurrency({ value: interes })}
        </Text>
      </div>
      <div className={styles.flowColumn}>
        <Text variant={TextVariants.RegularTextS}>
          {formatToCurrency({ value: cashFlow })}
        </Text>
      </div>
      {!isMobile && (
        <div className={styles.flowColumn}>
          <Text variant={TextVariants.RegularTextS}>
            {acumulated ? formatToCurrency({ value: acumulated }) : "-"}
          </Text>
        </div>
      )}
    </>
  );
};

export default FlowBodyRow;
