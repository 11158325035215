import { Cryptos } from "constants/cryptos";
import { CocosFunds } from "constants/instruments";

import { PaymentCurrencies } from "interfaces/pay/enums";
import { getFCIIconColor } from "components/page-cocos-pay/utils";
import ARGFlag from "assets/icons/ARGFlag";
import USDT from "assets/icons/USDT";
import USDC from "assets/icons/USDC";
import DAI from "assets/icons/DAI";
import USFlag from "assets/icons/USFlag";
import BRFlag from "assets/icons/BRFlag";
import CocosBrand, { CocosBrandVariants } from "assets/icons/CocosBrand";
import OverlappedIcons from "components/common/OverlappedIcons";
import { Currencies } from "interfaces/wallet";

const getCurrencyIcon = ({
  currency,
  size,
}: {
  currency: PaymentCurrencies;
  size: number;
}) => {
  const currencyIconMap = {
    [Currencies.ARS]: <ARGFlag size={size} />,
    [Currencies.USD]: <USFlag size={size} />,
    [Currencies.BRL]: <BRFlag size={size} />,
    [Cryptos.USDT]: <USDT size={size} />,
    [Cryptos.USDC]: <USDC size={size} />,
    [Cryptos.DAI]: <DAI size={size} />,
    [CocosFunds.AHORRO]: (
      <CocosBrand
        variant={CocosBrandVariants.CAPITAL}
        size={size}
        color={getFCIIconColor(CocosFunds.AHORRO)}
      />
    ),
    [CocosFunds.RMA]: (
      <CocosBrand
        variant={CocosBrandVariants.CAPITAL}
        size={size}
        color={getFCIIconColor(CocosFunds.RMA)}
      />
    ),
    [CocosFunds.USD]: (
      <CocosBrand
        variant={CocosBrandVariants.CAPITAL}
        size={size}
        color={getFCIIconColor(CocosFunds.USD)}
      />
    ),
    STABLECOIN: <OverlappedIcons icons={[USDT, USDC, DAI]} />,
  };

  return (
    currencyIconMap[currency] || (
      <CocosBrand variant={CocosBrandVariants.CAPITAL} size={size} />
    )
  );
};

export default getCurrencyIcon;
