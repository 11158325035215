import { useMutation } from "@tanstack/react-query";
import API from "apis";
import { queryClient } from "apis/queryClient";
import { userKeys } from "apis/queryKeyFactories";
import { userService } from "apis/services";
import type { ErrorMessage } from "interfaces/errors";
import { Tier } from "interfaces/user";

export const useActivateGoldMutation = () => {
  return useMutation<void, ErrorMessage>({
    mutationFn: async () => {
      return await API.post(userService.upgradeRequest(Tier.GOLD));
    },
    onError: () => {
      return {
        message: "Hubo un error para activar cocos gold",
        status: 500,
      };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: userKeys.accountTier });
    },
  });
};
