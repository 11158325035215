import { Cryptos } from "constants/cryptos";

import { useEffect, useState } from "react";

import API from "apis";
import { cryptoService } from "apis/services";
import { ThemeVariants } from "interfaces/theme";
import { CryptoAccount, CryptoTicker } from "interfaces/api-responses";
import LoadingSpinner from "components/common/LoadingSpinner";
import { SendMethod, useCryptoSend } from "context/CryptoSendProvider";
import { Operations } from "interfaces/crypto/enums";

import AccountsSection from "./AccountsSection";
import AssetsSection from "./AssetsSection";
import SearchTagSection from "./SearchTagSection";

import styles from "./styles.module.scss";

const SelectionPage: React.FC = () => {
  const {
    cryptos,
    sendMethod,
    selectedCrypto,
    setSelectedCrypto,
    setSendMethod,
  } = useCryptoSend();

  const [availableAccounts, setAvailableAccounts] = useState<CryptoAccount[]>(
    []
  );
  const [newAccount, setNewAccount] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isTag = sendMethod === SendMethod.COCOSTAG;
  const isBlockchain = sendMethod === SendMethod.BLOCKCHAIN;

  useEffect(() => {
    const getAccounts = async () => {
      try {
        setIsLoading(true);
        let { data } = await API.get<CryptoAccount[]>(
          cryptoService.withdrawalAddresses
        );
        if (selectedCrypto) {
          data = data.filter((a) =>
            a.tickers.includes(selectedCrypto.ticker as Cryptos)
          );
        }
        setAvailableAccounts(data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    getAccounts();
    setNewAccount(false);
  }, [newAccount]);

  const handleClickAsset = (asset: CryptoTicker) => {
    setAvailableAccounts(
      availableAccounts.filter((a) => a.tickers.includes(asset.ticker))
    );
    const selected = cryptos.find((crypto) => crypto.ticker === asset.ticker);

    const allowedTAG = asset?.allowed_operations?.includes(Operations.SEND_TAG);
    const allowedBlockchain = asset?.allowed_operations?.includes(
      Operations.SEND
    );

    if (selected) {
      setSelectedCrypto(selected);
    }
    if (!allowedBlockchain || !allowedTAG) {
      setSendMethod(
        allowedBlockchain ? SendMethod.BLOCKCHAIN : SendMethod.COCOSTAG
      );
    }
  };

  const title = `Enviar ${selectedCrypto?.ticker} a`;

  if (isLoading)
    return (
      <div className={styles.loaderWrapper}>
        <LoadingSpinner variant={ThemeVariants.Crypto} />
      </div>
    );

  return (
    <div className={styles.selectionPageContainer}>
      {selectedCrypto && isBlockchain && (
        <AccountsSection
          title={title}
          accounts={availableAccounts}
          onCreateAccount={() => setNewAccount(true)}
        />
      )}
      {selectedCrypto && isTag && <SearchTagSection />}

      {(!selectedCrypto || !sendMethod) && (
        <AssetsSection onClick={handleClickAsset} />
      )}
    </div>
  );
};

export default SelectionPage;
