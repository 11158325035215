import { ATENCION_CRYPTO_EMAIL } from "constants/emails";

import { useDeviceCheck } from "hooks/useDeviceCheck";
import { UIModalSlider } from "components/common/modal-slider";
import { useNavigate } from "react-router-dom";
import Heading, { HeadingVariants } from "components/common/Heading";
import { X } from "lucide-react";
import px2rem from "utils/px2rem";
import Text, { TextVariants } from "components/common/Text";
import FilledButton from "components/common/FilledButton";
import OutlinedButton from "components/common/OutlinedButton";
import { CryptoRoutes } from "router/routes";
import classNames from "classnames";
import { useCryptoCable } from "context/CryptoCableProvider";
import { ThemeVariants } from "interfaces/theme";

import styles from "./styles.module.scss";

interface CreationErrorBottomsheetProps {
  isOpen: boolean;
}

const CreationErrorBottomsheet: React.FC<CreationErrorBottomsheetProps> = ({
  isOpen,
}) => {
  const { isMobile } = useDeviceCheck();
  const navigate = useNavigate();
  const { setError, setIsCreationInProgress } = useCryptoCable();

  const handleRetry = () => {
    setIsCreationInProgress(false);
    setError(false);
  };

  return (
    <UIModalSlider
      open={isOpen}
      onToggleDisplay={() => undefined}
      classes={classNames(styles.sheetWrapper, { [styles.desktop]: !isMobile })}
    >
      <div className={styles.contentWrapper}>
        <Heading
          variant={HeadingVariants.RegularSubtitle}
          color="var(--slate900)"
          component="h2"
          className={styles.heading}
        >
          Error al realizar el pago
        </Heading>
        <div className={styles.wrapper}>
          <div className={styles.icon}>
            <X size={px2rem(24)} color="var(--red900)" />
          </div>
          <Text variant={TextVariants.RegularTextL} color="var(--slate800)">
            Ocurrió un error al realizar el pago. Por favor, volvé a intentarlo
            más tarde o comunicate a{" "}
            <Text
              variant={TextVariants.SemiboldTextL}
              component="a"
              color="var(--purple800)"
              href={`mailto:${ATENCION_CRYPTO_EMAIL}`}
            >
              {ATENCION_CRYPTO_EMAIL}
            </Text>{" "}
            .
          </Text>
        </div>
        <div className={styles.buttonsWrapper}>
          <FilledButton onClick={handleRetry} variant={ThemeVariants.Crypto}>
            Volver a intentar
          </FilledButton>
          <OutlinedButton
            onClick={() => navigate(CryptoRoutes.HOME)}
            variant={ThemeVariants.Crypto}
          >
            Volver al inicio
          </OutlinedButton>
        </div>
      </div>
    </UIModalSlider>
  );
};

export default CreationErrorBottomsheet;
