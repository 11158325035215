import { Cryptos } from "constants/cryptos";

import { IconProps } from "assets/icons/BTC";
import Variation from "components/common/CurrentVariation";
import Text, { TextVariants } from "components/common/Text";
import Currency from "components/common/Text/Currency";
import { Currencies } from "interfaces/wallet";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface SingleHoldingReturnProps {
  icon: React.FC<IconProps>;
  name: string;
  ticker: Cryptos | Currencies.ARS | string;
  price?: number;
  variation?: number;
}

const SingleHoldingReturn: React.FC<SingleHoldingReturnProps> = ({
  icon: Icon,
  name,
  ticker,
  price,
  variation = 0,
}) => {
  const getColor = () =>
    price && price < 0 ? "var(--red800)" : "var(--green800)";

  return (
    <div className={styles.singleHoldingReturnContainer}>
      <div className={styles.asset}>
        <Icon size={px2rem(32)} />
        <div className={styles.info}>
          <Text variant={TextVariants.RegularText} color="var(--slate900)">
            {name}
          </Text>
          <Text
            variant={TextVariants.RegularUpperTextS}
            color="var(--slate800)"
          >
            {ticker}
          </Text>
        </div>
      </div>
      {price && (
        <div className={styles.value}>
          <Currency amount={price} color={getColor()} />
          <Variation value={variation} />
        </div>
      )}
    </div>
  );
};

export default SingleHoldingReturn;
