import { Network } from "constants/cryptos";

import { useEffect, useState } from "react";

import Selector from "components/common/Selector";
import PageHeader from "components/common/PageHeader";
import Text, { TextVariants } from "components/common/Text";
import { CryptoTicker } from "interfaces/api-responses";
import { X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { cryptoScreenNames } from "router/routes";
import { Projects, trackAction } from "utils/amplitude";
import { SendMethod } from "context/CryptoSendProvider";

import NetworkAddress from "./NetworkAddress";
import NetworkBottomSheet from "./NetworkBottomSheet";
import ShareButton from "./ShareButton";

import styles from "./styles.module.scss";

interface ShareAddressPageProps {
  crypto: CryptoTicker;
  address?: string;
  network?: Network;
  onClickCrypto: () => void;
  availableNetworks?: Network[];
  setSelectedNetwork: (v?: Network) => void;
}

const ShareAddressPage: React.FC<ShareAddressPageProps> = ({
  crypto,
  address,
  network,
  onClickCrypto,
  availableNetworks,
  setSelectedNetwork,
}) => {
  const navigate = useNavigate();

  const [open, setOpen] = useState<boolean>();

  const handleOnClickNetwork = (network: Network) => {
    setOpen(false);
    setSelectedNetwork(network);
  };

  useEffect(() => {
    if (!crypto) return;

    const hasNetwork = availableNetworks?.find(
      (availableNetwork) => availableNetwork.name === network?.name
    );

    if (hasNetwork) return;

    if (availableNetworks?.length === 1) {
      setSelectedNetwork(availableNetworks[0]);
      return;
    }

    setOpen(true);
    setSelectedNetwork(undefined);
  }, [crypto]);

  const handleEditCrypto = () => {
    onClickCrypto();

    trackAction(
      `${cryptoScreenNames.receive} - Click Edit Asset`,
      undefined,
      Projects.CRYPTO
    );
  };

  const handleEditNetwork = () => {
    setOpen(true);

    trackAction(
      `${cryptoScreenNames.receive} - Click Edit Network`,
      undefined,
      Projects.CRYPTO
    );
  };

  return (
    <div className={styles.shareAddressPageContainer}>
      <div className={styles.wrapper}>
        <PageHeader
          title={`Recibir ${crypto.name}`}
          onClick={() => navigate(-1)}
          className={styles.header}
          icon={X}
        />
        <div id="address-data" className={styles.netDataWrapper}>
          <NetworkAddress network={network} address={address} />
          <Selector
            sendMethod={SendMethod.BLOCKCHAIN}
            ticker={crypto.ticker}
            network={network}
            onClickNetwork={handleEditNetwork}
            onClickCrypto={handleEditCrypto}
          />
        </div>
        <Text
          variant={TextVariants.RegularTextS}
          color="var(--slate600)"
          className={styles.info}
        >
          Utilizá esta dirección para recibir {crypto.name} en {network?.name}.
          Las transacciones pueden tardar unos minutos en completarse.
        </Text>
      </div>
      <ShareButton
        network={network}
        address={address}
        ticker={crypto.ticker}
        disabled={!network}
      />
      {open && availableNetworks && (
        <NetworkBottomSheet
          networks={availableNetworks}
          onClick={handleOnClickNetwork}
          onClose={() => setOpen(false)}
        />
      )}
    </div>
  );
};

export default ShareAddressPage;
