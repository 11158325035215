import API from "apis";
import { fundsKeys } from "apis/queryKeyFactories";
import { fciService } from "apis/services";
import { useAppQuery } from "apis/queryClient";
import { PeriodTime } from "interfaces/portfolio";

import { FundsHoldingResponse } from "../../../interfaces/fci";

const TEN_MINUTES = 60 * 1000 * 10;

interface useHoldingsQueryProps {
  securityId: number;
  period?: PeriodTime;
  offset?: number;
}

export const fetchHoldings = async ({
  securityId,
  period = PeriodTime.YEAR,
  offset = 0,
}: useHoldingsQueryProps) => {
  const url = fciService.holdings(securityId);
  const { data } = await API.get<FundsHoldingResponse>(url, {
    params: {
      period,
      offset,
    },
  });

  return data;
};

export const useFundsHoldings = ({
  securityId,
  period,
  offset,
}: useHoldingsQueryProps) => {
  return useAppQuery({
    options: {
      queryKey: fundsKeys.holdings(securityId, period, offset),
      queryFn: () => fetchHoldings({ securityId, period, offset }),
      staleTime: TEN_MINUTES,
      gcTime: TEN_MINUTES,
    },
  });
};
