import Avatar from "components/common/avatar";
import { BarChart, Home, LogOut, PieChart, TrendingUp } from "lucide-react";
import mainRoutes from "router/routes";

interface IMenuList {
  menuList: MenuNavigation[];
  menuSettings: SettingsNavigation[];
}

export interface MenuNavigation {
  icon: React.ReactNode;
  label: string;
  path: string;
  id: string;
}

export interface SettingsNavigation {
  icon: React.ReactNode;
  path: string;
}

export const getListMenuHome = (isNewActivity: boolean) => {
  const activityRoute = isNewActivity
    ? mainRoutes.activity
    : mainRoutes.movements;

  const activityId = isNewActivity ? "activity" : "movements";

  const menuList: MenuNavigation[] = [
    {
      icon: <Home size={20} color="var(--slate600)" />,
      label: "Inicio",
      path: mainRoutes.home,
      id: "",
    },
    {
      icon: <TrendingUp size={20} color="var(--slate600)" />,
      label: "Mercado",
      path: mainRoutes.market,
      id: "market",
    },
    {
      icon: <BarChart size={20} color="var(--slate600)" />,
      label: "Actividad",
      path: activityRoute,
      id: activityId,
    },
    {
      icon: <PieChart size={20} color="var(--slate600)" />,
      label: "Portfolio",
      path: mainRoutes.portfolio,
      id: "capital-portfolio",
    },
  ];

  const menuSettings: SettingsNavigation[] = [
    {
      icon: <Avatar />,
      path: mainRoutes.profile,
    },
    {
      icon: <LogOut size={20} color="var(--slate600)" />,
      path: mainRoutes.logout,
    },
  ];

  const result: IMenuList = {
    menuList,
    menuSettings,
  };
  return result;
};
