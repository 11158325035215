import { InvestmentSuggestedResponse } from "interfaces/markets";
import { useAppSelector } from "hooks/redux";
import { RootState } from "store/store";
import Text, { TextVariants } from "components/common/Text";
import { ACTIVE_BID } from "components/common/bid/constants";

import styles from "./styles.module.scss";

interface BidDetailDataProps {
  instrument: InvestmentSuggestedResponse;
  onChangePage: () => void;
}

const BidDetailData: React.FC<BidDetailDataProps> = ({
  instrument,
  onChangePage,
}) => {
  const gotFlow = useAppSelector((state: RootState) => state.calculator.check);
  const formatExpirationDate = ACTIVE_BID.expiration.replace(/-/g, "/");

  const cards = [
    {
      heading: "Interés",
      title: "Tasa estimada",
      value: `${ACTIVE_BID.tir}%`,
      text: "Pagos trimestrales a partir del primer mes.",
    },
    {
      heading: "Fechas y plazos",
      title: "Vencimiento",
      value: formatExpirationDate,
      text: "Fecha en que se pagará por completo la deuda.",
    },
    {
      heading: "Amortización",
      title: "Tipo",
      value: "Semestral",
      text: "Se amortiza el capital en 4 cuotas del 25%.",
    },
  ];

  return (
    <div className={styles.detailData}>
      <div className={styles.dataCards}>
        {cards.map((card) => (
          <div key={card.title}>
            <Text
              variant={TextVariants.RegularText}
              color="var(--slate800)"
              className={styles.cardHeadingDetail}
            >
              {card.heading}
            </Text>
            <div className={styles.cardDetail}>
              <div className={styles.cardTextDetail}>
                <Text
                  variant={TextVariants.RegularText}
                  color="var(--slate800)"
                >
                  {card.title}
                </Text>
                <Text
                  variant={TextVariants.SemiboldText}
                  color="var(--slate900)"
                >
                  {card.value}
                </Text>
              </div>
              <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
                {card.text}
              </Text>
            </div>
          </div>
        ))}
      </div>
      {gotFlow && (
        <Text
          variant={TextVariants.RegularText}
          className={styles.checkFlow}
          onClick={onChangePage}
        >
          Ver flujo de fondos
        </Text>
      )}
    </div>
  );
};

export default BidDetailData;
