import { useEffect, useState } from "react";

import { Currencies } from "interfaces/wallet";
import { formatToCurrency, getCurrencySymbol } from "utils";
import { ThemeVariants } from "interfaces/theme";
import NumberInput from "components/common/NumberInput";
import UseMaxButton from "components/common/UseMaxButton";

import Text, { TextVariants } from "../Text";

import styles from "./styles.module.scss";

interface TransactionInputProps {
  currency?: Currencies;
  balance?: number;
  onChange: (v: number) => void;
  onKeyDown?: (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  isDesktop?: boolean;
  trackingEvent?: () => void;
  minToBuy?: boolean;
  minLimit?: number;
}

const TransactionInput: React.FC<TransactionInputProps> = ({
  balance,
  onChange,
  onKeyDown,
  currency = Currencies.ARS,
  isDesktop = false,
  trackingEvent,
  minToBuy,
  minLimit,
}) => {
  const [value, setValue] = useState<string>("");
  const balanceColor = "var(--slate800)";

  const [insufficientBalance, setInsufficientBalance] = useState<boolean>(
    false
  );

  const hasBalance = balance !== undefined;

  const checkBalance = (value: number | string) => {
    if (!hasBalance) return;

    if (Number(value) > balance) {
      trackingEvent && trackingEvent();
      setInsufficientBalance(true);
    } else {
      setInsufficientBalance(false);
    }
  };

  const handleUseMax = () => {
    if (!hasBalance) return;

    if (balance > 0) {
      setValue(balance.toString());
      onChange(balance);
    } else {
      setValue("0");
      onChange(0);
    }
  };

  useEffect(() => {
    checkBalance(value);
  }, [currency, handleUseMax]);

  const onNumberInputChange = (value: string) => {
    checkBalance(value);
    onChange(Number(value));
  };

  const currencySymbol = getCurrencySymbol(currency);
  const displayinsufficientBalance = insufficientBalance && Number(value) !== 0;
  const formatMinLimit = `${currencySymbol} ${minLimit}`;

  const minToBuyLabel =
    currency === Currencies.BRL
      ? "El monto mínimo para pagar con Pix es de "
      : "El monto minimo de compra es ";

  return (
    <div className={styles.transactionInputContainer}>
      <NumberInput
        value={value}
        setValue={setValue}
        onKeyDown={onKeyDown}
        isDesktop={isDesktop}
        onChange={onNumberInputChange}
        className={styles.numberInput}
        currencySymbol={currencySymbol}
      />
      {hasBalance && (
        <>
          <Text
            color={balanceColor}
            className={styles.balance}
            variant={TextVariants.RegularTextS}
          >
            {currencySymbol}
            <Text
              component="span"
              variant={TextVariants.SemiboldUpperValueS}
              color={balanceColor}
            >
              {balance > 0 ? formatToCurrency({ value: balance }) : 0}
            </Text>{" "}
            disponible
          </Text>
          <UseMaxButton
            onClick={handleUseMax}
            variant={ThemeVariants.Capital}
          />
          {displayinsufficientBalance && (
            <>
              <Text
                className={styles.insufficientBalance}
                variant={TextVariants.RegularTextS}
                color="var(--red800)"
              >
                El monto que ingresaste es superior al saldo disponible
              </Text>
              <Text variant={TextVariants.RegularTextS} color="var(--red800)">
                Por favor, volvé a ingresar el monto.
              </Text>
            </>
          )}
        </>
      )}
      {minToBuy && Number(value) > 0 && (
        <>
          <Text
            className={styles.insufficientBalance}
            variant={TextVariants.RegularTextS}
            color="var(--red800)"
          >
            {minToBuyLabel}
            {formatMinLimit}
          </Text>
        </>
      )}
    </div>
  );
};

export default TransactionInput;
