import { Grid, Typography } from "@mui/material";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { BondFlow } from "interfaces/markets";
import { formatToCurrency } from "utils";

import styles from "./styles.module.scss";

interface InvestmentFlowBodyProps {
  rowsData: BondFlow[];
}

const InvestmentFlowBody: React.FC<InvestmentFlowBodyProps> = ({
  rowsData,
}) => {
  const { isMobile } = useDeviceCheck();
  return (
    <>
      {rowsData.map((row: BondFlow, index: number) => (
        <>
          <Grid className={styles.flowColumn} key={`date ${index}`} xs={4}>
            <Typography align="left" className={styles.tableCells}>
              {row.date}
            </Typography>
          </Grid>
          <Grid className={styles.flowColumn} key={`capital ${index}`} xs={2}>
            <Typography align="right" className={styles.tableCells}>
              {index === 0 ? "-" : formatToCurrency({ value: row.capital })}
            </Typography>
          </Grid>
          <Grid className={styles.flowColumn} key={`interes ${index}`} xs={3}>
            <Typography align="right" className={styles.tableCells}>
              {index === 0 ? "-" : formatToCurrency({ value: row.interes })}
            </Typography>
          </Grid>
          <Grid className={styles.flowColumn} key={`cashflow ${index}`} xs={3}>
            <Typography align="right" className={styles.tableCells}>
              {formatToCurrency({ value: row.cashFlow })}
            </Typography>
          </Grid>
          {!isMobile && (
            <Grid
              className={styles.flowColumn}
              key={`acumulado ${index}`}
              xs={4}
            >
              <Typography align="right" className={styles.tableCells}>
                {row.acumulated
                  ? formatToCurrency({ value: row.acumulated })
                  : "-"}
              </Typography>
            </Grid>
          )}
        </>
      ))}
    </>
  );
};

export default InvestmentFlowBody;
