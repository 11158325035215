import { useNavigate, useSearchParams } from "react-router-dom";
import { OperationSide, OrderStatus } from "interfaces/orders/enums";
import FilledButton, {
  FilledButtonVariants,
} from "components/common/FilledButton";
import { useCancelOrder } from "apis/activity/mutations/useCancelOrder";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import { useAppDispatch } from "hooks/redux";
import FixedHeader from "components/common/FixedHeader";
import NavigationHeader from "components/common/NavigationHeader";
import getPlatform from "utils/getPlatform";
import classNames from "classnames";
import { useOrders } from "apis/activity/queries/useOrders";
import Toast, { ToastVariants } from "components/common/Toast";
import { SpecialTickers } from "utils/constants";

import OrderDetailInfo from "./OrderDetailInfo";
import OrderDetailHeader from "./OrderDetailHeader";
import MepChildOrders from "../MepChildOrders";

import styles from "./styles.module.scss";

const OrderDetail: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { isDesktop } = useDeviceCheck();
  const { data } = useOrders();
  const { isIOS } = getPlatform();
  const dispatch = useAppDispatch();
  const { cancelOrder, isLoading, isSuccess, isError } = useCancelOrder();
  const navigate = useNavigate();

  const currentOrderId = searchParams.get("id");
  const mepOrderId = searchParams.get("mepOrderId");

  const id = currentOrderId ? currentOrderId : mepOrderId;

  const orderDetailData =
    data &&
    data
      .flatMap((response) => response.orders)
      .find(
        (order) =>
          order.orderId === id ||
          order.childOrders?.find((childOrder) => childOrder.orderId === id)
      );

  const childOrder = orderDetailData?.childOrders?.find(
    (order) => order.orderId === id
  );

  const isOpenMep = orderDetailData?.ticker === SpecialTickers.openMep;

  const orderDetail = childOrder || orderDetailData;

  if (!orderDetail) return null;

  const { side, status, orderId, childOrders, ticker } = orderDetail;

  const isMep = !!childOrders || !!childOrder || isOpenMep;

  const getOrderTitle = (side: OperationSide): string => {
    if (side === OperationSide.REDEMPTION) return "Rescate";
    if (side === OperationSide.SUBSCRIPTION) return "Suscripción";
    if (side === OperationSide.BUY) return "Compra";
    if (side === OperationSide.SELL) return "Venta";
    if (side === OperationSide.BID) return "Licitación";
    if (side === OperationSide.TAKER || side === OperationSide.SETTER)
      return "Caución";

    return "Retiro";
  };

  const onCloseOrderDetail = () => {
    if (mepOrderId) {
      return navigate(-1);
    }

    if (isDesktop) dispatch(closeRightModal());

    setSearchParams({});
  };

  const title = `Orden de ${getOrderTitle(side)}`;

  const canCancelOrder = ![
    OrderStatus.REJECTED,
    OrderStatus.CANCELLED,
    OrderStatus.EXECUTED,
    OrderStatus.PARTIALLY_CANCELLED,
  ].includes(status);

  const shouldShowCancelButton =
    canCancelOrder &&
    (side === OperationSide.BUY || side === OperationSide.SELL) &&
    !isMep;

  const toastVariant = isSuccess ? ToastVariants.Success : ToastVariants.Error;
  const toastMessage = isSuccess
    ? `¡Listo! Tu orden de ${ticker} ha sido cancelada con éxito.`
    : `No pudimos cancelar tu orden de ${ticker}. Por favor volvé a intentarlo.`;

  return (
    <div
      className={classNames(styles.orderDetailWrapper, {
        [styles.isIOS]: isIOS,
      })}
    >
      <FixedHeader className={styles.orderDetailNavigationHeader}>
        <NavigationHeader
          title={title}
          onClick={onCloseOrderDetail}
          withCloseIcon={!mepOrderId}
        />
      </FixedHeader>
      <OrderDetailHeader orderDetail={orderDetail} isMepDetail={isMep} />
      <OrderDetailInfo orderDetail={orderDetail} />
      {childOrders && <MepChildOrders childOrders={childOrders} side={side} />}
      {shouldShowCancelButton && (
        <div
          className={classNames(styles.cancelButton, { [styles.isIOS]: isIOS })}
        >
          <FilledButton
            isLoading={isLoading}
            variant={FilledButtonVariants.Warning}
            onClick={async () => await cancelOrder(orderId)}
          >
            Cancelar orden
          </FilledButton>
        </div>
      )}
      {(isError || isSuccess) && (
        <div className={styles.toastWrapper}>
          <Toast message={toastMessage} variant={toastVariant} />
        </div>
      )}
    </div>
  );
};

export default OrderDetail;
