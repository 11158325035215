import { useMutation } from "@tanstack/react-query";
import API from "apis";
import { queryClient } from "apis/queryClient";
import { BannersService } from "apis/services";
import type { Banner } from "interfaces/api-responses";
import type { ErrorMessage } from "interfaces/errors";

type BannerSeenProps = string;

export const useBannerSeen = () => {
  return useMutation<Banner, ErrorMessage, BannerSeenProps>({
    mutationFn: async (banner: string) => {
      const { data } = await API.put<Banner>(
        `${BannersService.banners}/${banner}`,
        {
          seen: true,
        }
      );

      return data;
    },
    onError: (err: ErrorMessage) => {
      return {
        message: err.message,
        status: 500,
      };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["banner"] });
    },
  });
};
