import { useEffect, useState } from "react";

import { Typography } from "@mui/material";
import ChipsContainer from "components/common/chips-container";
import { ConfirmOrder } from "components/common/confirm-order";
import { useTrading } from "context/TradingProvider";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { Instruments, Method, OrderType } from "interfaces/markets";
import Drawer from "layouts/drawer";
import { Navigate, useNavigate } from "react-router-dom";
import mainRoutes, { screenNames } from "router/routes";
import { getOrderById, setConfirmOrderData } from "features/markets";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import { RootState } from "store/store";
import { getLoggedUser } from "store/selectors/user.selector";
import ButtonUI, {
  ButtonSizes,
  ButtonVariants,
} from "components/common/button";
import { formatToCurrency, getComisiones, getCurrencyFormatted } from "utils";
import { trackAction } from "utils/amplitude";
import { Currencies } from "interfaces/wallet";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useAppState } from "context/AppStateProvider";
import { ampli } from "ampli";

import QuickOpError from "../errors";
import TradingFooter from "./TradingFooter";

import styles from "./styles.module.scss";

interface ConfirmSectionProps {
  instrumentProp: any;
  operationAble: boolean;
  isMarketOpen: boolean;
  handleChangePrice: (value: number) => void;
  numOfDecimals: number;
}

const ConfirmSection: React.FC<ConfirmSectionProps> = ({
  instrumentProp,
  operationAble,
  isMarketOpen,
  handleChangePrice,
  numOfDecimals,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { openRightModalContent } = useAppState();
  const { method } = useTrading();
  const user = useAppSelector(getLoggedUser);
  const { available } = useTrading();
  const MIN_AVAILABLE = 5;

  const { isMobile } = useDeviceCheck();
  const [selectedChip, setSelectedChip] = useState<number>();

  const order = useAppSelector((state: RootState) => state.markets.newOrder);
  const isBuy = order.type === OrderType.Buy;
  const noFees =
    order.currency === Currencies.USD || order.currency === Currencies.EXT;

  const comisiones = getComisiones(
    instrumentProp.instrument_type,
    instrumentProp.instrument_subtype,
    order.type,
    order.amount
  );

  const chipsPercentages = [0.25, 0.5, 0.75, 1];

  const chipsOptions = chipsPercentages.map((percentage) => ({
    value: Math.floor(available * percentage),
    label: `${percentage * 100}%`,
  }));

  const checkRightPrice = () => {
    if (order.price) {
      const newPrice = order.price * 1000;
      const newTick = instrumentProp.tick_size * 1000;
      const rest = Math.round(newPrice) % Math.round(newTick) === 0;
      return rest;
    }
    return false;
  };

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  const { hasAccount } = user;

  const isDisabled =
    !operationAble ||
    !hasAccount ||
    !isMarketOpen ||
    (method === Method.LIMIT && !checkRightPrice());

  const handleCloseSidebar = () => {
    if (isMobile) {
      navigate(-1);
      return;
    }
    dispatch(closeRightModal());
  };

  const handleClickOrderRow = (orderId: string) => {
    dispatch(getOrderById(orderId));
  };

  const goToOrder = (data: any) => (
    <Drawer>
      <ConfirmOrder
        data={data}
        onClickOrderDetail={handleClickOrderRow}
        onClose={handleCloseSidebar}
        instrument_type={instrumentProp.instrument_type ?? Instruments.ACCIONES}
      />
    </Drawer>
  );

  useEffect(() => {
    setSelectedChip(undefined);
  }, [order.type]);

  const handleClickChip = (option: number) => {
    if (selectedChip === option) {
      setSelectedChip(undefined);
    } else {
      ampli.predefinedAmountSelected();
      setSelectedChip(option);
    }
    handleChangePrice(option);
  };

  const handleOrder = () => {
    if (order.amount) {
      trackAction(`${screenNames.trading} - Show Confirmation`, {
        side: isBuy ? OrderType.Buy : OrderType.Sell,
        ticker: instrumentProp.instrument_code,
        settlement: order.term,
        type: instrumentProp.instrument_type,
        currency: order.currency,
        price: instrumentProp.last,
        amount: order.amount,
        quantity: order.quantity,
        fee: 0,
      });
      const data = [
        {
          label: "Especie",
          value: `${instrumentProp.long_ticker.split("-")[0]}${
            instrumentProp.instrument_short_name
              ? ` | ${instrumentProp.instrument_short_name}`
              : ""
          }`,
        },
        {
          label: "Plazo de liquidación",
          value: order.term,
        },
        {
          label: "Tipo de Orden",
          value: order.method,
        },
        {
          label: "Precio Estimado",
          value: formatToCurrency({
            value: order.price,
            numberOfDecimals: numOfDecimals,
          }),
        },
        {
          label: `Monto a ${isBuy ? "Invertir" : "Recibir"}`,
          value: formatToCurrency({
            value: comisiones[0],
            numberOfDecimals: numOfDecimals,
          }),
        },
      ];

      if (!noFees) {
        data.push({
          label: "Comisiones Estimadas",
          value: comisiones[1],
        });
      }
      dispatch(setConfirmOrderData(data));
      if (isMobile) {
        navigate(mainRoutes.orderConfirm);
      } else {
        openRightModalContent(goToOrder(data));
      }
    }
  };

  return (
    <div className={styles.divContainer}>
      <Typography id={styles.available}>
        Disponible&nbsp;
        {isBuy
          ? getCurrencyFormatted(instrumentProp.currency, available)
          : available}
      </Typography>
      {available >= MIN_AVAILABLE && (
        <ChipsContainer
          chipsOptions={chipsOptions}
          selectedChip={selectedChip}
          onClickChip={handleClickChip}
        />
      )}
      <QuickOpError instrumentProp={instrumentProp} />
      {hasAccount && (
        <ButtonUI
          disabled={isDisabled}
          size={ButtonSizes.Full}
          variant={ButtonVariants.Primary}
          onClick={handleOrder}
          id={`review-${isBuy ? "buy" : "sell"}-button`}
        >
          Revisar {isBuy ? "Compra" : "Venta"}
        </ButtonUI>
      )}
      <TradingFooter isBuy={isBuy} />
    </div>
  );
};

export default ConfirmSection;
