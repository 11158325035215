import { useEffect } from "react";

import { Typography } from "@mui/material";
import { Currency } from "components/common/currency";
import { useMarket } from "context/MarketProvider";
import { Instruments, SettlementTerms } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";
import {
  OverlappedButtonsSizes,
  UIOverlappedButtons,
} from "components/common/overlapped-buttons";
import { getCurrencyLabel } from "utils";
import { getIsCableEnabled } from "components/common/trading-container/components/trading-body/utils";
import { ampli } from "ampli";

const SecondaryOptions: React.FC = () => {
  const {
    currency,
    available,
    setCurrency,
    setFilterFlag,
    settlementDays,
    setSettlementDays,
    selectedInstrumentType,
  } = useMarket();
  const isFCI = selectedInstrumentType === Instruments.FCI;

  const isCableEnabled = getIsCableEnabled(selectedInstrumentType);

  useEffect(() => {
    if (isFCI) {
      handleChangeTerm(SettlementTerms.CI);
    }
  }, [isFCI]);

  const currencyOptions = [
    {
      label: "AR$",
      value: Currencies.ARS,
    },
    {
      label: "US$",
      value: Currencies.USD,
    },
  ];

  if (isCableEnabled) {
    currencyOptions.push({
      label: "US$ C",
      value: Currencies.EXT,
    });
  }

  const termOptions = [
    {
      label: "C.I.",
      value: SettlementTerms.CI,
    },
    {
      label: "24hs",
      value: SettlementTerms._24,
    },
  ];

  const handleChangeCurrency = (newValue: string) => {
    setFilterFlag(true);
    ampli.marketCurrencyChanged({ currency: newValue });
    if (
      newValue === Currencies.ARS ||
      newValue === Currencies.USD ||
      newValue === Currencies.EXT
    )
      setCurrency(newValue);
  };

  const handleChangeTerm = (newValue: SettlementTerms) => {
    setFilterFlag(true);
    ampli.marketSettlementChanged({ settlement: newValue });
    setSettlementDays(newValue);
  };

  return (
    <div className="options-container">
      <div className="second-options">
        <div className="options-row">
          <div className="option">
            <Typography component="p" variant="textS">
              Moneda
            </Typography>
            <UIOverlappedButtons
              options={currencyOptions}
              selectedValue={currency}
              size={OverlappedButtonsSizes.Small}
              onChangeValue={handleChangeCurrency}
            />
          </div>
          <div className={"option-settlement"}>
            <Typography component="p" variant="textS">
              Plazo de liquidación
            </Typography>
            <UIOverlappedButtons
              className="last"
              disabled={isFCI}
              options={termOptions}
              selectedValue={settlementDays}
              size={OverlappedButtonsSizes.Small}
              onChangeValue={(v) => handleChangeTerm(v as SettlementTerms)}
            />
          </div>
        </div>
        <div className="balance">
          <Typography variant="textS">
            Saldo disponible según plazo:{" "}
          </Typography>
          <Typography variant="textS_bold">
            {`${getCurrencyLabel(currency)} `}
            <Currency>{available}</Currency>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default SecondaryOptions;
