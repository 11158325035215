import ff from "constants/featureFlags";

import { useState } from "react";

import classNames from "classnames";
import getPlatform from "utils/getPlatform";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { CocosFund, FundScreen } from "components/page-capital-funds/helpers";
import NavigationHeader from "components/common/NavigationHeader";
import FixedHeader from "components/common/FixedHeader";
import { FundsHoldingResponse } from "interfaces/fci";
import SegmentedControl from "components/common/SegmentedControl";
import useFeatureFlag from "hooks/useFeatureFlag";

import FundsButtons from "./components/FundsButtons";
import HoldingsTab from "./HoldingsTab";
import InformationTab from "./InformationTab";

import styles from "./styles.module.scss";

interface FundsReturnsProps {
  holdings: FundsHoldingResponse;
  selectedFund: CocosFund;
  onClick: () => void;
  setScreen: (opt: FundScreen) => void;
}

enum FundTab {
  INFO = "Información",
  HOLDINGS = "Tenencia",
}
const fundsTabOptions = [{ name: FundTab.HOLDINGS }, { name: FundTab.INFO }];

const FundsHoldings = ({
  holdings,
  selectedFund,
  onClick,
  setScreen,
}: FundsReturnsProps) => {
  const { isIOS } = getPlatform();
  const { isMobile } = useDeviceCheck();
  const [selectedTab, setSelectedTab] = useState<FundTab>(FundTab.HOLDINGS);
  const { isFeatureEnabled: isInfoTabEnabled } = useFeatureFlag(
    ff.ENABLE_FCI_INFO_TAB
  );

  const handleTabClick = (tab: FundTab) => {
    setSelectedTab(tab);
  };

  return (
    <div
      className={classNames(styles.capitalFundsWrapper, {
        [styles.isIos]: isIOS,
      })}
    >
      <FixedHeader className={styles.fundsHeader}>
        <NavigationHeader
          title="Fondos de inversión"
          onClick={onClick}
          badgeText="24/7"
          withCloseIcon={!isMobile}
        />
        {isInfoTabEnabled && (
          <div className={styles.tabControlContainer}>
            <SegmentedControl
              options={fundsTabOptions}
              selected={selectedTab}
              onClick={(option) => handleTabClick(option)}
            />
          </div>
        )}
      </FixedHeader>
      <div
        className={classNames(styles.fundTabContent, {
          [styles.topTabMargin]: isInfoTabEnabled,
        })}
      >
        {selectedTab === FundTab.HOLDINGS && (
          <HoldingsTab holdings={holdings} selectedFund={selectedFund} />
        )}
        {selectedTab === FundTab.INFO && <InformationTab />}
      </div>
      <FundsButtons handleOnClick={setScreen} />
    </div>
  );
};

export default FundsHoldings;
