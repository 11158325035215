import { useEffect } from "react";

import { ampli } from "ampli";
import classNames from "classnames";
import ErrorPage from "components/common/ErrorPage";
import FilledButton from "components/common/FilledButton";
import Footer from "components/common/Footer";
import { useCocosPay } from "context/CocosPayProvider";
import { PayError, PaySource, PaymentMethodErrors } from "interfaces/pay/enums";
import { PaymentMethod } from "interfaces/pay/interface";
import { ThemeVariants } from "interfaces/theme";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import { Currencies } from "interfaces/wallet";

import PayLegals from "../Legals";
import PayMethodConfirm from "./components/ConfirmBottomSheet";
import MethodHeader from "./components/MethodHeader";
import MethodsList from "./components/MethodsList";

import styles from "./styles.module.scss";

interface PayMethodProps {
  isCapital?: boolean;
}

const PayMethod: React.FC<PayMethodProps> = ({ isCapital = false }) => {
  const {
    availableMethods,
    error,
    paymentMethodMessages,
    source,
  } = useCocosPay();

  const isBridge = source === PaySource.BRIDGE;

  const getVariant = () => {
    if (isCapital) {
      return ThemeVariants.Capital;
    }

    if (isBridge) {
      return ThemeVariants.Crypto;
    }

    return ThemeVariants.Pay;
  };

  const navigate = useNavigate();

  const noBalance = !!paymentMethodMessages.find(
    (value) =>
      value === PaymentMethodErrors.ARS_DEBT ||
      value === PaymentMethodErrors.INSUFFICIENT_FUNDS
  );

  useEffect(() => {
    if (availableMethods) {
      if (isBridge) {
        const methodsWithFunds = availableMethods
          .reduce((acc, method) => {
            if (method.hasEnoughFunds) {
              acc.push(method.name);
            }
            return acc;
          }, [] as string[])
          .join(", ");

        ampli.cclCryptoPaymentMethods({
          available_methods: methodsWithFunds,
        });
      } else {
        const ars = availableMethods.find(
          (method) => method.name === Currencies.ARS
        );
        const usd = availableMethods.find(
          (method) => method.name === Currencies.USD
        );

        const properties = {
          balance_ok: availableMethods.some(
            (method: PaymentMethod) => method.hasEnoughFunds
          ),
          amount_ars: ars?.amount || 0,
          amount_usd: usd ? usd?.amount * (usd?.exchangeRate || 1) : 0,
          balance_ars: ars?.amountAvailable || 0,
          balance_usd: usd?.amountAvailable || 0,
          exchange_rate: usd?.exchangeRate,
        };
        ampli.payScreenMethod(properties);
      }
    }
  }, [availableMethods]);

  const handleDeposit = () => {
    ampli.payDeposit();
    navigate(mainRoutes.receive);
  };

  if (error === PayError.PAYMENT_METHODS_ERROR) {
    return (
      <ErrorPage
        errorMessage="En este momento no es posible mostrar esta página."
        capitalTheme
      />
    );
  }

  return (
    <div
      className={classNames(styles.payMethodContainer, {
        [styles.noBalance]: noBalance,
      })}
    >
      <div>
        <MethodHeader balance={!noBalance} isCapital={isCapital} />
        <MethodsList />
      </div>
      <div>
        <PayLegals />
        {noBalance && (
          <Footer>
            <FilledButton
              className={styles.depositMoneyButton}
              variant={getVariant()}
              onClick={handleDeposit}
            >
              Ingresar dinero
            </FilledButton>
          </Footer>
        )}
      </div>
      <PayMethodConfirm variant={getVariant()} />
    </div>
  );
};

export default PayMethod;
