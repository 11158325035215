import { useActivity } from "context/ActivityProvider";
import { isEmpty } from "lodash";
import EmptyStateWithOnboarding from "components/common/EmptyStateWithOnboarding";
import mainRoutes from "router/routes";
import FirstInvestment from "assets/illustrations/FirstInvestment";
import { EmptyButtonTypes } from "components/common/EmptyState";
import { ThemeVariants } from "interfaces/theme";
import { useNavigate } from "react-router-dom";
import { useOrders } from "apis/activity/queries/useOrders";
import Skeleton from "react-loading-skeleton";

import BottomText from "../../components/BottomText";
import OrdersList from "./OrdersList";
import FilterPills from "../../components/FilterPills";
import { getHasFilteredOrders } from "../../utils";

import styles from "./style.module.scss";

const OrdersContent = () => {
  const { data: ordersData, isLoading, isRefetching } = useOrders();
  const { orderFilter, setOrderFilter } = useActivity();
  const navigate = useNavigate();

  if (isLoading || isRefetching)
    return (
      <div className={styles.activitySkeletonContainer}>
        <Skeleton height={50} count={4} className={styles.activitySkeleton} />
      </div>
    );

  const hasFilteredOrders = getHasFilteredOrders(orderFilter, ordersData);

  return (
    <div className={styles.activityContainer}>
      <FilterPills
        activeFilter={orderFilter}
        setActiveFilter={setOrderFilter}
      />
      {isEmpty(ordersData) || !ordersData || !hasFilteredOrders ? (
        <EmptyStateWithOnboarding
          extraEmptyState
          title="Hacé una inversión"
          buttonText="Ir al mercado"
          className={styles.emptyState}
          illustration={<FirstInvestment />}
          buttonType={EmptyButtonTypes.BUTTON}
          buttonVariant={ThemeVariants.Capital}
          onClick={() => navigate(mainRoutes.market)}
          subtitle="Visitá el mercado para invertir en Cedears, Acciones, Bonos Públicos, ONs, Letras, Fondos de Inversión y más."
        />
      ) : (
        <>
          <OrdersList orders={ordersData} filterStatus={orderFilter} />
          <BottomText />
        </>
      )}
    </div>
  );
};

export default OrdersContent;
