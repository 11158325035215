import API from "apis";
import { useAppQuery } from "apis/queryClient";
import { bannerKeys } from "apis/queryKeyFactories";
import { BannersService } from "apis/services";
import { Banner } from "interfaces/api-responses";

export const fetchBanner = async (banner: string) => {
  const { data } = await API.get<Banner>(`${BannersService.banners}/${banner}`);

  return data;
};

export const useBanner = (banner: string) => {
  return useAppQuery({
    options: {
      queryKey: bannerKeys.banner(banner),
      queryFn: () => fetchBanner(banner),
    },
  });
};
