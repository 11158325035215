import { IconProps } from "../../BTC";

const DOTIcon: React.FC<IconProps> = ({ size = 24, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <circle cx="12" cy="12" r="12" fill="#EDF0F4" />
    <path
      d="M12.0516 4C8.715 4.0134 6.0134 6.715 6 10.0516C6 10.7198 6.10786 11.3836 6.31911 12.0173C6.47675 12.4488 6.94457 12.6811 7.38367 12.5477C7.80808 12.385 8.03338 11.921 7.89807 11.487C7.71873 10.993 7.63895 10.469 7.66257 9.9444C7.73533 7.53447 9.74764 5.63959 12.1576 5.71235C14.5675 5.78511 16.4624 7.79742 16.3896 10.2073C16.3213 12.4635 14.5445 14.2958 12.291 14.433C12.291 14.433 11.4415 14.4847 11.019 14.5364C10.8633 14.5587 10.7088 14.5894 10.5563 14.6283C10.5346 14.6507 10.4995 14.6507 10.4771 14.629L10.4765 14.6283C10.4612 14.6085 10.4612 14.5804 10.4765 14.5607L10.608 13.8433L11.4057 10.2552C11.5015 9.80718 11.2162 9.36617 10.7682 9.27044C10.3201 9.17471 9.87911 9.45999 9.78338 9.90802C9.78338 9.90802 7.88595 18.6906 7.88595 18.7704C7.77745 19.1973 8.03529 19.6313 8.46226 19.7398C8.46673 19.7411 8.47183 19.7424 8.4763 19.743H8.52034C8.94667 19.8534 9.38194 19.5969 9.49171 19.1705C9.49363 19.1635 9.49554 19.1565 9.49682 19.1488C9.49554 19.1354 9.49554 19.122 9.49682 19.1093C9.52043 19.0059 9.75977 17.8373 9.75977 17.8373C9.94102 16.9553 10.652 16.2794 11.5417 16.1428C11.7249 16.1147 12.4945 16.063 12.4945 16.063C15.8184 15.7318 18.2443 12.7685 17.9124 9.44467C17.6099 6.41312 15.0985 4.07914 12.0516 4Z"
      fill="#1E1E1E"
    />
    <path
      d="M12.4186 17.9535C11.8729 17.8398 11.3394 18.1902 11.2258 18.7353C11.2245 18.7404 11.2238 18.7455 11.2226 18.7506C11.1045 19.2924 11.4479 19.8279 11.9903 19.946C11.991 19.946 11.9916 19.946 11.9923 19.9466H12.0203C12.5513 20.0749 13.0855 19.7481 13.2132 19.2178C13.2145 19.2139 13.2151 19.2095 13.2164 19.2056V19.1501C13.3102 18.6025 12.9604 18.0773 12.4186 17.9535Z"
      fill="#E6007A"
    />
  </svg>
);

export default DOTIcon;
