import { useState } from "react";

import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import { useMarket } from "context/MarketProvider";
import { MarketSection, Sections } from "interfaces/markets";
import MobileHeader from "components/page-market/mobile/components/Header";
import DesktopHeader from "components/page-market/desktop/components/Header";
import MEPSelector from "components/common/MEPSelector";
import { ampli } from "ampli";

const Header: React.FC = () => {
  const { setFilterFlag, selectedInstrumentType } = useMarket();
  const [isMepOpen, setIsMepOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleChangeSection = (list: MarketSection | Sections.MEP) => {
    if (selectedInstrumentType === list) return;
    ampli.instrumentTypeChosen({ type: list });
    setFilterFlag(true);
    if (list !== Sections.MEP) {
      navigate(`${mainRoutes.market}/${list}`);
      return;
    }
    setIsMepOpen(true);
  };

  return (
    <>
      <MobileHeader />
      <DesktopHeader onChangeSection={handleChangeSection} />
      <MEPSelector isOpen={isMepOpen} onClose={() => setIsMepOpen(false)} />
    </>
  );
};

export default Header;
