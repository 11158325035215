import API from "apis";
import { useAppQuery } from "apis/queryClient";
import { portfolioKeys } from "apis/queryKeyFactories";
import { portfolioService } from "apis/services";
import {
  PortfolioHistoryMetric,
  PeriodTime,
  PortfolioHistoryResponse,
} from "interfaces/portfolio";
import type { BalanceCurrencies } from "interfaces/wallet";

export const fetchPortfolioHistoryData = async (
  currency: BalanceCurrencies,
  period: PeriodTime
) => {
  const { data } = await API.get<PortfolioHistoryResponse>(
    portfolioService.history,
    {
      params: { metric: PortfolioHistoryMetric.BALANCE, period, currency },
    }
  );

  if (!data || !data.data || data.data.length === 0) {
    throw new Error("No historic data available");
  }

  return data;
};

export const usePortfolioHistoryQuery = (
  currency: BalanceCurrencies,
  period: PeriodTime
) => {
  return useAppQuery({
    options: {
      queryKey: portfolioKeys.history(currency, period),
      queryFn: () => fetchPortfolioHistoryData(currency, period),
    },
  });
};
