import { useEffect, useState } from "react";

import API from "apis";
import { ampli } from "ampli";
import { cardService } from "apis/services";
import { useShippingPaymentMethodsQuery } from "apis/card/queries/useShippingPaymentMethods";
import FilledButton from "components/common/FilledButton";
import Heading, { HeadingVariants } from "components/common/Heading";
import OutlinedButton from "components/common/OutlinedButton";
import Text, { TextVariants } from "components/common/Text";
import { UIModalSlider } from "components/common/modal-slider";
import { useCocosCard } from "context/CocosCardProvider";
import CardErrorScreen from "components/page-cocos-card/Error";
import {
  CardSections,
  CardStatus,
  CardTypes,
  UserStatus,
} from "interfaces/card/enums";
import { REGION_OPTIONS } from "components/page-cocos-card/constants";
import LoadingScreen from "components/page-cocos-card/Loading";
import { ThemeVariants } from "interfaces/theme";

import styles from "./styles.module.scss";

interface ShippingConfirmProps {
  open: boolean;
  onClose: () => void;
}

const ShippingConfirm: React.FC<ShippingConfirmProps> = ({ open, onClose }) => {
  const {
    shippingInfo,
    setSelectedSection,
    pomeloData,
    getPomeloData,
    virtualCard,
    userStatus,
  } = useCocosCard();

  const { data: shippingData } = useShippingPaymentMethodsQuery();

  const [isCardRequested, setIsCardRequested] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const slate800 = "var(--slate800)";
  const slate900 = "var(--slate900)";

  const shippingInfoFields = [
    {
      label: "Provincia",
      value: REGION_OPTIONS.find((r) => r.value === shippingInfo?.region)
        ?.label,
    },
    { label: "Localidad", value: shippingInfo?.city },
    { label: "Calle", value: shippingInfo?.street_name },
    { label: "Altura", value: shippingInfo?.street_number },
    { label: "Código Postal", value: shippingInfo?.zip_code },
  ];

  const handleFreeShippingConfirm = async () => {
    setIsLoading(true);

    try {
      const endpoint = pomeloData ? cardService.requestCard : cardService.user;

      const requestCardCreation = pomeloData
        ? [CardTypes.PHYSICAL]
        : [CardTypes.PHYSICAL, CardTypes.VIRTUAL];

      const payload = {
        requestCardCreation,
        shippingInformation: { ...shippingInfo, country: "ARG" },
      };

      await API.post(endpoint, payload);
      await getPomeloData();
      setIsCardRequested(true);
    } catch (e) {
      setError(true);
    }
  };

  const handleConfirm = async () => {
    ampli.cardDeliveryConfirm({ province: shippingInfo?.city });
    if (shippingData?.isFreeShipping) {
      handleFreeShippingConfirm();
      return;
    }

    setSelectedSection(CardSections.SHIPPING_PAYMENT);
  };

  useEffect(() => {
    if (isCardRequested) {
      if (virtualCard?.status === CardStatus.ACTIVE) {
        setIsLoading(false);
        setSelectedSection(CardSections.PHYSICAL_READY);
      } else if (userStatus === UserStatus.FAILED) {
        ampli.cardPhysicalCreationFailed();
        setIsLoading(false);
        setError(true);
      }

      const interval = setInterval(() => {
        getPomeloData();
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [userStatus, isCardRequested]);

  if (isLoading) {
    return (
      <LoadingScreen
        title="Configurando tu cuenta..."
        subtitle="Esto puede tardar unos segundos."
      />
    );
  }

  if (error) return <CardErrorScreen />;

  return (
    <UIModalSlider
      open={open}
      onToggleDisplay={onClose}
      classes={styles.shippingConfirmContainer}
    >
      <Heading
        variant={HeadingVariants.RegularSubtitle}
        color={slate900}
        component="h2"
        className={styles.title}
      >
        Confirmá tus datos
      </Heading>
      <Text variant={TextVariants.RegularText} color={slate800}>
        Datos de envío
      </Text>
      <div className={styles.confirmBottomSheetDetail}>
        {shippingInfoFields.map((field) => (
          <div className={styles.row} key={field.label}>
            <Text variant={TextVariants.RegularText} color={slate800}>
              {field.label}
            </Text>
            <Text variant={TextVariants.SemiboldText} color={slate800}>
              {field.value}
            </Text>
          </div>
        ))}
      </div>
      <div className={styles.buttonsWrapper}>
        <OutlinedButton variant={ThemeVariants.Pay} onClick={onClose}>
          Editar
        </OutlinedButton>
        <FilledButton
          variant={ThemeVariants.Pay}
          onClick={handleConfirm}
          isLoading={isLoading}
        >
          Confirmar
        </FilledButton>
      </div>
    </UIModalSlider>
  );
};

export default ShippingConfirm;
