import API from "apis";
import { useAppQuery } from "apis/queryClient";
import { portfolioKeys } from "apis/queryKeyFactories";
import { portfolioService } from "apis/services";
import type { PortfolioQueryResponse } from "interfaces/portfolio";
import type { BalanceCurrencies, PortfolioFromType } from "interfaces/wallet";

export const fetchPortfolioData = async (
  currency: BalanceCurrencies,
  from: PortfolioFromType
) => {
  const { data } = await API.get<PortfolioQueryResponse>(
    portfolioService.portfolio,
    {
      params: { currency, from },
    }
  );

  return data;
};

const TEN_MINUTES = 10 * 60 * 1000;

export const usePortfolioQuery = (
  currency: BalanceCurrencies,
  from: PortfolioFromType
) => {
  return useAppQuery({
    options: {
      queryKey: portfolioKeys.portfolio(currency, from),
      queryFn: () => fetchPortfolioData(currency, from),

      staleTime: TEN_MINUTES,
      gcTime: TEN_MINUTES,
    },
  });
};
