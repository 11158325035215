import { useState } from "react";

import { useShippingPaymentMethodsQuery } from "apis/card/queries/useShippingPaymentMethods";
import FilledButton from "components/common/FilledButton";
import { PaymentMethodErrors } from "interfaces/pay/enums";
import { ThemeVariants } from "interfaces/theme";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import { PaymentMethod } from "interfaces/pay/interface";

import PayMethodConfirm from "./components/ConfirmBottomSheet";
import MethodHeader from "./components/MethodHeader";
import MethodsList from "./components/MethodsList";

import styles from "./styles.module.scss";

const PayMethod: React.FC = () => {
  const [selectedMethod, setSelectedMethod] = useState<PaymentMethod>();

  const {
    data: shippingPaymentData,
    isLoading,
  } = useShippingPaymentMethodsQuery();

  const navigate = useNavigate();

  if (isLoading || !shippingPaymentData) return null;

  const hasDebt = shippingPaymentData.messages.includes(
    PaymentMethodErrors.ARS_DEBT
  );

  const noBalance = !!shippingPaymentData.messages.find(
    (value: string) =>
      value === PaymentMethodErrors.ARS_DEBT ||
      value === PaymentMethodErrors.INSUFFICIENT_FUNDS
  );

  const handleDeposit = () => {
    navigate(mainRoutes.receive);
  };

  return (
    <div className={styles.payMethodContainer}>
      <div>
        <MethodHeader
          balance={!noBalance}
          quantity={shippingPaymentData.shippingAmount}
        />
        <MethodsList
          availableMethods={shippingPaymentData.paymentMethods}
          setSelectedMethod={setSelectedMethod}
          hasDebt={hasDebt}
        />
      </div>
      <div>
        {noBalance && (
          <FilledButton
            className={styles.depositMoneyButton}
            variant={ThemeVariants.Pay}
            onClick={handleDeposit}
          >
            Ingresar dinero
          </FilledButton>
        )}
      </div>
      <PayMethodConfirm
        selectedMethod={selectedMethod}
        setSelectedMethod={setSelectedMethod}
        shippingAmount={shippingPaymentData.shippingAmount}
      />
    </div>
  );
};

export default PayMethod;
