import API from "apis";
import { activityKeys } from "apis/queryKeyFactories";
import { useAppQuery } from "apis/queryClient";
import type { OrdersResponseData } from "interfaces/orders/interface";
import { ordersService } from "apis/services";

const FIVE_MINUTES = 5 * 60 * 1000;

export const fetchOrders = async () => {
  const {
    data: { data },
  } = await API.get<OrdersResponseData>(ordersService.newOrders);

  return data;
};

export const useOrders = () => {
  return useAppQuery({
    options: {
      queryKey: activityKeys.orders,
      queryFn: async () => await fetchOrders(),
      staleTime: FIVE_MINUTES,
      gcTime: FIVE_MINUTES,
      select: (data) => {
        return data.map((ordersData) => {
          const mappedOrders = ordersData.orders.map((order) => {
            if (!order.quantity) {
              return order;
            }

            return {
              ...order,
              quantity: {
                executed: Number(order.quantity.executed.toFixed(2)),
                total: Number(order.quantity.total.toFixed(2)),
              },
            };
          });
          return { ...ordersData, orders: mappedOrders };
        });
      },
    },
  });
};
