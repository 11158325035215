import { AnimatePresence, motion } from "framer-motion";
import { AnalisisResult } from "components/common/BondCalculator/result";
import CommonLayout from "layouts/common";
import { DesktopOrTablet, Mobile } from "utils/responsive";

import "./index.scss";

interface ResultsPageProps {
  isInversion?: boolean;
}

const ResultsPage: React.FC<ResultsPageProps> = ({ isInversion }) => (
  <>
    <Mobile>
      <AnimatePresence>
        <motion.div
          className="page-calculadora"
          layoutId={"cal"}
          transition={{ duration: 0.1 }}
        >
          <CommonLayout
            hideBottomNavigation
            mobileHeaderTitle="Resultado del análisis"
            withHeader
          >
            <AnalisisResult isInversion={isInversion} />
          </CommonLayout>
        </motion.div>
      </AnimatePresence>
    </Mobile>
    <DesktopOrTablet>
      <AnalisisResult isInversion={isInversion} />
    </DesktopOrTablet>
  </>
);

export default ResultsPage;
