import { Cryptos, getCryptoElement } from "constants/cryptos";

import ARGFlag from "assets/icons/ARGFlag";
import AssetSelectCard from "components/common/AssetSelectCard";
import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";
import { UIModalSlider } from "components/common/modal-slider";
import { Currencies } from "interfaces/wallet";
import { useTickersQuery } from "apis/crypto/queries/useTickers";

import styles from "./styles.module.scss";

interface TransactionBottomSheetProps {
  isOpen: boolean;
  onToggleDisplay: () => void;
  onClickAsset: (asset: Cryptos | Currencies.ARS) => void;
  quoteCurrency?: Cryptos | Currencies.ARS;
  baseCurrency?: Cryptos | Currencies.ARS;
}

const TransactionBottomSheet: React.FC<TransactionBottomSheetProps> = ({
  isOpen,
  onClickAsset,
  baseCurrency,
  quoteCurrency,
  onToggleDisplay,
}) => {
  const { data: cryptos } = useTickersQuery();

  const showARSCurrency =
    baseCurrency !== Currencies.ARS && quoteCurrency !== Currencies.ARS;

  const filteredCryptos = cryptos?.filter(
    ({ ticker }) => ticker !== baseCurrency && ticker !== quoteCurrency
  );
  return (
    <UIModalSlider
      open={isOpen}
      onToggleDisplay={onToggleDisplay}
      classes={styles.sheetWrapper}
    >
      <Text
        variant={TextVariants.RegularText}
        color="var(--slate900)"
        className={styles.title}
      >
        Seleccioná un activo
      </Text>
      <div>
        {showARSCurrency && (
          <>
            <Heading
              variant={HeadingVariants.RegularSubtitle}
              component="h2"
              color="var(--slate800)"
              className={styles.subtitle}
            >
              Moneda
            </Heading>
            <div
              className={styles.assetWrapper}
              onClick={() => onClickAsset(Currencies.ARS)}
            >
              <AssetSelectCard
                icon={ARGFlag}
                ticker={Currencies.ARS}
                name={"Peso Argentino"}
              />
            </div>
          </>
        )}
        <Heading
          variant={HeadingVariants.RegularSubtitle}
          component="h2"
          color="var(--slate800)"
          className={styles.subtitle}
        >
          Cripto
        </Heading>
        {filteredCryptos?.map(({ ticker, name }) => {
          const { icon } = getCryptoElement(ticker);
          return (
            <div
              key={ticker}
              className={styles.assetWrapper}
              onClick={() => onClickAsset(ticker)}
            >
              <AssetSelectCard icon={icon} ticker={ticker} name={name} />
            </div>
          );
        })}
      </div>
    </UIModalSlider>
  );
};

export default TransactionBottomSheet;
