import { getCryptoElement } from "constants/cryptos";

import AssetValueCard from "components/common/AssetValueCard";
import { CryptoPrice } from "interfaces/api-responses";
import { useNavigate } from "react-router-dom";
import { CryptoRoutes } from "router/routes";

import styles from "./styles.module.scss";

interface AssetsListProps {
  cryptos: CryptoPrice[];
}

const AssetsList: React.FC<AssetsListProps> = ({ cryptos }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.assetsListContainer}>
      {cryptos.map(({ baseTicker, last, varDailyPricePercentage }) => {
        const { icon, name } = getCryptoElement(baseTicker);
        return (
          <AssetValueCard
            key={baseTicker}
            icon={icon}
            name={name}
            ticker={baseTicker}
            price={last}
            variation={varDailyPricePercentage}
            onClick={() => navigate(`${CryptoRoutes.MARKET}/${baseTicker}`)}
          />
        );
      })}
    </div>
  );
};

export default AssetsList;
