import API from "apis";
import { useAppQuery } from "apis/queryClient";
import { portfolioKeys } from "apis/queryKeyFactories";
import { cryptoService, walletService } from "apis/services";
import { PerformancePeriods } from "components/page-cocos-portfolio/utils";
import {
  CryptoPerformance,
  PerformanceDailyResponse,
} from "interfaces/api-responses";
import { Instruments } from "interfaces/markets";
import type { PerformanceHolding } from "interfaces/portfolio";
import { PeriodTime } from "interfaces/portfolio";

export const fetchPerformanceData = async (
  period: PeriodTime,
  isCrypto: boolean
) => {
  const isDaily = period === PeriodTime.DAY;

  if (isCrypto) {
    const period = isDaily
      ? PerformancePeriods.DAILY
      : PerformancePeriods.HISTORIC;

    const { data } = await API.get<CryptoPerformance[]>(
      `${cryptoService.performance}/${period}`
    );

    const transformedData = data.map((item) => ({
      instrument_code: item.ticker,
      instrument_short_name: item.ticker,
      instrument_type: Instruments.ACCIONES,
      short_ticker: item.ticker,
      quantity: item.quantity,
      last: 1,
      result: item.revenue,
      average_price: 1,
      result_percentage: item.revenuePercentage,
      logo_file_name: "",
    }));
    return { tickers: transformedData };
  }

  if (isDaily) {
    const { data } = await API.get<PerformanceDailyResponse>(
      walletService.dailyPerformance
    );

    const formattedDailyData = data.tickers.map(
      ({
        instrument_code,
        instrument_short_name,
        instrument_type,
        logo_file_name,
        short_ticker,
        quantity,
        last_price,
        result,
        percentage,
      }) => ({
        instrument_code,
        instrument_short_name,
        instrument_type,
        short_ticker,
        quantity,
        last: last_price,
        result,
        average_price: 1,
        result_percentage: percentage,
        logo_file_name,
      })
    );

    return { tickers: formattedDailyData };
  }

  const { data } = await API.get<PerformanceHolding[]>(
    walletService.historicPerformance
  );

  return { tickers: data };
};

export const usePerformanceQuery = (period: PeriodTime, isCrypto: boolean) => {
  return useAppQuery({
    options: {
      queryKey: portfolioKeys.performance(period, isCrypto),
      queryFn: () => fetchPerformanceData(period, isCrypto),
    },
  });
};
