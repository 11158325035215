import {
  DefaultError,
  QueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { Cookies } from "react-cookie";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const useAppQuery = <
  TQueryFnData,
  TError = DefaultError,
  TData = TQueryFnData
>({
  options,
}: {
  options: UseQueryOptions<TQueryFnData, TError, TData>;
}) => {
  const withAccountId = (queryKeys: readonly unknown[]) => {
    const cookies = new Cookies();
    const idAccount = cookies.get("id_account");

    if (!Array.isArray(queryKeys)) {
      throw new Error("queryKey must be an array");
    }

    return [...queryKeys, idAccount] as const;
  };

  return useQuery({ ...options, queryKey: withAccountId(options.queryKey) });
};
