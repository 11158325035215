import { PaymentCurrencies } from "interfaces/pay/enums";
import { IconProps } from "assets/icons/BTC";

import {
  CardTypes,
  CardStatus,
  UserStatus,
  CardTransactionStatus,
  CardTransactionType,
  CardTransactionActionType,
} from "../enums";

export interface PaymentMethodProfile {
  amountAvailable: number;
  amountAvailableArs: number;
  paymentMethod: PaymentCurrencies;
}

export interface Card {
  activatedAt: string;
  affinityGroupId: string;
  affinityGroupName: string;
  cardType: CardTypes;
  company: string;
  externalId: string;
  idCard: string;
  idCardUser: string;
  idExternal: string;
  idUserExternal: string;
  isShipping?: boolean;
  lastFour: string;
  nameOnCard: string;
  paymentMethodProfile: PaymentMethodProfile[];
  productType: string;
  provider: string;
  shipmentId: string;
  startDate: string;
  status: CardStatus;
  courierCompany?: string;
  courierTrackingUrl?: string | null;
}

export interface PomeloData {
  availableRequestCardCreation: CardTypes[];
  cardExternalProvider: string;
  cards: Card[];
  externalStatus: string;
  failedReason: string;
  idAccount: number;
  idCardUser: string;
  idExternal: string;
  status: UserStatus;
}

export interface PaymentMethodProps {
  name: string;
  icon: React.FC<IconProps>;
  ticker: PaymentCurrencies;
}

export interface CardTransactionPayment {
  created_at: string;
  updated_at: string;
  id_card_transaction_payment: string;
  id_card_transaction: string;
  amount: number;
  payment_method: PaymentCurrencies;
  payment_method_details?: Record<string, any> | null;
  status: string;
}

export interface CardTransaction {
  created_at: string;
  updated_at: string;
  id_card_transaction: string;
  id_card_transaction_short: string;
  id_external: string;
  id_account: number;
  id_card_user: string;
  id_card: string;
  type: CardTransactionType;
  origin: string;
  source: string;
  status: CardTransactionStatus;
  status_detail?: string | null;
  merchant_name: string;
  amount_local: number;
  amount_local_currency: string;
  amount_settlement: number;
  amount_settlement_currency: string;
  amount_transaction: number;
  amount_transaction_currency: string;
  amount_details: Record<string, any>;
  transaction_type?: CardTransactionActionType | null;
  payments: CardTransactionPayment[];
}

export interface CardTransactionsGroup {
  timestamp: string;
  transactions: CardTransaction[];
  total: number;
}

export interface CardTransactionsGroupResponse {
  groups: CardTransactionsGroup[];
}

export interface CardPaymentMethods {
  amountAvailable: number;
  amountAvailableArs: number;
  name: PaymentCurrencies;
}

export interface ShippingInfoValues {
  region: string;
  city: string;
  street_name: string;
  street_number: string;
  zip_code: string;
  floor: string;
  apartment: string;
  additional_info: string;
}

export type PaymentMethodSelected = PaymentCurrencies | null;

export type PaymentCurrencyDetails = {
  type: PaymentTypes;
  name: string;
  icon: React.FC<IconProps>;
  ticker: PaymentCurrencies;
};

export enum PaymentTypes {
  CURRENCY = "CURRENCY",
  FCI = "FCI",
  CRYPTO = "CRYPTO",
}
