import { IconProps } from "../../BTC";

const XRPIcon: React.FC<IconProps> = ({ size = 24, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M23.6383 14.9029C22.0356 21.3315 15.5244 25.2438 9.09503 23.6407C2.66833 22.0381 -1.24401 15.5265 0.359382 9.09837C1.96137 2.66903 8.47253 -1.24361 14.8999 0.359081C21.3289 1.96177 25.241 8.47405 23.6381 14.903L23.6382 14.9029H23.6383Z"
      fill="#23292F"
    />
    <path
      d="M17.9004 5H20.322L15.2825 9.98976C13.4575 11.7958 10.4986 11.7958 8.67223 9.98976L3.63076 5H6.0543L9.88302 8.79043C11.0438 9.93443 12.908 9.93443 14.0688 8.79043L17.9004 5Z"
      fill="white"
    />
    <path
      d="M6.02322 18.875H3.6L8.67223 13.8545C10.4972 12.0484 13.4562 12.0484 15.2825 13.8545L20.3547 18.875H17.9331L14.0717 15.0538C12.911 13.9098 11.0467 13.9098 9.88596 15.0538L6.02322 18.875Z"
      fill="white"
    />
  </svg>
);

export default XRPIcon;
