import { ArrowUpRight } from "lucide-react";
import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";
import Link from "components/common/Link";
import { ThemeVariants } from "interfaces/theme";

import CARP from "./CARP";
import RiverBG from "./BG";

import styles from "./styles.module.scss";

interface BidBannerProps {
  onClick: () => void;
  tir: number;
  title: string;
}

const BidBanner = ({ onClick, tir, title }: BidBannerProps) => {
  return (
    <div className={styles.bannerContainer}>
      <div className={styles.wrapper} onClick={onClick}>
        <div className={styles.header}>
          <div className={styles.title}>
            <div className={styles.icon}>
              <CARP />
            </div>
            <Heading
              variant={HeadingVariants.SemiboldSubtitle}
              color="var(--slate100)"
              component="h2"
            >
              {title}
            </Heading>
          </div>
          <Link
            onClick={onClick}
            variant={ThemeVariants.Pay}
            icon={ArrowUpRight}
            isSmall
            textColor="var(--slate200)"
            backgroundColor="var(--slate800)"
          >
            Licitar
          </Link>
        </div>
        <div className={styles.price}>
          <div className={styles.priceSection}>
            <Text variant={TextVariants.SemiboldTextS} color="var(--slate200)">
              Tasa estimada: {String(tir).replace(".", ",")}%
            </Text>
          </div>
        </div>
      </div>
      <RiverBG className={styles.bgFlag} />
    </div>
  );
};

export default BidBanner;
