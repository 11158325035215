import { Cryptos } from "constants/cryptos";

import { IconProps } from "assets/icons/BTC";
import Text, { TextVariants } from "components/common/Text";
import { Currencies } from "interfaces/wallet";
import { formatToCurrency } from "utils";
import px2rem from "utils/px2rem";
import classNames from "classnames";
import { CryptoTicker } from "interfaces/api-responses";
import { useCryptoByTicker } from "hooks/useCryptoByTicker";

import styles from "./styles.module.scss";

interface SendAssetProps {
  icon: React.FC<IconProps>;
  name: string;
  ticker: Cryptos | Currencies.ARS | string;
  price?: number;
  variation?: number;
  onClick?: (asset: CryptoTicker) => void;
  isPerformance?: boolean;
  disabled?: boolean;
}

const MIN_FOR_TWO_DECIMALS = 0.05;

const SendAsset: React.FC<SendAssetProps> = ({
  icon: Icon,
  name,
  ticker,
  price,
  onClick,
  disabled,
}) => {
  const crypto = useCryptoByTicker(ticker);

  const decimals = price && price < MIN_FOR_TWO_DECIMALS ? 8 : 2;

  const onClickAsset = () => {
    if (disabled) return;

    onClick && crypto && onClick(crypto);
  };

  return (
    <div
      className={classNames(styles.assetRowContainer, {
        [styles.disabled]: disabled,
      })}
      onClick={onClickAsset}
    >
      <div className={styles.asset}>
        <Icon size={px2rem(24)} />
        <Text variant={TextVariants.RegularText} color="var(--slate900)">
          {name}
        </Text>
      </div>
      {price && (
        <div className={styles.price}>
          <Text
            variant={TextVariants.SemiboldUpperValue}
            color="var(--slate900)"
          >
            {formatToCurrency({ value: price, numberOfDecimals: decimals })}
          </Text>

          <Text
            variant={TextVariants.RegularUpperValue}
            color="var(--slate800)"
          >
            {ticker}
          </Text>
        </div>
      )}
    </div>
  );
};

export default SendAsset;
