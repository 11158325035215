import API from "apis";
import { useAppQuery } from "apis/queryClient";
import { payKeys } from "apis/queryKeyFactories";
import { paymentService } from "apis/services";
import { PixPrices } from "interfaces/api-responses";

const ONE_MINUTE = 60000;

const fetchPixPrices = async () => {
  const { data } = await API.get<PixPrices>(paymentService.pixPrices);

  return data;
};

export const usePixPricesQuery = () => {
  return useAppQuery({
    options: {
      queryKey: payKeys.prices,
      queryFn: fetchPixPrices,
      retry: false,
      refetchInterval: ONE_MINUTE,
      staleTime: ONE_MINUTE,
      gcTime: ONE_MINUTE,
    },
  });
};
