import { CocosFunds } from "constants/instruments";

import { useEffect } from "react";

import Text, { TextVariants } from "components/common/Text";
import BasicWrapper from "components/common/BasicWrapper";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import Amount from "components/common/Amount";
import Success from "assets/illustrations/Success";
import { Currencies, BalanceCurrencies } from "interfaces/wallet";
import { useAppDispatch } from "hooks/redux";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { OrderType, OptionalOrder } from "interfaces/markets";
import { ampli } from "ampli";
import { FbEvents, trackFbEvent } from "utils/fbTracking";
import GoogleTagManager from "react-gtm-module";

import styles from "./styles.module.scss";

interface FundSuccessProps {
  currency: BalanceCurrencies;
  order: OptionalOrder;
  onClose: () => void;
}

const FundSuccess = ({ currency, order, onClose }: FundSuccessProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isDesktop } = useDeviceCheck();

  const { amount, type, instrument_code } = order;

  const isBuy = type === OrderType.Buy;

  const orderText = isBuy ? "Suscribiste" : "Rescataste";
  const currencyText = currency === Currencies.ARS ? "pesos" : "dólares";
  const text = isBuy
    ? `Ya podés encontrar los ${currencyText} invertidos en tu portfolio.`
    : `Ya podés encontrar los ${currencyText} disponibles para usar en tu portfolio.`;

  const goBackPortfolio = () => {
    if (isDesktop) dispatch(closeRightModal());

    navigate(mainRoutes.portfolio);
  };

  const goBackHome = () => {
    if (isDesktop) dispatch(closeRightModal());

    navigate(mainRoutes.home);
  };

  useEffect(() => {
    ampli.fciOrderSuccess({
      amount,
      side: type,
      currency,
      ticker: instrument_code,
    });

    GoogleTagManager.dataLayer({
      dataLayer: {
        event: "fci_order_success",
        ticker: instrument_code,
      },
    });

    if (order.long_ticker === CocosFunds.RMA) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.fbq("track", "cocorma_fci_order_success");

      trackFbEvent(FbEvents.COCORMA_ORDER_SUCCESS);
    }
  }, []);

  return (
    <BasicWrapper
      navigationHeaderProps={{
        title: "¡Listo!",
        onClick: onClose,
      }}
      primaryButtonProps={{
        children: "Ir a portfolio",
        onClick: goBackPortfolio,
      }}
      secondaryButtonProps={{
        children: "Volver al inicio",
        onClick: goBackHome,
      }}
    >
      <div className={styles.imageWrapper}>
        <Success />
      </div>
      <Text
        variant={TextVariants.RegularText}
        color="var(--slate800)"
        className={styles.successText}
      >
        {text}
      </Text>
      <div className={styles.amountContainer}>
        <Text variant={TextVariants.RegularText} color="var(--slate800)">
          {orderText}
        </Text>
        <Amount amount={amount ?? 0} currency={currency} />
      </div>
    </BasicWrapper>
  );
};

export default FundSuccess;
